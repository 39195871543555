<div class="container">
  <ng-container *ngFor="let area of areas">
    <div class="h5 area-title">{{ area.title }}</div>
    <mat-card>
      <mat-nav-list>
        <ng-container *ngFor="let setting of area.settings; last as isLast">
          <ng-container *ngIf="setting.link; else expansionPanel">
            <a mat-list-item [routerLink]="setting.link">
              {{ setting.title }}
              <mat-icon class="nav-arrow">arrow_right</mat-icon>
            </a>
          </ng-container>
          <ng-template #expansionPanel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ setting.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-settings-template [component]="setting.component"></app-settings-template>
            </mat-expansion-panel>
          </ng-template>
          <mat-divider *ngIf="!isLast"></mat-divider>
        </ng-container>
      </mat-nav-list>
    </mat-card>
  </ng-container>
</div>
