<div class="button-container">
   <div class="right-buttons">
      <button
         mat-icon-button
         class="download-button"
         color="accent"
         *ngIf="!hideExport"
         [matMenuTriggerFor]="csvMenu"
         matTooltip="Export as .csv"
      >
         <mat-icon svgIcon="file-download-outline"></mat-icon>
      </button>
      <mat-menu #csvMenu="matMenu">
         <button mat-menu-item (click)="export(false)">Export All</button>
         <button mat-menu-item (click)="export(true)">Export Filtered</button>
      </mat-menu>
      <app-edit-columns
         *ngIf="!filterBar?.hideEditColumns"
         [columns]="columns"
         [displayedColumns]="displayedColumns"
         (columnsUpdated)="setDisplayedColumns($event)"
      ></app-edit-columns>
   </div>
</div>
<div class="filter-bar">
   <app-filter-bar [config]="filterBar" [filter]="filter$ | async"></app-filter-bar>
</div>
