import { Component, OnInit } from '@angular/core';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';

@Component({
   selector: 'app-quick-actions-widget',
   templateUrl: './quick-actions-widget.component.html',
   styleUrls: ['./quick-actions-widget.component.scss', '../widget/widget.component.scss'],
})
export class QuickActionsWidgetComponent implements OnInit {
   constructor(private dashboardFacade: DashboardFacade) {}

   ngOnInit(): void {}

   newAction() {
      this.dashboardFacade.newAction();
   }

   newRock() {
      this.dashboardFacade.newRock();
   }
}
