import { OverviewService } from '@app/overview/overview.service';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { Observable, combineLatest, of } from 'rxjs';
import { Overview } from '@entities/overview';
import { User } from '@entities/user';
import { Injectable } from '@angular/core';
import { Dashboard } from '@entities/dashboard';
import { DashboardService } from '../services/dashboard.service';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { DashboardActions } from './dashboard.actions';
import { dashboardSelectors } from './dashboard.state';
import { EvolveFacade } from '@app/evolve/state/evolve.facade';
import { Rock } from '@entities/rock';
import { map } from 'rxjs/operators';
import { Status } from '@entities/enums/status';
import { RockStatus } from '@entities/enums/rock-status';
import { Router } from '@angular/router';
import { appRoutesNames } from '@app/app.routes.names';
import { evolveRouteNames } from '@app/evolve/evolve.routes.names';
import { Task } from '@entities/task';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { TaskDocumentationViewModel } from '@app/documentation/state/documentation.models';
import { documentationRouteNames } from '@app/documentation/documentation.routes.names';
import {
   getDepartmentFunctions,
   getBusinessUnits,
   getDepartments,
   getTasks,
} from '@app/org-builder/state/organization.state';
import { TeamMember } from '@entities/team-member';
import { UserService } from '@app/user-org/services/user.service';
import { adminActions } from '@app/admin/state/admin.actions';
import { getTeamMembers } from '@app/team/state/team.state';
import { RockAction } from '@entities/rock-action';
import { ActionItem } from '@entities/action-item';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { WidgetEntities } from './widget-components';
import { Goal } from '@entities/goal';
import * as moment from 'moment';

export interface TargetDateItem {
   targetDate: string;
}
@Injectable()
export class DashboardFacade {
   overview$: Observable<Overview>;
   user$: Observable<User>;
   dashboards$: Observable<Dashboard[]> = this.store.pipe(select(dashboardSelectors.getDashboards));
   selectedDashboard$: Observable<Dashboard> = this.store.pipe(
      select(dashboardSelectors.getSelectedDashboard)
   );
   actionItemCount$: Observable<number>;
   rocks$: Observable<Rock[]>;
   orgId: string;
   assignedTasks$: Observable<TaskDocumentationViewModel[]>;
   assignedActions$: Observable<ActionItem[]>;
   assignedMilestones$: Observable<RockAction[]>;
   assignedActionsAndMilestones$: Observable<any[]>;
   businessUnits$ = this.store.pipe(select(getBusinessUnits));
   departments$ = this.store.pipe(select(getDepartments));
   departmentFunctions$ = this.store.pipe(select(getDepartmentFunctions));
   tasks$ = this.store.pipe(select(getTasks));
   teamMembers$ = this.store.pipe(select(getTeamMembers));
   goals$: Observable<Goal[]>;
   filters$ = this.store.pipe(select(dashboardSelectors.getDashboardFilters));

   constructor(
      private overviewService: OverviewService,
      private userOrgFacade: UserOrgFacade,
      private dashboardService: DashboardService,
      private store: Store<State>,
      private evolveFacade: EvolveFacade,
      private router: Router,
      private documentationFacade: DocumentationFacade,
      private userService: UserService
   ) {
      this.overview$ = this.overviewService.overview$;
      this.user$ = this.userOrgFacade.currentUser$;
      this.userService.entities$.subscribe((users) => {
         this.store.dispatch(adminActions.UsersUpdated({ users }));
      });
      combineLatest([this.dashboardService.entities$, this.user$]).subscribe(
         ([dashboards, user]) => {
            const filteredDashboards = dashboards.filter((d) => d.userId == user?.id || d.sharable);
            this.store.dispatch(
               DashboardActions.DashboardsUpdated({ dashboards: filteredDashboards })
            );
         }
      );
      this.actionItemCount$ = combineLatest([this.evolveFacade.actionItems$, this.user$]).pipe(
         map(([actionItems, user]) => {
            if (user) {
               return actionItems.filter(
                  (actionItem) =>
                     actionItem.status != Status.Completed &&
                     (!user.teamMemberId || actionItem.assigneeId == user.teamMemberId)
               ).length;
            } else {
               return 0;
            }
         })
      );
      this.rocks$ = this.evolveFacade.rocks$;
      this.goals$ = this.evolveFacade.goals$;
      this.userOrgFacade.selectedOrgId$.subscribe((orgId) => {
         this.orgId = orgId;
      });
      this.assignedTasks$ = combineLatest([
         this.documentationFacade.tasksViewModel$,
         this.user$,
      ]).pipe(
         map(([taskViewModels, user]) => {
            const assignedTasks = taskViewModels.filter(
               (viewModel) =>
                  user &&
                  viewModel.task.assignees &&
                  viewModel.task.assignees.includes(user.teamMemberId)
            );
            return assignedTasks.sort((a, b) => a.task.name.localeCompare(b.task.name));
         })
      );
      this.assignedActions$ = combineLatest([this.evolveFacade.actionItems$, this.user$]).pipe(
         map(([actionItems, user]) =>
            actionItems
               .filter((i) => i.status !== Status.Completed && i.assigneeId === user.teamMemberId)
               .sort((a, b) => (a.targetDate || '').localeCompare(b.targetDate))
         )
      );
      this.assignedMilestones$ = combineLatest([this.evolveFacade.rocks$, this.user$]).pipe(
         map(([rocks, user]) =>
            rocks
               .map((rock) =>
                  rock.status !== RockStatus.Completed && rock.actions && rock.actions.length > 0
                     ? rock.actions
                          .filter(
                             (action) =>
                                !action.completed && action.assigneeId === user.teamMemberId
                          )
                          .map((a) => ({ ...a, rockId: rock.id }))
                     : []
               )
               .reduce((a, b) => a.concat(b), [])
               .sort((a, b) => (a.targetDate || '').localeCompare(b.targetDate))
         )
      );
      this.assignedActionsAndMilestones$ = combineLatest([
         this.assignedActions$,
         this.assignedMilestones$,
         this.user$,
      ]).pipe(
         map(([actions, milestones, user]) => {
            const items = [...actions, ...milestones];
            return items.sort((a, b) => (a.targetDate || '').localeCompare(b.targetDate));
         })
      );
   }

   saveDashboard(dashboard: Dashboard) {
      this.store.dispatch(DashboardActions.SaveDashboard({ dashboard }));
   }

   selectDashboard(dashboardId: string) {
      this.store.dispatch(DashboardActions.SelectDashboard({ dashboardId }));
   }

   deleteDashboard(dashboard: Dashboard) {
      this.store.dispatch(DashboardActions.DeleteDashboard({ dashboard }));
   }

   newAction() {
      this.evolveFacade.addActionItem();
   }

   newRock() {
      this.evolveFacade.addRock();
   }

   goToActionItems() {
      this.router.navigate([
         appRoutesNames.ORGANIZATION,
         this.orgId,
         appRoutesNames.EVOLVE,
         evolveRouteNames.ISSUES,
      ]);
   }

   goToRocks(filter?: FilterValues) {
      if (filter) {
         this.evolveFacade.updateRocksFilter(filter);
      }
      this.router.navigate([
         appRoutesNames.ORGANIZATION,
         this.orgId,
         appRoutesNames.EVOLVE,
         evolveRouteNames.ROCKS,
      ]);
   }

   goToRock(rockId: string) {
      const currentRoute = `${appRoutesNames.ORGANIZATION}/${this.orgId}/${appRoutesNames.DASHBOARD}`;
      this.router.navigate(
         [
            appRoutesNames.ORGANIZATION,
            this.orgId,
            appRoutesNames.EVOLVE,
            evolveRouteNames.ROCKS,
            rockId,
         ],
         { queryParams: { from: currentRoute } }
      );
   }

   goToActionItem(actionItemId: string) {
      this.router.navigate([
         appRoutesNames.ORGANIZATION,
         this.orgId,
         appRoutesNames.EVOLVE,
         evolveRouteNames.ISSUES,
         actionItemId,
      ]);
   }

   goToTask(task: Task) {
      this.router.navigate([
         appRoutesNames.ORGANIZATION,
         this.orgId,
         appRoutesNames.DOCUMENTATION,
         documentationRouteNames.TASKS,
         task.id,
      ]);
   }

   goToDocumentation(filter?: FilterValues) {
      if (filter) {
         this.documentationFacade.updateFunctionsFilter(filter);
      }
      this.router.navigate([appRoutesNames.ORGANIZATION, this.orgId, appRoutesNames.DOCUMENTATION]);
   }

   goToPerformance() {
      this.router.navigate([appRoutesNames.ORGANIZATION, this.orgId, appRoutesNames.PERFORMANCE]);
   }

   linkTeamMember(teamMember: TeamMember) {
      this.store.dispatch(DashboardActions.LinkTeamMember({ teamMember }));
   }

   getDataSource(entity: WidgetEntities): Observable<any[]> {
      switch (entity) {
         case WidgetEntities.Actions:
            return this.evolveFacade.actionItems$;
         case WidgetEntities['Business Units']:
            return this.businessUnits$;
         case WidgetEntities.Departments:
            return this.departments$;
         case WidgetEntities.Functions:
            return this.departmentFunctions$;
         case WidgetEntities.Milestones:
            return this.evolveFacade.rocks$.pipe(
               map((rocks) => {
                  const data = rocks.flatMap((r) =>
                     r.actions && r.actions.length > 0
                        ? r.actions.map((m) => ({
                             ...m,
                             completed: m.completed ? 1 : 0,
                             rockId: r.id,
                             rockName: r.name,
                             quarter: r.quarter,
                             departmentId: r.departmentId,
                             rockStatus: r.status,
                          }))
                        : []
                  );
                  return data;
               })
            );
         case WidgetEntities.Rocks:
            return this.evolveFacade.rocks$;
         case WidgetEntities.Tasks:
            return this.tasks$;
         case WidgetEntities['Team Members']:
            return this.teamMembers$;
         default:
            return of([]);
      }
   }

   saveWidgetData(id: string, data: any) {
      this.store.dispatch(DashboardActions.SaveWidgetData({ id, data }));
   }

   filterByTargetDate<T extends TargetDateItem>(data: Array<T>, filter: string) {
      const today = moment().startOf('day');
      data = data.filter((item) => {
         const targetDate = moment(item.targetDate);
         switch (filter) {
            case 'overdue':
               return targetDate.isBefore(today);
            case 'today':
               return targetDate.dayOfYear() === today.dayOfYear();
            case '7days':
               const sevenDays = moment().add(7, 'days');
               return targetDate.isSameOrAfter(today) && targetDate.isSameOrBefore(sevenDays);
            case '30days':
               const thirtyDays = moment().add(30, 'days');
               return targetDate.isSameOrAfter(today) && targetDate.isSameOrBefore(thirtyDays);
            case '90days':
               const ninetyDays = moment().add(90, 'days');
               return targetDate.isSameOrAfter(today) && targetDate.isSameOrBefore(ninetyDays);
            case 'week':
               return targetDate.isSame(today, 'week');
            case 'month':
               return targetDate.isSame(today, 'month');
            case 'quarter':
               return targetDate.isSame(today, 'quarter');
            default:
               return true;
         }
      });
      return data;
   }

   setWidgetFilter(widgetId: string, filter: FilterValues) {
      this.store.dispatch(DashboardActions.SetWidgetFilter({ widgetId, filter }));
   }
}
