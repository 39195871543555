<h3 mat-dialog-title>Assign task to team member</h3>
<form [formGroup]="form">
  <div mat-dialog-content>
    <p>Task: {{ task.name }}</p>
    <div
      class="team-member-row"
      formArrayName="teamMembers"
      *ngFor="let control of teamMemberControls; let i = index"
    >
      <app-team-member-autocomplete
        (optionSelected)="setTeamMember($event, i)"
        placeholder="Team Member"
        [showClear]="false"
      ></app-team-member-autocomplete>
      <button
        mat-icon-button
        class="remove-button"
        (click)="removeTeamMember(i)"
        matTooltip="Remove Team Member"
        *ngIf="teamMemberControls.length > 1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <button mat-button color="primary" (click)="addTeamMember()">
      <mat-icon>add</mat-icon> Assign another team member
    </button>
  </div>
  <div mat-dialog-actions>
    <div class="button-container">
      <button
        class="assign-button"
        mat-raised-button
        color="primary"
        (click)="assign()"
        [disabled]="!form.valid"
      >
        Assign
      </button>
      <button mat-raised-button class="cancel-button" (click)="close()">Cancel</button>
    </div>
  </div>
</form>
