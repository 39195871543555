import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import {
   Action,
   createAction,
   createFeatureSelector,
   createReducer,
   createSelector,
   on,
   props,
   select,
   Store,
} from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';
import * as AuthActions from '@app/auth/state/auth.actions';

export interface State {
   routes: string[];
   active: number;
}

const getHistoryState = createFeatureSelector<State>('history');
export const getHistoryRoutes = createSelector(getHistoryState, (state) => state.routes);
export const getHistoryActive = createSelector(getHistoryState, (state) => state.active);

export const historyInitialState: State = {
   routes: [],
   active: -1,
};

export const goBack = createAction('[History] Go back');
export const navigateTo = createAction('[History] Navigate to route', props<{ url: string }>());

export function reducer(state: State | undefined, action: Action) {
   return createReducer(
      historyInitialState,
      on(routerNavigatedAction, (state, action) => {
         const url = action.payload.event.url;
         const index = state.routes.indexOf(url);
         if (index >= 0) {
            return {
               ...state,
               active: index,
            };
         } else {
            const routes = [...state.routes.slice(0, state.active + 1), url];
            return {
               ...state,
               routes,
               active: routes.length - 1,
            };
         }
      }),
      on(AuthActions.AuthUserUpdated, (state, { authUser }) => {
         return authUser ? state : historyInitialState;
      })
   )(state, action);
}

@Injectable()
export class HistoryEffects {
   goBack$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(goBack),
            withLatestFrom(
               this.store.pipe(select(getHistoryRoutes)),
               this.store.pipe(select(getHistoryActive))
            ),
            tap(([action, routes, active]) => {
               const url = routes[active - 1];
               if (url) {
                  this.router.navigateByUrl(url);
               } else {
                  history.back();
               }
            })
         ),
      { dispatch: false }
   );

   constructor(private actions$: Actions, private store: Store, private router: Router) {}
}
