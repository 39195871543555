import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   OnChanges,
   SimpleChanges,
   OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AdminFacade } from '@app/admin/state/admin.facade';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { AuthUser } from '@entities/auth-user';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Organization } from '@entities/organization';

@Component({
   selector: 'app-org-info',
   templateUrl: './org-info.component.html',
   styleUrls: ['./org-info.component.scss'],
})
export class OrgInfoComponent implements OnInit, OnChanges, OnDestroy {
   @Input() signup = false;
   @Input() primaryContact: AuthUser;
   @Output() nextClicked = new EventEmitter<any>();

   orgForm: UntypedFormGroup;
   logo: any;
   logoData;
   fileTooLarge = false;
   org: Organization;

   private destroyed$ = new Subject<void>();

   constructor(private userOrgFacade: UserOrgFacade) {
      this.orgForm = new UntypedFormGroup({
         organizationId: new UntypedFormControl(),
         name: new UntypedFormControl(),
         primaryContact: new UntypedFormGroup({
            firstName: new UntypedFormControl(),
            lastName: new UntypedFormControl(),
            email: new UntypedFormControl(null, [Validators.email]),
         }),
         logo: new UntypedFormControl(),
      });
   }

   ngOnInit() {
      this.userOrgFacade.selectedOrg$.pipe(takeUntil(this.destroyed$)).subscribe((org) => {
         if (!this.signup && org) {
            this.orgForm.patchValue(org);
            this.orgForm.markAsPristine();
            this.logoData = org.logo;
            this.org = org;
         }
      });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['primaryContact'] && this.signup) {
         this.orgForm.get('primaryContact').patchValue(this.primaryContact);
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   save() {
      const org = {
         ...this.org,
         ...this.orgForm.value,
      };
      if (this.signup) {
         this.nextClicked.emit(org);
      } else {
         this.userOrgFacade.saveOrgInfo(org);
      }
   }

   setLogo(fileInputEvent) {
      this.fileTooLarge = false;
      this.logo = fileInputEvent.target.files[0];
      this.orgForm.markAsDirty();
      const reader = new FileReader();
      reader.onload = () => {
         if (reader.result.toString().length < 1048487) {
            this.logoData = reader.result;
            this.orgForm.get('logo').setValue(reader.result);
         } else {
            this.fileTooLarge = true;
         }
      };
      reader.readAsDataURL(this.logo);
   }
}
