import { createSelector, createFeatureSelector, Action } from '@ngrx/store';

export interface State {
   sidenavOpen: boolean;
   helpOpen: boolean;
   recentOrgs?: string[];
}

export const getLayoutState = createFeatureSelector<State>('layout');
export const getSidenavOpen = createSelector(getLayoutState, (state) => state.sidenavOpen);
export const getHelpOpen = createSelector(getLayoutState, (state) => state.helpOpen);

export const getRecentOrgs = createSelector(getLayoutState, (state) => state.recentOrgs);
