import { createAction, props } from '@ngrx/store';
import { Goal } from '@entities/goal';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';

const GoalsUpdated = createAction('[Goals] goals updated', props<{ goals: Goal[] }>());
const AddGoal = createAction('[Goals] Add goal', props<{ featureFlag: boolean }>());
const EditGoal = createAction('[Goals] Edit goal', props<{ goal: Goal; featureFlag: boolean }>());
const SelectGoal = createAction('[Goals] Select goal', props<{ goalId: string }>());
const SaveGoal = createAction('[Goals] Save goal', props<{ goal: Goal; featureFlag: boolean }>());
const DeleteGoal = createAction('[Goals] Delete goal', props<{ goal: Goal }>());
const HideEditGoal = createAction('[Goals] Hide edit goal', props<{ goalId: string }>());
const SetOrganizationGoalsFilter = createAction(
   '[Goals] Set organization goals filter',
   props<{ filterValues: FilterValues }>()
);
const SetBusinessUnitGoalsFilter = createAction(
   '[Goals] Set business unit goals filter',
   props<{ filterValues: FilterValues }>()
);
const SetDepartmentGoalsFilter = createAction(
   '[Goals] Set department goals filter',
   props<{ filterValues: FilterValues }>()
);
const SetGoalsActiveTab = createAction(
   '[Goal] Set goals active tab',
   props<{ activeTab: number }>()
);

export const GoalActions = {
   GoalsUpdated,
   AddGoal,
   EditGoal,
   SelectGoal,
   SaveGoal,
   DeleteGoal,
   HideEditGoal,
   SetOrganizationGoalsFilter,
   SetBusinessUnitGoalsFilter,
   SetDepartmentGoalsFilter,
   SetGoalsActiveTab,
};
