import { Department } from './department';
import { TeamMember } from './team-member';
import { Task } from './task';
import { DocumentationStatus } from './enums/documentation-status';
import { Benchmark } from './benchmark';
import { DepartmentFunctionFlags } from './enums/department-function-flags';
import { Rock } from './rock';
import { OrgEntity } from './org-entity';
import { PerformanceRating } from './enums/performance-rating';

export class DepartmentFunction implements OrgEntity {
   id: string;
   organizationId: string;
   name: string;
   purpose: string;
   description: string;
   departmentId: string;
   department?: Department;
   leadId: string;
   lead?: TeamMember;
   tasks: Task[];
   documentationStatus: DocumentationStatus;
   documentationUpdated: Date;
   kpi: string;
   benchmark: string;
   benchmarks: Benchmark[];
   rating: PerformanceRating;
   flag: DepartmentFunctionFlags;
   rocks: Rock[];
   order: number;
   policies: string;
   ratingNotes: string;
}
