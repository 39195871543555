import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { take, map, withLatestFrom } from 'rxjs/operators';
import { User } from '@entities/user';
import { UserRole } from '@entities/enums/user-role';
import { UserAccessType } from '@entities/enums/user-access-type';
import { State } from '@app/app.state';
import { AppAreas } from '@entities/enums/app-areas';
import { getCurrentUser } from '@app/user-org/state/user-org.selectors';
import { ProductType } from '@entities/enums/product-type';
import { appRoutesNames } from '@app/app.routes.names';
import { getAuthUser } from '@app/auth/state/auth.state';
import { combineLatest } from 'rxjs';
import { AccessLevel } from '@entities/enums/access-level';
import { PermissionsFacade } from '@app/shared/state/permissions.facade';
import { ModuleAccess } from '@entities/permission-group';

@Injectable({
   providedIn: 'root',
})
export class RoleGuard {
   constructor(
      private store: Store<State>,
      private router: Router,
      private permissionsFacade: PermissionsFacade
   ) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return combineLatest([
         this.store.pipe(select(getCurrentUser)),
         this.store.pipe(select(getAuthUser)),
         this.permissionsFacade.usePermissionGroups$,
         this.permissionsFacade.currentUserPermissionGroup$,
      ]).pipe(
         map(([user, authUser, usePermissionGroups, permissionGroup]) => {
            if (authUser && authUser.isInternalUser) {
               return true;
            }
            let canActivate: boolean;
            if (!user) {
               canActivate = false;
            } else if (usePermissionGroups) {
               const area: AppAreas = route.data.area;
               switch (area) {
                  case AppAreas.Dashboard:
                     canActivate = true;
                  case AppAreas.Admin:
                     canActivate = permissionGroup?.isAdmin;
                  default:
                     const access: ModuleAccess = permissionGroup?.accessMap[area];
                     canActivate = access?.view !== AccessLevel.NONE;
               }
            } else {
               const roles: UserRole[] = route.data['roles'];
               const products = route.data['products'];
               const rolesValid = roles ? roles.includes(user.role) : false;
               const productsValid = !!this._canView(user, products);
               canActivate = rolesValid && productsValid;
            }
            if (!canActivate) {
               this.router.navigate([appRoutesNames.UNAUTHORIZED]);
            }
            return canActivate;
         })
      );
   }

   canView(products: ProductType[], area: AppAreas) {
      return combineLatest([
         this.store.select(getCurrentUser),
         this.permissionsFacade.usePermissionGroups$,
         this.permissionsFacade.currentUserPermissionGroup$,
      ]).pipe(
         map(([user, usePermissionGroups, permissionGroup]) => {
            if (usePermissionGroups) {
               if (permissionGroup) {
                  const accessLevel = permissionGroup.accessMap[area]?.view;
                  return accessLevel !== AccessLevel.NONE;
               } else {
                  return false;
               }
            } else {
               if (user) {
                  return this._canView(user, products, area);
               }
            }
         })
      );
   }

   canEdit(products?: ProductType[], area?: AppAreas, assigneeIds?: string[]) {
      return combineLatest([
         this.store.select(getCurrentUser),
         this.permissionsFacade.usePermissionGroups$,
         this.permissionsFacade.currentUserPermissionGroup$,
         this.permissionsFacade.currentUserTeamMemberId$,
      ]).pipe(
         map(([user, usePermissionGroups, permissionGroup, teamMemberId]) => {
            if (usePermissionGroups) {
               const permission = permissionGroup?.accessMap[area]?.edit;
               switch (permission) {
                  case AccessLevel.ALL:
                     return true;
                  case AccessLevel.ASSIGNED:
                     return assigneeIds?.includes(teamMemberId);
                  case AccessLevel.NONE:
                  default:
                     return false;
               }
            } else {
               if (user) {
                  return (
                     this._canView(user, products) &&
                     (user.role == UserRole.User || user.role == UserRole.Admin) &&
                     user.product !== ProductType.Reference
                  );
               }
            }
         })
      );
   }

   private _canView(user: User, products?: ProductType[], area?: AppAreas) {
      if (user && (user.product || user.products)) {
         if (area && area === AppAreas.Admin) {
            return user.isAdmin || user.role === UserRole.Admin;
         }
         const product = user.product || user.products[0];
         const productMatches = products ? user.products?.some((p) => products.includes(p)) : false;
         return (
            product === ProductType.Complete || product === ProductType.Reference || productMatches
         );
      } else {
         return false;
      }
   }
}
