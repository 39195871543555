import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@entities/user';

@Component({
   selector: 'app-unassigned-users-warning',
   templateUrl: './unassigned-users-warning.component.html',
   styleUrls: ['./unassigned-users-warning.component.scss'],
})
export class UnassignedUsersWarningComponent {
   @Input() users: User[] = [];
   @Output() assignUser = new EventEmitter<User>();

   showList = false;
}

