import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSearch from './search.reducer';

export const selectSearchState = createFeatureSelector<fromSearch.State>(
   fromSearch.searchFeatureKey
);

export const getSearchResults = createSelector(selectSearchState, (state) => state.results);
export const getRecentSearchTerms = createSelector(
   selectSearchState,
   (state) => state.requestHistory
);
export const getSearchTerm = createSelector(selectSearchState, (state) => state.term);
