import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromSearch from './state/search.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SearchEffects } from './state/search.effects';
import { SearchService } from './services/search.service';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { AngularMaterialModule } from '@app/shared/material/angular-material.module';
import { SearchFacade } from './state/search.facade';
import { FormsModule } from '@angular/forms';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
   declarations: [SearchInputComponent, SearchResultsComponent],
   imports: [
      CommonModule,
      AngularMaterialModule,
      FormsModule,
      StoreModule.forFeature(fromSearch.searchFeatureKey, fromSearch.reducer),
      EffectsModule.forFeature([SearchEffects]),
      SharedModule,
   ],
   providers: [SearchService, SearchFacade],
   exports: [SearchInputComponent],
})
export class SearchModule {}
