<div class="container">
   <div class="logo">
      <img src="assets/tricolor-horizontal.svg" class="logo-image" />
   </div>
   <mat-card>
      <mat-card-title>Welcome to Trellis Tools!</mat-card-title>
      <mat-card-subtitle>
         Please provide the following information to create an account
      </mat-card-subtitle>
      <mat-card-content>
         <form #loginForm="ngForm" (ngSubmit)="signUp()">
            <mat-form-field>
               <input
                  matInput
                  type="text"
                  placeholder="First Name"
                  [(ngModel)]="firstName"
                  name="firstName"
                  required
               />
            </mat-form-field>
            <mat-form-field>
               <input
                  matInput
                  type="text"
                  placeholder="Last Name"
                  [(ngModel)]="lastName"
                  name="lastName"
                  required
               />
            </mat-form-field>
            <mat-form-field>
               <input
                  matInput
                  type="email"
                  placeholder="Email"
                  [(ngModel)]="email"
                  name="email"
                  required
                  email
               />
            </mat-form-field>
            <mat-form-field>
               <input
                  matInput
                  minlength="6"
                  type="password"
                  placeholder="Password"
                  [(ngModel)]="password"
                  name="password"
                  required
               />
               <mat-error *ngIf="password && password.length < 6">
                  Password must be at least 6 characters long.
               </mat-error>
            </mat-form-field>
            <mat-checkbox color="primary" [(ngModel)]="termsAccepted" name="termsAccepted">
               I agree to the
               <a href="https://trellistools.com/terms-of-service" target="__blank">
                  Terms of Service
               </a>
               and
               <a href="https://trellistools.com/privacy-policy" target="__blank2">
                  Privacy Policy
               </a>
            </mat-checkbox>
            <div class="login">
               <a routerLink="/login">Already have an account? Log in here.</a>
            </div>
            <div class="loginButtons">
               <button
                  type="submit"
                  mat-raised-button
                  color="primary"
                  [disabled]="!loginForm.valid || !termsAccepted"
               >
                  Sign Up
               </button>
            </div>
         </form>
      </mat-card-content>
   </mat-card>
</div>
