<!-- <mat-tab-group>
   <mat-tab label="Feature Flags">
      <app-feature-flags></app-feature-flags>
   </mat-tab>
   <mat-tab label="User Accounts">
      <app-auth-users-internal></app-auth-users-internal>
   </mat-tab>
   <mat-tab label="Organizations">
      <app-organizations-internal></app-organizations-internal>
   </mat-tab>
</mat-tab-group> -->

<nav mat-tab-nav-bar>
   <a
      mat-tab-link
      *ngFor="let route of routes"
      [routerLink]="route.path"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
   >
      {{ route.label }}
   </a>
</nav>
<router-outlet></router-outlet>
