import { Component, OnInit } from '@angular/core';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { TeamMember } from '@entities/team-member';
import { ActionItemType } from '@entities/enums/action-item-type';
import { Department } from '@entities/department';
import { ActionItem } from '@entities/action-item';

import { FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { WidgetInstance } from '../widget/widget-instance';

@Component({
   selector: 'app-assigned-actions-widget',
   templateUrl: './assigned-actions-widget.component.html',
   styleUrls: ['./assigned-actions-widget.component.scss'],
})
export class AssignedActionsWidgetComponent extends WidgetInstance implements OnInit {
   assignedActions$: Observable<ActionItem[]>;
   data$: Observable<any[]>;
   teamMemberLinked$: Observable<boolean>;
   filter$ = this.facade.filters$.pipe(
      map((filters) => {
         const filter = filters[this.id];
         return filter || {};
      })
   );
   displayedColumns = ['name', 'type', 'targetDate'];
   teamMember: TeamMember;
   departments: Department[] = [];

   actionType = ActionItemType;

   filterConfig: FilterBarConfig = {
      businessUnits: true,
      departments: true,
      targetDate: true,
      hideSearch: true,
   };

   private destroyed$ = new Subject<void>();

   constructor(private facade: DashboardFacade) {
      super();
   }

   ngOnInit(): void {
      this.assignedActions$ = this.facade.assignedActions$;
      this.teamMemberLinked$ = this.facade.user$.pipe(
         map((user) => user && user.teamMemberId != null)
      );
      this.facade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.data$ = combineLatest([this.assignedActions$, this.filter$]).pipe(
         map(([actions, filter]) => {
            let filtered = [...actions];
            if (filter.departments && filter.departments.length > 0) {
               filtered = filtered.filter((action) =>
                  filter.departments.some((dept) => dept.id === action.departmentId)
               );
            } else if (filter.businessUnits && filter.businessUnits.length > 0) {
               const matchingDepartments = this.departments.filter((dept) =>
                  filter.businessUnits.some((bu) => bu.id === dept.businessUnitId)
               );
               filtered = filtered.filter((action) =>
                  matchingDepartments.some((dept) => dept.id === action.departmentId)
               );
            }
            if (filter.targetDate) {
               filtered = this.facade.filterByTargetDate<ActionItem>(filtered, filter.targetDate);
            }

            return filtered;
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   goToItem(row: any) {
      this.facade.goToActionItem(row.id);
   }

   setLinkedTeamMember(event: MatAutocompleteSelectedEvent) {
      if (event && event.option && event.option.value) {
         this.teamMember = event.option.value;
      } else {
         this.teamMember = null;
      }
   }

   linkTeamMember() {
      this.facade.linkTeamMember(this.teamMember);
   }

   updateFilter(filter) {
      this.facade.setWidgetFilter(this.id, filter);
   }
}
