import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TeamModule } from '@app/team/team.module';

import { EvolveComponent } from './components/evolve/evolve.component';
import { GoalsComponent } from './components/goals/goals.component';
import { RockDetailsComponent } from './components/rock-details/rock-details.component';
import { RocksListComponent } from './components/rocks-list/rocks-list.component';

import { EvolveFacade } from './state/evolve.facade';

import { reducers, effects } from './state/evolve.state';
import { GoalService } from './services/goal.service';
import { RockService } from './services/rock.service';
import { SharedModule } from '@app/shared/shared.module';
import { ActionItemListComponent } from './components/action-item-list/action-item-list.component';
import { ActionItemService } from './services/action-item.service';
import { ActionItemEditComponent } from './components/action-item-edit/action-item-edit.component';
import { RockInfoComponent } from './components/rock-info/rock-info.component';
import { RockActionsComponent } from './components/rock-actions/rock-actions.component';
import { GoalDetailsComponent } from './components/goal-details/goal-details.component';
import { RocksRoadmapComponent } from './components/rocks-roadmap/rocks-roadmap.component';
import { GoalListComponent } from './components/goal-list/goal-list.component';
import { LinkedRocksComponent } from './components/linked-rocks/linked-rocks.component';
import { LinkedGoalsComponent } from './components/linked-goals/linked-goals.component';

@NgModule({
   declarations: [
      EvolveComponent,
      RocksListComponent,
      RockDetailsComponent,
      GoalsComponent,
      ActionItemListComponent,
      ActionItemEditComponent,
      RockInfoComponent,
      RockActionsComponent,
      GoalDetailsComponent,
      RocksRoadmapComponent,
      GoalListComponent,
      LinkedRocksComponent,
      LinkedGoalsComponent,
   ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      StoreModule.forFeature('evolve', reducers),
      EffectsModule.forFeature(effects),
      TeamModule,
      SharedModule,
   ],
   providers: [EvolveFacade, GoalService, RockService, ActionItemService, DatePipe],
   exports: [RockInfoComponent, RockActionsComponent],
})
export class EvolveModule {}
