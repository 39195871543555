import { Injectable } from '@angular/core';
import { SearchRequest } from '@entities/search-request';
import { FunctionNames } from '@app/utilities/functionNames';
import { partition } from 'lodash';
import { FunctionsService } from '@app/shared/services/functions.service';

@Injectable({
   providedIn: 'root',
})
export class SearchService {
   constructor(private functions: FunctionsService) {}

   search(request: SearchRequest) {
      return this.functions.httpsCallable(FunctionNames.SEARCH)({ request });
   }

   getScore(
      result: any,
      term: string,
      name: (item: any) => string,
      id: (item: any) => string,
      scoreModifier: number
   ) {
      return {
         name: name(result),
         id: id(result),
         score:
            term.length / name(result).length +
            scoreModifier +
            (name(result)?.toLowerCase().startsWith(term?.toLowerCase()) ? 1 : 0),
         result,
      };
   }

   getResults(
      collection: any[],
      term,
      name: (item: any) => string,
      id: (item: any) => string,
      content: Array<(item: any) => string>
   ) {
      const [nameHit, nameMiss] = partition(collection, (x: any) =>
         name(x)?.toLowerCase().includes(term?.toLowerCase())
      );
      const contentHit = nameMiss.filter((x: any) =>
         content
            .map((f) => {
               const val = f(x);
               const hit = f(x)?.toLowerCase().includes(term.toLowerCase());
               return hit;
            })
            .reduce((agg, current) => agg || current, false)
      );
      const nameScores = nameHit.map((result) => this.getScore(result, term, name, id, 1));
      const contentScores = contentHit.map((result) => this.getScore(result, term, name, id, 0));
      const results = [...nameScores, ...contentScores];
      return results;
   }
}
