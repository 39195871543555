import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  Input,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'app-settings-template',
  template: `
    <div #viewRef></div>
  `
})
export class SettingsTemplateComponent implements OnInit {
  @Input() component: any;
  @ViewChild('viewRef', { static: true, read: ViewContainerRef }) viewRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    this.viewRef.clear();
    this.viewRef.createComponent(componentFactory);
  }
}
