import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
   selector: 'app-quarterpicker',
   templateUrl: './quarterpicker.component.html',
   styleUrls: ['./quarterpicker.component.scss'],
})
export class QuarterpickerComponent implements OnInit {
   @Input() formCtrl: UntypedFormControl;
   @Input() label: string;
   @Input() multiple = false;
   @Input() required = false;
   @Input() canEdit = true;

   quarters: string[] = [];
   startYear: number;
   endYear: number;

   constructor() {}

   ngOnInit() {
      if (!this.formCtrl) {
         this.formCtrl = new UntypedFormControl();
      }
      const today = moment();
      this.startYear = today.get('year');
      this.endYear = this.startYear + 5;
      if (this.formCtrl.value) {
         if (this.multiple) {
            const years: number[] = this.formCtrl.value
               .map((v) => +v.slice(0, 4))
               .filter((v) => !isNaN(v));
            this.startYear = Math.min(...years, this.startYear);
         } else {
            const formVal: string = this.formCtrl.value;
            const year = +formVal.slice(0, 4);
            if (!isNaN(year)) {
               this.startYear = Math.min(this.startYear, year);
            }
         }
      }

      this.generateQuarters();
   }

   ngOnChanges() {
      if (this.formCtrl) {
         if (this.canEdit) {
            this.formCtrl.enable();
         } else {
            this.formCtrl.disable();
         }
      }
   }

   generateQuarters() {
      let year = this.startYear;
      let quarter = 1;
      this.quarters = [];
      while (year <= this.endYear) {
         while (quarter <= 4) {
            this.quarters.push(`${year}Q${quarter}`);
            quarter++;
         }
         quarter = 1;
         year++;
      }
   }

   showEarlier() {
      this.startYear -= 5;
      this.generateQuarters();
   }

   showLater() {
      this.endYear += 5;
      this.generateQuarters();
   }
}
