<ng-container *ngIf="showEditor; else table">
   <form [formGroup]="form">
      <div class="widget-container">
         <mat-form-field>
            <input matInput placeholder="Name" formControlName="name" />
         </mat-form-field>
         <mat-form-field>
            <mat-label>Entity</mat-label>
            <mat-select formControlName="entity">
               <mat-option *ngFor="let key of entityKeys" [value]="key">
                  {{ widgetEntities[key] }}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <app-filter-bar [config]="getFilterConfig()" [filter]="filter"></app-filter-bar>
         <div class="buttons">
            <button type="button" mat-raised-button color="primary" (click)="save()">
               Save Changes
            </button>
            <button type="button" mat-stroked-button color="primary" (click)="cancel()">
               Cancel
            </button>
         </div>
      </div>
   </form>
</ng-container>
<ng-template #table>
   <div class="widget-container">
      <div class="title">
         <div>
            {{ data.name }}
         </div>
         <div class="right-buttons">
            <button
               mat-icon-button
               class="download-button"
               color="accent"
               matTooltip="Export as .csv"
               (click)="export()"
            >
               <mat-icon svgIcon="file-download-outline"></mat-icon>
            </button>
            <button mat-icon-button class="edit-button" color="primary" (click)="edit()">
               <mat-icon>edit</mat-icon>
            </button>
         </div>
      </div>
      <div class="scroll-container">
         <app-table [config]="tableConfig"></app-table>
      </div>
   </div>
</ng-template>
