import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { TeamFacade } from '@app/team/state/team.facade';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Role } from '@entities/role';

@Component({
  selector: 'app-assign-role',
  templateUrl: './assign-role.component.html',
  styleUrls: ['./assign-role.component.scss']
})
export class AssignRoleComponent implements OnInit {
  form: UntypedFormGroup;
  private teamFacade: TeamFacade;
  role: Role;

  constructor(@Inject(MAT_DIALOG_DATA) data: { facade: TeamFacade; role: Role }) {
    this.teamFacade = data.facade;
    this.role = data.role;
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      teamMembers: new UntypedFormArray([new UntypedFormControl()])
    });
  }

  controls() {
    return (<UntypedFormArray>this.form.get('teamMembers')).controls;
  }

  setTeamMember(event: MatAutocompleteSelectedEvent, index: number) {
    (<UntypedFormArray>this.form.get('teamMembers')).at(index).setValue(event.option.value);
  }

  addTeamMember() {
    (<UntypedFormArray>this.form.get('teamMembers')).push(new UntypedFormControl());
  }

  removeTeamMember(index: number) {
    (<UntypedFormArray>this.form.get('teamMembers')).removeAt(index);
    if (this.controls().length == 0) {
      this.addTeamMember();
    }
  }

  assign() {
    const formValue = this.form.value;
    this.teamFacade.saveRoleAssignments(this.role, formValue.teamMembers);
  }

  close() {
    this.teamFacade.closeDialog();
  }
}
