<div class="top-button-container">
   <button mat-icon-button color="accent" matTooltip="Print View" (click)="viewSummary()">
      <mat-icon svgIcon="file-document"></mat-icon>
   </button>
</div>
<form [formGroup]="form">
   <div class="overview-container">
      <div class="overview-cards">
         <div class="overview-row">
            <div class="overview-col">
               <mat-card>
                  <mat-card-title>Guiding Beliefs</mat-card-title>
                  <mat-card-subtitle>
                     What is the inspiration for the organization's existence?
                  </mat-card-subtitle>
                  <mat-card-content #editorContainer>
                     <ng-container *ngIf="canEdit$ | async; else noEditBeliefs">
                        <textarea
                           [froalaEditor]="editorConfigs[0]"
                           (froalaInit)="initFroala($event, 0)"
                           formControlName="beliefs"
                        ></textarea>
                     </ng-container>
                     <ng-template #noEditBeliefs>
                        <div class="no-edit" *ngIf="overview" [innerHTML]="overview.beliefs"></div>
                     </ng-template>
                  </mat-card-content>
               </mat-card>
            </div>
            <div class="overview-col">
               <mat-card>
                  <mat-card-title>Purpose</mat-card-title>
                  <mat-card-subtitle>
                     What is the impact or value provided by the organization?
                  </mat-card-subtitle>
                  <mat-card-content #editorContainer>
                     <ng-container *ngIf="canEdit$ | async; else noEditPurpose">
                        <textarea
                           [froalaEditor]="editorConfigs[1]"
                           (froalaInit)="initFroala($event, 1)"
                           formControlName="purpose"
                        ></textarea>
                     </ng-container>
                     <ng-template #noEditPurpose>
                        <div class="no-edit" *ngIf="overview" [innerHTML]="overview.purpose"></div>
                     </ng-template>
                  </mat-card-content>
               </mat-card>
            </div>
         </div>
         <div class="overview-row">
            <div class="overview-col">
               <mat-card>
                  <mat-card-title>Vision Statement</mat-card-title>
                  <mat-card-subtitle>
                     What does the organization look like when it is operating at its full
                     potential?
                  </mat-card-subtitle>
                  <mat-card-content #editorContainer>
                     <ng-container *ngIf="canEdit$ | async; else noEditVision">
                        <textarea
                           [froalaEditor]="editorConfigs[3]"
                           (froalaInit)="initFroala($event, 3)"
                           formControlName="visionStatement"
                        ></textarea>
                     </ng-container>
                     <ng-template #noEditVision>
                        <div
                           class="no-edit"
                           *ngIf="overview"
                           [innerHTML]="overview.visionStatement"
                        ></div>
                     </ng-template>
                  </mat-card-content>
               </mat-card>
            </div>
            <div class="overview-col">
               <mat-card>
                  <mat-card-title>Core Values</mat-card-title>
                  <mat-card-subtitle>
                     What are the principles that inform how the organization operates?
                  </mat-card-subtitle>
                  <mat-card-content #editorContainer>
                     <ng-container *ngIf="canEdit$ | async; else noEditValues">
                        <textarea
                           [froalaEditor]="editorConfigs[2]"
                           (froalaInit)="initFroala($event, 2)"
                           formControlName="coreValues"
                        ></textarea>
                     </ng-container>
                     <ng-template #noEditValues>
                        <div
                           class="no-edit"
                           *ngIf="overview"
                           [innerHTML]="overview.coreValues"
                        ></div>
                     </ng-template>
                  </mat-card-content>
               </mat-card>
            </div>
         </div>
      </div>
      <div class="save-button-row" *ngIf="canEdit$ | async">
         <div class="save-button-col">
            <div class="button-container">
               <button
                  id="submit-button"
                  mat-raised-button
                  type="button"
                  (click)="save()"
                  color="primary"
                  [disabled]="form.pristine || saving"
               >
                  <span *ngIf="saving">Saving</span>
                  <span *ngIf="!saving">Save</span>
               </button>
            </div>
         </div>
      </div>
   </div>
</form>
