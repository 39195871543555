import { Component, OnInit } from '@angular/core';
import { FirebaseAuthService } from '@app/auth/services/firebase-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;

  constructor(private authService: FirebaseAuthService) {}

  ngOnInit() {}

  login() {
    this.authService.login(this.email, this.password);
  }

  resetPassword() {
    this.authService.resetPassword();
  }
}
