import { Injectable, inject } from '@angular/core';
import { Functions, HttpsCallableOptions, httpsCallable } from '@angular/fire/functions';

@Injectable({
   providedIn: 'root',
})
export class FunctionsService {
   private functions = inject(Functions);

   constructor() {}

   httpsCallable(name: string, options?: HttpsCallableOptions) {
      return httpsCallable(this.functions, name, options);
   }
}

