import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Observable, combineLatest, Subject } from 'rxjs';
import { Organization } from '@entities/organization';

import { Router } from '@angular/router';
import { appRoutesNames } from '@app/app.routes.names';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { skip, takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
   MatLegacyDialog as MatDialog,
   MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { environment } from '@src/environments/environment';

@Component({
   selector: 'app-org-list',
   templateUrl: './org-list.component.html',
   styleUrls: ['./org-list.component.scss'],
})
export class OrgListComponent implements OnInit, OnDestroy {
   @ViewChild('noOrgsTemplateRef') noOrgsTemplateRef: TemplateRef<any>;
   noOrgsDialogRef: MatDialogRef<any>;

   orgs$: Observable<Organization[]>;
   newOrg = appRoutesNames.NEWORG;
   columns = 4;
   showSpinner = false;

   private destroyed$ = new Subject<void>();

   constructor(
      private userOrgFacade: UserOrgFacade,
      private router: Router,
      private breakpoint: BreakpointObserver,
      private dialog: MatDialog
   ) {}

   ngOnInit() {
      this.orgs$ = this.userOrgFacade.orgsForUser$;
      this.userOrgFacade.authUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
         if (user && user.orgs && user.orgs.length == 0) {
            this.noOrgs();
         }
      });
      this.userOrgFacade.setOrganization(null);
      this.breakpoint
         .observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge,
         ])
         .subscribe((state) => {
            if (state.breakpoints[Breakpoints.XSmall]) {
               this.columns = 1;
            } else if (state.breakpoints[Breakpoints.Small]) {
               this.columns = 2;
            } else if (state.breakpoints[Breakpoints.Medium]) {
               this.columns = 3;
            } else if (
               state.breakpoints[Breakpoints.Large] ||
               state.breakpoints[Breakpoints.XLarge]
            ) {
               this.columns = 4;
            }
         });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   goToOrg(org: Organization) {
      this.userOrgFacade.selectOrganization(org.organizationId);
      // this.router.navigate([
      //    appRoutesNames.ORGANIZATION,
      //    org.organizationId,
      //    appRoutesNames.OVERVIEW
      // ]);
   }

   noOrgs(retry = 0) {
      if (!this.noOrgsDialogRef) {
         if (this.noOrgsTemplateRef) {
            this.noOrgsDialogRef = this.dialog.open(this.noOrgsTemplateRef);
         } else if (retry < 2) {
            setTimeout(() => {
               retry++;
               this.noOrgs(retry);
            }, 100);
         }
      }
   }

   createOrg() {
      if (this.noOrgsDialogRef) {
         this.noOrgsDialogRef.close();
      }
      this.userOrgFacade.setOrganization(null);
      this.router.navigate([appRoutesNames.NEWORG]);
   }

   demoOrg() {
      if (this.noOrgsDialogRef) {
         this.noOrgsDialogRef.close();
      }
      this.showSpinner = true;
      this.userOrgFacade.linkToDemoOrg().subscribe(({ authUser }) => {
         this.showSpinner = false;
         this.userOrgFacade.inviteAccepted(authUser, environment.demoOrg);
      });
   }
}
