import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MeetingsActions } from './meetings.actions';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { Observable, combineLatest } from 'rxjs';
import { Meeting } from '@entities/meeting';
import { meetingsSelectors } from './meetings.state';
import { ActionItem } from '@entities/action-item';
import { Rock } from '@entities/rock';
import { EvolveFacade } from '@app/evolve/state/evolve.facade';
import { map } from 'rxjs/operators';
import { MeetingService } from '../services/meeting.service';
import { Department } from '@entities/department';
import { Goal } from '@entities/goal';
import { RockActions } from '@app/evolve/state/rock/rock.actions';
import { ActionItemActions } from '@app/evolve/state/action-item/action-item.actions';
import { getTeamMembers } from '@app/team/state/team.state';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { BusinessUnit } from '@entities/business-unit';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';

@Injectable()
export class MeetingsFacade {
   orgTree$: Observable<any[]>;
   meetings$: Observable<Meeting[]>;
   selectedMeeting$: Observable<Meeting> = this.store.pipe(
      select(meetingsSelectors.getSelectedMeeting)
   );
   actionItems$: Observable<ActionItem[]>;
   actionItemsForMeeting$: Observable<ActionItem[]>;
   rocks$: Observable<Rock[]>;
   rocksForMeeting$: Observable<Rock[]>;
   businessUnits$: Observable<BusinessUnit[]>;
   departments$: Observable<Department[]>;
   goals$: Observable<Goal[]>;
   selectedRock$: Observable<Rock>;
   selectedActionItem$: Observable<ActionItem>;
   showRocks$ = this.store.pipe(select(meetingsSelectors.getShowRocks));
   showActionItems$ = this.store.pipe(select(meetingsSelectors.getShowActionItems));
   teamMembers$ = this.store.pipe(select(getTeamMembers));
   filter$ = this.store.pipe(select(meetingsSelectors.getFilter));

   constructor(
      private store: Store<State>,
      private orgBuilderFacade: OrgBuilderFacade,
      private evolveFacade: EvolveFacade,
      private meetingService: MeetingService,
      private userOrgFacade: UserOrgFacade
   ) {
      this.orgTree$ = this.orgBuilderFacade.departmentsTree$;
      this.actionItems$ = this.evolveFacade.actionItems$;
      this.actionItemsForMeeting$ = combineLatest([
         this.selectedMeeting$,
         this.evolveFacade.actionItems$,
      ]).pipe(
         map(([meeting, actionItems]) => {
            if (meeting) {
               return actionItems.filter((actionItem) =>
                  meeting.actionItems.includes(actionItem.id)
               );
            } else {
               return [];
            }
         })
      );
      this.rocks$ = this.evolveFacade.rocks$;
      this.rocksForMeeting$ = combineLatest([this.selectedMeeting$, this.evolveFacade.rocks$]).pipe(
         map(([meeting, rocks]) => {
            if (meeting) {
               return rocks.filter((rock) => meeting.rocks.includes(rock.id));
            } else {
               return [];
            }
         })
      );
      this.departments$ = this.orgBuilderFacade.departments$;
      this.businessUnits$ = this.orgBuilderFacade.businessUnits$;
      this.goals$ = this.evolveFacade.goals$;

      this.meetingService.entities$.subscribe((meetings) => {
         this.store.dispatch(MeetingsActions.MeetingsUpdated({ meetings }));
      });

      this.selectedRock$ = combineLatest([
         this.evolveFacade.rocks$,
         this.store.pipe(select(meetingsSelectors.getSelectedRockId)),
      ]).pipe(
         map(([rocks, selectedRockId]) => {
            return rocks.find((rock) => rock.id == selectedRockId);
         })
      );
      this.selectedActionItem$ = combineLatest([
         this.evolveFacade.actionItems$,
         this.store.pipe(select(meetingsSelectors.getSelectedActionItemId)),
      ]).pipe(
         map(([actionItems, selectedActionItemId]) => {
            return actionItems.find((actionItem) => actionItem.id == selectedActionItemId);
         })
      );

      this.meetings$ = combineLatest([
         this.store.pipe(select(meetingsSelectors.getMeetings)),
         this.userOrgFacade.currentUser$,
      ]).pipe(
         map(([meetings, currentUser]) => {
            return meetings.filter(
               (meeting) =>
                  !meeting.isPrivate ||
                  meeting.attendees?.includes(currentUser.teamMemberId) ||
                  meeting.sharedWith?.includes(currentUser.teamMemberId)
            );
         })
      );
   }

   createMeeting(meeting: Meeting) {
      this.store.dispatch(MeetingsActions.CreateMeeting({ meeting }));
   }

   // startMeeting(name: string) {
   //    this.store.dispatch(MeetingsActions.StartMeeting({ name }));
   // }

   viewMeeting(meeting: Meeting) {
      this.store.dispatch(MeetingsActions.ViewMeeting(meeting));
   }

   selectMeeting(meetingId: string) {
      this.store.dispatch(MeetingsActions.SelectMeeting({ selectedMeetingId: meetingId }));
   }

   selectRock(selectedRockId: string) {
      this.store.dispatch(MeetingsActions.SelectRock({ selectedRockId }));
   }

   selectActionItem(selectedActionItemId: string) {
      this.store.dispatch(MeetingsActions.SelectActionItem({ selectedActionItemId }));
   }

   createRock(rock: Rock) {
      this.store.dispatch(
         RockActions.CreateRock({ rock, preventNavigation: true, showSnackBar: true })
      );
   }

   saveRock(rock: Rock) {
      this.store.dispatch(RockActions.SaveRock({ rock }));
   }

   saveActionItem(actionItem: ActionItem, hideSnackBar = true) {
      this.store.dispatch(
         ActionItemActions.SaveActionItem({ actionItem, hideSnackBar, preventNavigation: true })
      );
   }

   saveMeeting(meeting: Meeting) {
      this.store.dispatch(MeetingsActions.SaveMeeting({ meeting }));
   }

   endMeeting() {
      this.store.dispatch(MeetingsActions.EndMeeting());
   }

   createDraftMeeting(meeting: Meeting) {
      this.store.dispatch(MeetingsActions.CreateDraftMeeting({ meeting }));
   }

   deleteMeeting(meeting: Meeting) {
      this.store.dispatch(MeetingsActions.DeleteMeeting(meeting));
   }

   startMeeting(meetingId: string) {
      this.store.dispatch(MeetingsActions.StartMeeting({ meetingId }));
   }

   setFilter(filter: FilterValues) {
      this.store.dispatch(MeetingsActions.SetMeetingsFilter({ filter }));
   }

   addRockToMeeting() {
      this.store.dispatch(MeetingsActions.AddRockToMeeting());
   }

   addActionItemToMeeting() {
      this.store.dispatch(MeetingsActions.AddActionItemToMeeting());
   }
}
