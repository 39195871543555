import { DashboardState } from './dashboard.state';
import { Action, createReducer, on } from '@ngrx/store';
import { DashboardActions } from './dashboard.actions';
import { UserOrgActions } from '@app/user-org/state/user-org.actions';
import { AuthUserUpdated } from '@app/auth/state/auth.actions';

export const dashboardInitialState: DashboardState = {
   dashboards: [],
   selectedDashboardId: null,
   organizationId: null,
   filters: {},
};

export function reducer(state: DashboardState | undefined, action: Action): DashboardState {
   return createReducer(
      dashboardInitialState,
      on(DashboardActions.DashboardsUpdated, (state, { dashboards }) => {
         return {
            ...state,
            dashboards,
         };
      }),
      on(DashboardActions.SelectDashboard, (state, { dashboardId }) => {
         return {
            ...state,
            selectedDashboardId: dashboardId,
         };
      }),
      on(
         UserOrgActions.SelectOrganization,
         UserOrgActions.SetOrganization,
         (state, { organizationId }) => {
            if (state.organizationId != organizationId) {
               return { ...dashboardInitialState, organizationId };
            } else {
               return state;
            }
         }
      ),
      on(DashboardActions.SetWidgetFilter, (state, { widgetId, filter }) => {
         const filters = {
            ...state.filters,
            [widgetId]: filter,
         };
         return {
            ...state,
            filters,
         };
      }),
      on(AuthUserUpdated, (state, { authUser }) => {
         if (authUser) {
            return state;
         } else {
            return dashboardInitialState;
         }
      })
   )(state, action);
}
