import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { DepartmentFunction } from '@entities/department-function';
import { getDepartmentFunctions } from '@app/org-builder/state/organization.state';
import { DepartmentFunctionService } from '@app/org-builder/services/department-function.service';
import * as OrganizationActions from '@app/org-builder/state/organization.actions';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { DepartmentFunctionViewModel } from '@app/documentation/state/documentation.state';
import { Task } from '@entities/task';
import { TaskDocumentationViewModel } from '@app/documentation/state/documentation.models';

@Injectable()
export class PerformanceFacade {
   departmentFunctions$: Observable<DepartmentFunctionViewModel[]>;
   tasks$: Observable<TaskDocumentationViewModel[]>;

   constructor(private store: Store<State>, private documentationFacade: DocumentationFacade) {
      this.departmentFunctions$ = this.documentationFacade.functionsViewModel$;
      this.tasks$ = this.documentationFacade.tasksViewModel$;
   }

   saveDepartmentFunction(departmentFunction: DepartmentFunction) {
      this.store.dispatch(OrganizationActions.SaveDepartmentFunction(departmentFunction));
   }

   saveTask(task: Task) {
      this.store.dispatch(OrganizationActions.SaveTask(task));
   }

   resetRatings() {
      this.store.dispatch(OrganizationActions.ResetRatings());
   }
}
