import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Goal } from '@entities/goal';
import { TableConfig } from '@app/shared/components/table/table.component';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { EvolveFacade } from '@app/evolve/state/evolve.facade';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { Status } from '@entities/enums/status';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { BusinessUnitNamePipe } from '@app/shared/pipes/business-unit-name.pipe';
import { DepartmentNamePipe } from '@app/shared/pipes/department-name.pipe';
import { Department } from '@entities/department';
import { BusinessUnit } from '@entities/business-unit';
import { takeUntil } from 'rxjs/operators';
import { RoleGuard } from '@app/admin/services/role.guard';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-goal-list',
   templateUrl: './goal-list.component.html',
   styleUrls: ['./goal-list.component.scss'],
})
export class GoalListComponent implements OnInit, OnDestroy {
   businessUnits: BusinessUnit[] = [];
   departments: Department[] = [];

   /* istanbul ignore next */
   columns: TableColumn<Goal>[] = [
      { label: 'Name', def: 'name', value: (goal) => goal.name, visible: true },
      {
         label: 'Business Unit',
         def: 'businessUnit',
         value: (goal) =>
            this.businessUnitNamePipe.transform(goal.linkedEntityId, this.businessUnits),
         visible: true,
         filter: 'businessUnits',
      },
      {
         label: 'Department',
         def: 'department',
         value: (goal) => this.departmentNamePipe.transform(goal.linkedEntityId, this.departments),
         visible: true,
         filter: 'departments',
      },
      {
         label: 'Target',
         def: 'target',
         value: (goal) => goal.targetQuarter,
         visible: true,
         filter: 'quarter',
      },
      { label: 'Status', def: 'status', value: (goal) => Status[goal.status], visible: true },
   ];

   orgTableConfig: TableConfig;
   orgFilterConfig: FilterBarConfig;

   buTableConfig: TableConfig;
   buFilterConfig: FilterBarConfig;

   deptTableConfig: TableConfig;
   deptFilterConfig: FilterBarConfig;

   activeTab$: Observable<number>;

   canEdit$: Observable<boolean>;

   private destroyed$ = new Subject<void>();

   constructor(
      private evolveFacade: EvolveFacade,
      private businessUnitNamePipe: BusinessUnitNamePipe,
      private departmentNamePipe: DepartmentNamePipe,
      private roleGuard: RoleGuard
   ) {}

   ngOnInit(): void {
      this.canEdit$ = this.roleGuard.canEdit([], AppAreas.Evolve);
      this.activeTab$ = this.evolveFacade.goalsActiveTab$;
      const orgColumns = this.columns.filter(
         (c) => c.def !== 'businessUnit' && c.def !== 'department'
      );
      this.orgTableConfig = {
         columns: orgColumns,
         visibleColumns$: of(['name', 'target', 'status']),
         data$: this.evolveFacade.organizationGoals$,
         filter$: this.evolveFacade.organizationGoalsFilter$,
         initialSort: { active: 'name', direction: 'asc' },
         rowClick: (row) => this.editGoal(row),
      };
      this.orgFilterConfig = {
         status: Status,
         quarter: true,
         hideEditColumns: true,
         filterChange: (filterValues) => this.evolveFacade.setOrgGoalsFilter(filterValues),
      };
      const buColumns = this.columns.filter((c) => c.def !== 'department');
      this.buTableConfig = {
         columns: buColumns,
         visibleColumns$: of(['name', 'target', 'status']),
         data$: this.evolveFacade.businessUnitGoals$,
         filter$: this.evolveFacade.businessUnitGoalsFilter$,
         initialSort: { active: 'name', direction: 'asc' },
         rowClick: (row) => this.editGoal(row),
      };
      this.buFilterConfig = {
         businessUnits: true,
         status: Status,
         quarter: true,
         hideEditColumns: true,
         filterChange: (filterValues) => this.evolveFacade.setBusinessUnitGoalsFilter(filterValues),
      };
      const deptColumns = this.columns.filter((c) => c.def !== 'businessUnit');
      this.deptTableConfig = {
         columns: deptColumns,
         visibleColumns$: of(['name', 'target', 'status']),
         data$: this.evolveFacade.departmentGoals$,
         filter$: this.evolveFacade.departmentGoalsFilter$,
         initialSort: { active: 'name', direction: 'asc' },
         rowClick: (row) => this.editGoal(row),
      };
      this.deptFilterConfig = {
         businessUnits: true,
         departments: true,
         status: Status,
         quarter: true,
         hideEditColumns: true,
         filterChange: (filterValues) => this.evolveFacade.setDepartmentGoalsFilter(filterValues),
      };

      this.evolveFacade.businessUnits$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((businessUnits) => {
            this.businessUnits = businessUnits;
         });
      this.evolveFacade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   newGoal() {
      this.evolveFacade.addGoal(true);
   }

   editGoal(goal: Goal) {
      this.evolveFacade.editGoal(goal, true);
   }

   selectTab(event: MatTabChangeEvent) {
      this.evolveFacade.setGoalsActiveTab(event.index);
   }
}
