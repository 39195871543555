import {
   Component,
   OnInit,
   Input,
   SimpleChanges,
   OnChanges,
   Output,
   EventEmitter,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { PerformanceRating } from '@entities/enums/performance-rating';
import { Task } from '@entities/task';
import { PerformanceFacade } from '@app/performance/state/performance.facade';
import { TaskDocumentationViewModel } from '@app/documentation/state/documentation.models';
import { Subscription } from 'rxjs';

@Component({
   selector: 'app-performance-task-details',
   templateUrl: './performance-task-details.component.html',
   styleUrls: ['./performance-task-details.component.scss'],
})
export class PerformanceTaskDetailsComponent implements OnInit, OnChanges {
   @Input() viewModel: TaskDocumentationViewModel;
   @Input() canEdit: boolean;
   @Output() dirtyChange = new EventEmitter<boolean>();

   task: Task;

   departmentFunctionName: string;
   businessUnitName: string;
   departmentName: string;

   form: UntypedFormGroup;

   rating = PerformanceRating;
   ratingKeys = Object.keys(PerformanceRating)
      .map((key) => +key)
      .filter((key) => !isNaN(key));

   dirty = false;
   dirtySubscription: Subscription;

   constructor(private performanceFacade: PerformanceFacade) {}

   ngOnInit(): void {
      this.initForm();
      this.setValues();
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['viewModel'] && this.form && this.form.pristine) {
         this.setValues();
      }
      if (changes['canEdit']) {
         this.initForm();
      }
   }

   initForm() {
      if (!this.form) {
         this.form = new UntypedFormGroup({
            benchmark: new UntypedFormControl(),
            rating: new UntypedFormControl(),
            kpi: new UntypedFormControl(),
            ratingNotes: new UntypedFormControl(),
         });
      }
      if (this.canEdit) {
         this.form.enable();
         if (this.dirtySubscription) {
            this.dirtySubscription.unsubscribe();
         }
         this.dirtySubscription = this.form.valueChanges.subscribe(() => {
            if (this.dirty !== this.form.dirty) {
               this.dirty = this.form.dirty;
               this.dirtyChange.emit(this.dirty);
            }
         });
      } else {
         this.form.disable();
      }
   }

   setValues() {
      if (this.viewModel) {
         if (this.viewModel.task) {
            this.task = this.viewModel.task;
            this.form.patchValue(this.task);
            if (this.task.rating === null || this.viewModel.task.rating === undefined) {
               this.form.patchValue({ rating: PerformanceRating['No Value'] });
               this.form.markAsDirty();
               this.dirtyChange.emit(true);
            }
         }
         if (this.viewModel.departmentFunction) {
            this.departmentFunctionName = this.viewModel.departmentFunction.name;
         }
         if (this.viewModel.department) {
            this.departmentName = this.viewModel.department.name;
         }
         if (this.viewModel.businessUnit) {
            this.businessUnitName = this.viewModel.businessUnit.name;
         }
      }
   }

   save() {
      const task = {
         ...this.viewModel.task,
         ...this.form.value,
      };
      this.performanceFacade.saveTask(task);
      this.form.markAsPristine();
      this.dirtyChange.emit(false);
   }
}
