<div style="border-bottom: 2px solid black; margin-bottom: 8px">
   <div style="height: 50px; width: 100%; display: flex; justify-content: space-between">
      <div style="font-size: 20pt; line-height: 50px; flex: 1 1 auto">{{ orgName }}</div>
      <img *ngIf="logo" [src]="logo" style="height: 50px; margin-top: 0px; margin-bottom: 0px" />
   </div>
   <div style="display: flex; flex-direction: column; align-items: center; width: 100%">
      <div style="font-size: 12pt; font-weight: bold; text-align: right">
         {{ category }}
      </div>
      <div style="font-size: 20pt; line-height: 20pt; font-weight: bold">
         {{ title }}
      </div>
      <div style="font-size: 12pt; text-transform: uppercase; color: #999">
         {{ subtitle }}
      </div>
   </div>
</div>
