<div class="h2" mat-dialog-title>
   Manage User
   <span *ngIf="!(userActive$ | async)">- Inactive</span>
   <button class="options-button" mat-icon-button [matMenuTriggerFor]="optionsMenu">
      <mat-icon>more_vert</mat-icon>
   </button>
   <mat-menu #optionsMenu="matMenu">
      <button mat-menu-item *ngIf="userActive$ | async" (click)="deactivate()">
         Deactivate User
      </button>
      <button mat-menu-item *ngIf="!(userActive$ | async)" (click)="reactivate()">
         Reactivate User
      </button>
      <button mat-menu-item (click)="delete()">Delete User</button>
   </mat-menu>
</div>
<mat-dialog-content>
   <form [formGroup]="form">
      <div class="row">
         <mat-form-field class="col-md-6 col-sm-12">
            <input matInput placeholder="First Name" formControlName="firstName" type="text" />
         </mat-form-field>
         <mat-form-field class="col-md-6 col-sm-12">
            <input matInput placeholder="Last Name" formControlName="lastName" type="text" />
         </mat-form-field>
         <mat-form-field class="col-12">
            <input matInput placeholder="Email" formControlName="email" type="text" />
         </mat-form-field>
         <mat-form-field class="col-md-6 col-sm-12" *ngIf="usePermissionGroups$ | async">
            <mat-label>Permission Group</mat-label>
            <mat-select #productSelect formControlName="permissionGroupId">
               <div
                  class="product-option"
                  *ngFor="let permissionGroup of permissionGroups$ | async"
               >
                  <mat-option [value]="permissionGroup.id">
                     {{ permissionGroup.name }}
                  </mat-option>
               </div>
            </mat-select>
         </mat-form-field>
         <mat-form-field class="col-md-6 col-sm-12" *ngIf="!(usePermissionGroups$ | async)">
            <mat-label>Product</mat-label>
            <mat-select #productSelect formControlName="product">
               <div class="product-option" *ngFor="let plan of orgPlans$ | async">
                  <mat-option [value]="plan.productType" [disabled]="productDisabled(plan)">
                     {{ ProductType[plan.productType] }}
                     <span class="mat-hint" *ngIf="productSelect.panelOpen">
                        ({{ available(plan) }} of {{ plan.quantity }} available)
                     </span>
                  </mat-option>
               </div>
            </mat-select>
         </mat-form-field>
         <app-team-member-autocomplete
            class="col-md-6 col-sm-12"
            placeholder="Linked Team Member"
            [idFormControl]="getControl('teamMemberId')"
            (optionSelected)="setTeamMember($event)"
         ></app-team-member-autocomplete>
         <div
            class="col-md-6 col-sm-12 admin-toggle-container"
            *ngIf="!(usePermissionGroups$ | async)"
         >
            <mat-slide-toggle
               color="primary"
               class="admin-toggle"
               formControlName="isAdmin"
               matTooltip="Admins can add or remove users and manage organization settings"
               matTooltipPosition="after"
            >
               Admin
            </mat-slide-toggle>
         </div>
      </div>
   </form>
</mat-dialog-content>

<mat-dialog-actions>
   <button
      data-test-id="save-button"
      class="action-button"
      type="submit"
      mat-raised-button
      color="primary"
      (click)="save()"
   >
      Save
   </button>
   <button
      data-test-id="cancel-button"
      class="action-button"
      type="button"
      mat-stroked-button
      color="primary"
      (click)="cancel()"
   >
      Cancel
   </button>
</mat-dialog-actions>
