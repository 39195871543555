<mat-card>
   <mat-card-title>Notes</mat-card-title>
   <mat-card-content>
      <div class="row">
         <ng-container *ngIf="canEdit">
            <div class="col-12 editor">
               <textarea
                  [froalaEditor]="editorOptions"
                  [(ngModel)]="commentText"
                  (ngModelChange)="updateDirty($event)"
               ></textarea>
            </div>
            <div class="col-12">
               <button [disabled]="!commentText" mat-button color="primary" (click)="addComment()">
                  Add Note
               </button>
            </div>
         </ng-container>
         <div class="col-md-3">
            <mat-form-field class="comment-sort">
               <mat-select
                  color="primary"
                  [(ngModel)]="sortNewest"
                  (selectionChange)="sortComments()"
               >
                  <mat-option [value]="false">Oldest First</mat-option>
                  <mat-option [value]="true">Newest First</mat-option>
               </mat-select>
            </mat-form-field>
         </div>
      </div>
      <ng-container *ngFor="let comment of comments">
         <mat-divider></mat-divider>
         <div class="row comment">
            <div class="col-12 comment-container">
               <div
                  *ngIf="!showEdit(comment)"
                  class="comment-text"
                  [innerHTML]="comment.text"
               ></div>
               <div *ngIf="showEdit(comment)" class="comment-text">
                  <textarea
                     [froalaEditor]="commentEditor()"
                     [(froalaModel)]="comment.text"
                  ></textarea>
               </div>
               <div class="comment-buttons">
                  <ng-container *ngIf="canEditComment(comment)">
                     <ng-container *ngIf="showEdit(comment)">
                        <button mat-icon-button color="primary" (click)="saveEditComment(comment)">
                           <mat-icon>save</mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelEditComment(comment)">
                           <mat-icon>cancel</mat-icon>
                        </button>
                     </ng-container>
                     <ng-container *ngIf="!showEdit(comment)">
                        <button mat-icon-button color="primary" (click)="editComment(comment)">
                           <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button (click)="deleteComment(comment)">
                           <mat-icon>delete</mat-icon>
                        </button>
                     </ng-container>
                  </ng-container>
               </div>
            </div>
            <div class="col-12">
               <span class="commenter">{{ comment.commenter }}</span>
               |
               {{ comment.timestamp | date : 'medium' }}
            </div>
         </div>
      </ng-container>
   </mat-card-content>
</mat-card>
