import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { DashboardActions } from './dashboard.actions';
import { tap, withLatestFrom, map } from 'rxjs/operators';
import { DashboardService } from '../services/dashboard.service';
import { ErrorService } from '@app/shared/services/error.service';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { getCurrentUser } from '@app/user-org/state/user-org.selectors';
import { Dashboard } from '@entities/dashboard';
import { adminActions } from '@app/admin/state/admin.actions';
import { dashboardSelectors } from './dashboard.state';

@Injectable()
export class DashboardEffects {
   constructor(
      private actions$: Actions,
      private dashboardService: DashboardService,
      private errorService: ErrorService,
      private store: Store<State>
   ) {}

   saveDashboard$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(DashboardActions.SaveDashboard),
            withLatestFrom(
               this.store.pipe(select(getCurrentUser)),
               this.store.pipe(select(dashboardSelectors.getDashboards))
            ),
            tap(([action, user, dashboards]) => {
               const dashboard: Dashboard = {
                  ...action.dashboard,
                  userId: user.id,
                  widgets: action.dashboard.widgets.map((widget, index) => ({
                     ...widget,
                     id: widget.id || `${Date.now()}-${index}`,
                  })),
               };
               if (user.id != action.dashboard.userId) {
                  // clone dashboard if not created by current user
                  dashboard.id = null;
                  dashboard.sharable = false;
               }
               if (dashboard.default) {
                  const toSave: Dashboard[] = [];
                  dashboards.forEach((dash) => {
                     if (dash.default) {
                        toSave.push({ ...dash, default: false });
                     }
                  });
                  toSave.push(dashboard);
                  this.dashboardService.batchSave(toSave).catch((error) => {
                     this.errorService.handleError(error);
                  });
               } else {
                  this.dashboardService.save(dashboard).catch((error) => {
                     this.errorService.handleError(error);
                  });
               }
            })
         ),
      { dispatch: false }
   );

   linkTeamMember$ = createEffect(() =>
      this.actions$.pipe(
         ofType(DashboardActions.LinkTeamMember),
         withLatestFrom(this.store.pipe(select(getCurrentUser))),
         map(([{ teamMember }, user]) => {
            const linkedUser = {
               ...user,
               teamMemberId: teamMember.id,
            };
            return adminActions.SaveUser({ user: linkedUser });
         })
      )
   );

   deleteDashboard$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(DashboardActions.DeleteDashboard),
            tap(({ dashboard }) => {
               this.dashboardService.delete(dashboard).catch((error) => {
                  this.errorService.handleError(error);
               });
            })
         ),
      { dispatch: false }
   );

   saveWidgetData$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(DashboardActions.SaveWidgetData),
            withLatestFrom(this.store.pipe(select(dashboardSelectors.getSelectedDashboard))),
            tap(([{ id, data }, dashboard]) => {
               const widget = dashboard.widgets.find((w) => w.id === id);
               if (widget) {
                  const updatedWidget = {
                     ...widget,
                     data,
                  };
                  const updatedDashboard = {
                     ...dashboard,
                     widgets: [...dashboard.widgets.filter((w) => w.id !== id), updatedWidget],
                  };
                  this.dashboardService.save(updatedDashboard);
               }
            })
         ),
      { dispatch: false }
   );
}
