import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { EvolveFacade } from '@app/evolve/state/evolve.facade';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Goal } from '@entities/goal';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { GoalLevel } from '@entities/enums/goal-level';
import { getKeys } from '@app/utilities/getKeys';
import { Status } from '@entities/enums/status';
import { froalaConfig } from '@app/shared/config/froala.config';
import { UserComment } from '@entities/user-comment';
import { Rock } from '@entities/rock';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { UserCommentsComponent } from '@app/shared/components/user-comments/user-comments.component';
import { RoleGuard } from '@app/admin/services/role.guard';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-goal-details',
   templateUrl: './goal-details.component.html',
   styleUrls: ['./goal-details.component.scss'],
})
export class GoalDetailsComponent implements OnInit, OnDestroy, SaveChangesComponent {
   @ViewChild(UserCommentsComponent) commentsComponent: UserCommentsComponent;

   goal$: Observable<Goal>;
   isNew$: Observable<boolean>;
   title$: Observable<string>;

   form: UntypedFormGroup;
   goal: Goal;
   name: string;
   showEditName = false;

   goalStatus = Status;
   goalStatusKeys = getKeys(Status);
   goalLevel = GoalLevel;
   goalLevelKeys = getKeys(GoalLevel);

   editorConfig = {
      ...froalaConfig,
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Description',
      minHeight: 100,
   };
   editor: any;

   canEdit = false;

   currentLevel: GoalLevel;

   businessUnits$: Observable<BusinessUnit[]>;
   departments$: Observable<Department[]>;
   groupedDepartments$: Observable<{ name: string; departments: Department[] }[]>;
   comments: UserComment[] = [];

   private destroyed$ = new Subject<void>();

   constructor(
      private facade: EvolveFacade,
      private route: ActivatedRoute,
      private roleGuard: RoleGuard
   ) {
      this.route.params.subscribe((params) => {
         const goalId = params['goalId'];
         if (goalId && goalId !== 'new') {
            this.facade.selectGoal(goalId);
         } else {
            this.facade.selectGoal(null);
         }
      });
   }

   ngOnInit(): void {
      this.initForm();

      this.goal$ = this.facade.selectedGoal$;
      this.isNew$ = this.goal$.pipe(map((goal) => !goal || !goal.id));
      this.title$ = combineLatest([this.isNew$, this.roleGuard.canEdit()]).pipe(
         map(([isNew, canEdit]) => (!canEdit ? 'Goal Details' : isNew ? 'New Goal' : 'Edit Goal'))
      );
      this.goal$.pipe(takeUntil(this.destroyed$)).subscribe((goal) => {
         if (goal) {
            this.goal = { ...goal };
            this.form.patchValue(goal);
            this.comments = goal.comments || [];
            this.form.markAsPristine();
         } else {
            this.goal = {} as Goal;
         }
      });
      this.roleGuard
         .canEdit([], AppAreas.Evolve, [this.goal?.assigneeId])
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
            this.initForm();
         });
      this.businessUnits$ = this.facade.businessUnits$;
      this.departments$ = this.facade.departments$;
      this.groupedDepartments$ = combineLatest([this.businessUnits$, this.departments$]).pipe(
         map(([businessUnits, departments]) => {
            const grouped = [];
            businessUnits.forEach((businessUnit) => {
               const depts = departments.filter(
                  (department) => department.businessUnitId === businessUnit.id
               );
               grouped.push({ name: businessUnit.name, departments: depts });
            });
            return grouped;
         })
      );
      this.form.get('level').valueChanges.subscribe((val) => {
         if (this.goal && this.goal.level && val !== this.goal.level) {
            this.form.get('linkedEntityId').reset();
         }
         this.goal.level = val;
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   initForm() {
      if (!this.form) {
         this.form = new UntypedFormGroup({
            name: new UntypedFormControl(),
            targetQuarter: new UntypedFormControl(null, Validators.required),
            status: new UntypedFormControl(),
            level: new UntypedFormControl(),
            description: new UntypedFormControl(),
            linkedEntityId: new UntypedFormControl(),
         });
      }
      if (this.canEdit) {
         this.form.enable();
      } else {
         this.form.disable();
      }
   }

   initFroala(controls) {
      controls.initialize();
      this.editor = controls.getEditor();
   }

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   focusEditor() {
      this.editor.events.focus();
   }

   updateName(name) {
      this.form.get('name').setValue(name);
   }

   updateComments(comments: UserComment[]) {
      this.comments = comments;
      this.form.markAsDirty();
   }

   save() {
      const goal = {
         ...this.goal,
         ...this.form.value,
         comments: this.comments,
      };
      this.facade.saveGoal(goal, true);
   }

   deleteGoal() {
      const goal = {
         ...this.goal,
         ...this.form.value,
         comments: this.comments,
      };
      this.facade.deleteGoal(goal);
   }

   back() {
      history.back();
   }

   linkRock(rock: Rock) {
      if (!this.goal.rocks) {
         this.goal.rocks = [];
      }
      if (!this.goal.rocks.includes(rock.id)) {
         this.goal = {
            ...this.goal,
            rocks: [...this.goal.rocks, rock.id],
         };
         this.form.markAsDirty();
      }
   }

   unlinkRock(rock: Rock) {
      if (!this.goal.rocks) {
         this.goal.rocks = [];
      } else {
         this.goal.rocks = this.goal.rocks.filter((id) => id !== rock.id);
         this.form.markAsDirty();
      }
   }

   linkGoal(goal: Goal) {
      if (!this.goal.linkedGoals) {
         this.goal.linkedGoals = [];
      }
      if (!this.goal.linkedGoals.includes(goal.id)) {
         this.goal = {
            ...this.goal,
            linkedGoals: [...this.goal.linkedGoals, goal.id],
         };
         this.form.markAsDirty();
      }
   }

   unlinkGoal(goal: Goal) {
      if (!this.goal.linkedGoals) {
         this.goal.linkedGoals = [];
      } else {
         this.goal.linkedGoals = this.goal.linkedGoals.filter((id) => id !== goal.id);
         this.form.markAsDirty();
      }
   }
}
