import { AuthState } from './auth.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export const authInitialState: AuthState = {
   authUser: null,
};

export function reducer(state: AuthState | undefined, action: Action): AuthState {
   return createReducer(
      authInitialState,
      on(AuthActions.AuthUserUpdated, (state, { authUser }) => {
         return {
            ...state,
            authUser,
         };
      }),
      on(AuthActions.SetDisplayName, (state, { displayName }) => {
         const user = {
            ...state.authUser,
            displayName,
         };
         return {
            ...state,
            user,
         };
      })
   )(state, action);
}
