import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { User } from '@entities/user';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
   selector: 'app-unauthorized',
   templateUrl: './unauthorized.component.html',
   styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
