import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'froala-editor/js/plugins.pkgd.min.js';

if (environment.production) {
   enableProdMode();
}
if (environment.name != 'LOCAL') {
   LogRocket.init(environment.logrocket.init);
   Sentry.init({
      dsn: environment.sentry.dsn,
      environment: environment.name ?? 'production',
   });
   LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
         scope.setExtra('sessionURL', sessionURL);
      });
   });
}

platformBrowserDynamic()
   .bootstrapModule(AppModule, {})
   .catch((err) => {
      console.error(err);
      if (environment.name != 'LOCAL') {
         Sentry.captureException(err.originalError || err);
      }
   });
