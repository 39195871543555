<div class="functions">
   <app-table-header
      [hideExport]="tableConfig.hideExport"
      [filterBar]="filterBarConfig"
      [filter$]="filter$"
      [columns]="columns"
      [displayedColumns]="visibleColumns$ | async"
      (columnsUpdated)="setDisplayedColumns($event)"
      (exportData)="export($event)"
   ></app-table-header>
   <app-table [config]="tableConfig"></app-table>
</div>

<ng-template #documentationStatusTemplate>
   <form #docStatusForm="ngForm" (ngSubmit)="saveDocStatus()">
      <div mat-dialog-title>Update Documentation Status</div>
      <mat-dialog-content>
         <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]="docStatusValue" name="docStatusValue" appAutoFocus>
               <mat-option *ngFor="let key of docStatusKeys" [value]="key">
                  {{ documentationStatus[key] }}
               </mat-option>
            </mat-select>
         </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
         <button mat-raised-button type="submit" color="primary">Update</button>
         <button mat-stroked-button color="primary" matDialogClose>Cancel</button>
      </mat-dialog-actions>
   </form>
</ng-template>
