import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DialogService, DialogOptions } from '@app/shared/services/dialog.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
   selector: 'app-confirm-dialog',
   templateUrl: './confirm-dialog.component.html',
   styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
   title: string;
   message: string;
   confirm = 'Yes';
   deny = 'No';
   cancel = 'Cancel';
   showCancel = false;
   confirmColor = 'accent';
   denyColor = 'primary';

   private dialogService: DialogService;

   @HostListener('window:keyup', ['$event'])
   enterKeyPressed(event: KeyboardEvent) {
      if (event.key === 'Enter') {
         this.yes();
      }
   }

   constructor(
      @Inject(MAT_DIALOG_DATA)
      data: DialogOptions
   ) {
      this.title = data.title;
      this.message = data.message;
      this.dialogService = data.service;
      this.confirm = data.confirm;
      this.deny = data.deny;
      this.cancel = data.cancel;
      this.showCancel = data.cancel != null;
      this.confirmColor = data.confirmColor || this.confirmColor;
      this.denyColor = data.denyColor || this.denyColor;
   }

   yes() {
      this.dialogService.closeConfirmationDialog(true);
   }

   no() {
      this.dialogService.closeConfirmationDialog(false);
   }

   close() {
      this.dialogService.closeConfirmationDialog(null);
   }
}
