import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
   selector: 'app-title-edit',
   templateUrl: './title-edit.component.html',
   styleUrls: ['./title-edit.component.scss'],
})
export class TitleEditComponent implements OnInit {
   @Input() formCtrl: UntypedFormControl;
   @Input() isNew: boolean;
   @Input() canEdit = true;
   @Output() titleChange = new EventEmitter();

   showEditName = false;
   tempTitle: string;

   constructor() {}

   ngOnInit(): void {
      this.showEditName = this.isNew && this.canEdit;
   }

   editName() {
      this.tempTitle = this.formCtrl.value;
      this.showEditName = true;
   }

   updateName() {
      this.showEditName = false;
   }

   cancelUpdateName() {
      this.showEditName = false;
      this.titleChange.emit(this.tempTitle);
   }
}
