import { Component, OnInit, Input } from '@angular/core';
import { Observable, timer, Subscription } from 'rxjs';

@Component({
   selector: 'app-timer',
   templateUrl: './timer.component.html',
   styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {
   @Input() start: number;
   @Input() end: number;
   @Input() countDown = true;
   @Input() overtime = false;
   @Input() interval = 1;

   timer: Observable<number>;
   subscription: Subscription;
   display: string;
   isOver = false;
   current = 0;
   paused = false;

   constructor() {}

   ngOnInit(): void {
      this.startTimer();
   }

   subscribeToTimer() {
      this.subscription = this.timer.subscribe(() => {
         this.countDown ? this.current-- : this.current++;
         if (this.current == 0 && this.countDown) {
            if (this.overtime) {
               this.isOver = true;
            } else {
               this.stopTimer();
            }
         }
         this.display = this.parseValue(Math.abs(this.current));
      });
   }

   parseValue(value: number) {
      let seconds = 0;
      let minutes = 0;
      let hours = 0;
      seconds = value % 60;
      if (value >= 60) {
         minutes = Math.floor(value / 60) % 60;
      }
      if (value >= 3600) {
         hours = Math.floor(value / 3600);
      }
      if (hours) {
         return `${hours}:${minutes ? minutes : '00'}:${seconds < 10 ? '0' + seconds : seconds}`;
      } else {
         return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      }
   }

   startTimer() {
      this.current = this.countDown ? this.start : 0;
      this.display = this.parseValue(this.current);
      this.paused = false;
      this.isOver = false;
      this.timer = timer(this.interval * 1000, this.interval * 1000);
      this.subscribeToTimer();
   }

   stopTimer() {
      this.subscription.unsubscribe();
      this.current = this.countDown ? this.start : 0;
   }

   pauseTimer() {
      this.paused = true;
      this.subscription.unsubscribe();
   }

   resumeTimer() {
      this.paused = false;
      this.timer = timer(this.interval * 1000, this.interval * 1000);
      this.subscribeToTimer();
   }

   restartTimer() {
      this.paused = false;
      this.subscription.unsubscribe();
      this.startTimer();
   }
}
