import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';
import { PerformanceRating } from '@entities/enums/performance-rating';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ChartData } from 'chart.js';

@Component({
   selector: 'app-performance-rating-widget',
   templateUrl: './performance-rating-widget.component.html',
   styleUrls: ['./performance-rating-widget.component.scss'],
})
export class PerformanceRatingWidgetComponent implements OnInit, OnDestroy {
   chartLabels = Object.keys(PerformanceRating).filter((s) => isNaN(+s));
   statusKeys = Object.keys(PerformanceRating)
      .map((s) => +s)
      .filter((s) => !isNaN(s));
   chartOptions = {
      title: {
         display: false,
         text: 'Rocks by status',
      },
   };
   data$: Observable<ChartData<'doughnut'>>;
   businessUnits: BusinessUnit[] = [];
   departments: Department[] = [];
   filteredDepartments: Department[] = [];

   form: UntypedFormGroup;

   colors: string[] = [
      '#00594f', // Strength
      '#d32f2f', // Weakness
      '#ff6f00', // Unknown
      '#9e9e9e', // No Value
   ];

   private destroyed$ = new Subject<void>();

   constructor(private dashboardFacade: DashboardFacade) {}

   ngOnInit(): void {
      this.form = new UntypedFormGroup({
         businessUnits: new UntypedFormControl(),
         departments: new UntypedFormControl(),
      });

      this.dashboardFacade.businessUnits$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((businessUnits) => {
            this.businessUnits = businessUnits;
         });
      this.dashboardFacade.departments$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((departments) => {
            this.departments = departments;
         });

      combineLatest([
         this.dashboardFacade.departments$,
         this.form.get('businessUnits').valueChanges.pipe(startWith([])),
      ])
         .pipe(
            takeUntil(this.destroyed$),
            map(([departments, businessUnits]) => {
               if (businessUnits && businessUnits.length > 0) {
                  const businessUnitIds = businessUnits.map((b) => b.id);
                  departments = departments.filter((department) =>
                     businessUnitIds.includes(department.businessUnitId)
                  );
                  let selectedDepartments = this.form.get('departments').value;
                  if (selectedDepartments) {
                     selectedDepartments = selectedDepartments.filter((dept) =>
                        businessUnitIds.includes(dept.businessUnitId)
                     );
                     this.form.get('departments').setValue(selectedDepartments);
                  }
               }
               return departments.sort((a, b) => (a.name || '').localeCompare(b.name));
            })
         )
         .subscribe((departments) => {
            this.filteredDepartments = departments;
         });

      this.data$ = combineLatest([
         this.dashboardFacade.departmentFunctions$,
         this.form.valueChanges.pipe(startWith({})),
      ]).pipe(
         takeUntil(this.destroyed$),
         map(([deptFns, formVal]) => {
            const data = [];
            if (deptFns) {
               let filtered = [...deptFns];
               if (formVal.departments && formVal.departments.length > 0) {
                  filtered = filtered.filter((deptFn) =>
                     formVal.departments.some((dept) => dept.id === deptFn.departmentId)
                  );
               } else if (formVal.businessUnits && formVal.businessUnits.length > 0) {
                  const matchingDepartments = this.departments.filter((dept) =>
                     formVal.businessUnits.some((bu) => bu.id === dept.businessUnitId)
                  );
                  filtered = filtered.filter((deptFn) =>
                     matchingDepartments.some((dept) => dept.id === deptFn.departmentId)
                  );
               }
               this.statusKeys.forEach((key) => {
                  const count = filtered.filter(
                     (deptFn) =>
                        deptFn.rating === key ||
                        (deptFn.rating == undefined && key === PerformanceRating['No Value'])
                  ).length;
                  data.push(count);
               });
            }
            return {
               labels: this.chartLabels,
               datasets: [{ data, backgroundColor: this.colors }],
            };
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   goToPerformance() {
      this.dashboardFacade.goToPerformance();
   }

   idCompare(a: { id: string }, b: { id: string }) {
      return a && b && a.id == b.id;
   }
}
