import { Injectable } from '@angular/core';
import { State } from '@app/app.state';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { EntityService } from '@app/shared/services/entity.service';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { PerformanceEvaluation } from '@entities/performance-evaluation';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class PerformanceEvaluationService implements EntityService<PerformanceEvaluation> {
   COLLECTION_NAME = COLLECTION_NAMES.PERFORMANCE_EVALUATIONS;
   entities$: Observable<PerformanceEvaluation[]>;
   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<PerformanceEvaluation>();

   constructor(private store: Store<State>) {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }

   save(entity: PerformanceEvaluation, overwrite: boolean = false): Promise<PerformanceEvaluation> {
      return this.orgEntityService.save(entity, { overwrite });
   }
   batchSave(entities: PerformanceEvaluation[]): Promise<PerformanceEvaluation[]> {
      return this.orgEntityService.batchSave(entities);
   }
   delete(entity: PerformanceEvaluation): Promise<void> {
      return this.orgEntityService.delete(entity);
   }
}
