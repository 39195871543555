import { meetingRouteNames } from './meetings.route.names';
import { Route } from '@angular/router';
import { MeetingsComponent } from './components/meetings/meetings.component';
import { ActiveMeetingComponent } from './components/active-meeting/active-meeting.component';
import { MeetingDetailsComponent } from './components/meeting-details/meeting-details.component';
import { MeetingAgendaComponent } from './components/meeting-agenda/meeting-agenda.component';

export const MEETING_ROUTES: Route[] = [
   { path: '', component: MeetingsComponent },
   { path: `:meetingId/${meetingRouteNames.AGENDA}`, component: MeetingAgendaComponent },
   { path: `:meetingId/${meetingRouteNames.ACTIVE}`, component: ActiveMeetingComponent },
   { path: `:meetingId/${meetingRouteNames.SUMMARY}`, component: MeetingDetailsComponent },
];
