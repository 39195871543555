import { Injectable, OnDestroy } from '@angular/core';
import { Observable, combineLatest, Subject } from 'rxjs';
import { Task } from '@entities/task';
import { DepartmentFunction } from '@entities/department-function';
import { Role } from '@entities/role';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { map, takeUntil } from 'rxjs/operators';
import * as DocumentationActions from './documentation.actions';
import {
   MatLegacyDialog as MatDialog,
   MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TaskAddComponent } from '@app/org-builder/components/task-add/task-add.component';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { DepartmentFunctionViewModel, documentationSelectors } from './documentation.state';
import { TaskService } from '@app/org-builder/services/task.service';
import { DepartmentFunctionService } from '@app/org-builder/services/department-function.service';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import * as OrganizationActions from '@app/org-builder/state/organization.actions';
import { TeamMember } from '@entities/team-member';
import { getTeamMembers } from '@app/team/state/team.state';
import { FunctionDocumentationViewModel, TaskDocumentationViewModel } from './documentation.models';
import { TaskFacade } from '@app/shared/interfaces/task-facade.interface';
import { Organization } from '@entities/organization';
import { getOrganization } from '@app/user-org/state/user-org.selectors';
import { DialogService } from '@app/shared/services/dialog.service';
import { Sort } from '@angular/material/sort';

@Injectable()
export class DocumentationFacade implements TaskFacade, OnDestroy {
   roles$: Observable<Role[]>;
   businessUnits$: Observable<BusinessUnit[]> = this.orgBuilderFacade.businessUnits$;
   departments$: Observable<Department[]> = this.orgBuilderFacade.departments$;
   departmentFunctions$: Observable<DepartmentFunction[]> = this.store.pipe(
      select(documentationSelectors.getDocumentationFunctions)
   );
   tasks$: Observable<Task[]> = this.store.pipe(
      select(documentationSelectors.getDocumentationTasks)
   );
   tasksViewModel$: Observable<TaskDocumentationViewModel[]>;
   functionsViewModel$: Observable<FunctionDocumentationViewModel[]>;
   taskFilter$: Observable<FilterValues> = this.store.pipe(
      select(documentationSelectors.getTaskFilter)
   );
   functionFilter$: Observable<FilterValues> = this.store.pipe(
      select(documentationSelectors.getFunctionFilter)
   );
   selectedDepartmentFunction$: Observable<{
      departmentFunction: DepartmentFunction;
      department: Department;
      businessUnit: BusinessUnit;
   }>;
   selectedTask$: Observable<Task> = this.store.pipe(
      select(documentationSelectors.getSelectedTask)
   );
   selectedTaskViewModel$: Observable<TaskDocumentationViewModel>;
   teamMembers$: Observable<TeamMember[]> = this.store.pipe(select(getTeamMembers));
   org$: Observable<Organization> = this.store.pipe(select(getOrganization));
   assignedTeamMembers$: Observable<TeamMember[]>;

   taskSort$ = this.store.pipe(select(documentationSelectors.getTaskSort));
   functionSort$ = this.store.pipe(select(documentationSelectors.getFunctionSort));

   taskDisplayedColumns$ = this.store.pipe(select(documentationSelectors.getTaskDisplayedColumns));
   deptFnDisplayedColumns$ = this.store.pipe(
      select(documentationSelectors.getDeptFnDisplayedColumns)
   );

   selectedDepartmentFunctionViewModel$;

   private dialogRef: MatDialogRef<any>;
   private destroyed$ = new Subject<void>();

   constructor(
      private store: Store<State>,
      private dialog: MatDialog,
      private orgBuilderFacade: OrgBuilderFacade,
      private taskService: TaskService,
      private departmentFunctionService: DepartmentFunctionService,
      private dialogService: DialogService
   ) {
      this.tasksViewModel$ = this.store.pipe(select(documentationSelectors.getTasksViewModel));
      this.functionsViewModel$ = this.getFunctionsViewModel();
      this.taskService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((tasks) => {
         this.store.dispatch(DocumentationActions.DocumentationTasksUpdated({ tasks }));
      });
      this.departmentFunctionService.entities$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((functions) => {
            this.store.dispatch(DocumentationActions.DocumentationFunctionsUpdated({ functions }));
         });
      this.assignedTeamMembers$ = combineLatest([this.teamMembers$, this.selectedTask$]).pipe(
         map(([teamMembers, task]) => {
            if (teamMembers && task) {
               return teamMembers.filter((t) => t.assignedTasks?.includes(task.id));
            } else {
               return [];
            }
         })
      );
      this.selectedDepartmentFunction$ = this.getSelectedDepartmentFunction();
      this.selectedTaskViewModel$ = this.store.pipe(
         select(documentationSelectors.getSelectedTaskViewModel)
      );
      this.selectedDepartmentFunctionViewModel$ = this.selectedDepartmentFunction$;
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getFunctionsViewModel() {
      return combineLatest([
         this.businessUnits$,
         this.departments$,
         this.departmentFunctions$,
         this.tasks$,
      ]).pipe(
         map(([businessUnits, departments, functions, tasks]) => {
            const viewModels = [];
            functions.forEach((deptFn) => {
               let department, businessUnit;
               const departmentFunction = { ...deptFn };
               departmentFunction.tasks = tasks
                  .filter((t) => t.departmentFunctionId == departmentFunction.id)
                  .sort((a, b) => a.order - b.order);
               department = departments.find((d) => d.id == departmentFunction.departmentId);
               if (department) {
                  businessUnit = businessUnits.find((b) => b.id == department.businessUnitId);
               }
               const viewModel = {
                  departmentFunction,
                  department,
                  businessUnit,
               };
               viewModels.push(viewModel);
            });
            return viewModels;
         })
      );
   }

   getSelectedDepartmentFunction() {
      return combineLatest([
         this.store.pipe(select(documentationSelectors.getSelectedDepartmentFunctionId)),
         this.functionsViewModel$,
      ]).pipe(
         map(([id, viewModels]) => {
            return viewModels.find((viewModel) => viewModel.departmentFunction.id == id);
         })
      );
   }

   addTask() {
      this.dialogRef = this.dialog.open(TaskAddComponent, { data: { service: this } });
   }

   saveTask(task: Task, onDeactivate: boolean = false, navigate = false) {
      this.store.dispatch(DocumentationActions.SaveTask({ task, onDeactivate, navigate }));
      this.dialog.closeAll();
   }

   cancelAddTask() {
      this.dialogRef.close();
   }

   deleteTask(task: Task) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Task?',
            message: 'Do you want to delete this task?.',
            confirm: 'Yes, delete',
            deny: 'No, go back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(OrganizationActions.DeleteTask(task));
            }
         });
   }
   taskReport(task: Task) {
      this.store.dispatch(DocumentationActions.TaskReport({ task }));
   }
   dragStarted(task: Task) {
      this.store.dispatch(OrganizationActions.DraggingStarted({ dragItem: task }));
   }
   reorderTasks(oldIndex: number, newIndex: number) {
      this.store.dispatch(DocumentationActions.ReorderTasks({ oldIndex, newIndex }));
   }
   taskFullEditor(url: string, task: Partial<Task>, viewModel: DepartmentFunctionViewModel) {
      this.selectTask(null);
      this.dialog.closeAll();
      this.store.dispatch(OrganizationActions.GoToTaskFullEditor({ url, task, viewModel }));
   }

   setTaskFilter(taskFilter: FilterValues) {
      this.store.dispatch(DocumentationActions.SetTaskFilter({ taskFilter }));
   }

   updateFunctionsFilter(functionFilter: FilterValues) {
      this.store.dispatch(DocumentationActions.SetFunctionFilter({ functionFilter }));
   }

   setFunctionSort(sort: Sort) {
      this.store.dispatch(DocumentationActions.SetFunctionSort({ sort }));
   }

   setTaskSort(sort: Sort) {
      this.store.dispatch(DocumentationActions.SetTaskSort({ sort }));
   }

   selectDepartmentFunction(departmentFunctionId: string) {
      this.store.dispatch(DocumentationActions.SelectDepartmentFunction({ departmentFunctionId }));
   }

   selectTask(taskId: string) {
      this.store.dispatch(DocumentationActions.SelectTask({ taskId }));
   }

   viewTask(taskId: string) {
      this.store.dispatch(DocumentationActions.ViewTask({ taskId }));
   }

   saveDepartmentFunction(departmentFunction: DepartmentFunction, onDeactivate = false) {
      this.store.dispatch(
         DocumentationActions.SaveDepartmentFunction({ departmentFunction, onDeactivate })
      );
   }

   deleteDepartmentFunction(departmentFunction: DepartmentFunction) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Function?',
            message:
               'Do you want to delete this function? All associated tasks will be deleted as well.',
            confirm: 'Yes, delete',
            deny: 'No, go back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(
                  OrganizationActions.DeleteDepartmentFunction(departmentFunction)
               );
            }
         });
   }

   assignTaskToTeamMember(task: Task) {
      this.orgBuilderFacade.assignTaskToTeamMember(task);
   }

   unassignTeamMember(task: Task, teamMember: TeamMember) {
      this.orgBuilderFacade.unassignTask(task, teamMember);
   }

   setDeptFnDisplayedColumns(columns: string[]) {
      this.store.dispatch(DocumentationActions.SetDeptFnDisplayedColumns({ columns }));
   }
   setTaskDisplayedColumns(columns: string[]) {
      this.store.dispatch(DocumentationActions.SetTaskDisplayedColumns({ columns }));
   }
}
