export const COLLECTION_NAMES = {
   DELETED: 'DELETED',
   ACTION_ITEMS: 'actionItems',
   AUTH_USERS: 'authUsers',
   BUSINESS_UNITS: 'businessUnits',
   COMPETENCIES: 'competencies',
   COMPETENCY_LEVELS: 'competencyLevels',
   DASHBOARDS: 'dashboards',
   DEPARTMENTS: 'departments',
   DEPARTMENT_FUNCTIONS: 'departmentFunctions',
   GOALS: 'goals',
   ISSUES: 'issues',
   MEETINGS: 'meetings',
   MILESTONES: 'milestones',
   ORGANIZATIONS: 'organizations',
   PERFORMANCE_EVALUATIONS: 'performanceEvaluations',
   PERMISSION_GROUPS: 'permissionGroups',
   PLANNING_PERIODS: 'planningPeriods',
   PRODUCTS: 'products',
   ROCKS: 'rocks',
   ROLES: 'roles',
   TASKS: 'tasks',
   TASK_ASSIGNMENTS: 'taskAssignments',
   TEAM_MEMBERS: 'teamMembers',
   USERS: 'users',
   USER_INVITES: 'userInvites',
};
