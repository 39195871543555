import { createAction, props } from '@ngrx/store';
import { SearchRequest } from '@entities/search-request';

const quickSearch = createAction('[Search] Quick search', props<{ term: string }>());
const loadSearch = createAction('[Search] Load search', props<{ request: SearchRequest }>());
const searchLoaded = createAction('[Search] Search loaded', props<{ results: any[] }>());
const searchError = createAction('[Search] Search error', props<{ error: any }>());
const goToItem = createAction('[Search] Go to item', props<{ item: any; category: string }>());
const seeAllResults = createAction(
   '[Search] See all results',
   props<{ term: string; category: string }>()
);

export const searchActions = {
   quickSearch,
   loadSearch,
   searchLoaded,
   searchError,
   goToItem,
   seeAllResults,
};
