import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { RockAction } from '@entities/rock-action';
import { map, takeUntil } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { TeamMember } from '@entities/team-member';
import * as moment from 'moment';
import { Status } from '@entities/enums/status';
import { Department } from '@entities/department';
import { Rock } from '@entities/rock';
import { WidgetInstance } from '../widget/widget-instance';

import { FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';

@Component({
   selector: 'app-assigned-items-widget',
   templateUrl: './assigned-items-widget.component.html',
   styleUrls: ['./assigned-items-widget.component.scss'],
})
export class AssignedItemsWidgetComponent extends WidgetInstance implements OnInit, OnDestroy {
   assignedActions$: Observable<any[]>;
   teamMemberLinked$: Observable<boolean>;
   displayedColumns = ['name', 'targetDate'];
   teamMember: TeamMember;
   departments: Department[] = [];
   rocks: Rock[] = [];

   data$: Observable<any[]>;
   filter$ = this.facade.filters$.pipe(
      map((filters) => {
         const filter = filters[this.id];
         return filter || {};
      })
   );

   filterConfig: FilterBarConfig = {
      businessUnits: true,
      departments: true,
      targetDate: true,
      hideSearch: true,
   };

   private destroyed$ = new Subject<void>();

   constructor(private facade: DashboardFacade) {
      super();
   }

   ngOnInit(): void {
      this.assignedActions$ = this.facade.assignedActionsAndMilestones$;
      this.teamMemberLinked$ = this.facade.user$.pipe(
         map((user) => user && user.teamMemberId != null)
      );
      this.facade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.facade.rocks$.pipe(takeUntil(this.destroyed$)).subscribe((rocks) => {
         this.rocks = rocks;
      });
      this.data$ = combineLatest([this.assignedActions$, this.filter$]).pipe(
         map(([actions, filter]) => {
            let filtered = [...actions];
            if (filter.departments && filter.departments.length > 0) {
               filtered = filtered.filter((action) =>
                  this.departmentFilter(action, filter.departments)
               );
            } else if (filter.businessUnits && filter.businessUnits.length > 0) {
               const matchingDepartments = this.departments.filter((dept) =>
                  filter.businessUnits.some((bu) => bu.id === dept.businessUnitId)
               );
               filtered = filtered.filter((action) =>
                  this.departmentFilter(action, matchingDepartments)
               );
            }
            if (filter.targetDate) {
               filtered = this.facade.filterByTargetDate(filtered, filter.targetDate);
            }

            return filtered;
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   filterChanged(value: any) {
      this.facade.setWidgetFilter(this.id, value);
   }

   goToItem(row: any) {
      if (row.rockId) {
         this.facade.goToRock(row.rockId);
      } else {
         this.facade.goToActionItem(row.id);
      }
   }

   setLinkedTeamMember(event: MatAutocompleteSelectedEvent) {
      if (event && event.option && event.option.value) {
         this.teamMember = event.option.value;
      } else {
         this.teamMember = null;
      }
   }

   linkTeamMember() {
      this.facade.linkTeamMember(this.teamMember);
   }

   private departmentFilter(action: any, departments: Department[]): boolean {
      let departmentId;
      if (action.departmentId) {
         departmentId = action.departmentId;
      } else {
         const rock = this.rocks.find((r) => r.id === action.rockId);
         departmentId = rock?.departmentId;
      }
      if (departmentId) {
         return departments.some((dept) => dept.id === departmentId);
      } else {
         return false;
      }
   }
}
