<div class="rocks-container">
   <div class="header">
      <div class="title">Rocks</div>
      <button
         mat-raised-button
         class="add-button"
         color="primary"
         *ngIf="canEdit"
         (click)="addRock()"
      >
         <mat-icon>add</mat-icon>
         New Rock
      </button>
   </div>
   <app-table-header
      [hideExport]="tableConfig.hideExport"
      [filterBar]="filterBarConfig"
      [filter$]="filter$"
      [columns]="columns"
      [displayedColumns]="visibleColumns$ | async"
      (columnsUpdated)="setDisplayedColumns($event)"
      (exportData)="export($event)"
   ></app-table-header>
   <mat-tab-group [selectedIndex]="activeTab$ | async" (selectedTabChange)="setActiveTab($event)">
      <mat-tab label="List">
         <div class="table-container">
            <app-table [config]="tableConfig" (dataUpdated)="setFilteredRocks($event)"></app-table>
         </div>
      </mat-tab>
      <mat-tab label="Roadmap">
         <app-rocks-roadmap
            [rocks]="filteredRocks"
            [businessUnits]="businessUnits"
            [departments]="departments"
            [quarters]="quarters"
            [teamMembers]="teamMembers"
            [canEdit]="canEdit"
            (editRock)="edit($event)"
            (saveRock)="save($event)"
         ></app-rocks-roadmap>
      </mat-tab>
   </mat-tab-group>
</div>
