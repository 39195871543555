import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { State } from '@app/app.state';
import { Store, select } from '@ngrx/store';
import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { TeamMember } from '@entities/team-member';
import { Sort } from '@angular/material/sort';
import { getTeamMembers } from '@app/team/state/team.state';
import { map, takeUntil } from 'rxjs/operators';
import { TableService } from '@app/shared/services/table.service';
import { TeamMemberStatus } from '@entities/enums/team-member-status';
import { TeamMemberService } from '@app/team/services/team-member.service';
import { TeamFacade } from '@app/team/state/team.facade';
import { Router, ActivatedRoute } from '@angular/router';
import { Department } from '@entities/department';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { DepartmentNamePipe } from '@app/shared/pipes/department-name.pipe';
import { TableComponent, TableConfig } from '@app/shared/components/table/table.component';
import { FilterValues, FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { teamRouteNames } from '@app/team/team.routes.names';
import { RoleGuard } from '@app/admin/services/role.guard';
import { deepCopy } from '@app/utilities/deep-copy';
import { AppAreas } from '@entities/enums/app-areas';

const initialSort: Sort = { active: 'lastName', direction: 'asc' };

@Component({
   selector: 'app-team-member-list',
   templateUrl: './team-member-list.component.html',
   styleUrls: ['./team-member-list.component.scss'],
})
export class TeamMemberListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent) table: TableComponent;

   displayedColumns: string[] = ['firstName', 'lastName', 'title', 'primaryDepartment.name'];

   teamMembers$: Observable<TeamMember[]>;
   dataSource$: Observable<TeamMember[]>;
   filter$: BehaviorSubject<FilterValues> = new BehaviorSubject({});
   showInactive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
   displayedColumns$ = this.teamFacade.teamMembersDisplayedColumns$;

   departments: Department[];
   teamMemberStatus = TeamMemberStatus;

   /* istanbul ignore next */
   columns: TableColumn<TeamMember>[] = [
      { def: 'firstName', label: 'First Name', visible: true, value: (row) => row.firstName },
      { def: 'lastName', label: 'Last Name', visible: true, value: (row) => row.lastName },
      { def: 'title', label: 'Title', visible: true, value: (row) => row.title },
      {
         def: 'primaryDepartment.name',
         label: 'Department',
         visible: true,
         value: (row) =>
            this.departmentNamePipe.transform(row.primaryDepartmentId, this.departments),
         filter: 'departments',
      },
      {
         def: 'status',
         label: 'Status',
         visible: false,
         value: (row) => TeamMemberStatus[row.status],
      },
   ];

   filterBarConfig: FilterBarConfig = {
      businessUnits: true,
      departments: true,
      filterChange: (filter) => this.filterFormChanged(filter),
   };

   tableConfig: TableConfig;

   canEdit = false;

   private destroyed$ = new Subject<void>();

   constructor(
      private teamFacade: TeamFacade,
      private router: Router,
      private route: ActivatedRoute,
      private departmentNamePipe: DepartmentNamePipe,
      private roleGuard: RoleGuard
   ) {}

   ngOnInit() {
      this.teamMembers$ = this.teamFacade.teamMembers$;
      this.teamFacade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.teamFacade.teamMembersDisplayedColumns$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((columns) => {
            this.displayedColumns = deepCopy(columns);
         });
      this.dataSource$ = combineLatest([this.teamMembers$, this.showInactive$]).pipe(
         map(([teamMembers, showInactive]) => {
            if (showInactive) {
               return teamMembers;
            } else {
               return teamMembers.filter((t) => t.status === TeamMemberStatus.Active);
            }
         })
      );
      this.roleGuard
         .canEdit([], AppAreas.Team)
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
         });

      /* istanbul ignore next */
      this.tableConfig = {
         data$: this.dataSource$,
         filter$: this.filter$,
         columns: this.columns,
         visibleColumns$: this.displayedColumns$,
         initialSort: { active: 'lastName', direction: 'asc' },
         rowClick: (row) => this.edit(row),
         departments: this.departments,
         options: [
            { label: 'Edit', action: (row) => this.edit(row), condition: () => this.canEdit },
            { label: 'Details', action: (row) => this.edit(row), condition: () => !this.canEdit },
            { label: 'View Report', action: (row) => this.viewRoleDescription(row) },
            { label: 'Delete', action: (row) => this.delete(row), condition: () => this.canEdit },
         ],
      };
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   filterFormChanged(filter: FilterValues) {
      this.filter$.next(filter);
   }

   newTeamMember() {
      this.teamFacade.addTeamMember();
      this.router.navigate(['new'], { relativeTo: this.route.parent });
   }

   edit(teamMember: TeamMember) {
      this.teamFacade.selectTeamMember(teamMember.id);
      this.router.navigate([teamMember.id], { relativeTo: this.route });
   }

   viewRoleDescription(teamMember: TeamMember) {
      this.teamFacade.selectTeamMember(teamMember.id);
      this.router.navigate([teamMember.id, teamRouteNames.REPORT], { relativeTo: this.route });
   }

   showInactive(event: any) {
      this.showInactive$.next(event.checked);
      if (event.checked) {
         this.displayedColumns.push('status');
      } else {
         this.displayedColumns = this.displayedColumns.filter((col) => col != 'status');
      }
      this.teamFacade.setTeamMemberDisplayedColumns(this.displayedColumns);
   }

   setActive(teamMember: TeamMember) {
      this.teamFacade.setActive(teamMember);
   }

   setInactive(teamMember: TeamMember) {
      this.teamFacade.setInactive(teamMember);
   }

   delete(teamMember: TeamMember) {
      this.teamFacade.deleteTeamMember(teamMember);
   }

   setDisplayedColumns(columns: TableColumn<TeamMember>[]) {
      this.teamFacade.setTeamMemberDisplayedColumns(columns.map((col) => col.def));
   }

   export(filterData: boolean) {
      this.table.export(filterData);
   }
}
