export const appRoutesNames = {
   LOGIN: 'login',
   CALLBACK: 'callback',
   SIGNUP: 'signup',
   INVITE: 'invite',
   NEWORG: 'create-organization',
   LANDING: 'landing',
   OVERVIEW: 'vision',
   BENCHMARKS: 'benchmarks',
   DOCUMENTATION: 'documentation',
   EVOLVE: 'evolve',
   ORGANIZATION: 'org',
   ORG_BUILDER: 'org-builder',
   TEAM: 'team',
   SETTINGS: 'settings',
   PERFORMANCE: 'performance',
   NEW_CUSTOMER: 'new-customer',
   MEETINGS: 'meetings',
   USER_ACCOUNT: 'user-account',
   DASHBOARD: 'dashboard',
   STATUS: 'status',
   INTERNAL: 'internal',
   UNAUTHORIZED: 'unauthorized',
   SEARCH: 'search',
};
