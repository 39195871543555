import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AuthGuardModule } from '@angular/fire/auth-guard';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule, routerReducer, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { initializeApp } from 'firebase/app';
import { NgxStripeModule } from 'ngx-stripe';

// modules
import { AdminModule } from './admin/admin.module';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DocumentationModule } from './documentation/documentation.module';
import { EvolveModule } from './evolve/evolve.module';
import { InternalToolsModule } from './internal-tools/internal-tools.module';
import { LayoutModule } from './layout/layout.module';
import { MeetingsModule } from './meetings/meetings.module';
import { OrgBuilderModule } from './org-builder/org-builder.module';
import { OverviewModule } from './overview/overview.module';
import { PerformanceModule } from './performance/performance.module';
import { SearchModule } from './search/search.module';
import { SharedModule } from './shared/shared.module';
import { TeamModule } from './team/team.module';
import { UserOrgModule } from './user-org/user-org.module';

// services
import { ConfigService } from './utilities/config.service';
import { HeadersInterceptor } from './utilities/headers.service';
import { FeatureFlagService } from './internal-tools/services/feature-flag.service';
import { SentryErrorHandler } from './utilities/sentry-error-handler';

// other
import { APP_ROUTES } from './app.routes';
import { devMetaReducers, prodMetaReducers } from './app.state';
import { environment } from '@src/environments/environment';
import { AuthGuard } from './auth/services/auth.guard';
import { AppComponent } from './app.component';
import { StatusComponent } from './utilities/status/status.component';
import { HistoryEffects, reducer as historyReducer } from './shared/state/history.state';

@NgModule({
   declarations: [AppComponent, StatusComponent],
   imports: [
      // angular modules
      CommonModule,
      BrowserModule,
      HttpClientModule,
      RouterModule.forRoot(APP_ROUTES, {}),
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideFirestore(() => getFirestore()),
      provideFunctions(() => getFunctions()),
      provideAuth(() => getAuth()),
      provideStorage(() => getStorage()),
      AuthGuardModule,
      // ngrx modules
      StoreModule.forRoot(
         { router: routerReducer, history: historyReducer },
         { metaReducers: environment.production ? prodMetaReducers : devMetaReducers }
      ),
      EffectsModule.forRoot([HistoryEffects]),
      StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
      StoreDevtoolsModule.instrument({}),
      // other third party modules
      NgxStripeModule.forRoot(environment.stripe.publicKey),
      // app modules
      AdminModule,
      AuthModule,
      DocumentationModule,
      EvolveModule,
      OrgBuilderModule,
      OverviewModule,
      TeamModule,
      LayoutModule,
      UserOrgModule,
      PerformanceModule,
      MeetingsModule,
      DashboardModule,
      SharedModule.forRoot(),
      InternalToolsModule,
      SearchModule,
   ],
   providers: [
      ConfigService,
      { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
      { provide: ErrorHandler, useClass: SentryErrorHandler },
      AuthGuard,
      FeatureFlagService,
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
