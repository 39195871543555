import { Component, OnInit, Input, AfterViewInit, HostListener, ViewChild } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';

import { Organization } from '@entities/organization';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { FocusMonitor } from '@angular/cdk/a11y';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { FirebaseAuthService } from '@app/auth/services/firebase-auth.service';
import { AuthUser } from '@entities/auth-user';
import { LayoutFacade } from '@app/layout/state/layout.facade';
import { navConfig } from '@app/layout/state/layout.config';
import { FeatureFlagService } from '@app/internal-tools/services/feature-flag.service';
import { FeatureFlagKeys } from '@app/internal-tools/state/feature-flag-keys';
import { appRoutesNames } from '@app/app.routes.names';
import { SearchInputComponent } from '@app/search/components/search-input/search-input.component';

@Component({
   selector: 'app-topnav',
   templateUrl: './topnav.component.html',
   styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent implements OnInit, AfterViewInit {
   title$: Observable<string>;
   loggedIn$: Observable<boolean>;
   sidenavOpen$: Observable<boolean>;
   currentUser$: Observable<AuthUser>;
   userDisplayName$: Observable<string>;
   breadcrumbs$: Observable<any[]>;
   orgSelected$: Observable<boolean>;
   showAllLink$: Observable<boolean>;
   recentOrgs$: Observable<Organization[]>;
   showRecent$: Observable<boolean>;
   helpFeatureFlag$: Observable<boolean>;
   searchFeatureFlag$: Observable<boolean>;
   selectedOrgId: string;
   loggedIn: boolean;
   showSearch = false;

   @ViewChild(SearchInputComponent) searchComponent: SearchInputComponent;

   constructor(
      private router: Router,
      private focusMonitor: FocusMonitor,
      private authService: FirebaseAuthService,
      private userOrgFacade: UserOrgFacade,
      private layoutFacade: LayoutFacade,
      private featureFlagService: FeatureFlagService
   ) {}

   ngOnInit() {
      this.loggedIn$ = this.layoutFacade.loggedIn$.pipe(
         tap((loggedIn) => {
            this.loggedIn = loggedIn;
         })
      );
      this.currentUser$ = this.layoutFacade.currentUser$;
      this.orgSelected$ = this.layoutFacade.orgId$.pipe(map((orgId) => !!orgId));
      this.recentOrgs$ = combineLatest([
         this.userOrgFacade.orgsForUser$,
         this.layoutFacade.recentOrgs$,
      ]).pipe(
         map(([orgs, recent]) => {
            const recentOrgs = [];
            if (recent) {
               recent.forEach((orgId) => {
                  const org = orgs.find((o) => o.organizationId == orgId);
                  if (org) {
                     recentOrgs.push(org);
                  }
               });
            }
            return recentOrgs;
         })
      );
      this.showRecent$ = this.layoutFacade.recentOrgs$.pipe(
         map((recent) => recent && recent.length > 0)
      );
      this.title$ = this.userOrgFacade.selectedOrg$.pipe(
         map((org) => {
            if (org) {
               this.selectedOrgId = org.organizationId;
               return org.name;
            } else {
               this.selectedOrgId = null;
               return null;
            }
         })
      );
      this.userDisplayName$ = this.currentUser$.pipe(
         map((user) => {
            if (user) {
               return user.displayName;
            } else {
               return '';
            }
         })
      );
      this.breadcrumbs$ = this.layoutFacade.route$.pipe(
         map((route) => {
            const breadcrumbs = [];
            if (route) {
               const segments = route.split('/');
               let routes: any[] = navConfig;
               for (let i = 2; i < segments.length; i++) {
                  const route = routes.find((r) => r.route == segments[i]);
                  if (route) {
                     breadcrumbs.push({ label: route.label, route: route.route });
                     if (route.children) {
                        routes = route.children;
                     } else {
                        break;
                     }
                  }
               }
            }
            return breadcrumbs;
         })
      );
      this.helpFeatureFlag$ = this.featureFlagService.isEnabled(FeatureFlagKeys.HELP_SIDEBAR);
   }

   ngAfterViewInit() {
      this.focusMonitor.stopMonitoring(document.getElementById('sidenav-toggle'));
   }

   toggleSidenav() {
      this.layoutFacade.toggleSidenav();
   }

   logoClick() {
      if (this.loggedIn) {
         this.goToOrgs();
      } else {
         this.goToLogin();
      }
   }

   logout() {
      this.authService.logout();
   }

   goToOrgs() {
      this.userOrgFacade.setOrganization(null);
      this.router.navigate([appRoutesNames.LANDING]);
   }

   goToOrg(organizationId: string) {
      this.userOrgFacade.setOrganization(organizationId);
      this.layoutFacade.goToOrg(organizationId);
   }

   getRoute(breadcrumb: any) {
      return this.router.url.split(breadcrumb.route)[0] + breadcrumb.route;
   }

   toggleHelp() {
      this.layoutFacade.toggleHelp();
   }

   goToUserAccount() {
      this.layoutFacade.goToUserAccount();
   }

   goToLogin() {
      this.layoutFacade.goToLogin();
   }

   toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch) {
         setTimeout(() => {
            this.searchComponent?.focusInput();
         }, 100);
      }
   }
}
