export function processEntity(entity: any) {
   if (typeof entity !== 'object') {
      return entity;
   }
   const processed = { ...entity };
   Object.keys(entity).forEach((key) => {
      // convert undefined to null so that firebase is happy
      if (entity[key] == undefined) {
         processed[key] = null;
      } else if (entity[key] && typeof entity[key] === 'object') {
         if (Array.isArray(entity[key])) {
            processed[key] = entity[key]
               .filter(Boolean)
               .map((x) => (typeof x === 'object' ? processEntity(x) : x));
         } else {
            processed[key] = processEntity(processed[key]);
         }
      }
   });
   return processed;
}
