import { Injectable, OnDestroy, inject } from '@angular/core';
import { Observable, combineLatest, of, Subject, from } from 'rxjs';
import { User } from '@entities/user';
import { Organization } from '@entities/organization';
import { map, switchMap, takeUntil, catchError, takeWhile, take, skip } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import * as UserOrgActions from './user-org.actions';
import {
   getOrgsForUser,
   getOrganizationId,
   getOrganization,
   getCurrentUser,
   getUsersForAccount,
} from './user-org.selectors';
import { getAuthUser } from '@app/auth/state/auth.state';

import { AuthUser } from '@entities/auth-user';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { FunctionNames } from '@app/utilities/functionNames';
import { AuthUserUpdated } from '@app/auth/state/auth.actions';
import { ProductType } from '@entities/enums/product-type';
import { adminActions } from '@app/admin/state/admin.actions';
import { UserRole } from '@entities/enums/user-role';
import { FirestoreService } from '@app/shared/services/firestore.service';
import { FunctionsService } from '@app/shared/services/functions.service';
import { or, where } from '@angular/fire/firestore';

@Injectable()
export class UserOrgFacade implements OnDestroy {
   orgsForUser$: Observable<Organization[]> = this.store.pipe(
      select(getOrgsForUser),
      map((orgs) => [...orgs].sort((a, b) => a.name.localeCompare(b.name)))
   );
   selectedOrgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   selectedOrg$: Observable<Organization> = this.store.pipe(select(getOrganization));
   currentUser$: Observable<User> = this.store.pipe(select(getCurrentUser));
   usersForAccount$: Observable<User[]> = this.store.pipe(select(getUsersForAccount));
   authUser$: Observable<AuthUser> = this.store.pipe(select(getAuthUser));

   private destroyed$ = new Subject<void>();
   private inviteComplete$ = new Subject<void>();
   private firestore = inject(FirestoreService);
   private functions = inject(FunctionsService);

   constructor(private store: Store<State>) {
      this.getUsersForAccount()
         .pipe(takeUntil(this.destroyed$))
         .subscribe((users) => {
            this.store.dispatch(UserOrgActions.UsersUpdated({ users }));
         });
      this.getOrgsForUser()
         .pipe(takeUntil(this.destroyed$))
         .subscribe((orgs) => {
            this.store.dispatch(UserOrgActions.OrganizationsUpdated({ orgs }));
         });
      this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
         if (user && !user.products) {
            const updatedUser = {
               ...user,
               products: [ProductType.Complete],
            };
            this.store.dispatch(adminActions.SaveUser({ user: updatedUser }));
         }
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   private getOrgsForUser() {
      return this.store.pipe(
         select(getAuthUser),
         switchMap((authUser) => {
            if (authUser && authUser.orgs && authUser.orgs.length > 0) {
               const ids = authUser.orgs.filter(
                  (org, index) => authUser.orgs.indexOf(org) === index
               );
               return combineLatest(
                  ids.map((id) => {
                     return this.firestore.docData(COLLECTION_NAMES.ORGANIZATIONS, id).pipe(
                        map((org) => {
                           return org;
                        }),
                        catchError((err) => {
                           // if error accessing org, return undefined
                           return of();
                        })
                     );
                  })
               ).pipe(
                  map((orgs) => {
                     // remove errored orgs from list
                     const filtered = orgs.filter((o) => o != undefined);
                     return filtered;
                  })
               );
            } else {
               return of([]);
            }
         })
      );
   }

   private getUsersForAccount() {
      return combineLatest([this.orgsForUser$, this.authUser$]).pipe(
         switchMap(([orgs, authUser]) => {
            if (orgs && orgs.length > 0) {
               return combineLatest(
                  orgs.map((org) => {
                     if (org.organizationId) {
                        const collection = this.firestore.collection(
                           COLLECTION_NAMES.ORGANIZATIONS,
                           org.organizationId,
                           COLLECTION_NAMES.USERS
                        );
                        return from(
                           this.firestore.queryCollection(
                              collection,
                              this.firestore.where('userAccountId', '==', authUser.uid)
                           )
                        ).pipe(
                           map((snap) => {
                              if (snap.docs.length > 0) {
                                 const user: User = snap.docs[0].data() as User;
                                 if (user && user.isAdmin === null) {
                                    user.isAdmin = user.role === UserRole.Admin;
                                    this.store.dispatch(adminActions.SaveUser({ user }));
                                 }
                                 return user;
                              } else {
                                 return null;
                              }
                           })
                        );
                     } else {
                        return of();
                     }
                  })
               ).pipe(
                  map((users) => {
                     return users.filter((u) => !!u);
                  })
               );
            } else {
               return of([]);
            }
         })
      );
   }

   createOrganization(organization: Organization) {
      this.store.dispatch(UserOrgActions.CreateOrganization({ organization }));
   }

   selectOrganization(organizationId: string) {
      this.store.dispatch(UserOrgActions.SelectOrganization({ organizationId }));
   }

   setOrganization(organizationId: string) {
      this.store.dispatch(UserOrgActions.SetOrganization({ organizationId }));
   }

   saveOrgInfo(organization: Organization) {
      this.store.dispatch(UserOrgActions.SaveOrgInfo({ organization }));
   }

   linkToDemoOrg(): Observable<any> {
      return from(this.functions.httpsCallable(FunctionNames.LINK_DEMO_ORG)({}));
   }

   acceptInvite(organizationId: string, inviteId: string, authId: string) {
      const data = {
         organizationId,
         inviteId,
         authId,
      };
      return from(this.functions.httpsCallable(FunctionNames.ACCEPT_INVITE)(data));
   }

   inviteAccepted(authUser: AuthUser, organizationId: string) {
      this.store.dispatch(AuthUserUpdated({ authUser }));
      combineLatest([this.orgsForUser$, this.usersForAccount$])
         .pipe(takeUntil(this.inviteComplete$))
         .subscribe(([orgs, users]) => {
            if (
               orgs.find((o) => o.organizationId === organizationId) &&
               users.find((u) => u.organizationId === organizationId)
            ) {
               this.store.dispatch(UserOrgActions.SelectOrganization({ organizationId }));
               this.inviteComplete$.next();
            }
         });
   }

   getProducts(): Observable<any[]> {
      return from(this.functions.httpsCallable(FunctionNames.GET_PRODUCTS)(null)).pipe(
         map((result: any) => {
            return result.data;
         })
      );
   }

   saveAuthUser(authUser: AuthUser) {
      this.store.dispatch(UserOrgActions.SaveAuthUser({ authUser }));
   }

   leaveOrganization(organization: Organization) {
      this.store.dispatch(UserOrgActions.LeaveOrganization({ organization }));
   }
}
