import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   SimpleChanges,
   OnChanges,
   ViewChild,
   TemplateRef,
} from '@angular/core';
import { RockAction } from '@entities/rock-action';
import { TeamMember } from '@entities/team-member';
import * as moment from 'moment';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
   MatLegacyDialog as MatDialog,
   MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { froalaConfig } from '@app/shared/config/froala.config';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
   selector: 'app-rock-actions',
   templateUrl: './rock-actions.component.html',
   styleUrls: ['./rock-actions.component.scss'],
})
export class RockActionsComponent implements OnInit, OnChanges {
   @Input() actions: RockAction[] = [];
   @Input() teamMembers: TeamMember[] = [];
   @Input() canEdit = false;
   @Output() actionsUpdated = new EventEmitter<RockAction[]>();

   @ViewChild('editActionDialogTemplate') editActionDialogTemplate: TemplateRef<any>;
   editActionDialogRef: MatDialogRef<any>;

   actionForm: UntypedFormGroup;
   editActions: RockAction[] = [];
   editActionCopy: RockAction;
   mobile = true;

   baseEditorConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Description',
      minHeight: 200,
   };
   editorConfig: any;

   validate$ = new Subject<void>();

   constructor(private breakpointObserver: BreakpointObserver, private dialog: MatDialog) {}

   ngOnInit(): void {
      this.actionForm = new UntypedFormGroup({
         description: new UntypedFormControl(),
         targetDate: new UntypedFormControl(),
         assigneeId: new UntypedFormControl(null, Validators.required),
      });
      this.sortActions();
      this.breakpointObserver.observe(['(max-width: 767px)']).subscribe((state) => {
         if (state.matches) {
            this.mobile = true;
         } else {
            this.mobile = false;
         }
      });
      this.actionForm.valueChanges.subscribe((value) => {
         if (!value.description && !value.targetDate && !value.assigneeId) {
            // form is empty, can mark as pristine
            this.actionForm.markAsPristine();
         }
      });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['actions']) {
         this.sortActions();
      }
   }

   getControl(name: string): UntypedFormControl {
      return this.actionForm.get(name) as UntypedFormControl;
   }

   sortActions() {
      this.actions = this.actions.sort((a, b) => {
         if (a.completed && !b.completed) {
            return 1;
         } else if (b.completed && !a.completed) {
            return -1;
         } else {
            return (a.targetDate || '').localeCompare(b.targetDate);
         }
      });
   }

   addAction() {
      const value = this.actionForm.value;
      const action: RockAction = {
         ...value,
         created: moment().toISOString(),
         completed: false,
      };
      this.actions = [action, ...this.actions];
      this.actionForm.reset();
      this.actionsUpdated.emit(this.actions);
   }

   editAction(action: RockAction) {
      // this.editActions.push({ ...action });
      this.editActionCopy = { ...action };
      this.editorConfig = { ...this.baseEditorConfig };
      this.editActionDialogRef = this.dialog.open(this.editActionDialogTemplate, {
         width: '750px',
         maxWidth: '80vw',
         height: '600px',
         maxHeight: '80vh',
      });
   }

   deleteAction(action: RockAction) {
      this.actions = this.actions.filter(
         (a) => a.created != action.created && a.description != action.description
      );
      this.actionsUpdated.emit(this.actions);
   }

   validateAddAction() {
      if (this.actionForm.dirty) {
         this.actionForm.markAllAsTouched();
         this.actionForm.get('description').markAsDirty();
         this.actionForm.get('targetDate').markAsDirty();
         this.actionForm.get('assigneeId').markAsDirty();
         this.actionForm.updateValueAndValidity({ emitEvent: true });
         this.validate$.next();
      }
   }

   setActionAssignedToId(event: MatAutocompleteSelectedEvent, action?: RockAction) {
      if (action) {
         action.assigneeId = event ? event.option.value.id : null;
      } else {
         this.actionForm.get('assigneeId').setValue(event ? event.option.value.id : null);
      }
   }

   showEditAction(action: RockAction) {
      return this.editActions.findIndex((a) => a.created == action.created) >= 0;
   }

   saveEditAction() {
      // this.editActions = this.editActions.filter((a) => a.created != action.created);
      this.actions = [
         ...this.actions.filter((a) => a.created != this.editActionCopy.created),
         this.editActionCopy,
      ];
      this.actionsUpdated.emit(this.actions);
      this.editActionDialogRef?.close();
   }

   cancelEditAction(action: RockAction) {
      const originalAction = this.editActions.find((a) => a.created == action.created);
      action.description = originalAction.description;
      this.editActions = this.editActions.filter((a) => a.created != action.created);
   }

   setDate(event: MatDatepickerInputEvent<moment.Moment>, action?: RockAction) {
      const date = event.value;
      const value = date ? date.toISOString() : null;
      if (action) {
         action.targetDate = value;
      } else {
         const formControl = this.actionForm.get('targetDate');
         formControl.setValue(value);
      }
   }

   setNotes(notes: string, action: RockAction) {
      action.notes = notes;
   }

   toggleCompleted() {
      this.actionsUpdated.emit(this.actions);
   }

   isOverdue(action: RockAction) {
      const targetDate = moment(action.targetDate);
      return !action.completed && moment().isAfter(targetDate);
   }
}
