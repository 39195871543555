import { Pipe, PipeTransform } from '@angular/core';
import { BusinessUnit } from '@entities/business-unit';

@Pipe({
  name: 'businessUnitName',
  pure: true
})
export class BusinessUnitNamePipe implements PipeTransform {
  transform(value: string, businessUnits: BusinessUnit[] = []): any {
    const businessUnit = businessUnits.find((dept) => dept.id == value);
    return businessUnit ? businessUnit.name : '';
  }
}
