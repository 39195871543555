import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { MeetingsFacade } from '@app/meetings/state/meetings.facade';
import { Meeting } from '@entities/meeting';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { MeetingsRockDetailComponent } from '../meetings-rock-detail/meetings-rock-detail.component';
import { UserComment } from '@entities/user-comment';
import { DiscussionItem } from '@entities/discussion-item';
import { deepCopy } from '@app/utilities/deep-copy';
import { MeetingActionItemDetailsComponent } from '../meeting-action-item-details/meeting-action-item-details.component';
import { RockAction } from '@entities/rock-action';
import { DialogService } from '@app/shared/services/dialog.service';
import { TimerComponent } from '@app/shared/components/timer/timer.component';
import { RockStatus } from '@entities/enums/rock-status';
import { froalaConfig } from '@app/shared/config/froala.config';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Status } from '@entities/enums/status';
import { Priority } from '@entities/enums/priority';
import { ActionItemType } from '@entities/enums/action-item-type';
import { TitleEditComponent } from '@app/shared/components/title-edit/title-edit.component';
import { Rock } from '@entities/rock';
import { ActionItem } from '@entities/action-item';

enum NextItemMode {
   DEFAULT,
   CREATE_ITEM,
   END_MEETING,
}

@Component({
   selector: 'app-active-meeting',
   templateUrl: './active-meeting.component.html',
   styleUrls: ['./active-meeting.component.scss'],
})
export class ActiveMeetingComponent implements OnInit, OnDestroy {
   showRocks$: Observable<boolean>;
   showActionItems$: Observable<boolean>;
   showEndMeeting = false;
   showRocks = true;
   showNotes = true;
   meeting: Meeting;
   selectedRockId: string;
   selectedActionItemId: string;
   selectedIndex = 0;
   timePerItem = 120;

   mode = NextItemMode;

   editorConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Description',
      minHeight: 100,
   };

   rockStatus = RockStatus;
   rockStatusKeys = Object.keys(RockStatus)
      .filter((key) => !isNaN(+key))
      .map((key) => +key);

   actionStatus = Status;
   actionStatusKeys = Object.keys(Status)
      .filter((key) => !isNaN(+key))
      .map((key) => +key);

   actionType = ActionItemType;
   actionTypeKeys = Object.keys(Status)
      .filter((key) => !isNaN(+key))
      .map((key) => +key);

   actionPriority = Priority;
   actionPriorityKeys = Object.keys(Priority)
      .filter((key) => !isNaN(+key))
      .map((key) => +key);

   newRockForm: UntypedFormGroup;
   newActionForm: UntypedFormGroup;
   meetingLoaded = false;

   @ViewChild(TimerComponent) timer: TimerComponent;
   @ViewChild(MeetingsRockDetailComponent)
   rockDetails: MeetingsRockDetailComponent;
   @ViewChild(MeetingActionItemDetailsComponent)
   actionItemDetails: MeetingActionItemDetailsComponent;
   @ViewChild('newRockDialog') newRockDialog: TemplateRef<any>;
   @ViewChild('newActionDialog') newActionDialog: TemplateRef<any>;
   @ViewChild(TitleEditComponent) titleEditComponent: TitleEditComponent;

   rockName: string;
   rockNameFormControl = new UntypedFormControl();
   actionName: string;
   actionNameFormControl = new UntypedFormControl();
   currentRock: Rock;
   currentAction: ActionItem;

   private destroyed$ = new Subject<void>();

   constructor(
      private meetingsFacade: MeetingsFacade,
      private dialogService: DialogService,
      private dialog: MatDialog
   ) {}

   ngOnInit() {
      this.meetingsFacade.selectedMeeting$.pipe(takeUntil(this.destroyed$)).subscribe((meeting) => {
         if (meeting) {
            this.meeting = deepCopy(meeting);
            if (this.meeting.rocks.length === 1 && this.meeting.actionItems.length === 0) {
               this.showEndMeeting = true;
            }
            if (meeting.timePerItem) {
               this.timePerItem = meeting.timePerItem;
            }
            // only do this check the first time the meeting loads
            if (meeting.rocks.length === 0 && !this.meetingLoaded) {
               this.showRocks = false;
               this.meetingsFacade.selectActionItem(meeting.actionItems[0]);
            }
            this.meetingLoaded = true;
         }
      });
      this.meetingsFacade.selectedRock$.pipe(takeUntil(this.destroyed$)).subscribe((rock) => {
         if (rock) {
            this.currentRock = rock;
            this.selectedRockId = rock.id;
            this.rockName = rock.name;
            this.rockNameFormControl.setValue(rock.name);
            if (this.showRocks) {
               this.selectedIndex = this.meeting.rocks.indexOf(rock.id);
            }
         }
      });
      this.meetingsFacade.selectedActionItem$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((actionItem) => {
            if (actionItem) {
               this.currentAction = actionItem;
               this.selectedActionItemId = actionItem.id;
               this.actionName = actionItem.name;
               this.actionNameFormControl.setValue(actionItem.name);
               if (!this.showRocks) {
                  this.selectedIndex =
                     this.meeting.actionItems.indexOf(actionItem.id) + this.meeting.rocks.length;
               }
            }
         });
      this.showActionItems$ = this.meetingsFacade.showActionItems$;
      this.showRocks$ = this.meetingsFacade.showRocks$;
      this.showRocks$.pipe(takeUntil(this.destroyed$)).subscribe((showRocks) => {
         this.showRocks = showRocks;
      });
      this.setRockForm();
      this.setActionForm();
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getControl(form: UntypedFormGroup, name: string): UntypedFormControl {
      return form.get(name) as UntypedFormControl;
   }

   nextItem(mode: NextItemMode = NextItemMode.DEFAULT) {
      this.setTimeSpent();
      if (this.showRocks) {
         this.trySaveRock(mode);
      } else {
         this.trySaveActionItem(mode);
      }
   }

   setTimeSpent() {
      const timeSpent = this.timePerItem - this.timer.current;
      const activeItemId = this.showRocks ? this.selectedRockId : this.selectedActionItemId;
      const discussionItem = this.meeting.discussionItems.find(
         (item) => item.entityId === activeItemId
      );
      if (discussionItem) {
         discussionItem.timeSpent = timeSpent;
      } else {
         const newItem: DiscussionItem = {
            entityId: activeItemId,
            entityType: this.showRocks ? 'Rock' : 'Action',
            timeSpent,
            comments: [],
            actions: [],
         };
         this.meeting = {
            ...this.meeting,
            discussionItems: [...this.meeting.discussionItems, newItem],
         };
      }
      this.meetingsFacade.saveMeeting(this.meeting);
   }

   endMeeting() {
      this.meetingsFacade.saveMeeting(this.meeting);
      this.meetingsFacade.endMeeting();
   }

   endMeetingEarly() {
      this.dialogService
         .showConfirmDialog({
            title: 'End Meeting?',
            message: 'Not all items have been reviewed. Would you like to end the meeting early?',
            confirm: 'Yes, end meeting',
            deny: 'No, go back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.nextItem(NextItemMode.END_MEETING);
            }
         });
   }

   newRock() {
      this.setRockForm();
      this.dialog
         .open(this.newRockDialog)
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               const rock = this.newRockForm.value;
               this.meetingsFacade.createRock(rock);
            }
            this.newRockForm.reset();
         });
   }

   setNewRockDepartment(event: MatAutocompleteSelectedEvent) {
      if (event) {
         this.newRockForm.get('departmentId').setValue(event.option.value.id);
      } else {
         this.newRockForm.get('departmentId').reset();
      }
   }

   setRockForm() {
      this.newRockForm = new UntypedFormGroup({
         name: new UntypedFormControl(),
         departmentId: new UntypedFormControl(),
         quarter: new UntypedFormControl('Pending'),
         status: new UntypedFormControl(RockStatus['Not Started']),
         description: new UntypedFormControl(),
      });
   }

   setActionForm() {
      this.newActionForm = new UntypedFormGroup({
         name: new UntypedFormControl(),
         type: new UntypedFormControl(),
         status: new UntypedFormControl(Status['Not Started']),
         priority: new UntypedFormControl(Priority.Medium),
         departmentId: new UntypedFormControl(),
         description: new UntypedFormControl(),
      });
   }
   setNewActionDepartment(event: MatAutocompleteSelectedEvent) {
      if (event) {
         this.newActionForm.get('departmentId').setValue(event.option.value.id);
      } else {
         this.newActionForm.get('departmentId').reset();
      }
   }

   newAction() {
      this.setActionForm();
      this.dialog
         .open(this.newActionDialog)
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               const action = this.newActionForm.value;
               this.meetingsFacade.saveActionItem(action, false);
            }
            this.newActionForm.reset();
         });
   }

   rockValid() {
      if (this.rockDetails) {
         this.rockDetails.form.markAllAsTouched();
         this.rockDetails.form.updateValueAndValidity();
         let actionFormValid = true;
         let actionsListValid = true;
         if (this.rockDetails.rockActionsComponent) {
            this.rockDetails.rockActionsComponent.validateAddAction();
            actionFormValid =
               this.rockDetails.rockActionsComponent.actionForm.valid ||
               this.rockDetails.rockActionsComponent.actionForm.pristine;
            actionsListValid = this.rockDetails.rockActionsComponent.editActions.length == 0;
         }
         return this.rockDetails.form.valid && actionFormValid;
      } else {
         return true;
      }
   }

   trySaveRock(mode: NextItemMode = NextItemMode.DEFAULT) {
      if (this.rockValid()) {
         this.saveRock(mode);
      } else {
         this.dialogService
            .showConfirmDialog({
               title: 'Validation Error',
               message:
                  'The current rock cannot be saved. Please fix any validation errors before proceeding.',
               confirm: 'Go Back',
               deny: 'Continue without saving',
               confirmColor: 'primary',
               denyColor: 'accent',
            })
            .afterClosed()
            .subscribe((result) => {
               if (result === false) {
                  this.rockDetails.rockActionsComponent.actionForm.reset();
                  this.rockDetails.userCommentsComponent.commentText = null;
                  switch (mode) {
                     case NextItemMode.CREATE_ITEM:
                        this.addRock();
                        break;
                     case NextItemMode.END_MEETING:
                        this.endMeeting();
                        break;
                     default:
                        this.nextRock();
                        break;
                  }
               }
            });
      }
   }

   saveRock(mode: NextItemMode = NextItemMode.DEFAULT) {
      if (
         this.rockDetails &&
         this.rockDetails.userCommentsComponent &&
         this.rockDetails.userCommentsComponent.commentText
      ) {
         this.rockDetails.userCommentsComponent.addComment();
      }
      if (
         this.rockDetails &&
         this.rockDetails.rockActionsComponent &&
         this.rockDetails.rockActionsComponent.actionForm.dirty &&
         this.rockDetails.rockActionsComponent.actionForm.valid
      ) {
         this.rockDetails.rockActionsComponent.addAction();
      }
      this.setRockTitle(this.rockNameFormControl.value);
      if (this.titleEditComponent) {
         this.titleEditComponent.showEditName = false;
      }
      this.checkFieldsChanged();
      this.rockDetails.save();
      switch (mode) {
         case NextItemMode.CREATE_ITEM:
            this.addRock();
            break;
         case NextItemMode.END_MEETING:
            this.endMeeting();
            break;
         default:
            this.nextRock();
            break;
      }
   }

   checkFieldsChanged() {
      const fieldsChanged = [];
      const entityToSave = this.showRocks
         ? this.rockDetails.form.value
         : this.actionItemDetails.form.value;
      const currentEntity = this.showRocks ? this.currentRock : this.currentAction;
      Object.keys(entityToSave).forEach((key) => {
         if (typeof entityToSave[key] !== 'object' && entityToSave[key] !== currentEntity[key]) {
            fieldsChanged.push({
               field: key,
               oldValue: currentEntity[key],
               newValue: entityToSave[key],
            });
         }
      });
      const discussionItem = this.meeting.discussionItems.find(
         (item) => item.entityId === currentEntity.id
      );
      if (discussionItem) {
         const updatedDiscussionItem = {
            ...discussionItem,
            fieldsChanged,
         };
         this.meeting = {
            ...this.meeting,
            discussionItems: [
               ...this.meeting.discussionItems.filter(
                  (item) => item.entityId !== discussionItem.entityId
               ),
               updatedDiscussionItem,
            ],
         };
      } else {
         const newDiscussionItem: DiscussionItem = {
            entityId: this.currentRock.id,
            entityType: this.showRocks ? 'Rock' : 'Action',
            comments: [],
            actions: [],
            fieldsChanged,
         };
         this.meeting = {
            ...this.meeting,
            discussionItems: [...this.meeting.discussionItems, newDiscussionItem],
         };
      }
      this.meetingsFacade.saveMeeting(this.meeting);
   }

   nextRock() {
      this.timer.restartTimer();
      this.selectedIndex++;
      this.meetingsFacade.saveMeeting(this.meeting);

      const nextIndex = this.meeting.rocks.indexOf(this.selectedRockId) + 1;
      const nextRock = this.meeting.rocks[nextIndex];
      if (nextRock) {
         this.meetingsFacade.selectRock(nextRock);
         this.showEndMeeting =
            this.meeting.rocks.length == nextIndex + 1 && this.meeting.actionItems.length == 0;
      } else {
         this.meetingsFacade.selectActionItem(this.meeting.actionItems[0]);
         this.showEndMeeting = this.meeting.actionItems.length == 1;
      }
   }

   actionItemValid() {
      if (this.actionItemDetails) {
         return this.actionItemDetails.form.valid;
      } else {
         return true;
      }
   }

   trySaveActionItem(mode: NextItemMode = NextItemMode.DEFAULT) {
      if (this.actionItemValid()) {
         this.saveActionItem(mode);
      } else {
         this.dialogService
            .showConfirmDialog({
               title: 'Validation Error',
               message:
                  'The current action cannot be saved. Please fix any validation errors before proceeding.',
               confirm: 'Go Back',
               deny: 'Continue without saving',
               confirmColor: 'primary',
               denyColor: 'accent',
            })
            .afterClosed()
            .subscribe((result) => {
               if (result === false) {
                  this.actionItemDetails.userCommentsComponent.commentText = null;
                  switch (mode) {
                     case NextItemMode.CREATE_ITEM:
                        this.addActionItem();
                        break;
                     case NextItemMode.END_MEETING:
                        this.endMeeting();
                        break;
                     default:
                        this.nextActionItem();
                        break;
                  }
               }
            });
      }
   }

   saveActionItem(mode: NextItemMode = NextItemMode.DEFAULT) {
      if (
         this.actionItemDetails &&
         this.actionItemDetails.userCommentsComponent &&
         this.actionItemDetails.userCommentsComponent.commentText
      ) {
         this.actionItemDetails.userCommentsComponent.addComment();
      }
      this.setActionTitle(this.actionNameFormControl.value);
      if (this.titleEditComponent) {
         this.titleEditComponent.showEditName = false;
      }
      this.checkFieldsChanged();
      this.actionItemDetails.save();
      switch (mode) {
         case NextItemMode.CREATE_ITEM:
            this.addActionItem();
            break;
         case NextItemMode.END_MEETING:
            this.endMeeting();
            break;
         default:
            this.nextActionItem();
            break;
      }
   }

   nextActionItem() {
      this.timer.restartTimer();
      this.selectedIndex++;
      this.meetingsFacade.saveMeeting(this.meeting);
      const nextIndex = this.meeting.actionItems.indexOf(this.selectedActionItemId) + 1;
      const nextActionItem = this.meeting.actionItems[nextIndex];
      this.meetingsFacade.selectActionItem(nextActionItem);
      this.showEndMeeting = this.meeting.actionItems.length == nextIndex + 1;
   }

   addComment(comment: UserComment) {
      const entityId = this.showRocks ? this.selectedRockId : this.selectedActionItemId;
      const currentItem = this.meeting.discussionItems.find((item) => item.entityId == entityId);
      if (currentItem) {
         currentItem.comments.push(comment);
      } else {
         const newItem: DiscussionItem = {
            entityId,
            entityType: this.showRocks ? 'Rock' : 'Action',
            comments: [comment],
            actions: [],
         };
         this.meeting = {
            ...this.meeting,
            discussionItems: [...this.meeting.discussionItems, newItem],
         };
      }
      this.meetingsFacade.saveMeeting(this.meeting);
   }

   addAction(action: RockAction) {
      const entityId = this.showRocks ? this.selectedRockId : this.selectedActionItemId;
      const currentItem = this.meeting.discussionItems.find((item) => item.entityId == entityId);
      if (currentItem) {
         currentItem.actions.push(action);
      } else {
         const newItem: DiscussionItem = {
            entityId,
            entityType: this.showRocks ? 'Rock' : 'Action',
            comments: [],
            actions: [action],
         };
         this.meeting = {
            ...this.meeting,
            discussionItems: [...this.meeting.discussionItems, newItem],
         };
      }
      this.meetingsFacade.saveMeeting(this.meeting);
   }

   addRock() {
      this.timer.restartTimer();
      this.selectedIndex++;
      this.meetingsFacade.addRockToMeeting();
   }

   addActionItem() {
      this.timer.restartTimer();
      this.selectedIndex++;
      this.meetingsFacade.addActionItemToMeeting();
   }

   getProgress() {
      if (this.meeting) {
         const rocks = this.meeting.rocks ? this.meeting.rocks.length : 0;
         const actionItems = this.meeting.actionItems ? this.meeting.actionItems.length : 0;
         return (100 * this.selectedIndex) / (rocks + actionItems);
      } else {
         return 0;
      }
   }

   toggleNotes() {
      this.showNotes = !this.showNotes;
   }

   nextItemText() {
      if (this.showRocks) {
         if (this.selectedIndex !== this.meeting.rocks.length - 1) {
            return 'Next Rock';
         } else {
            return 'Review Actions';
         }
      } else {
         return 'Next Action';
      }
   }

   setRockTitle(title: string) {
      if (this.rockDetails) {
         this.rockDetails.form.get('name').setValue(title);
         this.rockDetails.form.markAsDirty();
      }
   }

   setActionTitle(title: string) {
      if (this.actionItemDetails) {
         this.actionItemDetails.form.get('name').setValue(title);
         this.actionItemDetails.form.markAsDirty();
      }
   }
}
