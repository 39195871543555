import { Component, OnInit, OnDestroy } from '@angular/core';
import { froalaConfig } from '@app/shared/config/froala.config';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { BusinessUnit } from '@entities/business-unit';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { TeamFacade } from '@app/team/state/team.facade';
import { TeamMember } from '@entities/team-member';
import { Department } from '@entities/department';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
   selector: 'app-org-summary-report',
   templateUrl: './org-summary-report.component.html',
   styleUrls: ['./org-summary-report.component.scss'],
})
export class OrgSummaryReportComponent implements OnInit, OnDestroy {
   options = {
      ...froalaConfig,
      documentReady: true,
      toolbarButtons: ['print'],
   };

   orgName: string;
   logo: string;
   businessUnits: BusinessUnit[] = [];
   departments: Department[] = [];
   teamMembers: TeamMember[] = [];

   private destroyed$ = new Subject<void>();

   constructor(
      private orgBuilderFacade: OrgBuilderFacade,
      private userOrgFacade: UserOrgFacade,
      private teamFacade: TeamFacade
   ) {}

   ngOnInit() {
      this.orgBuilderFacade.businessUnits$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((businessUnits) => {
            this.businessUnits = businessUnits.sort((a, b) => a.order - b.order);
         });
      this.userOrgFacade.selectedOrg$.pipe(takeUntil(this.destroyed$)).subscribe((org) => {
         if (org) {
            this.orgName = org.name;
            this.logo = org.logo;
         }
      });
      this.teamFacade.teamMembers$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });
      this.orgBuilderFacade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((depts) => {
         this.departments = depts;
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getTeamMemberName(id: string) {
      const teamMember = this.teamMembers.find((t) => t.id == id);
      if (teamMember) {
         return `${teamMember.firstName} ${teamMember.lastName}`;
      } else {
         return '';
      }
   }

   getDepartments(businessUnitId: string) {
      const depts = this.departments.filter((d) => d.businessUnitId == businessUnitId);
      return depts.sort((a, b) => a.order - b.order);
   }

   back() {
      history.back();
   }
}
