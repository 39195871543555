import {
   Component,
   OnInit,
   Input,
   SimpleChanges,
   OnChanges,
   TemplateRef,
   ViewChild,
   ElementRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { RockStatus } from '@entities/enums/rock-status';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Rock } from '@entities/rock';
import { Goal } from '@entities/goal';
import { froalaConfig } from '@app/shared/config/froala.config';

@Component({
   selector: 'app-rock-info',
   templateUrl: './rock-info.component.html',
   styleUrls: ['./rock-info.component.scss'],
})
export class RockInfoComponent implements OnInit, OnChanges {
   @Input() rock: Rock;
   @Input() goals: Goal[];
   @Input() canEdit = true;

   form: UntypedFormGroup;

   title: string;
   showEditName = false;
   rockStatus = RockStatus;
   statusKeys = Object.keys(RockStatus)
      .filter((key) => !isNaN(+key))
      .map((key) => +key);

   editorConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Description',
      minHeight: 100,
   };

   constructor() {}

   ngOnInit(): void {
      this.initForm();
      if (this.rock) {
         this.form.patchValue(this.rock);
      }
      this.form.get('status').valueChanges.subscribe((val) => {
         if (val === RockStatus.Completed) {
            this.form.get('completedDate').setValue(moment().toISOString());
         } else {
            this.form.get('completedDate').reset();
         }
      });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (this.form && this.rock && changes['rock']) {
         this.form.reset();
         this.form.patchValue(this.rock);
      }
      if (changes['canEdit']) {
         this.initForm();
      }
   }

   initForm() {
      if (!this.form) {
         this.form = new UntypedFormGroup({
            id: new UntypedFormControl(),
            name: new UntypedFormControl(null, Validators.required),
            description: new UntypedFormControl(),
            startDate: new UntypedFormControl(),
            targetDate: new UntypedFormControl(),
            completedDate: new UntypedFormControl(),
            status: new UntypedFormControl(RockStatus['Not Started']),
            notes: new UntypedFormControl(),
            approvedDate: new UntypedFormControl(),
            quarter: new UntypedFormControl('Pending', Validators.required),
            assigneeId: new UntypedFormControl(),
            goalId: new UntypedFormControl(),
            departmentId: new UntypedFormControl(),
            newAction: new UntypedFormControl(),
         });
      }
      if (this.canEdit) {
         this.form.enable();
      } else {
         this.form.disable();
      }
   }

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   setDepartmentId(event: MatAutocompleteSelectedEvent) {
      if (event && event.option.value) {
         this.form.get('departmentId').setValue(event.option.value.id);
         this.form.markAsDirty();
      }
   }

   setAssignedToId(event: MatAutocompleteSelectedEvent) {
      if (event && event.option.value) {
         this.form.get('assigneeId').setValue(event.option.value.id);
         this.form.markAsDirty();
      } else if (event == null) {
         this.form.get('assigneeId').setValue(null);
         this.form.markAsDirty();
      }
   }

   setDate(event: MatDatepickerInputEvent<moment.Moment>, formControlName: string) {
      const date = event.value;
      const value = date ? date.toISOString() : null;
      const formControl = this.form.get(formControlName);
      formControl.setValue(value);
   }
}
