import { Injectable, OnDestroy } from '@angular/core';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { Goal } from '@entities/goal';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { EntityService } from '@app/shared/services/entity.service';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';

@Injectable()
export class GoalService implements EntityService<Goal>, OnDestroy {
   entities$: Observable<Goal[]>;

   readonly COLLECTION_NAME = COLLECTION_NAMES.GOALS;

   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<Goal>();

   constructor(private store: Store<State>) {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }
   save(entity: Goal): Promise<Goal> {
      return this.orgEntityService.save(entity);
   }
   batchSave(entities: Goal[]): Promise<Goal[]> {
      return this.orgEntityService.batchSave(entities);
   }
   delete(entity: Goal): Promise<void> {
      return this.orgEntityService.delete(entity);
   }
}
