import { Injectable, OnDestroy } from '@angular/core';
import { BusinessUnitService } from '../services/business-unit.service';
import { DepartmentFunctionService } from '../services/department-function.service';
import { TaskService } from '../services/task.service';
import { DepartmentService } from '../services/department.service';
import { Observable, Subject, combineLatest } from 'rxjs';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { Task } from '@entities/task';
import { BusinessUnit } from '@entities/business-unit';
import {
   MatLegacyDialogRef as MatDialogRef,
   MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { BusinessUnitEditComponent } from '../components/business-unit-edit/business-unit-edit.component';
import { DepartmentEditComponent } from '../components/department-edit/department-edit.component';
import { DepartmentFunctionEditComponent } from '../components/department-function-edit/department-function-edit.component';
import { TaskAddComponent } from '../components/task-add/task-add.component';
import { Router, ActivatedRoute } from '@angular/router';
import { documentationRouteNames } from '@app/documentation/documentation.routes.names';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import * as OrganizationSelectors from './organization.state';
import * as OrganizationActions from './organization.actions';
import * as TeamActions from '@app/team/state/team.actions';
import * as DocumentationActions from '@app/documentation/state/documentation.actions';

import { TeamMember } from '@entities/team-member';
import { getTeamMembers, getTeamMember } from '@app/team/state/team.state';
import { map, takeUntil } from 'rxjs/operators';
import { TeamMemberService } from '@app/team/services/team-member.service';
import { AssignTeamMemberComponent } from '../components/assign-team-member/assign-team-member.component';
import { DialogService } from '@app/shared/services/dialog.service';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { UserRole } from '@entities/enums/user-role';
import { TaskFacade } from '@app/shared/interfaces/task-facade.interface';
import { getOrganization } from '@app/user-org/state/user-org.selectors';
import { Organization } from '@entities/organization';
import { RoleGuard } from '@app/admin/services/role.guard';
import { DepartmentFunctionViewModel } from '@app/documentation/state/documentation.state';
import { AppAreas } from '@entities/enums/app-areas';
@Injectable()
export class OrgBuilderFacade implements TaskFacade, OnDestroy {
   orgName$: Observable<string>;
   selectedBusinessUnitId$: Observable<string> = this.store.pipe(
      select(OrganizationSelectors.getSelectedBusinessUnitId)
   );
   selectedDepartmentId$: Observable<string> = this.store.pipe(
      select(OrganizationSelectors.getSelectedDepartmentId)
   );
   selectedDepartmentFunctionId$: Observable<string> = this.store.pipe(
      select(OrganizationSelectors.getSelectedDepartmentFunctionId)
   );
   selectedTaskId$: Observable<string> = this.store.pipe(
      select(OrganizationSelectors.getSelectedTaskId)
   );

   selectedBusinessUnit$: Observable<BusinessUnit> = this.store.pipe(
      select(OrganizationSelectors.getSelectedBusinessUnit)
   );
   selectedDepartment$: Observable<Department> = this.store.pipe(
      select(OrganizationSelectors.getSelectedDepartment)
   );
   selectedDepartmentFunction$: Observable<DepartmentFunction> = this.store.pipe(
      select(OrganizationSelectors.getSelectedDepartmentFunction)
   );
   selectedTask$: Observable<Task> = this.store.pipe(select(OrganizationSelectors.getSelectedTask));

   businessUnits$: Observable<BusinessUnit[]> = this.store.pipe(
      select(OrganizationSelectors.getBusinessUnits)
   );
   departments$: Observable<Department[]> = this.store.pipe(
      select(OrganizationSelectors.getDepartments)
   );
   visibleDepartments$: Observable<Department[]> = this.store.pipe(
      select(OrganizationSelectors.getVisibleDepartments)
   );
   departmentsTree$: Observable<any[]> = this.store.pipe(
      select(OrganizationSelectors.getDepartmentsTree)
   );
   departmentFunctions$: Observable<DepartmentFunction[]> = this.store.pipe(
      select(OrganizationSelectors.getDepartmentFunctions)
   );
   visibleDepartmentFunctions$: Observable<DepartmentFunction[]> = this.store.pipe(
      select(OrganizationSelectors.getVisibleDepartmentFunctions)
   );
   tasks$: Observable<Task[]> = this.store.pipe(select(OrganizationSelectors.getTasks));
   visibleTasks$: Observable<Task[]> = this.store.pipe(
      select(OrganizationSelectors.getVisibleTasks)
   );
   orgTree$: Observable<any[]> = this.store.pipe(select(OrganizationSelectors.getOrgTree));
   departmentFunctionTree$: Observable<any[]> = this.store.pipe(
      select(OrganizationSelectors.getDepartmentFunctionsTree)
   );
   teamMembers$: Observable<TeamMember[]> = this.store.pipe(select(getTeamMembers));
   assignedTeamMembers$: Observable<TeamMember[]>;
   canEdit$: Observable<boolean>;
   organization$: Observable<Organization> = this.store.pipe(select(getOrganization));
   businessUnitSummary$: Observable<BusinessUnit> = this.store.pipe(
      select(OrganizationSelectors.getBusinessUnitSummary)
   );
   departmentSummary$: Observable<Department> = this.store.pipe(
      select(OrganizationSelectors.getDepartmentSummary)
   );

   selectedDepartmentFunctionViewModel$: Observable<DepartmentFunctionViewModel>;

   private _dialogRef: MatDialogRef<any>;
   private destroyed$ = new Subject<void>();

   constructor(
      private taskService: TaskService,
      private businessUnitService: BusinessUnitService,
      private departmentService: DepartmentService,
      private departmentFunctionService: DepartmentFunctionService,
      private dialog: MatDialog,
      private router: Router,
      private route: ActivatedRoute,
      private store: Store<State>,
      private teamMemberService: TeamMemberService,
      private dialogService: DialogService,
      private userOrgFacade: UserOrgFacade,
      private roleGuard: RoleGuard
   ) {
      this.subscribeToOrgEntities();
      this.assignedTeamMembers$ = combineLatest([this.teamMembers$, this.selectedTask$]).pipe(
         map(([teamMembers, task]) => {
            if (teamMembers && task) {
               return teamMembers.filter((t) => t.assignedTasks?.includes(task.id));
            } else {
               return [];
            }
         })
      );
      this.canEdit$ = this.roleGuard.canEdit([], AppAreas.Organization);

      this.orgName$ = this.userOrgFacade.selectedOrg$.pipe(map((org) => (org ? org.name : null)));
      this.selectedDepartmentFunctionViewModel$ = combineLatest([
         this.selectedDepartmentFunction$,
         this.selectedDepartment$,
         this.selectedBusinessUnit$,
      ]).pipe(
         map(([departmentFunction, department, businessUnit]) => {
            return {
               departmentFunction,
               department,
               businessUnit,
            };
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   subscribeToOrgEntities() {
      this.businessUnitService.entities$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((businessUnits) => {
            this.store.dispatch(OrganizationActions.BusinessUnitsUpdated({ businessUnits }));
         });
      this.departmentService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.store.dispatch(OrganizationActions.DepartmentsUpdated({ departments }));
      });
      this.departmentFunctionService.entities$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((departmentFunctions) => {
            this.store.dispatch(
               OrganizationActions.DepartmentFunctionsUpdated({ departmentFunctions })
            );
         });
      this.taskService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((tasks) => {
         this.store.dispatch(OrganizationActions.TasksUpdated({ tasks }));
      });
      this.teamMemberService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.store.dispatch(TeamActions.TeamMembersUpdated({ teamMembers }));
      });
   }

   addBusinessUnit() {
      this._dialogRef = this.dialog.open(BusinessUnitEditComponent, {
         data: { businessUnit: {}, service: this },
      });
   }

   editBusinessUnit(businessUnit: BusinessUnit) {
      this._dialogRef = this.dialog.open(BusinessUnitEditComponent, {
         data: { businessUnit: businessUnit, service: this },
      });
   }

   saveBusinessUnit(businessUnit: BusinessUnit) {
      this.store.dispatch(OrganizationActions.SaveBusinessUnit(businessUnit));
      this.dialog.closeAll();
   }

   cancelEditBusinessUnit() {
      this.dialog.closeAll();
   }

   selectBusinessUnit(businessUnitId: string) {
      this.store.dispatch(OrganizationActions.SelectBusinessUnit({ businessUnitId }));
   }

   deleteBusinessUnit(businessUnit: BusinessUnit) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Business Unit?',
            message:
               'Do you want to delete this business unit? All associated departments, functions, and tasks will be deleted as well.',
            confirm: 'Yes, delete',
            deny: 'No, go back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(OrganizationActions.DeleteBusinessUnit(businessUnit));
            }
         });
   }

   reorderBusinessUnits(oldIndex: number, newIndex: number) {
      this.store.dispatch(OrganizationActions.ReorderBusinessUnits({ oldIndex, newIndex }));
   }

   businessUnitSummary(businessUnitId: string) {
      this.store.dispatch(OrganizationActions.BusinessUnitSummary({ businessUnitId }));
   }

   addDepartment() {
      this._dialogRef = this.dialog.open(DepartmentEditComponent, {
         data: { department: {}, service: this },
      });
   }

   editDepartment(department: Department) {
      this._dialogRef = this.dialog.open(DepartmentEditComponent, {
         data: { department: department, service: this },
      });
   }

   saveDepartment(department: Department) {
      this.store.dispatch(OrganizationActions.SaveDepartment(department));
      this.dialog.closeAll();
   }

   cancelEditDepartment() {
      this.dialog.closeAll();
   }

   deleteDepartment(department) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Department?',
            message:
               'Do you want to delete this department? All associated functions and tasks will be deleted as well.',
            confirm: 'Yes, delete',
            deny: 'No, go back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(OrganizationActions.DeleteDepartment(department));
            }
         });
   }

   selectDepartment(departmentId: string) {
      this.store.dispatch(OrganizationActions.SelectDepartment({ departmentId }));
   }

   reorderDepartments(oldIndex: number, newIndex: number) {
      this.store.dispatch(OrganizationActions.ReorderDepartments({ oldIndex, newIndex }));
   }

   departmentSummary(departmentId: string) {
      this.store.dispatch(OrganizationActions.DepartmentSummary({ departmentId }));
   }

   addDepartmentFunction() {
      this._dialogRef = this.dialog.open(DepartmentFunctionEditComponent, {
         data: { departmentFunction: {}, service: this },
      });
   }

   editDepartmentFunction(departmentFunction: DepartmentFunction) {
      this._dialogRef = this.dialog.open(DepartmentFunctionEditComponent, {
         data: { departmentFunction: departmentFunction, service: this },
      });
   }

   saveDepartmentFunction(departmentFunction: DepartmentFunction) {
      this.store.dispatch(OrganizationActions.SaveDepartmentFunction(departmentFunction));
      this.dialog.closeAll();
   }

   cancelEditDepartmentFunction() {
      this.dialog.closeAll();
   }

   deleteDepartmentFunction(departmentFunction) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Function?',
            message:
               'Do you want to delete this function? All associated tasks will be deleted as well.',
            confirm: 'Yes, delete',
            deny: 'No, go back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(
                  OrganizationActions.DeleteDepartmentFunction(departmentFunction)
               );
            }
         });
   }

   selectDepartmentFunction(departmentFunctionId: string) {
      this.store.dispatch(OrganizationActions.SelectDepartmentFunction({ departmentFunctionId }));
   }

   departmentFunctionFullEditor(departmentFunction: Partial<DepartmentFunction>, url: string) {
      this.dialog.closeAll();
      this.store.dispatch(
         OrganizationActions.SelectDepartmentFunction({ departmentFunctionId: null })
      );
      this.store.dispatch(
         DocumentationActions.SelectDepartmentFunction({ departmentFunctionId: null })
      );
      this.store.dispatch(
         OrganizationActions.GoToDepartmentFunctionFullEditor({ departmentFunction, url })
      );
   }

   reorderDepartmentFunctions(oldIndex: number, newIndex: number) {
      this.store.dispatch(OrganizationActions.ReorderDepartmentFunctions({ oldIndex, newIndex }));
   }
   departmentFunctionReport(departmentFunction: DepartmentFunction) {
      this.store.dispatch(DocumentationActions.DepartmentFunctionReport({ departmentFunction }));
   }

   addTask() {
      this._dialogRef = this.dialog.open(TaskAddComponent, { data: { service: this } });
   }

   cancelAddTask() {
      this.dialog.closeAll();
   }

   taskFullEditor(url: string, task: Partial<Task>, viewModel: DepartmentFunctionViewModel) {
      this.dialog.closeAll();
      this.store.dispatch(OrganizationActions.SelectTask({ taskId: null }));
      this.store.dispatch(DocumentationActions.SelectTask({ taskId: null }));
      this.store.dispatch(OrganizationActions.GoToTaskFullEditor({ url, task, viewModel }));
   }
   taskReport(task: Task) {
      this.store.dispatch(DocumentationActions.TaskReport({ task }));
   }

   cancelEditTask() {}

   editTask(task: Task) {
      this._dialogRef = this.dialog.open(TaskAddComponent, {
         data: { task, service: this },
      });
   }

   saveTask(task: Task) {
      this.store.dispatch(OrganizationActions.SaveTask(task));
      if (this._dialogRef) {
         this.dialog.closeAll();
      }
   }

   deleteTask(task: Task) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Task?',
            message: 'Do you want to delete this task?.',
            confirm: 'Yes, delete',
            deny: 'No, go back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(OrganizationActions.DeleteTask(task));
            }
         });
   }

   reorderTasks(oldIndex: number, newIndex: number) {
      this.store.dispatch(OrganizationActions.ReorderTasks({ oldIndex, newIndex }));
   }

   selectTask(taskId: string) {
      this.store.dispatch(OrganizationActions.SelectTask({ taskId }));
   }

   getTeamMember(teamMemberId: string) {
      return this.store.pipe(select(getTeamMember, { id: teamMemberId }));
   }

   assignTaskToTeamMember(task: Task) {
      this._dialogRef = this.dialog.open(AssignTeamMemberComponent, {
         data: { task: task, service: this },
      });
   }

   unassignTask(task: Task, teamMember: TeamMember) {
      if (teamMember.assignedTasks) {
         const updated = {
            ...teamMember,
            assignedTasks: teamMember.assignedTasks.filter((t) => t != task.id),
         };
         this.store.dispatch(TeamActions.SaveTeamMember({ teamMember: updated }));
      }
   }

   cancelAssignTaskToTeamMember() {
      this.dialog.closeAll();
   }

   saveTaskAssignments(task: Task, teamMembers: TeamMember[]) {
      const ids = teamMembers.map((t) => t.id);
      if (!task.assignees) {
         task.assignees = [];
      }
      task.assignees = [...task.assignees, ...ids];
      this.store.dispatch(OrganizationActions.SaveTask(task));
      teamMembers.forEach((teamMember) => {
         if (!teamMember.assignedTasks) {
            teamMember.assignedTasks = [];
         }
         teamMember.assignedTasks.push(task.id);

         this.store.dispatch(TeamActions.SaveTeamMember({ teamMember }));
      });
      this.dialog.closeAll();
   }

   dragStarted(dragItem: any) {
      this.store.dispatch(OrganizationActions.DraggingStarted({ dragItem }));
   }

   itemDropped(dropTarget: any, dropTargetType: any) {
      this.store.dispatch(OrganizationActions.ItemDropped({ dropTarget, dropTargetType }));
   }

   dragStopped() {
      this.store.dispatch(OrganizationActions.DraggingStopped());
   }
}
