import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminState } from './admin.reducer';
import { getCurrentUser, getCurrentUserId } from '@app/user-org/state/user-org.selectors';

const getAdminState = createFeatureSelector<AdminState>('admin');

const getOrgUsers = createSelector(getAdminState, (state) => state.users);
const getEditUser = createSelector(getAdminState, (state) =>
   state.users.find((u) => u.id == state.selectedUserId)
);
const getPermissionGroups = createSelector(getAdminState, (state) => {
   const permissionGroups = state.permissionGroups || [];
   return [...permissionGroups].sort((a, b) => a.index - b.index);
});
const getUnassignedUsers = createSelector(getOrgUsers, (users) =>
   users.filter((user) => !user.permissionGroupId)
);

const getCurrentUserPermissionGroup = createSelector(
   getAdminState,
   getCurrentUser,
   (state, user) => {
      const permissionGroup = state.permissionGroups.find(
         (group) => group.id === user?.permissionGroupId
      );
      return permissionGroup;
   }
);

export const adminSelectors = {
   getAdminState,
   getOrgUsers,
   getEditUser,
   getPermissionGroups,
   getUnassignedUsers,
   getCurrentUserPermissionGroup,
};
