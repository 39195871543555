import { Route } from '@angular/router';
import { adminRouteNames } from './admin.routes.names';
import { UserListComponent } from './components/user-list/user-list.component';
import { SettingsComponent } from './components/settings/settings.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { ManagePermissionsComponent } from './components/manage-permissions/manage-permissions.component';

export const SETTINGS_ROUTES: Route[] = [
   { path: '', component: SettingsComponent },
   { path: adminRouteNames.PERMISSIONS, component: ManagePermissionsComponent },
   {
      path: adminRouteNames.USERS,
      children: [
         { path: '', component: UserListComponent },
         { path: adminRouteNames.EDIT, component: UserEditComponent },
         { path: adminRouteNames.NEW, component: UserEditComponent },
      ],
   },
];
