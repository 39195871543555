<div class="meeting-details-container">
   <div class="header">
      <button class="back-button" mat-icon-button (click)="back()">
         <mat-icon>arrow_back</mat-icon>
      </button>
      <h3>{{ meeting.name }}</h3>
   </div>
   <div class="meeting-content">
      <mat-card *ngIf="attendees">
         <mat-card-title>Attendees</mat-card-title>
         <mat-card-content>
            <div *ngFor="let attendee of attendees">
               {{ attendee }}
            </div>
         </mat-card-content>
      </mat-card>
      <mat-card *ngIf="meeting.notes">
         <mat-card-title>General Notes</mat-card-title>
         <mat-card-content>{{ meeting.notes }}</mat-card-content>
      </mat-card>
      <ng-container *ngIf="meeting.rocks && meeting.rocks.length > 0">
         <h4>Rocks</h4>
         <mat-accordion multi>
            <mat-expansion-panel *ngFor="let rockId of meeting.rocks">
               <mat-expansion-panel-header class="panel-header">
                  {{ rockId | rockName : rocks }}
               </mat-expansion-panel-header>
               <div *ngIf="timeSpent(rockId)">
                  Time Spent: {{ timeSpent(rockId) * 1000 | date : 'm:ss' }}
               </div>
               <ng-container *ngIf="itemFieldChanges(rockId).length > 0">
                  <div class="list-header">Updates</div>
                  <table mat-table [dataSource]="itemFieldChanges(rockId)">
                     <ng-container matColumnDef="field">
                        <th *matHeaderCellDef mat-header-cell>Field</th>
                        <td *matCellDef="let row" mat-cell>{{ row.field | propertyDisplay }}</td>
                     </ng-container>
                     <ng-container matColumnDef="oldValue">
                        <th *matHeaderCellDef mat-header-cell>Previous Value</th>
                        <td *matCellDef="let row" mat-cell>
                           <app-field-change
                              [field]="row.field"
                              [value]="row.oldValue"
                              [goals]="goals"
                              [teamMembers]="teamMembers"
                              [status]="rockStatus"
                           ></app-field-change>
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="newValue">
                        <th *matHeaderCellDef mat-header-cell>New Value</th>
                        <td *matCellDef="let row" mat-cell>
                           <app-field-change
                              [field]="row.field"
                              [value]="row.newValue"
                              [goals]="goals"
                              [teamMembers]="teamMembers"
                              [status]="rockStatus"
                           ></app-field-change>
                        </td>
                     </ng-container>
                     <tr mat-header-row *matHeaderRowDef="fieldChangeColumns"></tr>
                     <tr mat-row *matRowDef="let row; columns: fieldChangeColumns"></tr>
                  </table>
               </ng-container>
               <ng-container *ngIf="itemActions(rockId).length > 0">
                  <div class="list-header">Milestones Added</div>
                  <table mat-table [dataSource]="itemActions(rockId)">
                     <ng-container matColumnDef="description">
                        <th *matHeaderCellDef mat-header-cell>Description</th>
                        <td *matCellDef="let row" mat-cell>{{ row.description }}</td>
                     </ng-container>
                     <ng-container matColumnDef="assigneeId">
                        <th *matHeaderCellDef mat-header-cell>Assigned To</th>
                        <td *matCellDef="let row" mat-cell>
                           {{ row.assigneeId | teamMemberName : teamMembers }}
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="targetDate">
                        <th *matHeaderCellDef mat-header-cell>Target Date</th>
                        <td *matCellDef="let row" mat-cell>
                           <span *ngIf="row.targetDate">{{ row.targetDate | date }}</span>
                        </td>
                     </ng-container>
                     <tr mat-header-row *matHeaderRowDef="milestoneColumns"></tr>
                     <tr mat-row *matRowDef="let row; columns: milestoneColumns"></tr>
                  </table>
               </ng-container>
               <ng-container *ngIf="itemComments(rockId).length > 0">
                  <div class="list-header">Comments Added</div>
                  <div *ngFor="let comment of itemComments(rockId)">
                     <app-user-comment [comment]="comment"></app-user-comment>
                  </div>
               </ng-container>
            </mat-expansion-panel>
         </mat-accordion>
      </ng-container>

      <ng-container *ngIf="meeting.actionItems && meeting.actionItems.length > 0">
         <h4>Actions</h4>
         <mat-accordion multi>
            <mat-expansion-panel *ngFor="let actionItemId of meeting.actionItems">
               <mat-expansion-panel-header class="panel-header">
                  {{ actionItemId | actionItemName : actionItems }}
               </mat-expansion-panel-header>
               <div>Time Spent: {{ timeSpent(actionItemId) * 1000 | date : 'm:ss' }}</div>
               <ng-container *ngIf="itemFieldChanges(actionItemId).length > 0">
                  <div class="list-header">Fields Changed</div>
                  <table mat-table [dataSource]="itemFieldChanges(actionItemId)">
                     <ng-container matColumnDef="field">
                        <th *matHeaderCellDef mat-header-cell>Field</th>
                        <td *matCellDef="let row" mat-cell>{{ row.field | propertyDisplay }}</td>
                     </ng-container>
                     <ng-container matColumnDef="oldValue">
                        <th *matHeaderCellDef mat-header-cell>Previous Value</th>
                        <td *matCellDef="let row" mat-cell>
                           <app-field-change
                              [field]="row.field"
                              [value]="row.oldValue"
                              [goals]="goals"
                              [teamMembers]="teamMembers"
                              [status]="actionStatus"
                           ></app-field-change>
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="newValue">
                        <th *matHeaderCellDef mat-header-cell>New Value</th>
                        <td *matCellDef="let row" mat-cell>
                           <app-field-change
                              [field]="row.field"
                              [value]="row.newValue"
                              [goals]="goals"
                              [teamMembers]="teamMembers"
                              [status]="actionStatus"
                           ></app-field-change>
                        </td>
                     </ng-container>
                     <tr mat-header-row *matHeaderRowDef="fieldChangeColumns"></tr>
                     <tr mat-row *matRowDef="let row; columns: fieldChangeColumns"></tr>
                  </table>
               </ng-container>
               <ng-container *ngIf="itemComments(actionItemId).length > 0">
                  <div class="list-header">Comments Added</div>

                  <div *ngFor="let comment of itemComments(actionItemId)">
                     <app-user-comment [comment]="comment"></app-user-comment>
                  </div>
               </ng-container>
            </mat-expansion-panel>
         </mat-accordion>
      </ng-container>
   </div>
</div>
