import { EvolveComponent } from './components/evolve/evolve.component';
import { RocksListComponent } from './components/rocks-list/rocks-list.component';
import { RockDetailsComponent } from './components/rock-details/rock-details.component';
import { GoalsComponent } from './components/goals/goals.component';
import { Route } from '@angular/router';
import { evolveRouteNames } from './evolve.routes.names';
import { ActionItemListComponent } from './components/action-item-list/action-item-list.component';
import { ActionItemEditComponent } from './components/action-item-edit/action-item-edit.component';
import { CanDeactivateGuard } from '@app/shared/services/can-deactivate-guard.service';
import { SaveChangesGuard } from '@app/shared/services/save-changes.guard';
import { GoalDetailsComponent } from './components/goal-details/goal-details.component';
import { GoalListComponent } from './components/goal-list/goal-list.component';

export const EVOLVE_ROUTES: Route[] = [
   { path: '', redirectTo: 'rocks', pathMatch: 'full' },
   {
      path: evolveRouteNames.ROCKS,
      children: [
         { path: '', component: RocksListComponent },
         { path: ':rockId', component: RockDetailsComponent, canDeactivate: [SaveChangesGuard] },
      ],
   },
   {
      path: evolveRouteNames.GOALS,
      children: [
         { path: '', component: GoalListComponent },
         { path: evolveRouteNames.LIST, component: GoalListComponent },
         { path: ':goalId', component: GoalDetailsComponent, canDeactivate: [SaveChangesGuard] },
      ],
   },
   {
      path: evolveRouteNames.ISSUES,
      children: [
         { path: '', component: ActionItemListComponent },
         {
            path: ':actionItemId',
            component: ActionItemEditComponent,
            canDeactivate: [SaveChangesGuard],
         },
      ],
   },
];
