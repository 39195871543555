<div class="widget-container">
   <div class="title">Assigned Milestones</div>
   <div class="scroll-container">
      <ng-container *ngIf="teamMemberLinked$ | async; else notLinked">
         <table mat-table [dataSource]="assignedActions$">
            <ng-container matColumnDef="name">
               <th mat-header-cell *matHeaderCellDef>
                  Item
               </th>
               <td mat-cell *matCellDef="let row">
                  {{ row.name || row.description }}
               </td>
            </ng-container>
            <ng-container matColumnDef="targetDate">
               <th mat-header-cell *matHeaderCellDef>
                  Target Date
               </th>
               <td mat-cell *matCellDef="let row">
                  {{ row.targetDate | date }}
               </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
               mat-row
               *matRowDef="let row; columns: displayedColumns"
               (click)="goToItem(row)"
            ></tr>
         </table>
      </ng-container>
      <ng-template #notLinked>
         <div class="not-linked">
            This account is not linked to a team member. Link your account to a team member below.
            <app-team-member-autocomplete
               placeholder="Linked Team Member"
               (optionSelected)="setLinkedTeamMember($event)"
            ></app-team-member-autocomplete>
            <button
               mat-raised-button
               color="primary"
               [disabled]="!teamMember"
               (click)="linkTeamMember()"
            >
               Link
            </button>
         </div>
      </ng-template>
   </div>
</div>
