import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
   selector: 'app-sticky-header',
   templateUrl: './sticky-header.component.html',
   styleUrls: ['./sticky-header.component.scss'],
})
export class StickyHeaderComponent implements OnInit {
   @Input() title: string;
   @Input() saveDisabled: boolean;
   @Input() isNew: boolean;
   @Input() report = false;
   @Input() multiSave = false;
   @Input() titleFormControl: UntypedFormControl;
   @Input() canEdit = true;
   @Input() menuOptions: { label: string; action: () => void; condition: () => boolean }[];

   @Output() showReport = new EventEmitter();
   @Output() back = new EventEmitter();
   @Output() save = new EventEmitter();
   @Output() saveAndNew = new EventEmitter();
   @Output() saveAndClose = new EventEmitter();
   @Output() delete = new EventEmitter();
   @Output() titleChange = new EventEmitter();

   constructor() {}

   ngOnInit(): void {}

   showMenuOptions() {
      return (
         this.canEdit &&
         this.menuOptions &&
         this.menuOptions.map((option) => option.condition()).reduce((a, b) => a || b)
      );
   }
}
