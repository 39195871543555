import { UserRole } from './enums/user-role';
import { ProductType } from './enums/product-type';

export class User {
   id: string;

   displayName: string;

   email: string;

   userAccountId: string;

   organizationId: string;

   role: UserRole;

   isAdmin: boolean;

   products: ProductType[];

   product: ProductType;

   active: boolean;

   firstName: string;

   lastName: string;

   teamMemberId?: string;

   permissionGroupId?: string;
}
