import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TeamFacade } from '@app/team/state/team.facade';
import { deepCopy } from '@app/utilities/deep-copy';
import { CompetencyLevel } from '@entities/competency-level';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Component({
   selector: 'app-competency-levels',
   templateUrl: './competency-levels.component.html',
   styleUrls: ['./competency-levels.component.scss'],
})
export class CompetencyLevelsComponent implements OnInit, OnDestroy {
   // startAtZero = true;
   changes = false;
   competencyLevels: CompetencyLevel[] = [];
   editing: CompetencyLevel[] = [];

   private destroyed$ = new Subject<void>();

   constructor(private teamFacade: TeamFacade) {}

   ngOnInit(): void {
      this.teamFacade.competencyLevels$.pipe(takeUntil(this.destroyed$)).subscribe((levels) => {
         this.changes = levels.some((level) => !level.id);
         this.competencyLevels = deepCopy(
            levels.map((level) => ({
               ...level,
               id: level.id || uuid(),
            }))
         );
         this.competencyLevels.sort((a, b) => a.value - b.value);
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   add() {
      const newLevel = {
         id: uuid(),
         value: this.competencyLevels.length + 1,
         description: '',
         label: '',
      };
      this.competencyLevels.push(newLevel);
      this.edit(newLevel);
   }

   reorder(event: CdkDragDrop<CompetencyLevel>) {
      moveItemInArray(this.competencyLevels, event.previousIndex, event.currentIndex);
      this.updateIndexes();
   }

   showEdit(level: CompetencyLevel) {
      return this.editing.some((l) => l.id === level.id);
   }

   edit(level: CompetencyLevel) {
      this.editing.push({ ...level });
   }

   save(event: Event, level: CompetencyLevel) {
      event.stopPropagation();
      this.editing = this.editing.filter((l) => l.id !== level.id);
      this.updateIndexes();
      this.changes = true;
   }

   cancel(event: Event, level: CompetencyLevel) {
      event.stopPropagation();
      const unchanged = this.editing.find((l) => l.id === level.id);
      const index = this.competencyLevels.findIndex((l) => l.id === level.id);
      this.competencyLevels[index] = unchanged;
      this.editing = this.editing.filter((l) => l.id !== level.id);
      this.updateIndexes();
   }

   deleteLevel(event: Event, level: CompetencyLevel) {
      event.stopPropagation();
      this.editing = this.editing.filter((l) => l.id !== level.id);
      this.competencyLevels = this.competencyLevels.filter((l) => l.id !== level.id);
      this.updateIndexes();
   }

   updateIndexes() {
      this.competencyLevels.forEach((level, index) => {
         level.value = index + 1;
      });
      this.changes = true;
   }

   saveAll() {
      this.teamFacade.saveCompetencyLevels(this.competencyLevels);
      this.changes = false;
   }
}
