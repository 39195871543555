import { Injectable, inject } from '@angular/core';
import {
   Auth,
   User,
   authState,
   createUserWithEmailAndPassword,
   sendPasswordResetEmail,
   signInWithEmailAndPassword,
   updateEmail,
   updateProfile,
   user,
} from '@angular/fire/auth';

@Injectable({
   providedIn: 'root',
})
export class FirebaseAuthShim {
   private auth = inject(Auth);

   get currentUser() {
      return this.auth.currentUser;
   }

   constructor() {}

   authState() {
      return authState(this.auth);
   }

   createUserWithEmailAndPassword(email: string, password: string) {
      return createUserWithEmailAndPassword(this.auth, email, password);
   }

   sendPasswordResetEmail(email: string) {
      return sendPasswordResetEmail(this.auth, email);
   }

   signInWithEmailAndPassword(email: string, password: string) {
      return signInWithEmailAndPassword(this.auth, email, password);
   }

   signOut() {
      return this.auth.signOut();
   }

   updateEmail(user: User, newEmail: string) {
      return updateEmail(user, newEmail);
   }

   updateProfile(user: User, data: any) {
      return updateProfile(user, data);
   }

   user() {
      return user(this.auth);
   }
}

