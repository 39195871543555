import { Component, Input } from '@angular/core';
import { AccessLevel } from '@entities/enums/access-level';

@Component({
   selector: 'app-permission-level-icon',
   templateUrl: './permission-level-icon.component.html',
   styleUrls: ['./permission-level-icon.component.scss'],
})
export class PermissionLevelIconComponent {
   @Input() accessLevel: AccessLevel;

   accessLevels = AccessLevel;
}
