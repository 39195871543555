import { Component, OnInit } from '@angular/core';
import { PerformanceFacade } from '@app/performance/state/performance.facade';
import { DialogService } from '@app/shared/services/dialog.service';

@Component({
   selector: 'app-reset-performance-ratings',
   templateUrl: './reset-performance-ratings.component.html',
   styleUrls: ['./reset-performance-ratings.component.scss'],
})
export class ResetPerformanceRatingsComponent implements OnInit {
   constructor(
      private dialogService: DialogService,
      private performanceFacade: PerformanceFacade
   ) {}

   ngOnInit(): void {}

   confirmReset() {
      this.dialogService
         .showConfirmDialog({
            title: 'Reset Performance Ratings',
            message: 'Are you sure you want to reset performance ratings? This cannot be undone.',
            confirm: 'Yes, reset ratings',
            deny: 'No, keep current ratings',
            confirmColor: 'warn',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.performanceFacade.resetRatings();
            }
         });
   }
}

