import { createAction, props } from '@ngrx/store';
import { TeamMember } from '@entities/team-member';
import { Role } from '@entities/role';
import { TaskAssignTo } from './team.facade';
import { Competency } from '@entities/competency';
import { PerformanceEvaluation } from '@entities/performance-evaluation';
import { CompetencyLevel } from '@entities/competency-level';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';

export const TeamMembersUpdated = createAction(
   '[Team] Team members updated',
   props<{ teamMembers: TeamMember[] }>()
);
export const CreateTeamMember = createAction(
   '[Team] Create team member',
   props<{
      teamMember: TeamMember;
      options?: { overwrite?: boolean; close?: boolean; new?: boolean };
   }>()
);
export const SelectTeamMember = createAction(
   '[Team] Select team member',
   props<{ teamMemberId: string }>()
);
export const SaveTeamMember = createAction(
   '[Team] Save team member',
   props<{
      teamMember: TeamMember;
      options?: { overwrite?: boolean; close?: boolean; new?: boolean };
   }>()
);
export const DeleteTeamMember = createAction(
   '[Team] Delete team member',
   props<{ teamMember: TeamMember }>()
);
export const AssignTasks = createAction(
   '[Team] Assign tasks',
   props<{ taskIds: string[]; assignTo: TaskAssignTo }>()
);
export const UnassignTask = createAction(
   '[Team] Unassign task',
   props<{ taskId: string; assignedTo: TaskAssignTo }>()
);
export const RolesUpdated = createAction('[Team] Roles updated', props<{ roles: Role[] }>());
export const SaveRole = createAction('[Team] Save role', props<{ role: Role }>());
export const EditRole = createAction('[Team] Edit role', props<{ roleId: string }>());
export const SelectRole = createAction('[Team] Select role', props<{ roleId: string }>());
export const AssignRoleToTeamMember = createAction(
   '[Team] Assign role to team member',
   props<{ role: Role; teamMember: TeamMember }>()
);

export const SaveCompetency = createAction(
   '[Competencies] Save competency',
   props<{ competency: Competency }>()
);
export const CompetenciesUpdated = createAction(
   '[Competencies] Competencies updated',
   props<{ competencies: Competency[] }>()
);
export const DeleteCompetency = createAction(
   '[Competencies] Delete competency',
   props<{ competency: Competency }>()
);
export const SelectCompetency = createAction(
   '[Competencies] Select competency',
   props<{ competencyId: string }>()
);

export const EditCompetency = createAction(
   '[Competencies] Edit Competency',
   props<{ competencyId: string }>()
);

export const PerformanceEvaluationsUpdated = createAction(
   '[PerformanceEvaluations] performance evaluations updated',
   props<{ performanceEvaluations: PerformanceEvaluation[] }>()
);
export const CreatePerformanceEvaluation = createAction(
   '[Performance Evaluations] Create performance evaluation',
   props<{ teamMember: TeamMember; year: number }>()
);
export const EditPerformanceEvaluation = createAction(
   '[Performance Evaluations] Edit performance evaluation',
   props<{ performanceEvaluationId: string }>()
);
export const SelectPerformanceEvaluation = createAction(
   '[Performance Evaluations] Select performance evaluation',
   props<{ performanceEvaluationId: string }>()
);
export const SavePerformanceEvaluation = createAction(
   '[Performance Evaluations] Save performance evaluation',
   props<{ performanceEvaluation: PerformanceEvaluation }>()
);
export const DeletePerformanceEvaluation = createAction(
   '[Performance Evaluations] Delete performance evaluation',
   props<{ performanceEvaluation: PerformanceEvaluation }>()
);
export const CompetencyLevelsUpdated = createAction(
   '[Competency Levels] Competency Levels Updated',
   props<{ competencyLevels: CompetencyLevel[] }>()
);
export const SaveCompetencyLevels = createAction(
   '[Competency Levels] Save Competency Levels',
   props<{ competencyLevels: CompetencyLevel[] }>()
);
export const SelectTaskRatingTreeItem = createAction(
   '[Task Rating Tree] Select task rating tree item',
   props<{ ids: string[] }>()
);
export const EditTeamMember = createAction(
   '[Team Members] Edit team member',
   props<{ teamMemberId: string }>()
);
export const SetTeamTasksFilter = createAction(
   '[Team Member Detail] Set tasks filter',
   props<{ filter: FilterValues }>()
);
export const SetDisplayedColumns = createAction(
   '[Team Members] Set displayed columns',
   props<{ columns: string[] }>()
);
