<ng-container *ngIf="filterBar">
   <app-table-header
      [hideExport]="config.hideExport"
      [filterBar]="filterBar"
      [filter$]="filter$"
      [columns]="columns"
      (columnsUpdated)="setDisplayedColumns()"
      (exportData)="export($event)"
   ></app-table-header>
</ng-container>
<div class="results-count">{{ filteredData?.length || 0 }} Results</div>
<table
   mat-table
   [dataSource]="dataSource$"
   matSort
   matSortDisableClear="true"
   (matSortChange)="triggerSort($event)"
>
   <ng-container *ngIf="config.checkboxes">
      <ng-container matColumnDef="checkboxes">
         <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
               [checked]="allSelected()"
               [indeterminate]="selected.length > 0 && !allSelected()"
               (change)="toggleSelectAll($event)"
            ></mat-checkbox>
         </th>
         <td mat-cell *matCellDef="let row">
            <mat-checkbox [checked]="isSelected(row)" (change)="toggleSelected(row)"></mat-checkbox>
         </td>
      </ng-container>
   </ng-container>
   <ng-container *ngFor="let column of columns">
      <ng-container [matColumnDef]="column.def">
         <ng-container *ngIf="column.preventSort; else sortHeader">
            <th mat-header-cell *matHeaderCellDef [style]="column.style">
               {{ column.label }}
            </th>
         </ng-container>
         <ng-template #sortHeader>
            <th mat-header-cell *matHeaderCellDef mat-sort-header [style]="column.style">
               {{ column.label }}
            </th>
         </ng-template>
         <td mat-cell *matCellDef="let row" [style]="column.style" (click)="rowClick(row)">
            <div class="cell" [matTooltip]="column.tooltip ? column.tooltip(row) : null">
               <ng-container *ngIf="column.icon && column.icon(row)">
                  <ng-container *ngIf="column.icon(row).svg; else baseIcon">
                     <mat-icon
                        [svgIcon]="column.icon(row).icon"
                        [class]="column.icon(row).icon"
                     ></mat-icon>
                  </ng-container>
                  <ng-template #baseIcon>
                     <mat-icon [class]="column.icon(row).icon">
                        {{ column.icon(row).icon }}
                     </mat-icon>
                  </ng-template>
               </ng-container>
               <div class="cell-value">
                  {{ column.value(row) }}
               </div>
            </div>
         </td>
      </ng-container>
   </ng-container>
   <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
         <button mat-icon-button [matMenuTriggerFor]="optionsMenu">
            <mat-icon>more_vert</mat-icon>
         </button>
         <mat-menu #optionsMenu="matMenu">
            <ng-container *ngFor="let option of config.options">
               <button *ngIf="showOption(option, row)" mat-menu-item (click)="option.action(row)">
                  {{ option.label }}
               </button>
            </ng-container>
         </mat-menu>
      </td>
   </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
   <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="!config.noHover ? 'row-click' : ''"
   ></tr>
</table>
