import { createAction, props } from '@ngrx/store';
import { AuthUser } from '@entities/auth-user';

export const AuthUserUpdated = createAction(
   '[Auth] Auth user updated',
   props<{ authUser: AuthUser }>()
);
export const SetDisplayName = createAction(
   '[Auth] Set display name',
   props<{ displayName: string }>()
);
