import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Injectable, ErrorHandler } from '@angular/core';
import { Action } from '@ngrx/store';
import { stringify } from 'flatted';

@Injectable()
export class ErrorService {
   constructor(private snackBar: MatSnackBar, private sentry: ErrorHandler) {}

   handleError(error: any, message?: string, ignoreSentry?: boolean) {
      let errorMessage: string;
      if (message) {
         errorMessage = message;
      } else if (error.description) {
         errorMessage = `Error: ${error.description}`;
      } else if (error.message) {
         errorMessage = `Error: ${error.message}`;
      } else {
         errorMessage = `Error: ${stringify(error)}`;
      }
      this.snackBar.open(errorMessage, '', { duration: 3000, panelClass: 'error-snackbar' });
      if (!ignoreSentry) {
         this.sentry.handleError(error);
      }
   }
}

export interface ErrorAction extends Action {
   error: any;
}
