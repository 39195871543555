import * as root from '@app/app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Meeting } from '@entities/meeting';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';

export interface State extends root.State {
   meetings: MeetingsState;
}

export interface MeetingsState {
   entities: Meeting[];
   selectedMeetingId: string;
   selectedRockId: string;
   selectedActionItemId: string;
   showRocks: boolean;
   showActionItems: boolean;
   currentIndex: number;
   filter: FilterValues;
}

const getMeetingState = createFeatureSelector<MeetingsState>('meetings');

const getMeetings = createSelector(getMeetingState, (state) => state.entities);
const getSelectedMeeting = createSelector(getMeetingState, (state) =>
   state.entities.find((meeting) => meeting.id == state.selectedMeetingId)
);
const getSelectedRockId = createSelector(getMeetingState, (state) => state.selectedRockId);
const getSelectedActionItemId = createSelector(
   getMeetingState,
   (state) => state.selectedActionItemId
);
const getShowRocks = createSelector(getMeetingState, (state) => state.showRocks);
const getShowActionItems = createSelector(getMeetingState, (state) => state.showActionItems);
const getFilter = createSelector(getMeetingState, (state) => state.filter || {});
export const meetingsSelectors = {
   getMeetings,
   getSelectedMeeting,
   getSelectedRockId,
   getSelectedActionItemId,
   getShowActionItems,
   getShowRocks,
   getFilter,
};
