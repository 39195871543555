import { Injectable, OnDestroy, inject } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { Overview } from '@entities/overview';
import { map, takeUntil } from 'rxjs/operators';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { FirestoreService } from '@app/shared/services/firestore.service';

@Injectable()
export class OverviewService implements OnDestroy {
   overview$: Observable<Overview>;
   orgId: string;
   saving$ = new BehaviorSubject(false);

   private destroyed$ = new Subject<void>();
   private firestore = inject(FirestoreService);

   constructor(private userOrgService: UserOrgFacade) {
      this.userOrgService.selectedOrgId$.pipe(takeUntil(this.destroyed$)).subscribe((id) => {
         this.orgId = id;
      });
      this.overview$ = this.userOrgService.selectedOrg$.pipe(
         map((org) => {
            if (org) {
               return org.overview;
            } else {
               return null;
            }
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   saveOverview(overview: Overview) {
      this.saving$.next(true);
      this.firestore
         .updateDoc(this.firestore.doc(COLLECTION_NAMES.ORGANIZATIONS, this.orgId), { overview })
         .then(() => {
            this.saving$.next(false);
         });
   }
}
