import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionGroup } from '@entities/permission-group';
import { AccessLevel } from '@entities/enums/access-level';
import { AppAreas } from '@entities/enums/app-areas';
import { AdminFacade } from '@app/admin/state/admin.facade';
import { TeamFacade } from '@app/team/state/team.facade';
import { Observable, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { cloneDeep } from 'lodash';
import { User } from '@entities/user';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { permissionConfigs } from '@app/admin/constants/permission-areas';
import { DialogService } from '@app/shared/services/dialog.service';

@Component({
   selector: 'app-manage-permissions',
   templateUrl: './manage-permissions.component.html',
   styleUrls: ['./manage-permissions.component.scss'],
})
export class ManagePermissionsComponent implements OnInit, OnDestroy {
   @ViewChild('assignUserTemplate') assignUserTemplate: TemplateRef<any>;
   router = inject(Router);
   route = inject(ActivatedRoute);
   adminFacade = inject(AdminFacade);
   teamFacade = inject(TeamFacade);
   dialog = inject(MatDialog);
   dialogService = inject(DialogService);

   permissionGroups$: Observable<PermissionGroup[]>;
   users$ = this.adminFacade.users$;
   unassignedUsers$ = this.adminFacade.unassignedUsers$;

   permissionGroups: PermissionGroup[] = [];

   users = [];
   userToAssign?: User;

   accessLevel = AccessLevel;

   appAreas = Object.values(AppAreas);
   permissionConfigs = permissionConfigs;

   membersColumns = ['firstName', 'lastName', 'email', 'options'];

   private _destroyed$ = new Subject<void>();

   ngOnInit() {
      this.permissionGroups$ = combineLatest([
         this.adminFacade.permissionGroups$,
         this.users$,
      ]).pipe(
         takeUntil(this._destroyed$),
         map(([permissionGroups, users]) => {
            return permissionGroups.map((permissionGroup) => ({
               ...permissionGroup,
               members: users.filter((user) => permissionGroup.memberIds?.includes(user.id)),
            }));
         })
      );
      this.permissionGroups$.subscribe((permissionGroups) => {
         this.permissionGroups = permissionGroups;
      });
   }

   ngOnDestroy() {
      this._destroyed$.next();
      this._destroyed$.complete();
   }

   backToSettings() {
      this.router.navigate(['..'], { relativeTo: this.route });
   }

   editPermissionGroup(permissionGroup: PermissionGroup) {
      this.adminFacade.editPermissionGroup(permissionGroup);
   }

   newPermissionGroup() {
      this.adminFacade.newPermissionGroup();
   }

   assignMembers(permissionGroup: PermissionGroup) {
      this.adminFacade.assignUsersToPermissionGroup(permissionGroup);
   }

   assignUser(user: User) {
      this.userToAssign = cloneDeep(user);
      this.dialog.open(this.assignUserTemplate);
   }

   saveAssignedUser() {
      this.adminFacade.addUserToGroup(this.userToAssign);
      this.dialog.closeAll();
   }

   addDefaultPermissionGroups() {
      this.adminFacade.addDefaultPermissionGroups();
   }

   deletePermissionGroup(permissionGroup: PermissionGroup) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Permission Group?',
            message:
               'If you delete this permission group, all users in this group must be reassigned to a new group to continue to have access.',
            confirm: 'Yes, delete',
            deny: 'No, go back',
            confirmColor: 'warn',
         })
         .afterClosed()
         .subscribe((confirm) => {
            if (confirm) {
               this.adminFacade.deletePermissionGroup(permissionGroup);
            }
         });
   }

   canDeleteGroup(permissionGroup: PermissionGroup) {
      if (this.permissionGroups.length === 1) {
         return false;
      } else if (
         permissionGroup.isAdmin &&
         this.permissionGroups.filter((group) => group.isAdmin).length === 1
      ) {
         return false;
      } else {
         return true;
      }
   }
}
