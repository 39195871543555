import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Overview } from '@entities/overview';
import { takeUntil } from 'rxjs/operators';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';

@Component({
   selector: 'app-overview-widget',
   templateUrl: './overview-widget.component.html',
   styleUrls: ['./overview-widget.component.scss'],
})
export class OverviewWidgetComponent implements OnInit, OnDestroy {
   overview: Overview = new Overview();

   private destroyed$ = new Subject<void>();

   constructor(private dashboardFacade: DashboardFacade) {}

   ngOnInit() {
      this.dashboardFacade.overview$.pipe(takeUntil(this.destroyed$)).subscribe((overview) => {
         if (overview) {
            this.overview = overview;
         }
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }
}
