import { Component, OnInit, OnDestroy } from '@angular/core';
import { MeetingsFacade } from '@app/meetings/state/meetings.facade';
import { Meeting } from '@entities/meeting';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { Rock } from '@entities/rock';
import { ActionItem } from '@entities/action-item';
import { Router, ActivatedRoute } from '@angular/router';
import { TeamMember } from '@entities/team-member';
import { Goal } from '@entities/goal';
import { RockStatus } from '@entities/enums/rock-status';
import { Status } from '@entities/enums/status';

@Component({
   selector: 'app-meeting-details',
   templateUrl: './meeting-details.component.html',
   styleUrls: ['./meeting-details.component.scss'],
})
export class MeetingDetailsComponent implements OnInit, OnDestroy {
   meeting: Meeting;
   rocks: Rock[] = [];
   actionItems: ActionItem[] = [];
   teamMembers: TeamMember[] = [];
   goals: Goal[] = [];
   rockStatus = RockStatus;
   actionStatus = Status;
   attendees: string[] = [];

   milestoneColumns = ['description', 'assigneeId', 'targetDate'];
   fieldChangeColumns = ['field', 'oldValue', 'newValue'];

   private destroyed$ = new Subject<void>();

   constructor(
      private meetingsFacade: MeetingsFacade,
      private router: Router,
      private route: ActivatedRoute
   ) {}

   ngOnInit() {
      this.meetingsFacade.selectedMeeting$.pipe(takeUntil(this.destroyed$)).subscribe((meeting) => {
         if (meeting) {
            this.meeting = meeting;
         }
      });
      this.meetingsFacade.rocks$.pipe(takeUntil(this.destroyed$)).subscribe((rocks) => {
         this.rocks = rocks;
      });
      this.meetingsFacade.actionItems$.pipe(takeUntil(this.destroyed$)).subscribe((actionItems) => {
         this.actionItems = actionItems;
      });
      this.meetingsFacade.teamMembers$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });
      this.meetingsFacade.goals$.pipe(takeUntil(this.destroyed$)).subscribe((goals) => {
         this.goals = goals;
      });
      combineLatest([this.meetingsFacade.selectedMeeting$, this.meetingsFacade.teamMembers$])
         .pipe(takeUntil(this.destroyed$))
         .subscribe(([meeting, teamMembers]) => {
            this.attendees = teamMembers
               .filter((teamMember) => meeting.attendees?.includes(teamMember.id))
               .map((attendee) => `${attendee.firstName} ${attendee.lastName}`)
               .sort();
         });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   timeSpent(entityId: string) {
      const discussionItem = this.meeting.discussionItems.find((item) => item.entityId == entityId);
      return discussionItem ? discussionItem.timeSpent : 0;
   }

   itemComments(entityId: string) {
      const discussionItem = this.meeting.discussionItems.find((item) => item.entityId == entityId);
      return discussionItem ? discussionItem.comments : [];
   }

   itemActions(entityId: string) {
      const discussionItem = this.meeting.discussionItems.find((item) => item.entityId == entityId);
      return discussionItem ? discussionItem.actions : [];
   }

   itemFieldChanges(entityId: string) {
      const discussionItem = this.meeting.discussionItems.find((item) => item.entityId == entityId);
      return discussionItem && discussionItem.fieldsChanged ? discussionItem.fieldsChanged : [];
   }

   back() {
      this.router.navigate(['../..'], { relativeTo: this.route });
   }
}
