<div class="h3 title">Create a new organization</div>
<mat-vertical-stepper linear="true" class="stepper">
   <mat-step [stepControl]="orgForm">
      <ng-template matStepLabel>Organization Info</ng-template>
      <app-org-info
         [signup]="true"
         [primaryContact]="authUser$ | async"
         (nextClicked)="setOrgInfo($event)"
      ></app-org-info>
   </mat-step>
   <mat-step [stepControl]="productForm">
      <ng-template matStepLabel>Select Licenses</ng-template>
      <app-plan-select [signup]="true" (planSelected)="setPlan($event)"></app-plan-select>
   </mat-step>
   <mat-step>
      <ng-template matStepLabel>Billing Info</ng-template>
      <app-billing-info [signup]="true" (cardSubmitted)="setCardInfo($event)"></app-billing-info>
   </mat-step>
</mat-vertical-stepper>
<div class="spinner-backdrop" *ngIf="showSpinner">
   <mat-spinner></mat-spinner>
</div>
