<form [formGroup]="infoForm" (submit)="submit()">
   <mat-card
      class="billing-info-card"
      [ngClass]="{ 'show-stripe': showStripe, 'hide-stripe': !showStripe }"
   >
      <mat-card-content>
         <div #cardElement class="card-element"></div>
         <!-- A Stripe Element will be inserted here. -->
      </mat-card-content>
   </mat-card>
   <div class="last-four" *ngIf="!showStripe">
      <span>Card ending in {{ last4 }}</span>
      <button mat-button color="primary" (click)="updateBilling()">Update</button>
   </div>

   <!-- Used to display Element errors. -->
   <p class="errors" *ngIf="showStripe">{{ cardErrors }}</p>
   <div class="row">
      <mat-form-field class="col-12">
         <input matInput placeholder="Name on card" formControlName="name" required />
      </mat-form-field>
   </div>
   <div class="row">
      <mat-form-field class="col-12">
         <input matInput placeholder="Billing Address" formControlName="address_line1" required />
      </mat-form-field>
   </div>
   <div class="row">
      <mat-form-field class="col-md-6">
         <input matInput placeholder="City" formControlName="address_city" required />
      </mat-form-field>
      <mat-form-field class="col-md-3">
         <input matInput placeholder="State" formControlName="address_state" required />
      </mat-form-field>
      <mat-form-field class="col-md 3">
         <input
            matInput
            placeholder="Zip"
            autocomplete="postal-code"
            formControlName="address_zip"
            required
         />
      </mat-form-field>
   </div>
   <div class="row" *ngIf="signup || !billingInfo.termsAccepted">
      <div class="col-12">
         <mat-checkbox formControlName="termsAccepted" color="primary">
            I agree to the
            <a href="https://trellistools.com/terms-of-service" target="__blank">
               Terms of Service
            </a>
            and
            <a href="https://trellistools.com/privacy-policy" target="__blank">
               Privacy Policy
            </a>
         </mat-checkbox>
      </div>
   </div>

   <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
   <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="submitted || (infoForm.pristine && !showStripe) || !infoForm.valid"
      data-test-id="submit-button"
   >
      Submit
   </button>
   <button
      mat-stroked-button
      color="primary"
      (click)="cancel()"
      *ngIf="!signup"
      [disabled]="infoForm.pristine && !showStripe"
   >
      Cancel
   </button>
</form>
