import { Injectable, PipeTransform } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { FilterValues } from '../interfaces/filter.interfaces';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { TableColumn } from '../interfaces/table-column.interfaces';
import * as moment from 'moment';

export interface PipeOptions {
   pipe: PipeTransform;
   args?: any;
}

export interface PipeMap {
   [columnName: string]: PipeOptions;
}

@Injectable()
export class TableService {
   filterAndSort(
      data: any[],
      filter: string,
      columns: string[],
      sort: Sort,
      pipeMap: PipeMap = {}
   ) {
      const mutableData = [...data];
      const filteredData = this.filter(mutableData, filter, columns, pipeMap);
      return this.sort(filteredData, sort, pipeMap);
   }

   fullFilterAndSort(
      data: any[],
      filter: FilterValues,
      sort: Sort,
      columns: TableColumn<any>[],
      primaryObject?: string,
      statusField?: string,
      departments?: Department[],
      teamMemberField?: string
   ) {
      const mutableData = [...data];
      const filteredData = this.filterFull(
         mutableData,
         filter,
         columns,
         primaryObject,
         statusField,
         departments,
         teamMemberField
      );
      return this.newSort(filteredData, sort, columns);
   }

   sort(data: any[], sort: Sort, pipeMap: PipeMap = {}) {
      const mutableData = [...data];
      if (mutableData && mutableData.length > 0 && sort && sort.active) {
         mutableData.sort(this.getSortFunction(mutableData, sort, pipeMap));
      }
      return mutableData;
   }

   newSort(data: any[], sort: Sort, columns: TableColumn<any>[]) {
      let mutableData = [...data];
      if (mutableData && mutableData.length > 0 && sort && sort.active) {
         const column = columns.find((col) => col.def === sort.active);

         mutableData.sort(this.getSortFunctionNew(mutableData, sort, column));
         if (sort.direction === 'desc') {
            const definedValues = mutableData.filter(
               (item) => !!column.value(item) || column.value(item) === false
            );
            const undefinedValues = mutableData.filter(
               (item) => !column.value(item) && column.value(item) !== false
            );
            mutableData = [...definedValues.reverse(), ...undefinedValues];
         }
      }
      return mutableData;
   }

   filter(data: any[], term: string, columns: string[], pipeMap: PipeMap = {}) {
      if (term) {
         const filtered = data.filter((row) => {
            const vals: string[] = [];
            columns.forEach((col) => {
               let val = this.getPropValue(row, col, pipeMap[col]);
               if (val == null) {
                  val = '';
               }
               vals.push(val.toString().toLowerCase());
            });
            return vals.some((v) => v.includes(term.toLowerCase()));
         });
         return filtered;
      } else {
         return data;
      }
   }

   filterFull(
      data: any[],
      filter: FilterValues = {},
      columns: TableColumn<any>[],
      primaryObject?: string,
      statusField?: string,
      departments?: Department[],
      teamMemberField?: string
   ) {
      if (filter.departmentFunctions?.length > 0) {
         const column = columns.find((c) => c.filter == 'departmentFunctions');
         if (column) {
            data = data.filter((row) =>
               filter.departmentFunctions.some((deptFn) => deptFn.name === column.value(row))
            );
         }
      } else if (filter.departments?.length > 0) {
         const column = columns.find((c) => c.filter == 'departments');
         if (column) {
            data = data.filter((row) =>
               filter.departments.some((dept) => dept.name === column.value(row))
            );
         } else {
            const altColumn = columns.find((c) => c.filter == 'departments[]');
            if (altColumn) {
               data = data.filter((row) => {
                  const values = altColumn.value(row).split(', ');
                  return filter.departments.some((dept) => values.includes(dept.name));
               });
            }
         }
      } else if (filter.businessUnits?.length > 0) {
         const buColumn = columns.find((c) => c.filter == 'businessUnits');
         if (buColumn) {
            data = data.filter((row) =>
               filter.businessUnits.some(
                  (businessUnit) => businessUnit.name === buColumn.value(row)
               )
            );
         } else {
            if (departments) {
               const matchingDepartments = departments
                  .filter((dept) =>
                     filter.businessUnits.some((bu) => bu.id === dept.businessUnitId)
                  )
                  .map((dept) => dept.name);
               const deptColumn = columns.find((c) => c.filter == 'departments');
               if (deptColumn) {
                  data = data.filter((row) => matchingDepartments.includes(deptColumn.value(row)));
               } else {
                  const altColumn = columns.find((c) => c.filter == 'departments[]');
                  if (altColumn) {
                     data = data.filter((row) => {
                        const values = altColumn.value(row).split(', ');
                        return matchingDepartments.some((dept) => values.includes(dept));
                     });
                  }
               }
            }
         }
      }
      if (filter.teamMembers?.length > 0) {
         if (teamMemberField == undefined) {
            teamMemberField = 'teamMemberId';
         }
         if (primaryObject) {
            data = data.filter((viewModel) =>
               filter.teamMembers.some((t) => t.id == viewModel[primaryObject][teamMemberField])
            );
         } else {
            data = data.filter((row) =>
               filter.teamMembers.some((t) => t.id === row[teamMemberField])
            );
         }
      }
      if (filter.status?.length > 0) {
         if (statusField == undefined) {
            statusField = 'status';
         }
         if (this.statusFieldExists(data, statusField, primaryObject)) {
            if (primaryObject) {
               data = data.filter((viewModel) =>
                  filter.status.includes(viewModel[primaryObject][statusField])
               );
            } else {
               data = data.filter((row) => filter.status.includes(row[statusField]));
            }
         }
      }
      if (filter.type?.length > 0) {
         if (primaryObject) {
            data = data.filter((viewModel) => filter.type.includes(viewModel[primaryObject].type));
         } else {
            data = data.filter((row) => filter.type.includes(row.type));
         }
      }
      if (filter.rating?.length > 0) {
         const column = columns.find((c) => c.filter === 'rating');
         if (column) {
            data = data.filter((row) =>
               filter.rating.includes(
                  column.filterValue ? column.filterValue(row) : column.value(row)
               )
            );
         } else if (primaryObject) {
            data = data.filter((viewModel) =>
               filter.rating.includes(viewModel[primaryObject].rating)
            );
         } else {
            data = data.filter((row) => filter.rating.includes(row.rating));
         }
      }
      if (filter.priority?.length > 0) {
         if (primaryObject) {
            data = data.filter((viewModel) =>
               filter.priority.includes(viewModel[primaryObject].priority)
            );
         } else {
            data = data.filter((row) => filter.priority.includes(row.priority));
         }
      }
      if (filter.quarter?.length > 0) {
         const column = columns.find((c) => c.filter === 'quarter');
         if (column) {
            data = data.filter((row) => filter.quarter.includes(column.value(row)));
         }
      }
      if (filter.search) {
         const filtered = data.filter((row) => {
            const vals: string[] = [];
            columns.forEach((col) => {
               let val = col.value(row);
               if (val == null) {
                  val = '';
               }
               vals.push(val.toString().toLowerCase());
            });
            return vals.some((v) => v.includes(filter.search.toLowerCase()));
         });
         return filtered;
      } else {
         return data;
      }
   }

   private getSortFunctionNew(data: any[], sort: Sort, column: TableColumn<any>) {
      const prop = column.value(data[0]);
      const type = column.isDate ? 'date' : typeof prop;
      switch (type) {
         case 'number': {
            return (a, b) => column.value(a) - column.value(b);
         }
         case 'boolean': {
            return (a, b) => {
               const aVal = column.value(a);
               const bVal = column.value(b);
               if (aVal == bVal) {
                  return 0;
               } else {
                  return aVal ? -1 : 1;
               }
            };
         }
         case 'date': {
            return (a, b) => {
               const aVal = column.value(a);
               const bVal = column.value(b);
               if (aVal && bVal) {
                  return moment(aVal).isSameOrBefore(moment(bVal)) ? -1 : 1;
               } else if (!aVal && bVal) {
                  return 1;
               } else if (aVal && !bVal) {
                  return -1;
               } else {
                  return 0;
               }
            };
         }
         case 'string':
         default: {
            return (a, b) => {
               const aVal = column.value(a);
               const bVal = column.value(b);
               if (!aVal) {
                  return 1;
               } else if (!bVal) {
                  return -1;
               } else {
                  return aVal.toString().localeCompare(bVal.toString());
               }
            };
         }
      }
   }

   private getSortFunction(data: any[], sort: Sort, pipeMap: PipeMap) {
      const prop = this.getPropValue(data[0], sort.active, pipeMap[sort.active]);
      const pipe = pipeMap[sort.active];
      switch (typeof prop) {
         case 'number': {
            if (sort.direction == 'asc') {
               return (a, b) =>
                  this.getPropValue(a, sort.active, pipe) - this.getPropValue(b, sort.active, pipe);
            } else {
               return (a, b) =>
                  this.getPropValue(b, sort.active, pipe) - this.getPropValue(a, sort.active, pipe);
            }
         }
         case 'boolean': {
            if (sort.direction == 'asc') {
               return (a, b) => {
                  const aVal = this.getPropValue(a, sort.active, pipe);
                  const bVal = this.getPropValue(b, sort.active, pipe);
                  if (aVal == bVal) {
                     return 0;
                  } else {
                     return aVal ? -1 : 1;
                  }
               };
            } else {
               return (a, b) => {
                  const aVal = this.getPropValue(a, sort.active, pipe);
                  const bVal = this.getPropValue(b, sort.active, pipe);
                  if (aVal == bVal) {
                     return 0;
                  } else {
                     return aVal ? 1 : -1;
                  }
               };
            }
         }
         case 'string':
         default: {
            return this.stringSortFunction(sort, pipe);
         }
      }
   }

   private getPropValue(entry: any, prop: string, pipeOptions: PipeOptions) {
      const propValue = this.getPropValueRecursive(entry, prop.split('.'));
      if (pipeOptions) {
         return pipeOptions.pipe.transform(propValue, pipeOptions.args);
      } else {
         return propValue;
      }
   }

   private getPropValueRecursive(entry: any, props: string[]) {
      if (entry) {
         if (props.length == 1) {
            return entry[props[0]];
         } else {
            const childEntry = entry[props[0]];
            return this.getPropValueRecursive(childEntry, props.slice(1));
         }
      } else {
         return null;
      }
   }

   private stringSortFunction(sort: Sort, pipe) {
      if (sort.direction == 'asc') {
         return (a, b) => {
            const aVal = this.getPropValue(a, sort.active, pipe);
            const bVal = this.getPropValue(b, sort.active, pipe);
            if (!aVal) {
               return 1;
            } else if (!bVal) {
               return -1;
            } else {
               return aVal.toString().localeCompare(bVal.toString());
            }
         };
      } else {
         return (a, b) => {
            const aVal = this.getPropValue(a, sort.active, pipe);
            const bVal = this.getPropValue(b, sort.active, pipe);
            if (!aVal) {
               return 1;
            } else if (!bVal) {
               return -1;
            } else {
               return bVal.toString().localeCompare(aVal.toString());
            }
         };
      }
   }

   private statusFieldExists(data: any[], statusField: string, primaryObject?: string) {
      if (primaryObject) {
         return data.some((viewModel) =>
            Object.keys(viewModel[primaryObject]).includes(statusField)
         );
      } else {
         return data.some((item) => Object.keys(item).includes(statusField));
      }
   }
}
