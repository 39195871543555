import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { froalaConfig } from '@app/shared/config/froala.config';
import { PerformanceEvaluationItem } from '@entities/performance-evaluation-item';
import { debounceTime } from 'rxjs/operators';

@Component({
   selector: 'app-performance-evaluation-item-edit',
   templateUrl: './performance-evaluation-item-edit.component.html',
   styleUrls: ['./performance-evaluation-item-edit.component.scss'],
})
export class PerformanceEvaluationItemEditComponent implements OnInit {
   @Input() item: PerformanceEvaluationItem;
   @Input() showWeights = false;
   @Output() itemUpdated = new EventEmitter<PerformanceEvaluationItem>();

   form: UntypedFormGroup;

   editorConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Notes',
      minHeight: 100,
   };

   constructor() {}

   ngOnInit(): void {
      this.form = new UntypedFormGroup({
         notes: new UntypedFormControl(),
         score: new UntypedFormControl(),
         weight: new UntypedFormControl(),
         weightedScore: new UntypedFormControl({ value: 0, disabled: true }),
         competency: new UntypedFormGroup({
            name: new UntypedFormControl({ value: null, disabled: true }),
            departmentName: new UntypedFormControl({ value: null, disabled: true }),
         }),
      });
      if (this.item) {
         this.form.patchValue(this.item);
         // this.form.get('competencyName').setValue(this.item.competency.name);
      }
      this.form.get('score').valueChanges.subscribe(() => this.setWeightedScore());
      this.form.get('weight').valueChanges.subscribe(() => this.setWeightedScore());

      this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => {
         const updatedValue = {
            ...this.item,
            ...this.form.value,
         };
         this.itemUpdated.emit(updatedValue);
      });
   }

   setWeightedScore() {
      this.form.updateValueAndValidity();
      const score = this.form.value.score;
      const weight = this.form.value.weight;
      const weightedScore = score ? (weight ? score * weight : score) : 0;
      this.form.get('weightedScore').setValue(weightedScore);
   }

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }
}
