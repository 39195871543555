import { Component, ViewChild, inject } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AdminFacade } from '@app/admin/state/admin.facade';
import { UserAutocompleteComponent } from '@app/shared/components/user-autocomplete/user-autocomplete.component';
import { PermissionGroup } from '@entities/permission-group';
import { User } from '@entities/user';
import { cloneDeep } from 'lodash';

@Component({
   selector: 'app-permission-group-assign-users',
   templateUrl: './permission-group-assign-users.component.html',
   styleUrls: ['./permission-group-assign-users.component.scss'],
})
export class PermissionGroupAssignUsersComponent {
   @ViewChild(UserAutocompleteComponent) userAutoCompleteComponent: UserAutocompleteComponent;

   adminFacade = inject(AdminFacade);
   data = inject(MAT_DIALOG_DATA);

   users$ = this.adminFacade.users$;
   permissionGroup: PermissionGroup = cloneDeep(this.data.permissionGroup);

   ngOnInit() {
      if (!this.permissionGroup.memberIds) {
         this.permissionGroup.memberIds = [];
      }
      if (this.permissionGroup.members) {
         this.sortMembers();
      }
   }

   userSelected(userId: string) {
      return this.permissionGroup.memberIds.includes(userId);
   }

   addUser(event: MatAutocompleteSelectedEvent) {
      const user = event?.option?.value;
      if (user) {
         if (!this.permissionGroup.memberIds.includes(user.id)) {
            this.permissionGroup.memberIds.push(user.id);
            this.permissionGroup.members.push(user);
            this.sortMembers();
         }
         this.userAutoCompleteComponent?.clear();
      }
   }

   removeUser(user: User) {
      this.permissionGroup.memberIds = this.permissionGroup.memberIds.filter(
         (id) => id !== user.id
      );
      this.permissionGroup.members = this.permissionGroup.members.filter(
         (member) => member.id !== user.id
      );
   }

   assign() {
      this.adminFacade.savePermissionGroup(this.permissionGroup);
   }

   private sortMembers() {
      this.permissionGroup.members.sort((a, b) => {
         const lastNameDiff = a.lastName?.localeCompare(b.lastName);
         if (lastNameDiff === 0) {
            return a.firstName?.localeCompare(b.firstName);
         } else {
            return lastNameDiff;
         }
      });
   }
}
