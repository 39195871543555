import { createReducer, on, Action } from '@ngrx/store';
import { MeetingsState } from './meetings.state';
import { MeetingsActions } from './meetings.actions';
import { Meeting } from '@entities/meeting';

export const meetingsInitialState: MeetingsState = {
   entities: [],
   selectedMeetingId: null,
   selectedActionItemId: null,
   selectedRockId: null,
   showRocks: true,
   showActionItems: false,
   currentIndex: 0,
   filter: {},
};

export function reducer(state: MeetingsState | undefined, action: Action) {
   return createReducer(
      meetingsInitialState,
      on(MeetingsActions.MeetingsUpdated, (state, { meetings }) => {
         const selectedMeetingId = meetings.some((meeting) => meeting.id == state.selectedMeetingId)
            ? state.selectedMeetingId
            : null;
         return {
            ...state,
            entities: meetings,
            selectedMeetingId,
         };
      }),
      on(MeetingsActions.SelectMeeting, (state, { selectedMeetingId }) => {
         return {
            ...state,
            selectedMeetingId,
         };
      }),
      on(MeetingsActions.StartMeeting, (state, { meetingId }) => {
         return {
            ...state,
            selectedMeetingId: meetingId,
         };
      }),
      on(MeetingsActions.SelectActionItem, (state, { selectedActionItemId }) => {
         return {
            ...state,
            selectedActionItemId,
            showRocks: false,
            showActionItems: true,
            showSummary: false,
         };
      }),
      on(MeetingsActions.SelectRock, (state, { selectedRockId }) => {
         return {
            ...state,
            selectedRockId,
            showRocks: true,
            showActionItems: false,
            showSummary: false,
         };
      }),
      on(MeetingsActions.ViewMeeting, (state, meeting) => {
         return {
            ...state,
            selectedMeetingId: meeting.id,
         };
      }),
      on(MeetingsActions.SetMeetingsFilter, (state, { filter }) => {
         return {
            ...state,
            filter,
         };
      })
   )(state, action);
}
