import { createAction, props } from '@ngrx/store';
import { Dashboard } from '@entities/dashboard';
import { TeamMember } from '@entities/team-member';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';

const DashboardsUpdated = createAction(
   '[Dashboard] Dashboards updated',
   props<{ dashboards: Dashboard[] }>()
);
const SaveDashboard = createAction('[Dashboard] Save dashboard', props<{ dashboard: Dashboard }>());
const SelectDashboard = createAction(
   '[Dashboard] Select dashboard',
   props<{ dashboardId: string }>()
);

const LinkTeamMember = createAction(
   '[Dashboard] Link team member',
   props<{ teamMember: TeamMember }>()
);
const DeleteDashboard = createAction(
   '[Dashboard] Delete dashboard',
   props<{ dashboard: Dashboard }>()
);

const SaveWidgetData = createAction(
   '[Dashboard] Save widget data',
   props<{ id: string; data: any }>()
);

const SetWidgetFilter = createAction(
   '[Dashboard] set widget filter',
   props<{ widgetId: string; filter: FilterValues }>()
);

export const DashboardActions = {
   DashboardsUpdated,
   SaveDashboard,
   SelectDashboard,
   LinkTeamMember,
   DeleteDashboard,
   SaveWidgetData,
   SetWidgetFilter,
};
