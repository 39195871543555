import { Component, OnInit, Input } from '@angular/core';
import { TeamMember } from '@entities/team-member';
import { Goal } from '@entities/goal';

@Component({
   selector: 'app-field-change',
   templateUrl: './field-change.component.html',
   styleUrls: ['./field-change.component.scss'],
})
export class FieldChangeComponent implements OnInit {
   @Input() field: string;
   @Input() value: any;
   @Input() goals: Goal[] = [];
   @Input() teamMembers: TeamMember[] = [];
   @Input() status: any = {};

   constructor() {}

   ngOnInit(): void {}
}
