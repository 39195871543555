import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BusinessUnit } from '@entities/business-unit';
import { map, takeUntil } from 'rxjs/operators';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { TeamMember } from '@entities/team-member';

@Component({
   selector: 'app-business-unit-list',
   templateUrl: './business-unit-list.component.html',
   styleUrls: [
      './business-unit-list.component.scss',
      '../organization/organization.component.scss',
   ],
})
export class BusinessUnitListComponent implements OnInit, OnDestroy {
   @Output() selected = new EventEmitter();

   businessUnits$: Observable<BusinessUnit[]>;
   selectedBusinessUnitId$: Observable<string>;
   selectedId: string;
   canEdit$: Observable<boolean>;

   teamMembers: TeamMember[] = [];
   orgTree: any[] = [];

   private destroyed$ = new Subject<void>();

   constructor(private orgBuilderFacade: OrgBuilderFacade) {}

   ngOnInit() {
      this.businessUnits$ = this.orgBuilderFacade.businessUnits$.pipe(
         map((businessUnits) => businessUnits.sort((a, b) => a.order - b.order))
      );
      this.selectedBusinessUnitId$ = this.orgBuilderFacade.selectedBusinessUnitId$;
      this.selectedBusinessUnitId$.pipe(takeUntil(this.destroyed$)).subscribe((id) => {
         this.selectedId = id;
      });
      this.canEdit$ = this.orgBuilderFacade.canEdit$;
      this.orgBuilderFacade.teamMembers$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((teamMembers) => {
            this.teamMembers = teamMembers;
         });
      this.orgBuilderFacade.orgTree$.pipe(takeUntil(this.destroyed$)).subscribe((orgTree) => {
         this.orgTree = orgTree;
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   addBusinessUnit() {
      this.orgBuilderFacade.addBusinessUnit();
   }

   select(businessUnitId: string) {
      this.orgBuilderFacade.selectBusinessUnit(businessUnitId);
      this.selected.emit(businessUnitId);
   }

   edit(businessUnit: BusinessUnit) {
      this.orgBuilderFacade.editBusinessUnit(businessUnit);
   }

   viewSummary(businessUnit: BusinessUnit) {
      this.orgBuilderFacade.businessUnitSummary(businessUnit.id);
   }

   delete(businessUnit: BusinessUnit) {
      this.orgBuilderFacade.deleteBusinessUnit(businessUnit);
   }

   drop(event: CdkDragDrop<any>) {
      this.orgBuilderFacade.reorderBusinessUnits(event.previousIndex, event.currentIndex);
   }

   getTeamMember(teamMemberId: string) {
      return this.orgBuilderFacade.getTeamMember(teamMemberId);
   }
   itemDropped(event: any, businessUnit: BusinessUnit) {
      this.orgBuilderFacade.itemDropped(businessUnit, BusinessUnit);
   }

   getDepartments(businessUnit: BusinessUnit) {
      const fromTree = this.orgTree.find((bu) => bu.id === businessUnit.id);
      return fromTree?.departments?.length || 0;
   }
}
