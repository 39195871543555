import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { froalaConfig } from '@app/shared/config/froala.config';

import { Subject } from 'rxjs';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';

import { takeUntil } from 'rxjs/operators';
import { OverviewService } from '../overview.service';
import { Overview } from '@entities/overview';

@Component({
   selector: 'app-vision-report',
   templateUrl: './vision-report.component.html',
   styleUrls: ['./vision-report.component.scss'],
})
export class VisionReportComponent implements OnInit, OnDestroy {
   options = {
      ...froalaConfig,
      documentReady: true,
      toolbarButtons: ['print'],
   };

   orgName: string;
   logo: string;

   overview: Overview;

   private destroyed$ = new Subject<void>();

   constructor(private overviewService: OverviewService, private userOrgFacade: UserOrgFacade) {}

   ngOnInit() {
      this.userOrgFacade.selectedOrg$.pipe(takeUntil(this.destroyed$)).subscribe((org) => {
         if (org) {
            this.orgName = org.name;
            this.logo = org.logo;
         }
      });
      this.overviewService.overview$.subscribe((overview) => {
         this.overview = overview;
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   pageBreak() {
      const rowOne = document.getElementById('row-one');
      const rowTwo = document.getElementById('row-two');
      if (rowOne && rowTwo) {
         const height = rowOne.clientHeight + rowTwo.clientHeight;
         return height > 792;
      } else {
         return false;
      }
   }

   initEditor(initControls) {
      setTimeout(() => {
         initControls.initialize();
      }, 100);
   }

   back() {
      history.back();
   }
}
