<mat-card class="roadmap-card">
   <mat-card-content>
      <div class="quarters">
         <div class="quarter" *ngFor="let quarter of activeQuarters; index as i">
            <div class="quarter-header">
               {{ quarter }}
            </div>
         </div>
      </div>
      <div cdkDropListGroup>
         <ng-container *ngFor="let bu of activeBusinessUnits">
            <div class="business-unit-name">
               {{ bu.name }}
            </div>
            <ng-container *ngFor="let dept of bu.departments">
               <div class="department-name">
                  {{ dept.name }}
               </div>
               <div class="quarters">
                  <ng-container *ngFor="let quarter of activeQuarters">
                     <div
                        class="quarter rock-column"
                        cdkDropList
                        [id]="dept.id + '.' + quarter"
                        (cdkDropListDropped)="rockDropped($event)"
                     >
                        <ng-container *ngFor="let rock of rocksForQuarter(dept.rocks, quarter)">
                           <mat-card
                              class="rock status-card"
                              [ngClass]="['rock-status-' + rock.status, 'edit-' + canEdit]"
                              cdkDrag
                              [cdkDragData]="rock"
                              [cdkDragDisabled]="!canEdit"
                           >
                              <mat-card-header>
                                 <mat-card-title class="rock-name" (click)="editRock.emit(rock)">
                                    {{ rock.name }}
                                 </mat-card-title>
                              </mat-card-header>
                              <mat-card-content>
                                 <div class="assigned-to">
                                    <mat-icon>person</mat-icon>
                                    <div class="assigned-to-text">
                                       {{ rock.assigneeId | teamMemberName: teamMembers }}
                                    </div>
                                 </div>
                                 <div class="rock-status" [ngClass]="'rock-status-' + rock.status">
                                    <ng-container [ngSwitch]="rock.status">
                                       <ng-container *ngSwitchCase="rockStatus['On Track']">
                                          <mat-icon svgIcon="sign-direction"></mat-icon>
                                       </ng-container>
                                       <ng-container *ngSwitchCase="rockStatus['Off Track']">
                                          <mat-icon>warning</mat-icon>
                                       </ng-container>
                                       <ng-container *ngSwitchCase="rockStatus['On Hold']">
                                          <mat-icon>schedule</mat-icon>
                                       </ng-container>
                                       <ng-container *ngSwitchCase="rockStatus['Pending Approval']">
                                          <mat-icon>how_to_reg</mat-icon>
                                       </ng-container>
                                       <ng-container *ngSwitchCase="rockStatus.Completed">
                                          <mat-icon>check_circle</mat-icon>
                                       </ng-container>
                                    </ng-container>
                                    <div class="rock-status-text">
                                       {{ rockStatus[rock.status] }}
                                    </div>
                                 </div>
                              </mat-card-content>
                           </mat-card>
                        </ng-container>
                     </div>
                  </ng-container>
               </div>
            </ng-container>
         </ng-container>
      </div>
   </mat-card-content>
</mat-card>
