import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { FirebaseAuthService } from './firebase-auth.service';

@Injectable({
   providedIn: 'root',
})
export class AuthGuard implements CanActivate {
   constructor(private auth: FirebaseAuthService) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.auth.firebaseUser$.pipe(
         take(1),
         map((user) => {
            if (user) {
               return true;
            } else {
               localStorage.setItem('redirectUrl', state.url);
               this.auth.logout(route.data['logoutRedirect']);
               return false;
            }
         })
      );
   }
}
