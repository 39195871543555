import { Component, OnInit } from '@angular/core';
import { UserRole } from '@entities/enums/user-role';
import { UntypedFormGroup, FormArray, UntypedFormControl } from '@angular/forms';
import { AdminFacade } from '@app/admin/state/admin.facade';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ProductType } from '@entities/enums/product-type';
import { Observable } from 'rxjs';
import { PlanDetails } from '@entities/plan-details';
import { map } from 'rxjs/operators';
import { UserInvite } from '@entities/user-invite';
import * as moment from 'moment';
import { Role } from '@entities/role';

@Component({
   selector: 'app-user-invite',
   templateUrl: './user-invite.component.html',
   styleUrls: ['./user-invite.component.scss'],
})
export class UserInviteComponent implements OnInit {
   userRoleKeys = [UserRole.User, UserRole.Admin];
   userRole = UserRole;
   form: UntypedFormGroup;
   createTeamMember = false;
   ProductType = ProductType;
   orgPlans$: Observable<PlanDetails[]>;
   plansAvailable$: Observable<boolean>;

   constructor(
      private adminFacade: AdminFacade,
      private dialogRef: MatDialogRef<UserInviteComponent>
   ) {}

   ngOnInit() {
      this.form = new UntypedFormGroup({
         firstName: new UntypedFormControl(),
         lastName: new UntypedFormControl(),
         email: new UntypedFormControl(),
         role: new UntypedFormControl(),
         product: new UntypedFormControl(),
         isAdmin: new UntypedFormControl(),
      });
      this.orgPlans$ = this.adminFacade.orgPlans$;
      this.plansAvailable$ = this.orgPlans$.pipe(
         map((plans) => plans && plans.map((p) => p.available).reduce((a, b) => a + b) > 0)
      );
      this.form.get('product').valueChanges.subscribe((val) => {
         if (val === ProductType.Reference) {
            this.form.get('role').setValue(UserRole.User);
            this.form.get('isAdmin').setValue(false);
         }
      });
   }

   sendInvite() {
      const invite = this.form.value;
      invite.sent = moment().toISOString();
      invite.role = invite.isAdmin ? UserRole.Admin : UserRole.User;
      this.adminFacade.sendInvite(invite, this.createTeamMember);
      this.dialogRef.close();
   }

   inviteAnother() {
      const invite: UserInvite = this.form.value;
      invite.sent = moment().toISOString();
      invite.role = invite.isAdmin ? UserRole.Admin : UserRole.User;
      this.adminFacade.sendInvite(invite, this.createTeamMember);
      this.form.reset();
      this.createTeamMember = false;
   }

   setProducts(change: MatSelectChange) {
      if (change.value.includes(ProductType.Complete)) {
         this.form.get('products').setValue([ProductType.Complete]);
      }
   }

   roleDisabled(role: UserRole) {
      const selectedProduct = this.form.get('product').value;
      return selectedProduct === ProductType.Reference && role === UserRole.Admin;
   }
}
