<div class="logo">
   <img src="assets/tricolor-horizontal.svg" class="logo-image" />
</div>
<mat-card>
   <mat-card-title id="login-text">Log In</mat-card-title>
   <mat-card-content>
      <form #loginForm="ngForm">
         <mat-form-field>
            <input
               matInput
               type="email"
               placeholder="email"
               [(ngModel)]="email"
               name="email"
               data-test-id="email"
            />
         </mat-form-field>
         <mat-form-field>
            <input
               matInput
               type="password"
               placeholder="password"
               [(ngModel)]="password"
               name="password"
               data-test-id="password"
            />
         </mat-form-field>
         <div class="signup">
            <a routerLink="/signup">Don't have an account? Create one here.</a>
         </div>
         <div class="loginButtons">
            <button mat-button type="button" color="primary" (click)="resetPassword()">
               Forgot Password?
            </button>
            <button
               type="submit"
               mat-raised-button
               color="primary"
               (click)="login()"
               data-test-id="login-button"
            >
               Log In
            </button>
         </div>
      </form>
   </mat-card-content>
</mat-card>
