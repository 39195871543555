<div class="tasks">
   <app-table-header
      [hideExport]="tableConfig.hideExport"
      [filterBar]="filterBarConfig"
      [filter$]="taskFilter$"
      [columns]="columns"
      [displayedColumns]="displayedColumns$ | async"
      (columnsUpdated)="setDisplayedColumns($event)"
      (exportData)="export($event)"
   ></app-table-header>
   <app-table [config]="tableConfig"></app-table>
</div>
