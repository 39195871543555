import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TableConfig } from '../table/table.component';
import { FilterBarConfig, FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { CsvService } from '@app/shared/services/csv.service';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { Observable } from 'rxjs';

@Component({
   selector: 'app-table-header',
   templateUrl: './table-header.component.html',
   styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent implements OnInit {
   @Input() hideExport: boolean;
   @Input() filterBar: FilterBarConfig;
   @Input() filter$: Observable<FilterValues>;
   @Input() columns: TableColumn<any>[] = [];
   @Input() displayedColumns: string[] = [];

   @Output() columnsUpdated = new EventEmitter();
   @Output() exportData = new EventEmitter();

   constructor() {}

   ngOnInit(): void {}

   setDisplayedColumns(displayedColumns: string[]) {
      const cols = this.columns.filter((col) => displayedColumns.includes(col.def));
      this.columnsUpdated.emit(cols);
   }

   export(filterData: boolean) {
      this.exportData.emit(filterData);
   }
}
