<h3 mat-dialog-title>Assign Team Members to {{ role.name }} Role</h3>
<form [formGroup]="form">
  <div mat-dialog-content>
    <ng-container formArrayName="teamMembers" *ngFor="let control of controls(); let i = index">
      <div class="team-member-row">
        <app-team-member-autocomplete
          (optionSelected)="setTeamMember($event, i)"
          placeholder="Team Member"
          [showClear]="false"
        ></app-team-member-autocomplete>
        <button
          mat-icon-button
          class="remove-button"
          (click)="removeTeamMember(i)"
          matTooltip="Remove Team Member"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </ng-container>
    <button mat-button color="primary" (click)="addTeamMember()">
      <mat-icon>add</mat-icon> Add another team member
    </button>
  </div>
  <div mat-dialog-actions>
    <div class="button-container">
      <button mat-raised-button color="primary" (click)="assign()">
        Assign
      </button>
      <button mat-raised-button (click)="close()">Cancel</button>
    </div>
  </div>
</form>
