import { createAction, props } from '@ngrx/store';
import { FeatureFlag } from './internal-tools.reducer';

const ToggleFeatureFlag = createAction('[FeatureFlags] Set feature flag', props<{ key: string }>());
const SyncFeatureFlags = createAction('[FeatureFlags] Sync feature flags');

export const InternalToolsActions = {
   ToggleFeatureFlag,
   SyncFeatureFlags,
};
