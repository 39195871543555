import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { Status } from '@entities/enums/status';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ActionItem } from '@entities/action-item';
import { map, takeUntil } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { Priority } from '@entities/enums/priority';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { MeetingsFacade } from '@app/meetings/state/meetings.facade';
import { UserComment } from '@entities/user-comment';
import { froalaConfig } from '@app/shared/config/froala.config';
import { UserCommentsComponent } from '@app/shared/components/user-comments/user-comments.component';
import { ActionItemType } from '@entities/enums/action-item-type';
import { getKeys } from '@app/utilities/getKeys';

@Component({
   selector: 'app-meeting-action-item-details',
   templateUrl: './meeting-action-item-details.component.html',
   styleUrls: ['./meeting-action-item-details.component.scss'],
})
export class MeetingActionItemDetailsComponent implements OnInit, OnDestroy, SaveChangesComponent {
   @Output('commentAdded') commentAdded = new EventEmitter<UserComment>();
   @ViewChild(UserCommentsComponent) userCommentsComponent: UserCommentsComponent;

   editorConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Description',
      minHeight: 100,
   };

   form: UntypedFormGroup;

   type = ActionItemType;
   typeKeys = getKeys(ActionItemType);

   status = Status;
   statusKeys = Object.keys(Status)
      .filter((key) => !isNaN(+key))
      .map((key) => +key);

   priority = Priority;
   priorityKeys = Object.keys(Priority)
      .map((key) => +key)
      .filter((key) => !isNaN(key));
   comments: UserComment[] = [];
   private oldComments: UserComment[] = [];

   actionItem$: Observable<ActionItem>;
   title$: Observable<string>;
   private destroyed$ = new Subject<void>();

   constructor(private meetingsFacade: MeetingsFacade) {}

   ngOnInit() {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(null, Validators.required),
         description: new UntypedFormControl(),
         startDate: new UntypedFormControl(),
         targetDate: new UntypedFormControl(),
         completedDate: new UntypedFormControl(),
         status: new UntypedFormControl(),
         assigneeId: new UntypedFormControl(),
         priority: new UntypedFormControl(),
         departmentId: new UntypedFormControl(),
      });

      this.actionItem$ = this.meetingsFacade.selectedActionItem$;
      this.title$ = this.actionItem$.pipe(
         map((actionItem) => {
            if (actionItem && actionItem.id) {
               return 'ActionItem Details';
            } else {
               return 'Create ActionItem';
            }
         })
      );
      this.actionItem$.pipe(takeUntil(this.destroyed$)).subscribe((actionItem) => {
         if (actionItem) {
            this.form.reset();
            this.form.patchValue(actionItem);
            this.form.markAsPristine();
            if (actionItem.comments) {
               this.comments = [...actionItem.comments]
                  .sort((a, b) => a.timestamp.localeCompare(b.timestamp))
                  .map((x) => {
                     return { ...x };
                  });
               this.oldComments = [...this.comments];
            } else {
               this.comments = [];
            }
         } else {
            return [];
         }
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   setDepartmentId(event: MatAutocompleteSelectedEvent) {
      if (event && event.option.value) {
         this.form.get('departmentId').setValue(event.option.value.id);
         this.form.markAsDirty();
      }
   }

   setAssignedToId(event: MatAutocompleteSelectedEvent) {
      if (event && event.option.value) {
         this.form.get('assigneeId').setValue(event.option.value.id);
         this.form.markAsDirty();
      }
   }

   setDate(event: MatDatepickerInputEvent<Moment>, formControlName: string) {
      const date = event.value;
      const formControl = this.form.get(formControlName);
      if (date) {
         formControl.setValue(date.toISOString());
      } else {
         formControl.reset();
      }
   }

   save() {
      const actionItem: ActionItem = this.form.value;
      const toSave = {
         ...actionItem,
         comments: this.comments,
      };
      this.meetingsFacade.saveActionItem(toSave);
   }

   delete() {
      const actionItem = this.form.value;
      // this.meetingsFacade.deleteActionItem(actionItem);
   }

   saveDisabled() {
      return this.form.pristine || !this.form.valid;
   }

   addComment(comment: UserComment) {
      this.comments.push(comment);
      this.commentAdded.emit(comment);
      this.form.markAsDirty();
   }

   updateComments(updatedComments: UserComment[]) {
      const newComments = updatedComments.filter(
         (a) => !this.oldComments.some((b) => b.timestamp == a.timestamp)
      );
      newComments.forEach((comment) => {
         this.commentAdded.emit(comment);
      });
      this.comments = updatedComments;
      this.oldComments = [...updatedComments];
      this.form.markAsDirty();
   }
}
