import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from './material/angular-material.module';
import { DepartmentAutocompleteComponent } from './components/department-autocomplete/department-autocomplete.component';
import { DepartmentNamePipe } from './pipes/department-name.pipe';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { TeamMemberNamePipe } from './pipes/team-member-name.pipe';
import { ErrorService } from './services/error.service';
import { TableService } from './services/table.service';
import { HttpClientModule } from '@angular/common/http';
import { TaskAutocompleteComponent } from './components/task-autocomplete/task-autocomplete.component';
import { BusinessUnitNamePipe } from './pipes/business-unit-name.pipe';
import { AssignedTeamMembersComponent } from './components/assigned-team-members/assigned-team-members.component';
import { DialogService } from './services/dialog.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { TeamMemberAutocompleteComponent } from './components/team-member-autocomplete/team-member-autocomplete.component';
import { GoalNamePipe } from './pipes/goal.pipe';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { EditColumnsComponent } from './components/edit-columns/edit-columns.component';
import { QuarterpickerComponent } from './components/quarterpicker/quarterpicker.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { SaveChangesGuard } from './services/save-changes.guard';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { GridsterModule } from 'angular-gridster2';
import { NgChartsModule } from 'ng2-charts';
import { UserCommentComponent } from './components/user-comment/user-comment.component';
import { RockNamePipe } from './pipes/rock-name.pipe';
import { ActionItemNamePipe } from './pipes/action-item-name.pipe';
import { UserCommentsComponent } from './components/user-comments/user-comments.component';
import { MultiSaveComponent } from './components/multi-save/multi-save.component';
import { StickyHeaderComponent } from './components/sticky-header/sticky-header.component';
import { TableComponent } from './components/table/table.component';
import { CsvService } from './services/csv.service';
import { CardTitleComponent } from './components/card-title/card-title.component';
import { TeamMemberMultiselectComponent } from './components/team-member-multiselect/team-member-multiselect.component';
import { TimerComponent } from './components/timer/timer.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TitleEditComponent } from './components/title-edit/title-edit.component';
import { PropertyDisplayPipe } from './pipes/property-display.pipe';
import { NameByIdPipe } from './pipes/name-by-id.pipe';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { StorageService } from './services/storage.service';
import { DatepickerClearComponent } from './components/datepicker-clear/datepicker-clear.component';
import { HistoryFacade } from './state/history.facade';
import { ReportHeaderComponent } from './components/report-header/report-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UserAutocompleteComponent } from './components/user-autocomplete/user-autocomplete.component';
import { PermissionsFacade } from './state/permissions.facade';

@NgModule({
   declarations: [
      AssignedTeamMembersComponent,
      AutoFocusDirective,
      BusinessUnitNamePipe,
      ConfirmDialogComponent,
      DepartmentAutocompleteComponent,
      DepartmentNamePipe,
      EditColumnsComponent,
      FilterBarComponent,
      GoalNamePipe,
      ActionItemNamePipe,
      QuarterpickerComponent,
      RockNamePipe,
      TaskAutocompleteComponent,
      TeamMemberAutocompleteComponent,
      TeamMemberNamePipe,
      UserCommentComponent,
      UserCommentsComponent,
      MultiSaveComponent,
      StickyHeaderComponent,
      TableComponent,
      CardTitleComponent,
      TeamMemberMultiselectComponent,
      TimerComponent,
      TableHeaderComponent,
      TitleEditComponent,
      PropertyDisplayPipe,
      NameByIdPipe,
      TextEditorComponent,
      DatepickerClearComponent,
      ReportHeaderComponent,
      UserAutocompleteComponent,
   ],
   imports: [
      CommonModule,
      AngularMaterialModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      FroalaEditorModule.forRoot(),
      FroalaViewModule.forRoot(),
      GridsterModule,
      NgChartsModule,
      NgxMatSelectSearchModule,
   ],
   exports: [
      // modules
      AngularMaterialModule,
      NgChartsModule,
      FormsModule,
      FroalaEditorModule,
      FroalaViewModule,
      GridsterModule,
      HttpClientModule,
      ReactiveFormsModule,
      // components
      AssignedTeamMembersComponent,
      AutoFocusDirective,
      BusinessUnitNamePipe,
      CardTitleComponent,
      DepartmentAutocompleteComponent,
      DepartmentNamePipe,
      EditColumnsComponent,
      FilterBarComponent,
      GoalNamePipe,
      ActionItemNamePipe,
      MultiSaveComponent,
      QuarterpickerComponent,
      ReportHeaderComponent,
      RockNamePipe,
      StickyHeaderComponent,
      TableComponent,
      TableHeaderComponent,
      TaskAutocompleteComponent,
      TeamMemberNamePipe,
      TeamMemberAutocompleteComponent,
      TeamMemberMultiselectComponent,
      TextEditorComponent,
      TimerComponent,
      TitleEditComponent,
      UserAutocompleteComponent,
      UserCommentComponent,
      UserCommentsComponent,
      PropertyDisplayPipe,
      NameByIdPipe,
      DatepickerClearComponent,
   ],
   providers: [
      ErrorService,
      TableService,
      BusinessUnitNamePipe,
      DepartmentNamePipe,
      DialogService,
      CanDeactivateGuard,
      GoalNamePipe,
      TeamMemberNamePipe,
      SaveChangesGuard,
      CsvService,
      NameByIdPipe,
      StorageService,
      HistoryFacade,
      PermissionsFacade,
   ],
})
export class SharedModule {
   static forRoot(): ModuleWithProviders<SharedModule> {
      return {
         ngModule: SharedModule,
         providers: [ErrorService, TableService],
      };
   }
}
