<div class="unassigned-users" *ngIf="users.length > 0">
   <mat-accordion>
      <mat-expansion-panel>
         <mat-expansion-panel-header>
            <mat-panel-title class="unassigned-users-title">
               <mat-icon>warning</mat-icon>
               There are {{ users.length }} users that are not assigned to a permission group.
            </mat-panel-title>
         </mat-expansion-panel-header>
         <app-permission-group-membership-table
            [users]="users"
            (assignUser)="assignUser.emit($event)"
         ></app-permission-group-membership-table>
      </mat-expansion-panel>
   </mat-accordion>
</div>

