import { EntityService } from '@app/shared/services/entity.service';
import { TeamMember } from '@entities/team-member';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { State } from '@app/app.state';
import { Store, select } from '@ngrx/store';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { PermissionedEntity } from '@app/shared/interfaces/permissioned-entity.interface';
import { WhereFilterOp, where } from '@angular/fire/firestore';
import { PermissionsFacade } from '@app/shared/state/permissions.facade';
import { AppAreas } from '@entities/enums/app-areas';

@Injectable()
export class TeamMemberService implements EntityService<TeamMember>, PermissionedEntity, OnDestroy {
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.TEAM_MEMBERS;
   entities$: Observable<TeamMember[]>;

   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<TeamMember>();
   private teamMemberId$ = this.permissionsFacade.currentUserTeamMemberId$;
   private permissionGroup$ = this.permissionsFacade.currentUserPermissionGroup$;

   constructor(private store: Store<State>, private permissionsFacade: PermissionsFacade) {
      this.orgEntityService.init(
         this.orgId$,
         this.COLLECTION_NAME,
         AppAreas.Team,
         this.permissionGroup$,
         this.assigneeFilter,
         this.teamMemberId$
      );
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }
   save(entity: TeamMember, overwrite: boolean = false): Promise<TeamMember> {
      return this.orgEntityService.save(entity, { overwrite });
   }
   batchSave(entities: TeamMember[]) {
      return this.orgEntityService.batchSave(entities);
   }

   delete(entity: TeamMember) {
      return this.orgEntityService.delete(entity);
   }

   assigneeFilter(teamMemberId: string): Array<[string, WhereFilterOp, string]> {
      return [
         ['id', '==', teamMemberId],
         ['managerId', '==', teamMemberId],
      ];
   }
}
