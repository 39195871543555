import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   OnDestroy,
   ViewChild,
   SimpleChanges,
} from '@angular/core';
import { TeamMember } from '@entities/team-member';
import { Observable, combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import {
   MatLegacyAutocomplete as MatAutocomplete,
   MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import { TeamMemberService } from '@app/team/services/team-member.service';
import { TeamMemberStatus } from '@entities/enums/team-member-status';

@Component({
   selector: 'app-team-member-autocomplete',
   templateUrl: './team-member-autocomplete.component.html',
   styleUrls: ['./team-member-autocomplete.component.scss'],
})
export class TeamMemberAutocompleteComponent implements OnInit, OnDestroy {
   @Input() class: any;
   @Input() placeholder: string;
   @Input() idFormControl: UntypedFormControl;
   @Input() showClear = true;
   @Input() canEdit = true;
   @Input() required = false;
   @Input() value: string;
   @Input() validate: Observable<any>;
   @Input() showInactive = false;
   @Output() optionSelected: EventEmitter<MatAutocompleteSelectedEvent> = new EventEmitter();
   @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;

   formCtrl: UntypedFormControl = new UntypedFormControl();
   teamMembers: TeamMember[];
   filtered$: Observable<TeamMember[]>;
   focus$ = new BehaviorSubject<boolean>(false);
   filter$ = new BehaviorSubject<any>('');
   selected = false;

   private destroyed$ = new Subject<void>();

   constructor(private teamMemberService: TeamMemberService) {}

   ngOnInit() {
      if (!this.idFormControl) {
         this.idFormControl = new UntypedFormControl();
      }
      this.teamMemberService.entities$
         .pipe(
            takeUntil(this.destroyed$),
            map((teamMembers) => {
               return teamMembers.sort((a, b) => {
                  const diff = a.lastName?.localeCompare(b.lastName);
                  return diff || a.firstName?.localeCompare(b.firstName);
               });
            })
         )
         .subscribe((teamMembers) => {
            this.teamMembers = teamMembers;
            this.setTeamMember();
         });
      this.formCtrl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
         this.filter$.next(val);
      });
      this.idFormControl.valueChanges
         .pipe(takeUntil(this.destroyed$))
         .subscribe(() => this.setTeamMember());

      if (this.validate) {
         this.validate.subscribe(() => {
            if (this.idFormControl.dirty && this.idFormControl.invalid) {
               this.formCtrl.markAsTouched();
               this.formCtrl.markAsDirty();
               this.formCtrl.updateValueAndValidity();
               this.formCtrl.setErrors({ error: true });
            }
         });
      }

      this.filtered$ = combineLatest([
         this.filter$.asObservable(),
         this.focus$.asObservable(),
      ]).pipe(
         map(([filter, focus]) => {
            if (this.canEdit) {
               if (this.teamMembers && filter && !filter.id) {
                  return this.teamMembers.filter(
                     (t) =>
                        ((t.status === TeamMemberStatus.Active ||
                           this.idFormControl.value === t.id) &&
                           t.firstName?.toLowerCase().includes(filter.toLowerCase())) ||
                        t.lastName?.toLowerCase().includes(filter.toLowerCase())
                  );
               } else {
                  return this.teamMembers;
               }
            } else {
               return [];
            }
         })
      );

      if (this.value) {
         this.idFormControl.setValue(this.value);
         this.setTeamMember();
      }
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['canEdit']) {
         if (this.canEdit) {
            this.formCtrl.enable();
         } else {
            this.formCtrl.disable();
         }
      }
   }

   setTeamMember() {
      if (this.idFormControl.value && this.teamMembers) {
         const teamMember = this.teamMembers.find((t) => t.id == this.idFormControl.value);
         this.formCtrl.setValue(teamMember);
         this.selected = true;
      } else {
         this.formCtrl.reset();
         this.selected = false;
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   displayFn(teamMember?: TeamMember): string | undefined {
      return teamMember ? `${teamMember.lastName}, ${teamMember.firstName}` : undefined;
   }

   isActive(teamMember: TeamMember) {
      return teamMember.status === TeamMemberStatus.Active;
   }

   onOptionSelect(event: MatAutocompleteSelectedEvent) {
      this.optionSelected.emit(event);
      this.selected = true;
   }

   selectFirstOption() {
      this.autocomplete.options.first.select();
   }

   onFocus() {
      this.focus$.next(true);
   }

   clear() {
      this.formCtrl.reset();
      this.selected = false;
      this.optionSelected.emit(null);
   }
}
