import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthUser } from '@entities/auth-user';

export interface State {
  auth: AuthState;
}

export interface AuthState {
  authUser: AuthUser;
}

export const getAuthState = createFeatureSelector<AuthState>('auth');
export const getLoggedIn = createSelector(
  getAuthState,
  (state) => !!state.authUser
);
export const getAuthUser = createSelector(
  getAuthState,
  (state) => state.authUser
);
