<div *ngIf="error; else processing">
   <div *ngIf="error.message; else generic">
      {{ error.message }}
   </div>
   <ng-template #generic>
      An error occurred. Please refresh the page to try again. If the problem persists, please
      contact support at support@alignedapps.com
   </ng-template>
</div>
<ng-template #processing>
   Please wait while we set up your access to this organization...
</ng-template>
