import {
   Component,
   OnInit,
   ComponentFactoryResolver,
   Input,
   ViewChild,
   ViewContainerRef,
} from '@angular/core';
import { widgetComponentMap } from '@app/dashboard/state/widget-component-map';
import { Widget } from '@entities/widget';

export interface WidgetInstance {
   data?: any;
   id: string;
}

@Component({
   selector: 'app-widget',
   template: `
      <div #viewRef></div>
   `,
})
export class WidgetComponent implements OnInit {
   @Input() widget: Widget;
   @ViewChild('viewRef', { static: true, read: ViewContainerRef }) viewRef: ViewContainerRef;

   constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

   ngOnInit() {
      if (this.widget && this.widget.component) {
         const widget = widgetComponentMap[this.widget.component];
         if (widget) {
            const componentFactory =
               this.componentFactoryResolver.resolveComponentFactory<WidgetInstance>(widget);
            this.viewRef.clear();
            const componentRef = this.viewRef.createComponent(componentFactory);
            componentRef.instance.data = this.widget.data;
            componentRef.instance.id = this.widget.id;
         }
      }
   }
}
