import { appRoutesNames } from '@app/app.routes.names';

import { documentationRouteNames } from '@app/documentation/documentation.routes.names';

import { evolveRouteNames } from '@app/evolve/evolve.routes.names';

import { teamRouteNames } from '@app/team/team.routes.names';
import { AppAreas } from '@entities/enums/app-areas';
import { ProductType } from '@entities/enums/product-type';
import { performanceRouteNames } from '@app/performance/performance.routes.names';
import { FeatureFlagKeys } from '@app/internal-tools/state/feature-flag-keys';

export const navConfig = [
   {
      route: appRoutesNames.DASHBOARD,
      area: AppAreas.Dashboard,
      label: 'Dashboard',
      svgIcon: false,
      iconPath: 'dashboard',
      children: [],
      products: [ProductType.Evolve, ProductType.Process],
   },
   {
      route: appRoutesNames.OVERVIEW,
      area: AppAreas.Overview,
      products: [ProductType.Evolve, ProductType.Process],
      label: 'Vision',
      svgIcon: true,
      iconPath: 'telescope',
      children: [],
   },
   {
      route: appRoutesNames.ORG_BUILDER,
      area: AppAreas.Organization,
      products: [ProductType.Evolve, ProductType.Process],
      label: 'Org Builder',
      svgIcon: true,
      iconPath: 'sitemap',
      children: [],
   },
   {
      route: appRoutesNames.DOCUMENTATION,
      area: AppAreas.Documentation,
      products: [ProductType.Process],
      label: 'Documentation',
      svgIcon: false,
      iconPath: 'description',
      children: [
         { label: 'Functions', route: documentationRouteNames.FUNCTIONS },
         { label: 'Tasks', route: documentationRouteNames.TASKS },
         // { label: 'Reports', route: documentationRouteNames.REPORTS }
      ],
   },
   {
      route: appRoutesNames.PERFORMANCE,
      area: AppAreas.Performance,
      products: [ProductType.Evolve],
      label: 'Performance',
      svgIcon: true,
      iconPath: 'chart-bar',
      children: [
         { label: 'Functions', route: performanceRouteNames.FUNCTIONS },
         { label: 'Tasks', route: performanceRouteNames.TASKS },
      ],
   },
   {
      route: appRoutesNames.EVOLVE,
      area: AppAreas.Evolve,
      products: [ProductType.Evolve],
      label: 'Evolve',
      svgIcon: false,
      iconPath: 'trending_up',
      children: [
         { label: 'Goals', route: evolveRouteNames.GOALS },
         { label: 'Rocks', route: evolveRouteNames.ROCKS },
         { label: 'Actions', route: evolveRouteNames.ISSUES },
      ],
   },
   {
      route: appRoutesNames.TEAM,
      area: AppAreas.Team,
      products: [ProductType.Evolve, ProductType.Process],
      label: 'Team',
      svgIcon: true,
      iconPath: 'account-group',
      children: [
         { label: 'Team Members', route: teamRouteNames.TEAM_MEMBERS },
         {
            label: 'Competencies',
            route: teamRouteNames.COMPETENCIES,
         },
         {
            label: 'Role Templates',
            route: teamRouteNames.ROLES,
            featureFlag: FeatureFlagKeys.ROLE_TEMPLATES,
         },
      ],
   },
   {
      route: appRoutesNames.MEETINGS,
      area: AppAreas.Evolve,
      products: [ProductType.Evolve],
      label: 'Meetings',
      svgIcon: false,
      iconPath: 'calendar_today',
      children: [],
   },
   {
      route: appRoutesNames.SETTINGS,
      area: AppAreas.Admin,
      products: [ProductType.Evolve, ProductType.Process],
      label: 'Settings',
      svgIcon: false,
      iconPath: 'settings',
      children: [],
   },
];
