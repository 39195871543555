import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Status } from '@entities/enums/status';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ActionItem } from '@entities/action-item';
import { EvolveFacade } from '@app/evolve/state/evolve.facade';
import { Router, ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Priority } from '@entities/enums/priority';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { UserComment } from '@entities/user-comment';
import { deepCopy } from '@app/utilities/deep-copy';
import { ActionItemType } from '@entities/enums/action-item-type';
import { getKeys } from '@app/utilities/getKeys';
import { froalaConfig } from '@app/shared/config/froala.config';
import { UserCommentsComponent } from '@app/shared/components/user-comments/user-comments.component';
import { RoleGuard } from '@app/admin/services/role.guard';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-action-item-edit',
   templateUrl: './action-item-edit.component.html',
   styleUrls: ['./action-item-edit.component.scss'],
})
export class ActionItemEditComponent implements OnInit, OnDestroy, SaveChangesComponent {
   @ViewChild(UserCommentsComponent) commentsComponent: UserCommentsComponent;
   @ViewChild('convertToRockTemplate') convertToRockTemplate: TemplateRef<any>;
   editorConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Description',
      minHeight: 100,
   };
   form: UntypedFormGroup;
   status = Status;
   statusKeys = Object.keys(Status)
      .filter((key) => !isNaN(+key))
      .map((key) => +key);

   priority = Priority;
   priorityKeys = Object.keys(Priority)
      .map((key) => +key)
      .filter((key) => !isNaN(key));

   actionItemType = ActionItemType;
   actionItemTypeKeys = getKeys(ActionItemType);

   actionItem$: Observable<ActionItem>;
   actionItem: ActionItem;
   title$: Observable<string>;
   private destroyed$ = new Subject<void>();
   editTitle = false;
   deleteAfterConvert = false;

   comments: UserComment[] = [];
   saving = false;

   menuOptions = [
      {
         label: 'Convert to Rock',
         action: () => this.checkConvertToRock(),
         condition: () => this.form?.value?.id,
      },
   ];

   canEdit$: Observable<boolean>;
   canEdit = false;

   constructor(
      private evolveFacade: EvolveFacade,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private roleGuard: RoleGuard,
      private dialog: MatDialog
   ) {}

   ngOnInit() {
      this.initForm();

      this.actionItem$ = this.evolveFacade.selectedActionItem$;

      this.actionItem$.pipe(takeUntil(this.destroyed$)).subscribe((actionItem) => {
         if (actionItem) {
            this.actionItem = actionItem;
            this.saving = false;
            this.form.patchValue(actionItem);
            this.form.markAsPristine();
            if (actionItem.comments) {
               this.comments = deepCopy(actionItem.comments)
                  .sort((a, b) => a.timestamp.localeCompare(b.timestamp))
                  .map((x) => deepCopy(x));
            }
         }
      });
      this.canEdit$ = this.roleGuard.canEdit([], AppAreas.Evolve, [this.actionItem?.assigneeId]);
      this.canEdit$.pipe(takeUntil(this.destroyed$)).subscribe((canEdit) => {
         this.canEdit = canEdit;
         this.initForm();
      });
      this.title$ = combineLatest([this.actionItem$, this.canEdit$]).pipe(
         map(([actionItem, canEdit]) => {
            if (!canEdit) {
               return 'Action Details';
            }
            if (actionItem && actionItem.id) {
               return 'Edit Action';
            } else {
               this.editTitle = true;
               return 'Create Action';
            }
         })
      );

      this.activatedRoute.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
         if (params['actionItemId']) {
            this.evolveFacade.selectActionItem(params['actionItemId']);
         }
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   initForm() {
      if (!this.form) {
         this.form = new UntypedFormGroup({
            id: new UntypedFormControl(),
            name: new UntypedFormControl(),
            description: new UntypedFormControl(),
            startDate: new UntypedFormControl(),
            targetDate: new UntypedFormControl(),
            completedDate: new UntypedFormControl(),
            status: new UntypedFormControl(Status['Not Started']),
            assigneeId: new UntypedFormControl(),
            priority: new UntypedFormControl(Priority.Medium),
            departmentId: new UntypedFormControl(null, Validators.required),
            type: new UntypedFormControl(),
         });
      }

      if (this.canEdit) {
         this.form.enable();
      } else {
         this.form.disable();
      }
   }

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   setName(name: string) {
      this.form.get('name').setValue(name);
      this.form.markAsDirty();
   }

   setDepartmentId(event: MatAutocompleteSelectedEvent) {
      if (event && event.option.value) {
         this.form.get('departmentId').setValue(event.option.value.id);
         this.form.markAsDirty();
      }
   }

   setAssignedToId(event: MatAutocompleteSelectedEvent) {
      if (event && event.option.value) {
         this.form.get('assigneeId').setValue(event.option.value.id);
         this.form.markAsDirty();
      }
   }

   setDate(event: MatDatepickerInputEvent<moment.Moment>, formControlName: string) {
      const date = event.value;
      const formControl = this.form.get(formControlName);
      if (date) {
         formControl.setValue(date.toISOString());
      } else {
         formControl.reset();
      }
      this.form.markAsDirty();
   }

   save(onDeactivate: boolean = false) {
      const actionItem: ActionItem = {
         ...this.actionItem,
         ...this.form.value,
         comments: this.comments,
      };
      this.saving = true;
      this.evolveFacade.saveActionItem(actionItem, onDeactivate);
   }

   delete() {
      const actionItem = this.form.value;
      this.evolveFacade.deleteActionItem(actionItem);
   }

   saveDisabled() {
      return this.form.pristine || !this.form.valid;
   }

   addComment(comment: UserComment) {
      this.comments.push(comment);
      this.form.markAsDirty();
   }

   updateComments(updatedComments: UserComment[]) {
      this.comments = updatedComments;
      this.form.markAsDirty();
   }

   backToList() {
      history.back();
   }

   checkConvertToRock() {
      if (this.convertToRockTemplate) {
         this.dialog.open(this.convertToRockTemplate);
      }
   }

   convertToRock() {
      const actionItem: ActionItem = {
         ...this.actionItem,
         ...this.form.value,
         comments: this.comments,
      };
      this.saving = true;
      this.evolveFacade.convertActionToRock(actionItem, this.deleteAfterConvert);
      this.dialog.closeAll();
   }

   statusChanged(event: MatSelectChange) {
      if (event.value === Status.Completed) {
         const today = moment();
         this.form.get('completedDate').setValue(today.toISOString());
      } else if (this.form.value.completedDate) {
         this.form.get('completedDate').reset();
      }
   }
}
