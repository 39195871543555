import { Component, OnInit, OnDestroy } from '@angular/core';
import { froalaConfig } from '@app/shared/config/froala.config';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { ActivatedRoute } from '@angular/router';
import { BusinessUnit } from '@entities/business-unit';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TeamMember } from '@entities/team-member';

@Component({
   selector: 'app-business-unit-summary',
   templateUrl: './business-unit-summary.component.html',
   styleUrls: ['./business-unit-summary.component.scss'],
})
export class BusinessUnitSummaryComponent implements OnInit, OnDestroy {
   options = {
      ...froalaConfig,
      toolbarButtons: ['print'],
      documentReady: true,
   };

   orgName: string;
   logo: string;
   businessUnit: BusinessUnit;
   teamMembers: TeamMember[];

   private destroyed$ = new Subject<void>();

   constructor(private facade: OrgBuilderFacade, private route: ActivatedRoute) {
      this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
         if (params['businessUnitId']) {
            this.facade.selectBusinessUnit(params['businessUnitId']);
         }
      });
   }

   ngOnInit(): void {
      this.facade.businessUnitSummary$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((businessUnit) => {
            this.businessUnit = businessUnit;
         });
      this.facade.organization$.pipe(takeUntil(this.destroyed$)).subscribe((org) => {
         if (org) {
            this.orgName = org.name;
            this.logo = org.logo;
         }
      });
      this.facade.teamMembers$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });
   }

   back() {
      history.back();
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }
}
