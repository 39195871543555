import { Component, OnDestroy, OnInit } from '@angular/core';
import { TeamMemberNamePipe } from '@app/shared/pipes/team-member-name.pipe';
import { TeamFacade } from '@app/team/state/team.facade';
import { TaskRatingTreeItem } from '@app/team/state/team.state';
import { BusinessUnit } from '@entities/business-unit';
import { CompetencyLevel } from '@entities/competency-level';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { Task } from '@entities/task';
import { TaskRating } from '@entities/task-rating';
import { TeamMember } from '@entities/team-member';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
   selector: 'app-competency-dashboard',
   templateUrl: './competency-dashboard.component.html',
   styleUrls: ['./competency-dashboard.component.scss'],
})
export class CompetencyDashboardComponent implements OnInit, OnDestroy {
   competencyLevels$: Observable<CompetencyLevel[]> = this.teamFacade.competencyLevels$;
   taskRatingTree$: Observable<TaskRatingTreeItem[]> = this.teamFacade.selectedTaskRatingTree$;
   teamMembers$: Observable<TeamMember[]> = this.teamFacade.teamMembers$;

   teamMembers: TeamMember[] = [];
   selectedIds: string[] = [];
   businessUnits: BusinessUnit[] = [];
   departments: Department[] = [];
   departmentFunctions: DepartmentFunction[] = [];
   tasks: Task[] = [];

   mode: 'org' | 'individual' = 'org';

   displayedColumns = ['name', 'children', 'rating'];

   private destroyed$ = new Subject<void>();

   constructor(private teamFacade: TeamFacade, private teamMemberNamePipe: TeamMemberNamePipe) {}

   ngOnInit(): void {
      this.teamMembers$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });
      this.teamFacade.businessUnits$.pipe(takeUntil(this.destroyed$)).subscribe((businessUnits) => {
         this.businessUnits = businessUnits;
      });
      this.teamFacade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.teamFacade.departmentFunctions$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((departmentFunctions) => {
            this.departmentFunctions = departmentFunctions;
         });
      this.teamFacade.tasks$.pipe(takeUntil(this.destroyed$)).subscribe((tasks) => {
         this.tasks = tasks;
      });
      this.teamFacade.selectedTaskRatingTreeIds$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((ids) => {
            this.selectedIds = [...ids];
            if (this.selectedIds.length < 4) {
               this.mode = 'org';
               this.displayedColumns = ['name', 'children', 'rating'];
            } else {
               this.mode = 'individual';
               this.displayedColumns = ['name', 'rating'];
            }
         });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getHeader() {
      switch (this.selectedIds.length) {
         case 0:
            return 'Business Unit';
         case 1:
            return 'Department';
         case 2:
            return 'Function';
         case 3:
            return 'Task';
         default:
            return 'Name';
      }
   }

   getChildHeader() {
      switch (this.selectedIds.length) {
         case 0:
            return 'Departments';
         case 1:
            return 'Functions';
         case 2:
            return 'Tasks';
         default:
            return 'Assignees';
      }
   }

   getName(item: TaskRatingTreeItem | TaskRating) {
      if (this.mode === 'org') {
         return (item as TaskRatingTreeItem).name;
      } else {
         return this.teamMemberNamePipe.transform(
            (item as TaskRating).teamMemberId,
            this.teamMembers
         );
      }
   }

   rowClick(item: TaskRatingTreeItem | TaskRating) {
      if (this.mode === 'org') {
         this.teamFacade.selectTaskRatingItems([
            ...this.selectedIds,
            (item as TaskRatingTreeItem).id,
         ]);
      } else {
         this.teamFacade.editTeamMember((item as TaskRating).teamMemberId);
      }
   }

   viewParent() {
      this.teamFacade.selectTaskRatingItems([...this.selectedIds.slice(0, -1)]);
   }

   goToLevel(level: number) {
      const ids = this.selectedIds.slice(0, level);
      this.teamFacade.selectTaskRatingItems(ids);
   }
}
