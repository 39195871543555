<mat-card>
   <mat-card-content>
      <form [formGroup]="form">
         <div class="action-item-info">
            <div class="action-item-info-col-left">
               <div class="action-item-info-row">
                  <mat-form-field class="action-item-info-col-4">
                     <mat-label>Type</mat-label>
                     <mat-select formControlName="status" required>
                        <mat-option [value]="key" *ngFor="let key of typeKeys">
                           {{ type[key] }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
                  <mat-form-field class="action-item-info-col-4">
                     <mat-label>Status</mat-label>
                     <mat-select formControlName="status" required>
                        <mat-option [value]="key" *ngFor="let key of statusKeys">
                           {{ status[key] }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
                  <mat-form-field class="action-item-info-col-4">
                     <mat-label>Priority</mat-label>
                     <mat-select formControlName="priority" required>
                        <mat-option [value]="key" *ngFor="let key of priorityKeys">
                           {{ priority[key] }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
               <div class="action-item-info-row">
                  <app-department-autocomplete
                     class="action-item-info-col-6"
                     placeholder="Department"
                     [idFormControl]="getControl('departmentId')"
                     (optionSelected)="setDepartmentId($event)"
                  ></app-department-autocomplete>

                  <app-team-member-autocomplete
                     class="action-item-info-col-6"
                     placeholder="Assigned To"
                     [idFormControl]="getControl('assigneeId')"
                     (optionSelected)="setAssignedToId($event)"
                  ></app-team-member-autocomplete>
               </div>
               <div class="description">
                  <mat-form-field class="fill-height">
                     <mat-label *ngIf="form.value.description">Description</mat-label>
                     <textarea
                        class="editor"
                        matInput
                        type="text"
                        formControlName="description"
                        [froalaEditor]="editorConfig"
                     ></textarea>
                  </mat-form-field>
               </div>
            </div>
            <div class="action-item-info-col-right">
               <mat-form-field>
                  <input
                     matInput
                     formControlName="startDate"
                     placeholder="Start Date"
                     [matDatepicker]="startDatePicker"
                     (dateChange)="setDate($event, 'startDate')"
                  />
                  <app-datepicker-clear
                     class="datepicker-clear"
                     [for]="startDatePicker"
                  ></app-datepicker-clear>
                  <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #startDatePicker></mat-datepicker>
               </mat-form-field>
               <mat-form-field>
                  <input
                     matInput
                     formControlName="targetDate"
                     placeholder="Target Date"
                     [matDatepicker]="targetDatePicker"
                     (dateChange)="setDate($event, 'targetDate')"
                  />
                  <app-datepicker-clear
                     class="datepicker-clear"
                     [for]="targetDatePicker"
                  ></app-datepicker-clear>
                  <mat-datepicker-toggle matSuffix [for]="targetDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #targetDatePicker></mat-datepicker>
               </mat-form-field>
               <mat-form-field>
                  <input
                     matInput
                     formControlName="completedDate"
                     placeholder="Completed Date"
                     [matDatepicker]="completedDatePicker"
                     (dateChange)="setDate($event, 'completedDate')"
                  />
                  <app-datepicker-clear
                     class="datepicker-clear"
                     [for]="completedDatePicker"
                  ></app-datepicker-clear>
                  <mat-datepicker-toggle
                     matSuffix
                     [for]="completedDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #completedDatePicker></mat-datepicker>
               </mat-form-field>
            </div>
         </div>
      </form>
   </mat-card-content>
</mat-card>

<app-user-comments
   [comments]="comments"
   (commentsUpdated)="updateComments($event)"
></app-user-comments>
