import * as root from '@app/app.state';

import { TeamMember } from '@entities/team-member';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Role } from '@entities/role';
import { Competency } from '@entities/competency';
import { PerformanceEvaluation } from '@entities/performance-evaluation';
import { CompetencyLevel } from '@entities/competency-level';
import { TaskRating } from '@entities/task-rating';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';

export interface State extends root.State {
   team: TeamState;
}

export interface TeamState {
   teamMembers: TeamMember[];
   selectedTeamMemberId: string;
   roles: Role[];
   selectedRoleId: string;
   competencies: Competency[];
   selectedCompetencyId: string;
   performanceEvaluations: PerformanceEvaluation[];
   selectedPerformanceEvaluationId: string;
   competencyLevels: CompetencyLevel[];
   selectedTaskRatingTreeIds: string[];
   tasksFilter: FilterValues;
   teamMembersDisplayedColumns: string[];
}

export interface TaskRatingTreeItem {
   id: string;
   name: string;
   rating: number;
   children?: TaskRatingTreeItem[];
   individualRatings?: TaskRating[];
}

export const getTeamState = createFeatureSelector<TeamState>('team');
export const getTeamMembers = createSelector(getTeamState, (state) => state.teamMembers);
export const getTeamMember = createSelector(getTeamState, (state, props) =>
   state.teamMembers.find((t) => t.id == props.id)
);
export const getSelectedTeamMember = createSelector(getTeamState, (state) =>
   state.teamMembers.find((t) => t.id == state.selectedTeamMemberId)
);

export const getRoles = createSelector(getTeamState, (state) => state.roles);

export const getSelectedRole = createSelector(getTeamState, (state) =>
   state.roles.find((r) => r.id == state.selectedRoleId)
);

export const getTeamMembersForSelectedRole = createSelector(getTeamState, (state) => {
   const selectedRole = state.roles.find((r) => r.id == state.selectedRoleId);
   if (selectedRole && selectedRole.teamMembers) {
      return state.teamMembers.filter((t) => selectedRole.teamMembers.includes(t.id));
   } else {
      return [];
   }
});

export const getGrowthPlans = createSelector(getSelectedTeamMember, (teamMember) => {
   if (teamMember) {
      return teamMember.growthPlans;
   } else {
      return [];
   }
});

export const getCompetencies = createSelector(getTeamState, (state) => state.competencies);
export const getSelectedCompetency = createSelector(getTeamState, (state) =>
   state.competencies.find((c) => c.id === state.selectedCompetencyId)
);
export const getAssignedCompetencies = createSelector(
   getSelectedTeamMember,
   getCompetencies,
   (teamMember, competencies) => {
      if (teamMember && teamMember.assignedTasks && competencies) {
         return competencies
            .filter((c) => teamMember.assignedTasks.some((id) => c.linkedTasks.includes(id)))
            .map((comp) => ({
               ...comp,
               linkedTasks: comp.linkedTasks.filter((id) => teamMember.assignedTasks?.includes(id)),
            }));
      } else {
         return [];
      }
   }
);

const getPerformanceEvaluations = createSelector(getTeamState, (state, props) =>
   state.performanceEvaluations.filter((perfEval) => perfEval.teamMemberId === props.teamMemberId)
);

const getSelectedPerformanceEvaluation = createSelector(getTeamState, (state) =>
   state.performanceEvaluations.find(
      (perfEval) => perfEval.id === state.selectedPerformanceEvaluationId
   )
);

const getCompetencyLevels = createSelector(getTeamState, (state) => state.competencyLevels);
const getSelectedTaskRatingTreeIds = createSelector(
   getTeamState,
   (state) => state.selectedTaskRatingTreeIds
);
const getTeamTasksFilter = createSelector(getTeamState, (state) => state.tasksFilter);
const getTeamMembersDisplayedColumns = createSelector(
   getTeamState,
   (state) => state.teamMembersDisplayedColumns
);
export const teamSelectors = {
   getTeamMembers,
   getTeamMember,
   getSelectedTeamMember,
   getRoles,
   getSelectedRole,
   getCompetencies,
   getSelectedCompetency,
   getAssignedCompetencies,
   getPerformanceEvaluations,
   getSelectedPerformanceEvaluation,
   getCompetencyLevels,
   getSelectedTaskRatingTreeIds,
   getTeamTasksFilter,
   getTeamMembersDisplayedColumns,
};
