<form [formGroup]="filterForm">
   <button
      *ngIf="!config.disableToggle"
      mat-stroked-button
      class="toggle-button"
      color="primary"
      (click)="toggleShowFilters()"
   >
      {{ showFilters ? 'Hide Filters' : 'Show Filters' }}
   </button>
   <ng-container *ngIf="showFilters">
      <mat-card>
         <mat-card-content>
            <div class="filter-row">
               <mat-form-field *ngIf="config.businessUnits" class="filter">
                  <mat-label>Business Unit</mat-label>
                  <mat-select multiple formControlName="businessUnits" [compareWith]="idCompare">
                     <mat-option *ngFor="let option of businessUnits" [value]="option">
                        {{ option.name }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field *ngIf="config.departments" class="filter">
                  <mat-label>Department</mat-label>
                  <mat-select multiple formControlName="departments" [compareWith]="idCompare">
                     <ng-container *ngIf="config.groupDepartments; else ungrouped">
                        <mat-optgroup *ngFor="let group of groupedDepartments" [label]="group.name">
                           <mat-option
                              class="group-option"
                              *ngFor="let option of group.departments"
                              [value]="option"
                           >
                              {{ option.name }}
                           </mat-option>
                        </mat-optgroup>
                     </ng-container>
                     <ng-template #ungrouped>
                        <mat-option *ngFor="let option of departments" [value]="option">
                           {{ option.name }}
                        </mat-option>
                     </ng-template>
                  </mat-select>
               </mat-form-field>
               <mat-form-field *ngIf="config.departmentFunctions" class="filter">
                  <mat-label>Function</mat-label>
                  <mat-select
                     multiple
                     formControlName="departmentFunctions"
                     [compareWith]="idCompare"
                  >
                     <mat-option *ngFor="let option of departmentFunctions" [value]="option">
                        {{ option.name }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field *ngIf="config.type" class="filter">
                  <mat-label>Type</mat-label>
                  <mat-select multiple formControlName="type">
                     <mat-option *ngFor="let option of getKeys(config.type)" [value]="option">
                        {{ config.type[option] }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field *ngIf="config.status" class="filter">
                  <mat-label>Status</mat-label>
                  <mat-select multiple formControlName="status">
                     <mat-option *ngFor="let option of getKeys(config.status)" [value]="option">
                        {{ config.status[option] }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field *ngIf="config.priority" class="filter">
                  <mat-label>Priority</mat-label>
                  <mat-select multiple formControlName="priority">
                     <mat-option *ngFor="let option of getKeys(config.priority)" [value]="option">
                        {{ config.priority[option] }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field *ngIf="config.rating" class="filter">
                  <mat-label>Rating</mat-label>
                  <mat-select multiple formControlName="rating">
                     <mat-option *ngFor="let option of getKeys(config.rating)" [value]="option">
                        {{ config.rating[option] }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <app-quarterpicker
                  *ngIf="config.quarter"
                  class="filter"
                  label="Quarter"
                  [canEdit]="canEdit"
                  [formCtrl]="getControl('quarter')"
                  [multiple]="true"
               ></app-quarterpicker>
               <mat-form-field *ngIf="config.targetDate" class="filter">
                  <mat-label>Target Date</mat-label>
                  <mat-select formControlName="targetDate">
                     <mat-option value="all">All</mat-option>
                     <mat-option value="overdue">Overdue</mat-option>
                     <mat-option value="today">Today</mat-option>
                     <mat-option value="week">Current Week</mat-option>
                     <mat-option value="7days">Next 7 Days</mat-option>
                     <mat-option value="month">Current Month</mat-option>
                     <mat-option value="30days">Next 30 Days</mat-option>
                     <mat-option value="quarter">Current Quarter</mat-option>
                     <mat-option value="90days">Next 90 Days</mat-option>
                  </mat-select>
               </mat-form-field>
               <app-team-member-multiselect
                  *ngIf="config.teamMembers"
                  class="filter"
                  [canEdit]="canEdit"
                  [label]="config.teamMembersLabel"
                  [selected]="filter?.teamMembers"
                  (selectionChange)="setTeamMembers($event)"
               ></app-team-member-multiselect>
               <mat-form-field *ngIf="!config.hideSearch" class="search">
                  <input matInput placeholder="Search" formControlName="search" />
               </mat-form-field>
               <div class="filter">
                  <button
                     mat-button
                     class="filter-button"
                     color="primary"
                     *ngIf="canEdit"
                     [disabled]="filterEmpty()"
                     (click)="filterForm.reset()"
                  >
                     Clear Filters
                  </button>
               </div>
            </div>
         </mat-card-content>
      </mat-card>
   </ng-container>
</form>
