import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AssignedTaskViewModel } from '@app/documentation/state/documentation.models';
import { deepCopy } from '@app/utilities/deep-copy';
import { CompetencyLevel } from '@entities/competency-level';

@Component({
   selector: 'app-task-rating-edit',
   templateUrl: './task-rating-edit.component.html',
   styleUrls: ['./task-rating-edit.component.scss'],
})
export class TaskRatingEditComponent implements OnInit {
   selectedTask: AssignedTaskViewModel;
   competencyLevels: CompetencyLevel[] = [];

   constructor(
      @Inject(MAT_DIALOG_DATA)
      data: { viewModel: AssignedTaskViewModel; competencyLevels: CompetencyLevel[] },
      private dialogRef: MatDialogRef<TaskRatingEditComponent>
   ) {
      this.selectedTask = deepCopy(data.viewModel);
      this.competencyLevels = data.competencyLevels;
   }

   ngOnInit(): void {}

   ratingDescription() {
      const rating = this.competencyLevels.find(
         (l) => l.value === this.selectedTask?.taskRating?.rating
      );
      if (rating) {
         return rating.description;
      } else {
         return '';
      }
   }

   save() {
      const competencyLevel = this.competencyLevels.find(
         (l) => l.value === this.selectedTask?.taskRating?.rating
      );
      this.selectedTask.taskRating.competencyLevelId = competencyLevel.id;
      this.dialogRef.close(this.selectedTask);
   }

   cancel() {
      this.dialogRef.close();
   }
}
