import { createAction, props } from '@ngrx/store';
import { Meeting } from '@entities/meeting';
import { DiscussionItem } from '@entities/discussion-item';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';

const CreateMeeting = createAction('[Meetings] Create meeting', props<{ meeting: Meeting }>());
const MeetingsUpdated = createAction(
   '[Meetings] Meetings updated',
   props<{ meetings: Meeting[] }>()
);
const SelectMeeting = createAction(
   '[Meetings] Select meeting',
   props<{ selectedMeetingId: string }>()
);
const StartMeeting = createAction('[Meetings] Start meeting', props<{ meetingId: string }>());
const SelectRock = createAction('[Meetings] Select rock', props<{ selectedRockId: string }>());
const SelectActionItem = createAction(
   '[Meetings] Select actionItem',
   props<{ selectedActionItemId: string }>()
);
const SaveMeeting = createAction('[Meetings] Save meeting', props<{ meeting: Meeting }>());
const EndMeeting = createAction('[Meetings] End meeting');
const ViewMeeting = createAction('[Meetings] View meeting', props<Meeting>());
const CreateDraftMeeting = createAction(
   '[Meetings] Create draft meeting',
   props<{ meeting: Meeting }>()
);
const DeleteMeeting = createAction('[Meetings] Delete meeting', props<Meeting>());
const SetMeetingsFilter = createAction('[Meetings] Set Filter', props<{ filter: FilterValues }>());
const AddRockToMeeting = createAction('[Meetings] Add rock to meeting');
const AddActionItemToMeeting = createAction('[Meetings] Add actionItem to meeting');

export const MeetingsActions = {
   CreateMeeting,
   MeetingsUpdated,
   SelectMeeting,
   StartMeeting,
   SelectRock,
   SelectActionItem,
   SaveMeeting,
   EndMeeting,
   ViewMeeting,
   CreateDraftMeeting,
   DeleteMeeting,
   SetMeetingsFilter,
   AddRockToMeeting,
   AddActionItemToMeeting,
};
