import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TeamFacade } from '@app/team/state/team.facade';
import { BehaviorSubject, Observable, combineLatest, Subject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { TeamMember } from '@entities/team-member';
import { map, takeUntil } from 'rxjs/operators';
import { TableService } from '@app/shared/services/table.service';
import { Department } from '@entities/department';

const initialSort: Sort = { active: 'lastName', direction: 'asc' };

@Component({
   selector: 'app-assigned-team-members',
   templateUrl: './assigned-team-members.component.html',
   styleUrls: ['./assigned-team-members.component.scss'],
})
export class AssignedTeamMembersComponent implements OnInit, OnDestroy {
   @Input('teamMembers') teamMembers$: Observable<TeamMember[]>;
   @Input() canEdit = true;
   @Output() assign: EventEmitter<any> = new EventEmitter();
   @Output() unassign: EventEmitter<any> = new EventEmitter();
   @Output() view: EventEmitter<any> = new EventEmitter();

   teamMemberDataSource$: Observable<TeamMember[]>;
   sort$: BehaviorSubject<Sort> = new BehaviorSubject(initialSort);

   displayedColumns: string[] = [
      'firstName',
      'lastName',
      'title',
      'primaryDepartment.name',
      'options',
   ];

   departments: Department[];
   private destroyed$ = new Subject<void>();

   constructor(private tableService: TableService, private teamFacade: TeamFacade) {}

   ngOnInit() {
      this.teamFacade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.teamMemberDataSource$ = combineLatest([
         this.teamMembers$,
         this.sort$.asObservable(),
      ]).pipe(
         map(([teamMembers, sort]) => {
            return this.tableService.sort(teamMembers, sort);
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   assignTeamMembers() {
      this.assign.emit();
   }

   unassignTeamMember(teamMember: TeamMember) {
      this.unassign.emit(teamMember);
   }

   viewTeamMember(teamMember: TeamMember) {
      this.view.emit(teamMember);
   }

   triggerSort(sort: Sort) {
      this.sort$.next(sort);
   }
}
