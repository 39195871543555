<div class="chart-container">
   <button class="back-button" mat-button color="primary" (click)="back()">
      <mat-icon>arrow_back</mat-icon>
      Back
   </button>
   <div class="org-name">
      {{ orgName$ | async }}
   </div>
   <!-- <div class="vert-line"></div> -->
   <div class="business-units">
      <ng-container *ngFor="let businessUnit of businessUnits$ | async">
         <div class="entity business-unit">
            <div class="horiz-line"></div>
            <div class="vert-line"></div>
            <mat-card>
               <mat-card-title
                  class="cursor-pointer"
                  (click)="showBusinessUnitDetails(businessUnit)"
               >
                  {{ businessUnit.name }}
               </mat-card-title>
               <div class="assignee" *ngIf="businessUnit.directorId">
                  <mat-icon>person</mat-icon>
                  <div class="person-name">
                     {{ businessUnit.directorId | teamMemberName: teamMembers }}
                  </div>
               </div>
               <div
                  class="expand"
                  *ngIf="businessUnit.departments.length > 0"
                  (click)="toggleBusinessUnit(businessUnit)"
               >
                  <mat-icon *ngIf="businessUnitExpanded(businessUnit)">expand_less</mat-icon>
                  <mat-icon *ngIf="!businessUnitExpanded(businessUnit)">expand_more</mat-icon>
               </div>
            </mat-card>
            <ng-container *ngIf="businessUnitExpanded(businessUnit)">
               <div class="vert-line"></div>
               <div class="departments">
                  <div
                     class="entity department"
                     *ngFor="let department of businessUnit.departments"
                  >
                     <div class="horiz-line"></div>
                     <div class="vert-line"></div>
                     <mat-card>
                        <mat-card-title
                           class="cursor-pointer"
                           (click)="showDepartmentDetails(department)"
                        >
                           {{ department.name }}
                        </mat-card-title>
                        <div class="assignee" *ngIf="department.managerId">
                           <mat-icon>person</mat-icon>
                           <div class="person-name">
                              {{ department.managerId | teamMemberName: teamMembers }}
                           </div>
                        </div>
                        <div
                           class="expand"
                           *ngIf="department.departmentFunctions.length > 0"
                           (click)="toggleDepartment(department)"
                        >
                           <mat-icon *ngIf="departmentExpanded(department)">expand_less</mat-icon>
                           <mat-icon *ngIf="!departmentExpanded(department)">expand_more</mat-icon>
                        </div>
                     </mat-card>
                     <ng-container *ngIf="departmentExpanded(department)">
                        <div class="vert-line"></div>
                        <div class="department-functions">
                           <div
                              class="department-function"
                              *ngFor="let departmentFunction of department.departmentFunctions"
                           >
                              <mat-card>
                                 <mat-card-title
                                    class="cursor-pointer"
                                    (click)="showDepartmentFunctionDetails(departmentFunction)"
                                 >
                                    {{ departmentFunction.name }}
                                 </mat-card-title>
                                 <div class="assignee" *ngIf="departmentFunction.leadId">
                                    <mat-icon>person</mat-icon>
                                    <div class="person-name">
                                       {{ departmentFunction.leadId | teamMemberName: teamMembers }}
                                    </div>
                                 </div>
                                 <mat-card-content
                                    *ngIf="departmentFunctionExpanded(departmentFunction)"
                                 >
                                    <ul>
                                       <ng-container *ngFor="let task of departmentFunction.tasks">
                                          <li
                                             class="cursor-pointer"
                                             (click)="showTaskDetails(task)"
                                          >
                                             {{ task.name }}
                                          </li>
                                       </ng-container>
                                    </ul>
                                 </mat-card-content>
                                 <div
                                    class="expand"
                                    *ngIf="departmentFunction.tasks.length > 0"
                                    (click)="toggleExpanded(departmentFunction)"
                                 >
                                    <mat-icon
                                       *ngIf="departmentFunctionExpanded(departmentFunction)"
                                    >
                                       expand_less
                                    </mat-icon>
                                    <mat-icon
                                       *ngIf="!departmentFunctionExpanded(departmentFunction)"
                                    >
                                       expand_more
                                    </mat-icon>
                                 </div>
                              </mat-card>
                           </div>
                        </div>
                     </ng-container>
                  </div>
               </div>
            </ng-container>
         </div>
      </ng-container>
   </div>
</div>
