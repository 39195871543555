<table mat-table [dataSource]="featureFlags$">
   <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef>
         Key
      </th>
      <td mat-cell *matCellDef="let row">
         {{ row.key }}
      </td>
   </ng-container>
   <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>
         Description
      </th>
      <td mat-cell *matCellDef="let row">
         {{ row.description }}
      </td>
   </ng-container>
   <ng-container matColumnDef="enabled">
      <th mat-header-cell *matHeaderCellDef>
         Enabled
      </th>
      <td mat-cell *matCellDef="let row">
         {{ row.enabled }}
      </td>
   </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
   <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="toggleFeatureFlag(row.key)"
   ></tr>
</table>
