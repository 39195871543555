import { Component, OnInit } from '@angular/core';
import { InternalToolsFacade } from '@app/internal-tools/state/internal-tools.facade';
import { Organization } from '@entities/organization';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { TableConfig } from '@app/shared/components/table/table.component';

@Component({
   selector: 'app-organizations-internal',
   templateUrl: './organizations-internal.component.html',
   styleUrls: ['./organizations-internal.component.scss'],
})
export class OrganizationsInternalComponent implements OnInit {
   organizations$: Observable<Organization[]>;

   /* istanbul ignore next */
   columns: TableColumn<Organization>[] = [
      { def: 'name', label: 'Name', value: (row) => row.name, visible: true },
      {
         def: 'authUsers',
         label: 'Active Users',
         value: (row) => (row.authUsers ? row.authUsers.length : 0),
         visible: true,
      },
   ];

   tableConfig: TableConfig;

   constructor(private facade: InternalToolsFacade) {}

   ngOnInit(): void {
      this.organizations$ = this.facade.organizations$;

      this.tableConfig = {
         data$: this.organizations$,
         filter$: new BehaviorSubject({}),
         columns: this.columns,
         initialSort: { active: 'name', direction: 'asc' },
         visibleColumns$: of(['name', 'authUsers']),
         rowClick: null,
      };
   }
}
