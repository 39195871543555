import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';
import { Milestone } from '@entities/milestone';
import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { WidgetFilterComponent } from '../widget-filter/widget-filter.component';
import { map, takeUntil } from 'rxjs/operators';
import { Department } from '@entities/department';
import { TeamMember } from '@entities/team-member';
import * as moment from 'moment';
import { RockStatus } from '@entities/enums/rock-status';
import { FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { WidgetInstance } from '../widget/widget-instance';

@Component({
   selector: 'app-upcoming-milestones-widget',
   templateUrl: './upcoming-milestones-widget.component.html',
   styleUrls: ['./upcoming-milestones-widget.component.scss', '../widget/widget.component.scss'],
})
export class UpcomingMilestonesWidgetComponent extends WidgetInstance implements OnInit {
   id: string;
   data$: Observable<Milestone[]>;
   filter$ = this.facade.filters$.pipe(
      map((filters) => {
         const filter = filters[this.id];
         return filter || {};
      })
   );

   departments: Department[] = [];
   teamMembers: TeamMember[] = [];
   columnsToDisplay = ['rock', 'name', 'assignee', 'targetDate'];

   filterConfig: FilterBarConfig = {
      businessUnits: true,
      departments: true,
      targetDate: true,
      teamMembers: true,
      teamMembersLabel: 'Assignee',
      hideSearch: true,
   };

   private destroyed$ = new Subject<void>();

   constructor(private facade: DashboardFacade) {
      super();
   }

   ngOnInit(): void {
      this.facade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.facade.teamMembers$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });

      this.data$ = combineLatest([this.facade.rocks$, this.filter$]).pipe(
         map(([rocks, filter]) => {
            let data = [];
            if (rocks) {
               let filtered = [...rocks];
               if (filter.departments && filter.departments.length > 0) {
                  filtered = filtered.filter((rock) =>
                     filter.departments.some((dept) => dept.id === rock.departmentId)
                  );
               } else if (filter.businessUnits && filter.businessUnits.length > 0) {
                  const matchingDepartments = this.departments.filter((dept) =>
                     filter.businessUnits.some((bu) => bu.id === dept.businessUnitId)
                  );
                  filtered = filtered.filter((rock) =>
                     matchingDepartments.some((dept) => dept.id === rock.departmentId)
                  );
               }
               const today = moment().startOf('day');
               data = filtered
                  .map((rock) =>
                     rock.status !== RockStatus.Completed && rock.actions && rock.actions.length > 0
                        ? rock.actions
                             .filter((action) => action.targetDate && !action.completed)
                             .map((action) => ({
                                ...action,
                                rockId: rock.id,
                                rockName: rock.name,
                                overdue: moment(action.targetDate).isBefore(today),
                             }))
                        : []
                  )
                  .reduce((a, b) => [...a, ...b], []);
               if (filter.targetDate) {
                  data = this.facade.filterByTargetDate(data, filter.targetDate);
               }
               if (filter.teamMembers) {
                  data = data.filter((x) =>
                     filter.teamMembers.some((teamMember) => teamMember.id === x.assigneeId)
                  );
               }
            }
            return data.sort((a, b) => a.targetDate.localeCompare(b.targetDate));
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   filterChanged(value: any) {
      this.facade.setWidgetFilter(this.id, value);
   }

   goToRock(row: any) {
      this.facade.goToRock(row.rockId);
   }

   goToRocks() {
      this.facade.goToRocks();
   }
}
