import { Dashboard } from '@entities/dashboard';
import { FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { Status } from '@entities/enums/status';
import { ActionItemType } from '@entities/enums/action-item-type';
import { Priority } from '@entities/enums/priority';
import { DocumentationStatus } from '@entities/enums/documentation-status';
import { RockStatus } from '@entities/enums/rock-status';
import { TeamMemberStatus } from '@entities/enums/team-member-status';

export const widgetComponents = [
   { cols: 3, rows: 3, component: 'AssignedActionsWidgetComponent', name: 'Assigned Actions' },
   {
      cols: 3,
      rows: 3,
      component: 'AssignedMilestonesWidgetComponent',
      name: 'Assigned Milestones',
   },
   {
      cols: 3,
      rows: 3,
      component: 'AssignedItemsWidgetComponent',
      name: 'Assigned Actions and Milestones',
   },
   { cols: 4, rows: 4, component: 'AssignedTasksWidgetComponent', name: 'Assigned Tasks' },
   { cols: 4, rows: 4, component: 'QueryWidgetComponent', name: 'Custom Query' },
   {
      cols: 4,
      rows: 5,
      component: 'DocumentationStatusWidgetComponent',
      name: 'Documentation Status',
   },
   { cols: 4, rows: 4, component: 'OverviewWidgetComponent', name: 'Org Vision' },
   { cols: 2, rows: 2, component: 'ActionItemsWidgetComponent', name: 'Open Actions' },
   { cols: 4, rows: 5, component: 'PerformanceRatingWidgetComponent', name: 'Performance Rating' },
   { cols: 2, rows: 1, component: 'QuickActionsWidgetComponent', name: 'Quick Actions' },
   { cols: 4, rows: 5, component: 'RocksWidgetComponent', name: 'Rocks' },
   {
      cols: 6,
      rows: 5,
      component: 'UpcomingMilestonesWidgetComponent',
      name: 'Upcoming Milestones',
   },
];

export enum WidgetEntities {
   Actions,
   'Business Units',
   Departments,
   Functions,
   Milestones,
   Rocks,
   Tasks,
   'Team Members',
}

enum MilestoneStatus {
   Incomplete,
   Complete,
}

export const widgetFilterConfigMap: { [key: number]: FilterBarConfig } = {
   [WidgetEntities.Actions]: {
      businessUnits: true,
      departments: true,
      status: Status,
      quarter: true,
      hideSearch: true,
      teamMembers: true,
      teamMembersLabel: 'Assigned To',
      type: ActionItemType,
      priority: Priority,
   },
   [WidgetEntities['Business Units']]: {
      teamMembers: true,
      teamMembersLabel: 'Director',
      hideSearch: true,
   },
   [WidgetEntities.Departments]: {
      businessUnits: true,
      teamMembers: true,
      teamMembersLabel: 'Manager',
      hideSearch: true,
   },
   [WidgetEntities.Functions]: {
      businessUnits: true,
      departments: true,
      teamMembers: true,
      teamMembersLabel: 'Lead',
      status: DocumentationStatus,
      hideSearch: true,
   },
   [WidgetEntities.Milestones]: {
      businessUnits: true,
      departments: true,
      quarter: true,
      teamMembers: true,
      teamMembersLabel: 'Assigned To',
      status: MilestoneStatus,
      hideSearch: true,
   },
   [WidgetEntities.Rocks]: {
      businessUnits: true,
      departments: true,
      quarter: true,
      status: RockStatus,
      teamMembers: true,
      teamMembersLabel: 'Assigned To',
      hideSearch: true,
   },
   [WidgetEntities.Tasks]: {
      businessUnits: true,
      departments: true,
      departmentFunctions: true,
      teamMembers: true,
      teamMembersLabel: 'Assigned To',
      hideSearch: true,
   },
   [WidgetEntities['Team Members']]: {
      businessUnits: true,
      departments: true,
      status: TeamMemberStatus,
      teamMembers: true,
      teamMembersLabel: 'Manager',
      hideSearch: true,
   },
};

export const defaultDashboard: Dashboard = {
   name: 'Default Dashboard',
   id: null,
   userId: null,
   sharable: true,
   default: false,
   widgets: [
      { id: '0', cols: 3, rows: 3, x: 0, y: 0, component: 'RocksWidgetComponent' },
      { id: '1', cols: 3, rows: 3, x: 3, y: 0, component: 'AssignedTasksWidgetComponent' },
      { id: '2', cols: 3, rows: 3, x: 0, y: 3, component: 'ActionItemsWidgetComponent' },
      { id: '3', cols: 3, rows: 3, x: 3, y: 3, component: 'OverviewWidgetComponent' },
      { id: '4', cols: 3, rows: 3, x: 0, y: 6, component: 'DocFunctionsWidgetComponent' },
      { id: '5', cols: 3, rows: 3, x: 3, y: 6, component: 'RocksWidgetComponent' },
      { id: '6', cols: 3, rows: 3, x: 9, y: 0, component: 'AssignedActionsWidgetComponent' },
   ],
};
