<div class="top-menu">
   <div class="title-container">
      <button mat-icon-button class="back-button" (click)="back.emit()" matTooltip="Back">
         <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="title-form-container">
         <div [ngClass]="titleFormControl ? 'small-title' : 'full-title'">{{ title }}</div>
         <ng-container *ngIf="titleFormControl">
            <app-title-edit
               [isNew]="isNew"
               [formCtrl]="titleFormControl"
               [canEdit]="canEdit"
               (titleChange)="titleChange.emit($event)"
            ></app-title-edit>
         </ng-container>
      </div>
      <ng-container *ngIf="canEdit">
         <app-multi-save
            *ngIf="multiSave; else iconSave"
            [disabled]="saveDisabled"
            (save)="save.emit()"
            (saveAndNew)="saveAndNew.emit()"
            (saveAndClose)="saveAndClose.emit()"
         ></app-multi-save>
         <ng-template #iconSave>
            <button
               mat-icon-button
               color="primary"
               (click)="save.emit()"
               [disabled]="saveDisabled"
               class="save-button"
               matTooltip="Save"
            >
               <mat-icon>save</mat-icon>
            </button>
         </ng-template>
      </ng-container>
      <button
         mat-icon-button
         color="accent"
         class="print-button"
         *ngIf="report && !isNew"
         (click)="showReport.emit()"
         matTooltip="View Report"
      >
         <mat-icon svgIcon="file-document"></mat-icon>
      </button>
      <button
         mat-icon-button
         *ngIf="canEdit"
         [disabled]="isNew"
         (click)="delete.emit()"
         matTooltip="Delete"
      >
         <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button *ngIf="showMenuOptions()" [matMenuTriggerFor]="optionsMenu">
         <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #optionsMenu="matMenu">
         <ng-container *ngFor="let option of menuOptions">
            <button mat-menu-item *ngIf="option.condition()" (click)="option.action()">
               {{ option.label }}
            </button>
         </ng-container>
      </mat-menu>
   </div>
</div>
