<div class="widget-container">
   <div class="title">Assigned Tasks</div>
   <div class="scroll-container">
      <table mat-table [dataSource]="tasks$">
         <ng-container matColumnDef="task">
            <th mat-header-cell *matHeaderCellDef>
               Task
            </th>
            <td mat-cell *matCellDef="let row">
               {{ row.task.name }}
            </td>
         </ng-container>
         <ng-container matColumnDef="departmentFunction">
            <th mat-header-cell *matHeaderCellDef>
               Function
            </th>
            <td mat-cell *matCellDef="let row">
               {{ row.departmentFunction ? row.departmentFunction.name : '' }}
            </td>
         </ng-container>
         <ng-container matColumnDef="department">
            <th mat-header-cell *matHeaderCellDef>
               Department
            </th>
            <td mat-cell *matCellDef="let row">
               {{ row.department ? row.department.name : '' }}
            </td>
         </ng-container>
         <ng-container matColumnDef="businessUnit">
            <th mat-header-cell *matHeaderCellDef>
               Business Unit
            </th>
            <td mat-cell *matCellDef="let row">
               {{ row.businessUnit ? row.businessUnit.name : '' }}
            </td>
         </ng-container>
         <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
         <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="goToTask(row.task)"
         ></tr>
      </table>
   </div>
</div>
