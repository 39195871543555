import { inject, Injectable } from '@angular/core';

import { AuthUser } from '@entities/auth-user';
import { switchMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { FirestoreService } from '@app/shared/services/firestore.service';
import { FirebaseAuthShim } from './firebase-auth.shim';

@Injectable()
export class AuthUserService {
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.AUTH_USERS;

   currentUser$: Observable<AuthUser>;

   private firestore = inject(FirestoreService);
   private auth = inject(FirebaseAuthShim);

   constructor() {
      this.currentUser$ = this.auth.authState().pipe(
         switchMap((user) => {
            if (user) {
               return this.firestore.docData(
                  this.COLLECTION_NAME,
                  user.uid
               ) as Observable<AuthUser>;
            } else {
               return of(null);
            }
         })
      );
   }

   createAuthUser(fbUser: any, firstName?: string, lastName?: string) {
      const authUser: AuthUser = {
         uid: fbUser.uid,
         displayName: fbUser.displayName,
         firstName,
         lastName,
         email: fbUser.email,
         orgs: [],
      };
      return this.firestore
         .setDoc(this.firestore.doc(this.COLLECTION_NAME, fbUser.uid), authUser)
         .then(() => {
            return authUser;
         });
   }

   getAuthUser(fbUser: any) {
      return from(
         this.firestore
            .getDoc(this.firestore.doc(this.COLLECTION_NAME, fbUser.uid))
            .then((ref) => ref.data())
      );
   }

   save(entity: AuthUser): Promise<AuthUser> {
      if (entity.uid) {
         return this.firestore
            .updateDoc(this.firestore.doc(this.COLLECTION_NAME, entity.uid), entity)
            .then(() => {
               return entity;
            });
      } else {
         throw new Error('uid required');
      }
   }
   delete(entity: AuthUser): Promise<void> {
      if (entity.uid) {
         return this.firestore.deleteDoc(this.firestore.doc(this.COLLECTION_NAME, entity.uid));
      } else {
         throw new Error('uid required');
      }
   }
}
