import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Sort } from '@angular/material/sort';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { TableConfig } from '@app/shared/components/table/table.component';
import { FilterBarConfig, FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { TableService } from '@app/shared/services/table.service';
import { TeamFacade } from '@app/team/state/team.facade';
import { deepCopy } from '@app/utilities/deep-copy';
import { BusinessUnit } from '@entities/business-unit';
import { CompetencyLevel } from '@entities/competency-level';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { Task } from '@entities/task';
import { TaskRating } from '@entities/task-rating';
import { TeamMember } from '@entities/team-member';
import { Observable, BehaviorSubject, of, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { TaskRatingEditComponent } from '../task-rating-edit/task-rating-edit.component';
import { RoleGuard } from '@app/admin/services/role.guard';
import { AppAreas } from '@entities/enums/app-areas';

interface TaskRatingViewModel {
   taskRating: TaskRating;
   task: Task;
   departmentFunction: DepartmentFunction;
   department: Department;
   businessUnit: BusinessUnit;
}

@Component({
   selector: 'app-task-ratings',
   templateUrl: './task-ratings.component.html',
   styleUrls: ['./task-ratings.component.scss'],
})
export class TaskRatingsComponent implements OnInit {
   @Input() teamMember$: Observable<TeamMember>;
   @Input() taskRatings: TaskRatingViewModel[] = [];
   @Output() taskRatingsUpdated = new EventEmitter();

   editIds: string[] = [];
   editedTaskRatings: TaskRatingViewModel[] = [];
   displayedColumns = [
      'task.name',
      'departmentFunction.name',
      'department.name',
      'businessUnit.name',
      'competencies',
      'rating',
      'notes',
      'options',
   ];

   competencyLevels: CompetencyLevel[] = [];
   competencyLevelsMap = {};

   tableColumns: TableColumn<TaskRatingViewModel>[] = [
      {
         def: 'task.name',
         value: (row) => row.task.name,
         label: 'Task',
         visible: true,
         style: 'width: 20%; padding-right: 16px;',
      },

      {
         def: 'departmentFunction',
         value: (row) => row.departmentFunction.name,
         label: 'Function',
         visible: false,
         filter: 'departmentFunctions',
      },
      {
         def: 'department',
         value: (row) => row.department.name,
         label: 'Department',
         visible: false,
         filter: 'departments',
      },
      {
         def: 'businessUnit',
         value: (row) => row.businessUnit.name,
         label: 'Business Unit',
         visible: false,
         filter: 'businessUnits',
      },
      {
         def: 'competencies',
         value: (row) => row.task.competencies,
         label: 'Competencies',
         visible: true,
         style: 'width: 30%; padding-right: 16px;',
      },
      {
         def: 'taskRating.rating',
         value: (row) => this.competencyLevelsMap[row.taskRating.rating],
         filterValue: (row) => row.taskRating.rating,
         label: 'Rating',
         visible: true,
         style: 'width: 20%; padding-right: 16px;',
         filter: 'rating',
      },
      {
         def: 'notes',
         value: (row) => row.taskRating.notes,
         label: 'Notes',
         visible: true,
         style: 'width: 30%; padding-right: 16px;',
      },
   ];

   filterConfig: FilterBarConfig = {};
   tableConfig: TableConfig;

   tasks$: Observable<Task[]>;
   data$: Observable<any[]>;
   sort$: BehaviorSubject<Sort> = new BehaviorSubject({ direction: 'asc', active: 'task.name' });
   filter$ = this.teamFacade.tasksFilter$;
   rows$ = new BehaviorSubject([]);

   private destroyed$ = new Subject<void>();

   constructor(
      private teamFacade: TeamFacade,
      private tableService: TableService,
      private documentationFacade: DocumentationFacade,
      private dialog: MatDialog,
      private roleGuard: RoleGuard
   ) {}

   ngOnInit(): void {
      this.teamFacade.competencyLevels$.pipe(takeUntil(this.destroyed$)).subscribe((levels) => {
         if (levels?.length > 0) {
            this.competencyLevels = deepCopy(levels);
            this.competencyLevels.sort((a, b) => a.value - b.value);
            this.competencyLevelsMap = {};
            levels.forEach((level) => {
               this.competencyLevelsMap[level.value] = level.label;
            });
            this.setFilter();
         }
      });
      // this.teamFacade.assignedTasks$.pipe(takeUntil(this.destroyed$)).subscribe((taskRatings) => {
      //    this.taskRatings = taskRatings;
      //    this.rows$.next(taskRatings);
      // });

      this.tableConfig = {
         data$: this.rows$,
         filter$: this.filter$,
         columns: this.tableColumns,
         visibleColumns$: of(),
         initialSort: { active: 'task.name', direction: 'asc' },
         rowClick: (row) => this.edit(row),
         options: [{ label: 'Edit', action: (row) => this.edit(row) }],
         debug: 'task-ratings',
      };

      this.roleGuard
         .canEdit([], AppAreas.Team)
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            if (canEdit) {
               this.tableConfig.options = [{ label: 'Edit', action: (row) => this.edit(row) }];
            } else {
               this.tableConfig.options = [];
            }
         });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['taskRatings']) {
         this.rows$.next(this.taskRatings);
      }
   }

   setFilter() {
      this.filterConfig = {
         businessUnits: true,
         departments: true,
         departmentFunctions: true,
         rating: this.competencyLevelsMap,
         filterChange: (filter) => this.teamFacade.setTeamTasksFilter(filter),
      };
   }

   edit(taskRating: TaskRatingViewModel) {
      this.dialog
         .open(TaskRatingEditComponent, {
            width: '800px',
            maxWidth: '90vw',
            data: { viewModel: taskRating, competencyLevels: this.competencyLevels },
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.save(result);
            }
         });
   }

   save(taskRating: TaskRatingViewModel) {
      this.taskRatings = [
         ...this.taskRatings.filter((rating) => rating.task.id !== taskRating.task.id),
         taskRating,
      ];
      this.rows$.next(this.taskRatings);
      this.taskRatingsUpdated.emit(this.taskRatings.map((viewModel) => viewModel.taskRating));
   }

   goToTask(viewModel: TaskRatingViewModel) {
      this.documentationFacade.viewTask(viewModel.task.id);
   }
}
