import { Injectable } from '@angular/core';
import { SearchRequest } from '@entities/search-request';
import { defaultSearchOptions, defaultSearchCollections } from './search.config';
import { State } from '@app/app.state';
import { select, Store } from '@ngrx/store';
import { searchActions } from './search.actions';
import { getSearchResults, getSearchTerm } from './search.selectors';
import { getTeamMembers } from '@app/team/state/team.state';

@Injectable({
   providedIn: 'root',
})
export class SearchFacade {
   searchTerm$ = this.store.pipe(select(getSearchTerm));
   searchResults$ = this.store.pipe(select(getSearchResults));

   teamMembers$ = this.store.pipe(select(getTeamMembers));

   constructor(private store: Store<State>) {}

   quickSearch(term: string) {
      this.store.dispatch(searchActions.quickSearch({ term }));
   }

   goToItem(item: any, category: string) {
      this.store.dispatch(searchActions.goToItem({ item, category }));
   }

   seeAllResults(term: string, category: string) {
      this.store.dispatch(searchActions.seeAllResults({ term, category }));
   }
}
