import { createAction, props } from '@ngrx/store';
import { Task } from '@entities/task';
import { Role } from '@entities/role';
import { DepartmentFunction } from '@entities/department-function';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { Sort } from '@angular/material/sort';

export const DocumentationTasksUpdated = createAction(
   '[Documentation] tasks updated',
   props<{ tasks: Task[] }>()
);
export const DocumentationFunctionsUpdated = createAction(
   '[Documentation] Functions updated',
   props<{ functions: DepartmentFunction[] }>()
);
export const AssignTaskToRole = createAction(
   '[Documentation] assign task to role',
   props<{ task: Task; role: Role }>()
);
export const SetTaskFilter = createAction(
   '[Documentation] Set task filter',
   props<{ taskFilter: FilterValues }>()
);
export const SetFunctionFilter = createAction(
   '[Documentation] Set function filter',
   props<{ functionFilter: FilterValues }>()
);
export const SaveTask = createAction(
   '[Documentation] Save task',
   props<{ task: Task; onDeactivate: boolean; navigate?: boolean }>()
);
export const SelectDepartmentFunction = createAction(
   '[Documentation] Select department function',
   props<{ departmentFunctionId: string }>()
);
export const SaveDepartmentFunction = createAction(
   '[Documentation] Save Department Function',
   props<{ departmentFunction: DepartmentFunction; onDeactivate: boolean }>()
);
export const SelectTask = createAction('[Documentation] Select Task', props<{ taskId: string }>());
export const ReorderTasks = createAction(
   '[Documentation] Reorder Tasks',
   props<{ oldIndex: number; newIndex: number }>()
);
export const ViewTask = createAction('[Documentation] View Task', props<{ taskId: string }>());

export const SetFunctionSort = createAction(
   '[Documentation] Set Function Sort',
   props<{ sort: Sort }>()
);
export const SetTaskSort = createAction('[Documentation] Set Task Sort', props<{ sort: Sort }>());

export const DepartmentFunctionReport = createAction(
   '[Documentation] Department function report',
   props<{ departmentFunction: DepartmentFunction }>()
);
export const TaskReport = createAction('[Documentation] Task report', props<{ task: Task }>());

export const SetDeptFnDisplayedColumns = createAction(
   '[Documentation] Set Dept Fn displayed columns',
   props<{ columns: string[] }>()
);
export const SetTaskDisplayedColumns = createAction(
   '[Documentation] Set Task displayed columns',
   props<{ columns: string[] }>()
);

export const DocumentationActions = {
   DocumentationTasksUpdated,
   DocumentationFunctionsUpdated,
   AssignTaskToRole,
   SetTaskFilter,
   SetFunctionFilter,
   SaveTask,
   SelectDepartmentFunction,
   SaveDepartmentFunction,
   SelectTask,
   ReorderTasks,
   ViewTask,
   DepartmentFunctionReport,
   TaskReport,
   SetDeptFnDisplayedColumns,
   SetTaskDisplayedColumns,
};
