<div class="agenda-container">
   <div class="header">
      <button mat-icon-button color="primary" (click)="back()">
         <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="header-text">{{ headerText }} Meeting</div>
      <div class="header-buttons">
         <button
            mat-icon-button
            color="accent"
            (click)="exportAgenda()"
            matTooltip="Export Agenda"
            matTooltipPosition="before"
            data-test-id="export-agenda"
         >
            <mat-icon svgIcon="file-download-outline"></mat-icon>
         </button>
         <button
            mat-stroked-button
            color="primary"
            *ngIf="canEdit"
            [disabled]="!meetingValid()"
            (click)="saveMeeting()"
            data-test-id="save-meeting"
         >
            Save Meeting
         </button>
         <button
            mat-raised-button
            color="primary"
            *ngIf="canEdit"
            [disabled]="!meetingValid()"
            (click)="startMeeting()"
            data-test-id="start-meeting"
         >
            Start Meeting
            <mat-icon>chevron_right</mat-icon>
         </button>
      </div>
   </div>
   <div class="scroll-content">
      <ng-container *ngIf="meeting">
         <mat-card>
            <div class="meeting-name">
               <mat-form-field>
                  <input
                     matInput
                     placeholder="Meeting Name"
                     [(ngModel)]="meeting.name"
                     required
                     [readonly]="!canEdit"
                  />
               </mat-form-field>
            </div>
            <div class="meeting-notes">
               <mat-form-field>
                  <mat-label>Items to discuss</mat-label>
                  <mat-select
                     [ngModel]="meeting.itemsToDiscuss"
                     [disabled]="!canEdit"
                     (ngModelChange)="updateFilterBar($event)"
                  >
                     <mat-option [value]="meetingOptions.ROCKS">Rocks Only</mat-option>
                     <mat-option [value]="meetingOptions.ACTIONS">Actions Only</mat-option>
                     <mat-option [value]="meetingOptions.BOTH">Rocks and Actions</mat-option>
                  </mat-select>
               </mat-form-field>
            </div>
            <div class="meeting-notes">
               <mat-form-field>
                  <input
                     matInput
                     type="number"
                     placeholder="Minutes per item"
                     min="1"
                     [(ngModel)]="timePerItem"
                     [readonly]="!canEdit"
                  />
               </mat-form-field>
            </div>
            <div class="meeting-notes meeting-visibility">
               <mat-form-field>
                  <mat-label>Meeting Type</mat-label>
                  <mat-select [(ngModel)]="isPrivate">
                     <mat-option [value]="false">Public</mat-option>
                     <mat-option [value]="true">Private</mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-icon
                  matTooltip="Public meetings are visible to everyone in the organization. Private meetings are visible only to attendees"
               >
                  info
               </mat-icon>
            </div>
            <div class="meeting-notes">
               <mat-label>Attendees</mat-label>
               <mat-chip-list>
                  <mat-chip
                     *ngFor="let attendee of attendees"
                     [removable]="true"
                     (removed)="removeAttendee(attendee)"
                  >
                     {{ attendee.firstName }} {{ attendee.lastName }}
                     <mat-icon *ngIf="canEdit" matChipRemove>cancel</mat-icon>
                  </mat-chip>
               </mat-chip-list>
               <div class="add-attendee">
                  <app-team-member-autocomplete
                     placeholder="Add attendee"
                     [canEdit]="canEdit"
                     [showClear]="false"
                     [idFormControl]="addAttendeeFormControl"
                     (optionSelected)="addAttendee($event)"
                  ></app-team-member-autocomplete>
               </div>
            </div>
            <div class="meeting-notes">
               <mat-form-field>
                  <mat-label>General Meeting Notes</mat-label>
                  <textarea
                     rows="6"
                     matInput
                     [(ngModel)]="meeting.notes"
                     [readonly]="!canEdit"
                  ></textarea>
               </mat-form-field>
            </div>
         </mat-card>
         <div class="instructions">
            {{ canEdit ? 'Select' : '' }}
            {{ meeting.itemsToDiscuss !== meetingOptions.ACTIONS ? 'Rocks' : '' }}
            {{ meeting.itemsToDiscuss === meetingOptions.BOTH ? 'and' : '' }}
            {{ meeting.itemsToDiscuss !== meetingOptions.ROCKS ? 'Actions' : '' }} to discuss in
            this meeting
         </div>
         <app-filter-bar
            [config]="filterBarConfig"
            [canEdit]="canEdit"
            (filterChange)="filterFormChanged($event)"
         ></app-filter-bar>
         <mat-card *ngIf="meeting.itemsToDiscuss !== meetingOptions.ACTIONS">
            <mat-card-title>Rocks</mat-card-title>
            <mat-card-content>
               <app-table
                  #rocksTable
                  data-test-id="rocks-table"
                  [config]="rocksTableConfig"
                  (selectedChanged)="setSelectedRocks($event)"
               ></app-table>
            </mat-card-content>
         </mat-card>
         <mat-card *ngIf="meeting.itemsToDiscuss !== meetingOptions.ROCKS">
            <mat-card-title>Actions</mat-card-title>
            <mat-card-content>
               <app-table
                  #actionItemsTable
                  data-test-id="action-items-table"
                  [config]="actionItemsTableConfig"
                  (selectedChanged)="setSelectedActionItems($event)"
               ></app-table>
            </mat-card-content>
         </mat-card>
      </ng-container>
   </div>
</div>
