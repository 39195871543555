import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Department } from '@entities/department';

import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { TeamMember } from '@entities/team-member';
import { takeUntil } from 'rxjs/operators';
import { DepartmentFunction } from '@entities/department-function';

@Component({
   selector: 'app-department-list',
   templateUrl: './department-list.component.html',
   styleUrls: ['./department-list.component.scss', '../organization/organization.component.scss'],
})
export class DepartmentListComponent implements OnInit {
   @Output() selected = new EventEmitter();

   departments$: Observable<Department[]>;
   selectedDepartmentId$: Observable<string>;
   businessUnitId$: Observable<string>;
   canEdit$: Observable<boolean>;

   departments: Department[] = [];
   departmentFunctions: DepartmentFunction[] = [];
   teamMembers: TeamMember[] = [];

   private destroyed$ = new Subject<void>();

   constructor(private orgBuilderFacade: OrgBuilderFacade) {}

   ngOnInit() {
      this.selectedDepartmentId$ = this.orgBuilderFacade.selectedDepartmentId$;
      this.departments$ = this.orgBuilderFacade.visibleDepartments$;
      this.businessUnitId$ = this.orgBuilderFacade.selectedBusinessUnitId$;
      this.canEdit$ = this.orgBuilderFacade.canEdit$;
      this.orgBuilderFacade.teamMembers$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((teamMembers) => {
            this.teamMembers = teamMembers;
         });
      this.orgBuilderFacade.departmentFunctions$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((deptFns) => {
            this.departmentFunctions = deptFns;
         });
   }

   addDepartment() {
      this.orgBuilderFacade.addDepartment();
   }

   select(departmentId: string) {
      this.orgBuilderFacade.selectDepartment(departmentId);
      this.selected.emit(departmentId);
   }

   edit(department: Department) {
      this.orgBuilderFacade.editDepartment(department);
   }

   delete(department: Department) {
      this.orgBuilderFacade.deleteDepartment(department);
   }

   dragStarted(event: CdkDragStart, department: Department) {
      this.orgBuilderFacade.dragStarted(department);
   }

   drop(event: CdkDragDrop<any>) {
      if (event.isPointerOverContainer) {
         this.orgBuilderFacade.reorderDepartments(event.previousIndex, event.currentIndex);
      }
   }

   getTeamMember(teamMemberId: string) {
      return this.orgBuilderFacade.getTeamMember(teamMemberId);
   }

   itemDropped(event: any, department: Department) {
      this.orgBuilderFacade.itemDropped(department, Department);
   }

   viewSummary(dept: Department) {
      this.orgBuilderFacade.departmentSummary(dept.id);
   }

   getDepartmentFunctions(dept: Department) {
      return this.departmentFunctions.filter((deptFn) => deptFn.departmentId === dept.id).length;
   }
}
