import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { User } from '@entities/user';

@Component({
   selector: 'app-permission-group-membership-table',
   templateUrl: './permission-group-membership-table.component.html',
   styleUrls: ['./permission-group-membership-table.component.scss'],
})
export class PermissionGroupMembershipTableComponent {
   @Input() users: User[] = [];
   @Input() permissionGroupId?: string;

   @Output() assignUser = new EventEmitter<User>();

   membersColumns = ['accountIcon', 'firstName', 'lastName', 'email', 'options'];
   assignUserLabel = 'Assign user';
   userNotLinkedMessage =
      'This user is not linked to a team member. Users must be linked to a team member for "Assigned Items Only" access level to work as expected.';

   ngOnChanges(changes: SimpleChanges) {
      if (changes['permissionGroupId']) {
         this.assignUserLabel = this.permissionGroupId ? 'Reassign user' : 'Assign user';
      }
   }
}
