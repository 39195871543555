import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SearchFacade } from '@app/search/state/search.facade';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
   selector: 'app-search-input',
   templateUrl: './search-input.component.html',
   styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
   term: string;
   showAll = {};
   noResults = true;

   searchResults$ = this.searchFacade.searchResults$;

   @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

   private _destroyed$ = new Subject<void>();

   constructor(private searchFacade: SearchFacade) {}

   ngOnInit(): void {
      this.searchResults$.pipe(takeUntil(this._destroyed$)).subscribe((categories) => {
         this.showAll = {};
         this.noResults = categories.length === 0;
         categories.forEach((category) => {
            this.showAll[category.label] = false;
         });
      });
   }

   search() {
      this.searchFacade.quickSearch(this.term);
   }

   quickSearch(term: string) {
      this.term = term;
      this.searchFacade.quickSearch(this.term);
   }

   goToItem(item: any, category: string) {
      this.searchFacade.goToItem(item, category);
   }

   seeAll(category: string, event: Event) {
      event.stopPropagation();
      // this.searchFacade.seeAllResults(this.term, category);
      this.showAll[category] = true;
   }

   focusInput() {
      this.searchInput?.nativeElement?.focus();
   }
}
