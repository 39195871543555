import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Goal } from '@entities/goal';
import { PlanningPeriod } from '@entities/planning-period';
import { Rock } from '@entities/rock';
import { Router, ActivatedRoute } from '@angular/router';
import { EvolveFacade } from '@app/evolve/state/evolve.facade';
import { UserComment } from '@entities/user-comment';
import { RockAction } from '@entities/rock-action';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { TeamMember } from '@entities/team-member';
import { RockInfoComponent } from '../rock-info/rock-info.component';
import { UserCommentsComponent } from '@app/shared/components/user-comments/user-comments.component';
import { deepCopy } from '@app/utilities/deep-copy';
import { RoleGuard } from '@app/admin/services/role.guard';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-rock-details',
   templateUrl: './rock-details.component.html',
   styleUrls: ['./rock-details.component.scss'],
})
export class RockDetailsComponent
   implements OnInit, AfterViewInit, OnDestroy, SaveChangesComponent
{
   @ViewChild(UserCommentsComponent) commentsComponent: UserCommentsComponent;
   @ViewChild(RockInfoComponent) rockInfoComponent: RockInfoComponent;
   form: UntypedFormGroup = new UntypedFormGroup({});

   rock$: Observable<Rock>;
   title$: Observable<string>;
   planningPeriods$: Observable<PlanningPeriod[]>;
   goals$: Observable<Goal[]>;
   teamMembers$: Observable<TeamMember[]>;

   actions: RockAction[] = [];
   actionsUpdated = false;
   comments: UserComment[] = [];
   commentsUpdated = false;

   isNew = false;
   saving = false;

   navigatedFrom: string;

   canEdit$: Observable<boolean>;

   private destroyed$ = new Subject<void>();

   constructor(
      private evolveFacade: EvolveFacade,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private roleGuard: RoleGuard
   ) {}

   ngOnInit() {
      this.rock$ = this.evolveFacade.selectedRock$;
      this.canEdit$ = this.rock$.pipe(
         switchMap((rock) => this.roleGuard.canEdit([], AppAreas.Evolve, [rock?.assigneeId]))
      );
      this.title$ = this.rock$.pipe(
         map((rock) => {
            if (rock?.id) {
               return 'Rock Details';
            } else {
               return 'Create Rock';
            }
         })
      );
      this.rock$.pipe(takeUntil(this.destroyed$)).subscribe((rock) => {
         if (rock) {
            if (rock.actions && rock.actions.length > 0) {
               this.actions = [...rock.actions].map((action) => ({ ...action }));
            }
            if (rock.comments && rock.comments.length > 0) {
               this.comments = deepCopy(rock.comments);
            }
            this.form.markAsPristine();
            this.saving = false;
            this.actionsUpdated = false;
            this.commentsUpdated = false;
            // disable delete for new rocks
            this.isNew = !rock.id;
         } else {
            this.isNew = true;
         }
      });

      this.activatedRoute.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
         if (params['rockId']) {
            this.evolveFacade.selectRock(params['rockId']);
         }
      });
      this.activatedRoute.queryParams.subscribe((queryParams) => {
         if (queryParams['from']) {
            this.navigatedFrom = queryParams['from'];
         }
      });

      this.goals$ = this.evolveFacade.goals$;

      this.teamMembers$ = this.evolveFacade.teamMembers$;
   }

   ngAfterViewInit() {
      if (this.rockInfoComponent && this.rockInfoComponent.form) {
         this.form = this.rockInfoComponent.form;
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getControl(name: string): UntypedFormControl {
      const control = this.form.get(name) as UntypedFormControl;
      return control ?? new UntypedFormControl();
   }

   save(onDeactivate: boolean = false) {
      const rock: Rock = {
         ...this.form.value,
         actions: this.actions,
         comments: this.comments,
      };
      this.saving = true;
      this.evolveFacade.saveRock(rock, onDeactivate);
   }

   saveDisabled() {
      return (
         (this.form.pristine && !this.commentsUpdated && !this.actionsUpdated) || !this.form.valid
      );
   }

   backToList() {
      if (this.navigatedFrom) {
         this.router.navigateByUrl(this.navigatedFrom);
      } else {
         this.router.navigate(['..'], { relativeTo: this.activatedRoute });
      }
   }

   delete() {
      const rock: Rock = this.form.value;
      this.evolveFacade.deleteRock(rock);
   }

   setActions(actions: RockAction[]) {
      this.actions = actions;
      this.actionsUpdated = true;
      this.form.markAsDirty();
   }

   updateComments(comments: UserComment[]) {
      this.comments = comments;
      this.commentsUpdated = true;
      this.form.markAsDirty();
   }

   setName(name: string) {
      this.form.get('name').setValue(name);
      this.form.markAsDirty();
   }
}
