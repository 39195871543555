<button mat-button color="primary" (click)="back()">
   <mat-icon>arrow_back</mat-icon>
   Back
</button>
<div class="editor" [froalaEditor]="options" (froalaInit)="initEditor($event)">
   <ng-container *ngIf="orgName">
      <div style="display: flex; justify-content: space-between;">
         <div style="display: flex;">
            <img *ngIf="logo" [src]="logo" style="height: 50px;" />
            <div style="font-size: 24pt; line-height: 60px;">{{ orgName }}</div>
         </div>
         <div
            style="
               font-size: 12pt;
               font-weight: bold;
               text-transform: uppercase;
               padding-top: 12pt;
               text-align: right;
            "
         >
            Vision Report
         </div>
      </div>
   </ng-container>

   <div
      id="row-one"
      style="
         display: flex;
         min-height: 4in;
         border: 1px solid #212121;
         margin-bottom: -2px;
         break-inside: avoid;
         margin-top: 16px;
      "
   >
      <div style="flex: 1 1 50%; border: 1px solid #212121; padding: 8px;">
         <div style="font-size: 16px; font-weight: bold; text-align: center;">
            Guiding Beliefs
         </div>
         <div style="font-size: 14px; text-align: center;">
            What is the inspiration for the organization's existence?
         </div>
         <div [innerHTML]="overview.beliefs"></div>
      </div>
      <div style="flex: 1 1 50%; border: 1px solid #212121; padding: 8px;">
         <div style="font-size: 16px; font-weight: bold; text-align: center;">Purpose</div>
         <div style="font-size: 14px; text-align: center;">
            What is the impact or value provided by the organization?
         </div>
         <div [innerHTML]="overview.purpose"></div>
      </div>
   </div>
   <ng-container *ngIf="pageBreak()">
      <hr class="no-print" />
   </ng-container>
   <div
      id="row-two"
      style="display: flex; border: 1px solid #212121; margin-bottom: 16px;"
      [style.min-height]="pageBreak() ? '10in' : '4in'"
      [style.page-break-before]="pageBreak() ? 'always' : 'auto'"
   >
      <div style="flex: 1 1 50%; border: 1px solid #212121; padding: 8px;">
         <div style="font-size: 16px; font-weight: bold; text-align: center;">
            Vision Statement
         </div>
         <div style="font-size: 14px; text-align: center;">
            What does the organization look like when it is operating at its full potential?
         </div>
         <div [innerHTML]="overview.visionStatement"></div>
      </div>
      <div style="flex: 1 1 50%; border: 1px solid #212121; padding: 8px;">
         <div style="font-size: 16px; font-weight: bold; text-align: center;">
            Core Values
         </div>
         <div style="font-size: 14px; text-align: center;">
            What are the principles that inform how the organization operates?
         </div>
         <div [innerHTML]="overview.coreValues"></div>
      </div>

   </div>
</div>
