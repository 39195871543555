import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FirebaseAuthService } from '@app/auth/services/firebase-auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  email: string;
  private authService: FirebaseAuthService;
  emailSent = false;
  constructor(@Inject(MAT_DIALOG_DATA) data: { service: FirebaseAuthService }) {
    this.authService = data.service;
  }

  ngOnInit() {}

  reset() {
    this.authService.sendResetEmail(this.email);
    this.emailSent = true;
  }

  close() {
    this.authService.closeResetDialog();
  }
}
