import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
   selector: '[appAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
   constructor(private el: ElementRef) {}

   ngAfterViewInit() {
      setTimeout(() => {
         this.el.nativeElement.focus();
      }, 200);
   }
}
