import { EntityService } from '@app/shared/services/entity.service';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@entities/user';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { getOrganizationId } from '../state/user-org.selectors';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';

@Injectable()
export class UserService implements EntityService<User>, OnDestroy {
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.USERS;
   entities$: Observable<User[]>;

   private store: Store<State> = inject(Store<State>);
   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<User>();

   constructor() {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }

   save(entity: User, orgId?: string): Promise<User> {
      return this.orgEntityService.save(entity, { orgId });
   }
   batchSave(entities: User[]): Promise<User[]> {
      return this.orgEntityService.batchSave(entities);
   }
   delete(entity: User): Promise<void> {
      return this.orgEntityService.delete(entity);
   }
}
