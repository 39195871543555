import { Injectable } from '@angular/core';
import {
   CollectionReference,
   DocumentReference,
   FieldPath,
   Firestore,
   QueryCompositeFilterConstraint,
   QueryConstraint,
   WhereFilterOp,
   arrayUnion,
   collection,
   collectionData,
   deleteDoc,
   doc,
   docData,
   getDoc,
   getDocs,
   query,
   setDoc,
   updateDoc,
   where,
   writeBatch,
} from '@angular/fire/firestore';

@Injectable({
   providedIn: 'root',
})
export class FirestoreService {
   constructor(private firestore: Firestore) {}

   collection(...path: string[]) {
      return collection(this.firestore, path.join('/'));
   }

   collectionData(...path: string[]) {
      const collection = this.collection(...path);
      return collectionData(collection);
   }

   doc(...path: string[]) {
      if (path.length % 2 == 0) {
         return doc(this.firestore, path.join('/'));
      } else {
         const collection = this.collection(...path);
         return doc(collection);
      }
   }

   docData(...path: string[]) {
      const doc = this.doc(...path);
      return docData(doc);
   }

   getDoc(doc: DocumentReference) {
      return getDoc(doc);
   }

   setDoc(ref: DocumentReference, entity: any) {
      return setDoc(ref, entity);
   }

   updateDoc(ref: DocumentReference, entity: any) {
      return updateDoc(ref, entity);
   }

   deleteDoc(ref: DocumentReference) {
      return deleteDoc(ref);
   }

   writeBatch() {
      return writeBatch(this.firestore);
   }

   arrayUnion(...elements: any[]) {
      return arrayUnion(...elements);
   }

   // queryCollection(
   //    collection: CollectionReference,
   //    fieldPath: string | FieldPath,
   //    opStr: WhereFilterOp,
   //    value: any
   // ) {
   //    return getDocs(query(collection, where(fieldPath, opStr, value)));
   // }

   queryCollection(collection: CollectionReference, ...filters: QueryConstraint[]) {
      return getDocs(query(collection, ...filters));
   }

   where(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: any) {
      return where(fieldPath, opStr, value);
   }
}
