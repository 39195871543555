import { Component, OnInit, OnDestroy } from '@angular/core';
import { MeetingsFacade } from '@app/meetings/state/meetings.facade';
import { BehaviorSubject, Subject } from 'rxjs';
import { Meeting } from '@entities/meeting';
import { Router, ActivatedRoute } from '@angular/router';
import { MeetingStatus } from '@entities/enums/meeting-status';
import { DialogService } from '@app/shared/services/dialog.service';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { DatePipe } from '@angular/common';
import { TableConfig } from '@app/shared/components/table/table.component';
import { FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { DepartmentNamePipe } from '@app/shared/pipes/department-name.pipe';
import { Department } from '@entities/department';
import { takeUntil, map } from 'rxjs/operators';
import { meetingRouteNames } from '@app/meetings/meetings.route.names';
import { RoleGuard } from '@app/admin/services/role.guard';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-meetings',
   templateUrl: './meetings.component.html',
   styleUrls: ['./meetings.component.scss'],
})
export class MeetingsComponent implements OnInit, OnDestroy {
   /* istanbul ignore next */
   columns: TableColumn<Meeting>[] = [
      { def: 'name', label: 'Name', value: (row) => row.name, visible: true },
      { def: 'status', label: 'Status', value: (row) => MeetingStatus[row.status], visible: true },
      {
         def: 'departments',
         label: 'Department(s)',
         value: (row) => this.getDepartments(row),
         visible: true,
         filter: 'departments[]',
      },
      {
         def: 'startTime',
         label: 'Start Time',
         value: (row) => this.datePipe.transform(row.startTime, 'short'),
         visible: true,
         isDate: true,
      },
      {
         def: 'endTime',
         label: 'End Time',
         value: (row) => this.datePipe.transform(row.endTime, 'short'),
         isDate: true,
         visible: true,
      },
   ];

   tableConfig: TableConfig;

   filterConfig: FilterBarConfig = {
      departments: true,
      businessUnits: true,
      status: MeetingStatus,
      filterChange: (filter) => this.meetingsFacade.setFilter(filter),
   };
   canEdit = false;
   departments: Department[] = [];

   private destroyed$ = new Subject<void>();

   constructor(
      private meetingsFacade: MeetingsFacade,
      private route: ActivatedRoute,
      private router: Router,
      private dialogService: DialogService,
      private datePipe: DatePipe,
      private departmentNamePipe: DepartmentNamePipe,
      private roleGuard: RoleGuard
   ) {}

   ngOnInit() {
      this.meetingsFacade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.roleGuard
         .canEdit([], AppAreas.Meetings)
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
         });

      /* istanbul ignore next */
      this.tableConfig = {
         data$: this.meetingsFacade.meetings$,
         columns: this.columns,
         filter$: this.meetingsFacade.filter$,
         initialSort: { active: 'startTime', direction: 'desc' },
         visibleColumns$: new BehaviorSubject([]),
         rowClick: (row) => this.rowClicked(row),
         departments: this.departments,
         hideExport: true,
         options: [
            {
               label: 'Start Meeting',
               action: (row) => this.startMeeting(row),
               condition: (row) => this.canEdit && row.status === MeetingStatus['Not Started'],
            },
            {
               label: 'View Agenda',
               action: (row) => this.viewAgenda(row),
               condition: (row) =>
                  (!this.canEdit && row.status !== MeetingStatus.Completed) ||
                  row.status === MeetingStatus['Not Started'],
            },
            {
               label: 'Resume Meeting',
               action: (row) => this.startMeeting(row),
               condition: (row) => this.canEdit && row.status === MeetingStatus['In Progress'],
            },
            {
               label: 'View Notes',
               action: (row) => this.viewSummary(row),
               condition: (row) => row.status === MeetingStatus.Completed,
            },
            { label: 'Delete', action: (row) => this.delete(row), condition: () => this.canEdit },
         ],
      };
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   create() {
      this.meetingsFacade.selectMeeting(null);
      this.router.navigate(['new', meetingRouteNames.AGENDA], { relativeTo: this.route });
   }

   rowClicked(meeting: Meeting) {
      if (meeting.status === MeetingStatus.Completed) {
         this.viewSummary(meeting);
      } else {
         this.viewAgenda(meeting);
      }
   }

   viewSummary(meeting: Meeting) {
      this.meetingsFacade.viewMeeting(meeting);
      this.router.navigate([meeting.id, meetingRouteNames.SUMMARY], { relativeTo: this.route });
   }

   viewAgenda(meeting: Meeting) {
      this.meetingsFacade.selectMeeting(meeting.id);
      this.router.navigate([meeting.id, meetingRouteNames.AGENDA], { relativeTo: this.route });
   }

   startMeeting(meeting: Meeting) {
      this.meetingsFacade.startMeeting(meeting.id);
   }

   delete(meeting: Meeting) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Meeting?',
            message: 'Are you sure you want to delete this meeting?',
            confirm: 'Yes, delete',
            deny: 'No, keep meeting',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.meetingsFacade.deleteMeeting(meeting);
            }
         });
   }

   getDepartments(meeting: Meeting) {
      if (meeting.departments.length < 4) {
         return meeting.departments
            .map((dept) => this.departmentNamePipe.transform(dept, this.departments))
            .join(', ');
      } else {
         const firstThree = meeting.departments
            .slice(0, 3)
            .map((dept) => this.departmentNamePipe.transform(dept, this.departments))
            .join(', ');
         return `${firstThree}, and ${meeting.departments.length - 3} other${
            meeting.departments.length > 4 ? 's' : ''
         }`;
      }
   }
}
