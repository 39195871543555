<div class="dashboard-container">
   <div class="dashboard-select">
      <mat-form-field *ngIf="!showEdit">
         <mat-label>Dashboard</mat-label>
         <mat-select [(ngModel)]="selectedDashboardId" (selectionChange)="selectDashboard($event)">
            <mat-option [value]="dashboard.id" *ngFor="let dashboard of dashboards$ | async">
               {{ dashboard.name }}
               <ng-container *ngIf="dashboard.default">(Default)</ng-container>
               <ng-container *ngIf="dashboard.sharable">(Shared)</ng-container>
            </mat-option>
         </mat-select>
      </mat-form-field>
      <ng-container *ngIf="showEdit">
         <mat-form-field>
            <input matInput placeholder="Dashboard Name" [(ngModel)]="dashboard.name" />
         </mat-form-field>
         <div class="checkboxes">
            <mat-checkbox [(ngModel)]="dashboard.default">Default Dashboard</mat-checkbox>
            <mat-checkbox [(ngModel)]="dashboard.sharable">Sharable</mat-checkbox>
         </div>
      </ng-container>
   </div>
   <div class="dashboard-grid">
      <gridster class="dashboard" [options]="gridsterOptions">
         <gridster-item [item]="item" *ngFor="let item of dashboard.widgets; index as i">
            <app-widget class="widget" [widget]="item"></app-widget>
            <div *ngIf="showEdit" class="button-container">
               <button
                  mat-fab
                  class="delete-button"
                  (mousedown)="deleteWidget($event, i)"
                  (touchstart)="deleteWidget($event, i)"
                  matTooltip="Remove Widget"
               >
                  <mat-icon>delete</mat-icon>
               </button>
            </div>
         </gridster-item>
      </gridster>
   </div>
</div>

<div class="fab-buttons">
   <button
      mat-fab
      matTooltip="New Dashboard"
      matTooltipPosition="before"
      color="accent"
      *ngIf="!showEdit"
      (click)="newDashboard()"
      data-test-id="new-dashboard-button"
   >
      <mat-icon>dashboard</mat-icon>
   </button>
   <button
      mat-fab
      matTooltip="Edit Dashboard"
      matTooltipPosition="before"
      color="primary"
      *ngIf="!showEdit"
      (click)="editGrid()"
      data-test-id="edit-grid-button"
   >
      <mat-icon>edit</mat-icon>
   </button>
   <button
      mat-fab
      matTooltip="Delete Dashboard"
      color="warn"
      *ngIf="dashboard.id && !showEdit"
      (click)="deleteDashboard()"
      data-test-id="delete-dashboard-button"
   >
      <mat-icon>delete</mat-icon>
   </button>
   <button
      mat-fab
      color="accent"
      matTooltip="Add Widget"
      matTooltipPosition="before"
      *ngIf="showEdit"
      (click)="addWidget()"
      data-test-id="add-widget-button"
   >
      <mat-icon>add</mat-icon>
   </button>
   <button
      mat-fab
      color="primary"
      matTooltip="Save"
      matTooltipPosition="before"
      *ngIf="showEdit"
      (click)="save()"
      data-test-id="save-button"
   >
      <mat-icon>save</mat-icon>
   </button>
   <button
      mat-fab
      *ngIf="showEdit"
      color="warn"
      matTooltip="Cancel"
      matTooltipPosition="before"
      (click)="cancelEdit()"
      data-test-id="cancel-edit-button"
   >
      <mat-icon>cancel</mat-icon>
   </button>
</div>
<ng-template #addWidgetRef>
   <h3 mat-dialog-title>Select Widget</h3>
   <mat-dialog-content>
      <mat-form-field>
         <mat-select [(ngModel)]="newWidget">
            <mat-option *ngFor="let widget of widgets" [value]="widget">
               {{ widget.name }}
            </mat-option>
         </mat-select>
      </mat-form-field>
   </mat-dialog-content>
   <mat-dialog-actions>
      <button mat-raised-button [matDialogClose]="true" color="primary" [disabled]="!newWidget">
         Add Widget
      </button>
      <button mat-raised-button [matDialogClose]="false">Cancel</button>
   </mat-dialog-actions>
</ng-template>
