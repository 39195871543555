import { Component, inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { permissionConfigs } from '@app/admin/constants/permission-areas';
import { AdminFacade } from '@app/admin/state/admin.facade';
import { deepCopy } from '@app/utilities/deep-copy';
import { AccessLevel } from '@entities/enums/access-level';
import { AppAreas } from '@entities/enums/app-areas';
import { PermissionGroup } from '@entities/permission-group';

@Component({
   selector: 'app-permission-group-edit',
   templateUrl: './permission-group-edit.component.html',
   styleUrls: ['./permission-group-edit.component.scss'],
})
export class PermissionGroupEditComponent {
   accessLevel = AccessLevel;
   appAreas = Object.values(AppAreas);
   permissionConfigs = permissionConfigs;
   adminFacade = inject(AdminFacade);
   data = inject(MAT_DIALOG_DATA);

   permissionGroup: PermissionGroup = deepCopy(this.data.permissionGroup);

   setAllView?: AccessLevel;
   setAllEdit?: AccessLevel;

   ngOnInit() {
      this.permissionConfigs.forEach((config) => {
         if (!this.permissionGroup.accessMap[config.area]) {
            this.permissionGroup.accessMap[config.area] = {
               edit: AccessLevel.NONE,
               view: AccessLevel.NONE,
            };
         }
      });
   }

   save() {
      this.adminFacade.savePermissionGroup(this.permissionGroup);
   }

   setAll(value: AccessLevel, action: 'edit' | 'view') {
      Object.keys(this.permissionGroup.accessMap).forEach((area: AppAreas) => {
         const config = this.permissionConfigs.find((config) => config.area === area);
         if (config?.accessOptions.includes(value)) {
            this.permissionGroup.accessMap[area][action] = value;
            if (action === 'view') {
               this.matchEditToView(area, value);
            }
         }
      });
   }

   isEditOptionEnabled(option: AccessLevel, area: AppAreas) {
      const canView = this.permissionGroup.accessMap[area].view;
      switch (canView) {
         case AccessLevel.ALL:
            return true;
         case AccessLevel.ASSIGNED:
            return option !== AccessLevel.ALL;
         case AccessLevel.NONE:
            return option === AccessLevel.NONE;
         default:
            return true;
      }
   }

   canViewUpdated(area: AppAreas) {
      const canView = this.permissionGroup.accessMap[area].view;
      this.matchEditToView(area, canView);
      this.checkSetAll();
   }

   checkSetAll() {
      if (this.setAllView) {
         if (
            Object.values(this.permissionGroup.accessMap)
               .map((area) => area.view)
               .some((value) => value !== this.setAllView)
         ) {
            this.setAllView = null;
         }
      }
      if (this.setAllEdit) {
         if (
            Object.values(this.permissionGroup.accessMap)
               .map((area) => area.edit)
               .some((value) => value !== this.setAllEdit)
         ) {
            this.setAllView = null;
         }
      }
   }

   matchEditToView(area: AppAreas, canView: AccessLevel) {
      let canEdit = this.permissionGroup.accessMap[area].edit;
      if (canView === AccessLevel.ASSIGNED && canEdit === AccessLevel.ALL) {
         this.permissionGroup.accessMap[area].edit = AccessLevel.ASSIGNED;
      } else if (canView === AccessLevel.NONE && canEdit !== AccessLevel.NONE) {
         this.permissionGroup.accessMap[area].edit = AccessLevel.NONE;
      }
   }
}
