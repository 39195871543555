import { Injectable } from '@angular/core';
import { BusinessUnitService } from '../services/business-unit.service';
import { DepartmentService } from '../services/department.service';
import { DepartmentFunctionService } from '../services/department-function.service';
import { TaskService } from '../services/task.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as OrganizationActions from './organization.actions';
import { map, tap, withLatestFrom, switchMap, catchError, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import {
   getSelectedBusinessUnitId,
   getSelectedDepartmentId,
   getSelectedDepartmentFunctionId,
   getBusinessUnits,
   getVisibleDepartments,
   getVisibleDepartmentFunctions,
   getVisibleTasks,
   getDragItem,
   getDepartmentsForBusinessUnit,
   getDepartmentFunctionsForDepartment,
   getTasksForDepartmentFunction,
   getTasks,
   getDepartments,
   getDepartmentFunctions,
   getSelectedDepartment,
   getSelectedBusinessUnit,
} from './organization.state';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { Task } from '@entities/task';
import { BusinessUnit } from '@entities/business-unit';
import { ErrorService } from '@app/shared/services/error.service';
import { from, of } from 'rxjs';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TaskAssignmentService } from '../services/task-assignment.service';

import { Router } from '@angular/router';
import { appRoutesNames } from '@app/app.routes.names';
import { documentationRouteNames } from '@app/documentation/documentation.routes.names';
import { DocumentationActions } from '@app/documentation/state/documentation.actions';
import { orgBuilderRouteNames } from '../org-builder.routes.names';
import { DocumentationStatus } from '@entities/enums/documentation-status';
import { PerformanceRating } from '@entities/enums/performance-rating';

@Injectable()
export class OrganizationEffects {
   constructor(
      private actions$: Actions,
      private businessUnitService: BusinessUnitService,
      private departmentService: DepartmentService,
      private departmentFunctionService: DepartmentFunctionService,
      private taskService: TaskService,
      private taskAssignmentService: TaskAssignmentService,
      private store: Store<State>,
      private errorService: ErrorService,
      private snackBar: MatSnackBar,
      private router: Router
   ) {}

   saveBusinessUnit$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.SaveBusinessUnit),
         withLatestFrom(
            this.store.pipe(select(getOrganizationId)),
            this.store.pipe(select(getBusinessUnits))
         ),
         switchMap(([action, organizationId, businessUnits]) => {
            const businessUnit: BusinessUnit = {
               ...action.businessUnit,
               organizationId,
               order:
                  action.businessUnit.order != null
                     ? action.businessUnit.order
                     : businessUnits.length,
            };
            return from(this.businessUnitService.save(businessUnit, action.overwrite)).pipe(
               map((bu) => {
                  this.snackBar.open('Business Unit Saved');

                  return OrganizationActions.SelectBusinessUnit({ businessUnitId: bu.id });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   deleteBusinessUnit$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(OrganizationActions.DeleteBusinessUnit),
            tap((action) => {
               this.businessUnitService
                  .delete(action.businessUnit)
                  .then(() => {
                     const snackBarRef = this.snackBar.open('Business Unit Deleted', 'Undo');
                     snackBarRef.afterDismissed().subscribe((event) => {
                        if (event.dismissedByAction) {
                           this.store.dispatch(
                              OrganizationActions.SaveBusinessUnit(action.businessUnit, true)
                           );
                        } else {
                           this.store.dispatch(
                              OrganizationActions.DeleteBusinessUnitChildren({
                                 businessUnitId: action.businessUnit.id,
                              })
                           );
                        }
                     });
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   deleteBusinessUnitChildren$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.DeleteBusinessUnitChildren),
         withLatestFrom(this.store.pipe(select(getDepartments))),
         switchMap(([action, depts]) => {
            const toDelete = depts.filter((t) => t.businessUnitId == action.businessUnitId);
            return toDelete.map((dept) => OrganizationActions.DeleteDepartment(dept, false));
         })
      )
   );

   reorderBusinessUnits$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.ReorderBusinessUnits),
         withLatestFrom(this.store.pipe(select(getBusinessUnits))),
         switchMap(([action, businessUnits]) => {
            const toMove = businessUnits[action.oldIndex];
            const reordered = [...businessUnits];
            const toUpdate = [];
            reordered.splice(action.oldIndex, 1);
            reordered.splice(action.newIndex, 0, toMove);
            for (let i = 0; i < reordered.length; i++) {
               if (reordered[i].id != businessUnits[i].id) {
                  toUpdate.push({
                     ...reordered[i],
                     order: i,
                  });
               }
            }
            return from(this.businessUnitService.batchSave(toUpdate)).pipe(
               map(() => {
                  return OrganizationActions.DraggingStopped();
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   saveDepartment$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.SaveDepartment),
         withLatestFrom(
            this.store.pipe(select(getSelectedBusinessUnitId)),
            this.store.pipe(select(getOrganizationId)),
            this.store.pipe(select(getVisibleDepartments))
         ),
         switchMap(([action, businessUnitId, organizationId, depts]) => {
            const department: Department = {
               ...action.department,
               businessUnitId,
               organizationId,
               order: action.department.order != null ? action.department.order : depts.length,
            };
            return from(this.departmentService.save(department, action.overwrite)).pipe(
               map((dept) => {
                  this.snackBar.open('Department Saved');
                  return OrganizationActions.SelectDepartment({ departmentId: dept.id });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   deleteDepartment$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(OrganizationActions.DeleteDepartment),
            tap((action) => {
               this.departmentService
                  .delete(action.department)
                  .then(() => {
                     if (action.showSnackBar) {
                        const snackBarRef = this.snackBar.open('Department Deleted', 'Undo');
                        snackBarRef.afterDismissed().subscribe((event) => {
                           if (event.dismissedByAction) {
                              this.store.dispatch(
                                 OrganizationActions.SaveDepartment(action.department, true)
                              );
                           } else {
                              this.store.dispatch(
                                 OrganizationActions.DeleteDepartmentChildren({
                                    departmentId: action.department.id,
                                 })
                              );
                           }
                        });
                     } else {
                        this.store.dispatch(
                           OrganizationActions.DeleteDepartmentChildren({
                              departmentId: action.department.id,
                           })
                        );
                     }
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   deleteDepartmentChildren$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.DeleteDepartmentChildren),
         withLatestFrom(this.store.pipe(select(getDepartmentFunctions))),
         switchMap(([action, deptFns]) => {
            const toDelete = deptFns.filter((t) => t.departmentId == action.departmentId);
            return toDelete.map((deptFn) =>
               OrganizationActions.DeleteDepartmentFunction(deptFn, false)
            );
         })
      )
   );

   reorderDepartments$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.ReorderDepartments),
         withLatestFrom(this.store.pipe(select(getVisibleDepartments))),
         switchMap(([action, departments]) => {
            const toMove = departments[action.oldIndex];
            const reordered = [...departments];
            const toUpdate = [];
            reordered.splice(action.oldIndex, 1);
            reordered.splice(action.newIndex, 0, toMove);
            for (let i = 0; i < reordered.length; i++) {
               if (reordered[i].id != departments[i].id) {
                  toUpdate.push({
                     ...reordered[i],
                     order: i,
                  });
               }
            }
            return from(this.departmentService.batchSave(toUpdate)).pipe(
               map(() => {
                  return OrganizationActions.DraggingStopped();
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   saveDepartmentFunction$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.SaveDepartmentFunction),
         withLatestFrom(
            this.store.pipe(select(getSelectedDepartmentId)),
            this.store.pipe(select(getOrganizationId)),
            this.store.pipe(select(getVisibleDepartmentFunctions))
         ),
         switchMap(([action, departmentId, organizationId, deptFns]) => {
            const departmentFunction: DepartmentFunction = {
               ...action.departmentFunction,
               departmentId: action.departmentFunction.departmentId || departmentId,
               organizationId,
               order:
                  action.departmentFunction.order != null
                     ? action.departmentFunction.order
                     : deptFns.length,
               documentationStatus:
                  action.departmentFunction.documentationStatus ||
                  DocumentationStatus['Not Started'],
            };
            return from(
               this.departmentFunctionService.save(departmentFunction, action.overwrite)
            ).pipe(
               map((deptFn) => {
                  this.snackBar.open('Function Saved');
                  return OrganizationActions.SelectDepartmentFunction({
                     departmentFunctionId: deptFn.id,
                  });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   deleteDepartmentFunction$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(OrganizationActions.DeleteDepartmentFunction),
            tap((action) => {
               this.departmentFunctionService
                  .delete(action.departmentFunction)
                  .then(() => {
                     if (action.showSnackBar) {
                        const snackBarRef = this.snackBar.open('Function Deleted', 'Undo');
                        snackBarRef.afterDismissed().subscribe((event) => {
                           if (event.dismissedByAction) {
                              this.store.dispatch(
                                 OrganizationActions.SaveDepartmentFunction(
                                    action.departmentFunction,
                                    true
                                 )
                              );
                           } else {
                              this.store.dispatch(
                                 OrganizationActions.DeleteDepartmentFunctionChildren({
                                    departmentFunctionId: action.departmentFunction.id,
                                 })
                              );
                           }
                        });
                     } else {
                        this.store.dispatch(
                           OrganizationActions.DeleteDepartmentFunctionChildren({
                              departmentFunctionId: action.departmentFunction.id,
                           })
                        );
                     }
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   deleteDepartmentFunctionChildren$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.DeleteDepartmentFunctionChildren),
         withLatestFrom(this.store.pipe(select(getTasks))),
         switchMap(([action, tasks]) => {
            const toDelete = tasks.filter(
               (t) => t.departmentFunctionId == action.departmentFunctionId
            );
            return toDelete.map((task) => OrganizationActions.DeleteTask(task, false));
         })
      )
   );

   reorderDepartmentFunctions$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.ReorderDepartmentFunctions),
         withLatestFrom(this.store.pipe(select(getVisibleDepartmentFunctions))),
         switchMap(([action, departmentFunctions]) => {
            const toMove = departmentFunctions[action.oldIndex];
            const reordered = [...departmentFunctions];
            const toUpdate = [];
            reordered.splice(action.oldIndex, 1);
            reordered.splice(action.newIndex, 0, toMove);
            for (let i = 0; i < reordered.length; i++) {
               if (reordered[i].id != departmentFunctions[i].id) {
                  toUpdate.push({
                     ...reordered[i],
                     order: i,
                  });
               }
            }
            return from(this.departmentFunctionService.batchSave(toUpdate)).pipe(
               map(() => {
                  return OrganizationActions.DraggingStopped();
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   saveTask$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.SaveTask),
         withLatestFrom(
            this.store.pipe(select(getSelectedDepartmentFunctionId)),
            this.store.pipe(select(getOrganizationId)),
            this.store.pipe(select(getVisibleTasks))
         ),
         switchMap(([action, departmentFunctionId, organizationId, tasks]) => {
            const task: Task = {
               ...action.task,
               departmentFunctionId: action.task.departmentFunctionId || departmentFunctionId,
               organizationId,
               order: action.task.order != null ? action.task.order : tasks.length,
            };
            return from(this.taskService.save(task, action.overwrite)).pipe(
               map((t) => {
                  this.snackBar.open('Task Saved');

                  return OrganizationActions.SelectTask({ taskId: t.id });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   deleteTask$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(OrganizationActions.DeleteTask),
            tap((action) => {
               this.taskService
                  .delete(action.task)
                  .then(() => {
                     if (action.showSnackBar) {
                        const snackBarRef = this.snackBar.open('Task Deleted', 'Undo');
                        snackBarRef.afterDismissed().subscribe((event) => {
                           if (event.dismissedByAction) {
                              this.store.dispatch(OrganizationActions.SaveTask(action.task, true));
                           }
                        });
                     }
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   reorderTasks$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.ReorderTasks),
         withLatestFrom(this.store.pipe(select(getVisibleTasks))),
         switchMap(([action, tasks]) => {
            const toMove = tasks[action.oldIndex];
            const reordered = [...tasks];
            const toUpdate = [];
            reordered.splice(action.oldIndex, 1);
            reordered.splice(action.newIndex, 0, toMove);
            for (let i = 0; i < reordered.length; i++) {
               if (reordered[i].id != tasks[i].id) {
                  toUpdate.push({
                     ...reordered[i],
                     order: i,
                  });
               }
            }
            return from(this.taskService.batchSave(toUpdate)).pipe(
               map(() => {
                  return OrganizationActions.DraggingStopped();
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   saveTaskAssignment$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(OrganizationActions.AssignTaskToTeamMember),
            tap((action) => {
               this.taskAssignmentService.save(action.taskAssignment).catch((error) => {
                  this.errorService.handleError(error);
               });
            })
         ),
      { dispatch: false }
   );

   itemDropped$ = createEffect(() =>
      this.actions$.pipe(
         ofType(OrganizationActions.ItemDropped),
         withLatestFrom(this.store.pipe(select(getDragItem))),
         switchMap(([action, dragItem]) => {
            if (dragItem) {
               if (action.dropTargetType == BusinessUnit && 'businessUnitId' in dragItem) {
                  const reassigned = { ...dragItem, businessUnitId: action.dropTarget.id };
                  return this.store.pipe(
                     select(getDepartmentsForBusinessUnit, {
                        businessUnitId: action.dropTarget.id,
                     }),
                     take(1),
                     switchMap((departments) => {
                        reassigned.order = departments.length;
                        return from(this.departmentService.save(reassigned));
                     })
                  );
               } else if (action.dropTargetType == Department && 'departmentId' in dragItem) {
                  const reassigned = { ...dragItem, departmentId: action.dropTarget.id };
                  return this.store.pipe(
                     select(getDepartmentFunctionsForDepartment, {
                        departmentId: action.dropTarget.id,
                     }),
                     take(1),
                     switchMap((departmentFunctions) => {
                        reassigned.order = departmentFunctions.length;
                        return from(this.departmentFunctionService.save(reassigned));
                     })
                  );
               } else if (
                  action.dropTargetType == DepartmentFunction &&
                  'departmentFunctionId' in dragItem
               ) {
                  const reassigned = { ...dragItem, departmentFunctionId: action.dropTarget.id };
                  return this.store.pipe(
                     select(getTasksForDepartmentFunction, {
                        departmentFunctionId: action.dropTarget.id,
                     }),
                     take(1),
                     switchMap((tasks) => {
                        reassigned.order = tasks.length;
                        return from(this.taskService.save(reassigned));
                     })
                  );
               }
            }
            return of();
         }),
         map(() => {
            return OrganizationActions.DraggingStopped();
         })
      )
   );

   taskFullEditor$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(OrganizationActions.GoToTaskFullEditor),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([action, orgId]) => {
               const departmentFunctionId = action.viewModel.departmentFunction.id;
               const task = { ...action.task, departmentFunctionId };
               const viewModel = {
                  ...action.viewModel,
                  task,
               };
               this.router.navigate(
                  [
                     appRoutesNames.ORGANIZATION,
                     orgId,
                     appRoutesNames.DOCUMENTATION,
                     documentationRouteNames.TASKS,
                     'new',
                  ],
                  { state: { source: action.url, viewModel } }
               );
               this.store.dispatch(
                  DocumentationActions.SelectDepartmentFunction({
                     departmentFunctionId: departmentFunctionId,
                  })
               );
            })
         ),
      { dispatch: false }
   );

   departmentFunctionFullEditor$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(OrganizationActions.GoToDepartmentFunctionFullEditor),
            withLatestFrom(
               this.store.pipe(select(getOrganizationId)),
               this.store.pipe(select(getSelectedBusinessUnit)),
               this.store.pipe(select(getSelectedDepartment)),
               this.store.pipe(select(getVisibleDepartmentFunctions))
            ),
            tap(([{ departmentFunction, url }, orgId, businessUnit, department, deptFns]) => {
               const id = departmentFunction.id || 'new';
               let viewModel;
               if (!departmentFunction.id) {
                  viewModel = {
                     businessUnit,
                     department,
                     departmentFunction: {
                        ...departmentFunction,
                        departmentId: department.id,
                        order: deptFns.length,
                        documentationStatus: DocumentationStatus['Not Started'],
                     },
                  };
               }
               this.router.navigate(
                  [
                     appRoutesNames.ORGANIZATION,
                     orgId,
                     appRoutesNames.DOCUMENTATION,
                     documentationRouteNames.FUNCTIONS,
                     id,
                  ],
                  { state: { source: url, viewModel } }
               );
            })
         ),
      { dispatch: false }
   );

   businessUnitSummary$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(OrganizationActions.BusinessUnitSummary),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ businessUnitId }, orgId]) => {
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.ORG_BUILDER,
                  orgBuilderRouteNames.BUSINESS_UNIT_SUMMARY,
                  businessUnitId,
               ]);
            })
         ),
      { dispatch: false }
   );

   departmentSummary$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(OrganizationActions.DepartmentSummary),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ departmentId }, orgId]) => {
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.ORG_BUILDER,
                  orgBuilderRouteNames.DEPARTMENT_SUMMARY,
                  departmentId,
               ]);
            })
         ),
      { dispatch: false }
   );

   resetRatings$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(OrganizationActions.ResetRatings),
            withLatestFrom(
               this.store.pipe(select(getDepartmentFunctions)),
               this.store.pipe(select(getTasks))
            ),
            tap(([action, deptFns, tasks]) => {
               const deptFnsToSave = [];
               const tasksToSave = [];
               deptFns.forEach((deptFn) => {
                  if (deptFn.rating !== PerformanceRating['No Value']) {
                     deptFnsToSave.push({ ...deptFn, rating: PerformanceRating['No Value'] });
                  }
               });
               tasks.forEach((task) => {
                  if (task.rating !== PerformanceRating['No Value']) {
                     tasksToSave.push({ ...task, rating: PerformanceRating['No Value'] });
                  }
               });
               this.departmentFunctionService.batchSave(deptFnsToSave);
               this.taskService.batchSave(tasksToSave);
            })
         ),
      { dispatch: false }
   );
}
