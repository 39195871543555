<form [formGroup]="form">
   <mat-form-field>
      <mat-label>{{ label }}</mat-label>
      <mat-select
         formControlName="teamMembers"
         [multiple]="true"
         [compareWith]="compareById"
         (selectionChange)="teamMembersUpdated($event)"
      >
         <mat-option>
            <ngx-mat-select-search
               placeholderLabel="Search"
               [formControl]="getControl('filter')"
            ></ngx-mat-select-search>
         </mat-option>
         <mat-option *ngFor="let teamMember of filteredTeamMembers$ | async" [value]="teamMember">
            {{ teamMember.firstName }} {{ teamMember.lastName }}
         </mat-option>
      </mat-select>
   </mat-form-field>
</form>
