import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Task } from '@entities/task';
import { DepartmentFunction } from '@entities/department-function';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { documentationRouteNames } from '@app/documentation/documentation.routes.names';
import { DocumentationStatus } from '@entities/enums/documentation-status';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { DialogService } from '@app/shared/services/dialog.service';
import { RoleGuard } from '@app/admin/services/role.guard';
import { HistoryFacade } from '@app/shared/state/history.facade';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-documentation-function-edit',
   templateUrl: './documentation-function-edit.component.html',
   styleUrls: ['./documentation-function-edit.component.scss'],
})
export class DocumentationFunctionEditComponent implements OnInit, SaveChangesComponent, OnDestroy {
   form: UntypedFormGroup;
   deptFnId: string;
   tasks: Task[] = [];
   source: string;
   docStatus = DocumentationStatus;
   statusKeys = Object.keys(DocumentationStatus)
      .map((key) => +key)
      .filter((key) => !isNaN(key));

   private destroyed$ = new Subject<void>();
   showEditName = false;
   title: string;
   departmentName: string;
   businessUnitName: string;

   departmentFunction: DepartmentFunction;
   fromNew = false;
   canEdit$: Observable<boolean>;
   header$: Observable<string>;

   constructor(
      public documentationFacade: DocumentationFacade,
      private router: Router,
      private route: ActivatedRoute,
      private dialogService: DialogService,
      private roleGuard: RoleGuard,
      private historyFacade: HistoryFacade
   ) {
      this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
         if (params['functionId']) {
            this.documentationFacade.selectDepartmentFunction(params['functionId']);
            this.deptFnId = params['functionId'];
         }
      });
      this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((queryParams) => {
         this.fromNew = queryParams['from'] === 'new';
      });
   }

   ngOnInit() {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         description: new UntypedFormControl(),
         benchmark: new UntypedFormControl(),
         departmentId: new UntypedFormControl(),
         leadId: new UntypedFormControl(),
         order: new UntypedFormControl(),
         purpose: new UntypedFormControl(),
         documentationStatus: new UntypedFormControl(DocumentationStatus['Not Started']),
         kpi: new UntypedFormControl(),
         policies: new UntypedFormControl(),
         ratingNotes: new UntypedFormControl(),
      });

      if (history.state) {
         this.source = history.state.source;
         const viewModel = history.state.viewModel;
         if (viewModel) {
            const deptFn = viewModel.departmentFunction;
            this.form.patchValue(deptFn);
            if (deptFn.description && !deptFn.policies) {
               this.form.get('policies').setValue(deptFn.description);
            }
            this.businessUnitName = viewModel.businessUnit.name;
            this.departmentName = viewModel.department.name;
            this.showEditName = true;
            this.form.markAsDirty();
         }
      }

      this.documentationFacade.selectedDepartmentFunction$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((viewModel) => {
            if (viewModel) {
               this.departmentFunction = viewModel.departmentFunction;
               if (viewModel.businessUnit) {
                  this.businessUnitName = viewModel.businessUnit.name;
               }
               if (viewModel.department) {
                  this.departmentName = viewModel.department.name;
               }
               if (this.form.pristine) {
                  this.form.patchValue(this.departmentFunction);
                  if (this.departmentFunction.description && !this.departmentFunction.policies) {
                     this.form.get('policies').setValue(this.departmentFunction.description);
                  }
               }
               const overview = this.departmentFunction['overview'];
               if (overview && !this.departmentFunction.description) {
                  this.form.get('description').setValue(overview);
               }
               this.tasks = viewModel.departmentFunction.tasks;
            }
         });
      this.canEdit$ = this.roleGuard.canEdit([], AppAreas.Documentation, [
         this.departmentFunction?.leadId,
      ]);
      this.canEdit$.pipe(takeUntil(this.destroyed$)).subscribe((canEdit) => {
         if (canEdit) {
            this.form.enable();
         } else {
            this.form.disable();
         }
      });
      this.header$ = this.canEdit$.pipe(
         map((canEdit) => (canEdit ? 'Edit Function' : 'Function Details'))
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   isNew() {
      return !this.form.get('id').value;
   }

   save(onDeactivate = false) {
      const departmentFunction: DepartmentFunction = {
         ...this.departmentFunction,
         ...this.form.value,
      };
      this.documentationFacade.saveDepartmentFunction(departmentFunction, onDeactivate);
      this.form.markAsPristine();
   }

   delete() {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Function?',
            message: 'Are you sure you want to delete this function?',
            confirm: 'Yes, Delete',
            deny: 'No, Go Back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.documentationFacade.deleteDepartmentFunction(this.departmentFunction);
               this.back();
            }
         });
   }

   setLeadId(event: MatAutocompleteSelectedEvent) {
      if (event) {
         const id = event.option.value.id;
         this.form.get('leadId').setValue(id);
         this.form.markAsDirty();
      } else {
         this.form.get('leadId').reset();
      }
   }

   onEditTask(task: Task) {
      this.documentationFacade.selectTask(task.id);
      this.router.navigate([documentationRouteNames.TASKS, task.id], {
         relativeTo: this.route.parent.parent,
         state: {
            source: this.router.url,
         },
      });
   }

   back() {
      if (this.fromNew) {
         history.go(-2);
      } else {
         this.historyFacade.back();
         // history.back();
      }
   }

   print() {
      this.router.navigate(['report'], { relativeTo: this.route });
   }

   saveDisabled() {
      return this.form ? this.form.pristine || !this.form.valid : true;
   }

   editName() {
      this.title = this.form.value.name;
      this.showEditName = true;
   }

   updateName() {
      this.showEditName = false;
   }

   cancelUpdateName() {
      this.showEditName = false;
      this.form.get('name').setValue(this.title);
   }
}
