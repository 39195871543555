<a
   id="main-route"
   mat-list-item
   [routerLink]="route"
   routerLinkActive="active"
   (mouseenter)="showMenu()"
   (mouseleave)="checkHideMenu($event)"
   (mouseup)="clickEnd($event)"
>
   <ng-container *ngIf="config.svgIcon; else baseIcon">
      <mat-icon
         class="icon"
         [svgIcon]="config.iconPath"
         [ngClass]="{ expanded: expanded }"
      ></mat-icon>
   </ng-container>
   <ng-template #baseIcon>
      <mat-icon class="icon" [ngClass]="{ expanded: expanded }">{{ config.iconPath }}</mat-icon>
   </ng-template>
   <span class="label" *ngIf="expanded">{{ config.label }}</span>
</a>

<ng-container *ngIf="active && expanded">
   <div class="sub-nav" [ngClass]="{ active: active }">
      <ng-container *ngFor="let child of config.children">
         <a
            mat-list-item
            *ngIf="showChild(child) | async"
            class="sub-nav-link"
            [routerLink]="getSubRoute(child.route)"
            routerLinkActive="active"
         >
            {{ child.label }}
         </a>
      </ng-container>
   </div>
</ng-container>

<div
   #subnavMenu
   class="subnav-menu mat-elevation-z1"
   [ngClass]="{ expanded: expanded }"
   (mouseleave)="checkHideMenu($event)"
   (mouseup)="clickEnd($event)"
>
   <a mat-menu-item [routerLink]="route" class="subnav-header subnav-menu-link" *ngIf="!expanded">
      {{ config.label }}
   </a>
   <ng-container *ngFor="let child of config.children">
      <a
         mat-menu-item
         *ngIf="showChild(child) | async"
         [routerLink]="getSubRoute(child.route)"
         class="subnav-menu-link"
      >
         {{ child.label }}
      </a>
   </ng-container>
</div>
