<ng-container [ngSwitch]="field">
   <ng-container *ngSwitchCase="'goalId'">
      {{ value | goalName: goals }}
   </ng-container>
   <ng-container *ngSwitchCase="'assigneeId'">
      {{ value | teamMemberName: teamMembers }}
   </ng-container>
   <ng-container *ngSwitchCase="'status'">
      {{ status[value] }}
   </ng-container>
   <ng-container *ngSwitchCase="'description'">
      <div [innerHTML]="value"></div>
   </ng-container>
   <ng-container *ngSwitchDefault>
      <ng-container *ngIf="field && field.toLowerCase().includes('date'); else plainText">
         {{ value | date }}
      </ng-container>
      <ng-template #plainText>
         {{ value }}
      </ng-template>
   </ng-container>
</ng-container>
