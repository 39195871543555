import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select, Action } from '@ngrx/store';
import { State, routerSelectors } from '@app/app.state';
import * as LayoutActions from './layout.actions';
import { getHelpOpen, getRecentOrgs, getSidenavOpen } from './layout.state';
import { getLoggedIn, getAuthUser } from '@app/auth/state/auth.state';
import * as UserOrgActions from '@app/user-org/state/user-org.actions';

import { AppAreas } from '@entities/enums/app-areas';
import { RoleGuard } from '@app/admin/services/role.guard';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { FirebaseAuthService } from '@app/auth/services/firebase-auth.service';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { AuthUser } from '@entities/auth-user';
import { appRoutesNames } from '@app/app.routes.names';
import { Router } from '@angular/router';
import { ProductType } from '@entities/enums/product-type';

@Injectable()
export class LayoutFacade {
   loggedIn$: Observable<boolean> = this.store.pipe(select(getLoggedIn));
   currentUser$: Observable<AuthUser> = this.store.pipe(select(getAuthUser));
   orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   helpOpen$: Observable<boolean> = this.store.pipe(select(getHelpOpen));
   recentOrgs$: Observable<string[]> = this.store.pipe(select(getRecentOrgs));
   route$: Observable<string> = this.store.pipe(select(routerSelectors.selectUrl));
   sidenavOpen$: Observable<boolean> = this.store.pipe(select(getSidenavOpen));

   constructor(
      private store: Store<State>,
      private authService: FirebaseAuthService,
      private roleGuard: RoleGuard,
      private router: Router
   ) {}

   toggleSidenav() {
      this.store.dispatch(LayoutActions.ToggleSidenav());
   }
   hideSidenav() {
      this.store.dispatch(LayoutActions.CloseSidenav());
   }

   logout() {
      this.authService.logout();
   }

   canView(products: ProductType[], area?: AppAreas) {
      return this.roleGuard.canView(products, area);
   }
   goToOrg(organizationId: string) {
      this.store.dispatch(UserOrgActions.SelectOrganization({ organizationId }));
   }

   toggleHelp() {
      this.store.dispatch(LayoutActions.ToggleHelp());
   }
   closeHelp() {
      this.store.dispatch(LayoutActions.CloseHelp());
   }

   goToUserAccount() {
      this.store.dispatch(UserOrgActions.SetOrganization({ organizationId: null }));
      this.router.navigate([appRoutesNames.USER_ACCOUNT]);
   }

   goToLogin() {
      this.router.navigate([appRoutesNames.LOGIN]);
   }
}
