import {
   Component,
   OnInit,
   EventEmitter,
   Input,
   Output,
   SimpleChanges,
   OnChanges,
   OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Observable, combineLatest, Subject } from 'rxjs';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { FilterValues, FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { TeamMember } from '@entities/team-member';
import { isEmpty, isEqual } from 'lodash';
@Component({
   selector: 'app-filter-bar',
   templateUrl: './filter-bar.component.html',
   styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent implements OnInit, OnChanges, OnDestroy {
   @Input() config: FilterBarConfig = {};
   @Input() filter: FilterValues;
   @Input() canEdit = true;
   @Output() filterChange: EventEmitter<any> = new EventEmitter();

   filterForm: UntypedFormGroup;
   showFilters = true;
   businessUnits: BusinessUnit[];
   departments: Department[];
   filteredDepartments: Department[];
   departmentFunctions: DepartmentFunction[];
   filteredDepartmentFunctions: DepartmentFunction[];
   groupedDepartments: any[];
   onFilterChange: (filter: FilterValues) => any;

   private destroyed$ = new Subject<void>();

   constructor(private orgBuilderFacade: OrgBuilderFacade) {
      this.filterForm = new UntypedFormGroup({
         businessUnits: new UntypedFormControl(),
         departments: new UntypedFormControl(),
         groupedDepartments: new UntypedFormControl(),
         departmentFunctions: new UntypedFormControl(),
         status: new UntypedFormControl(),
         rating: new UntypedFormControl(),
         quarter: new UntypedFormControl(),
         search: new UntypedFormControl(),
         teamMembers: new UntypedFormControl(),
         type: new UntypedFormControl(),
         priority: new UntypedFormControl(),
         targetDate: new UntypedFormControl(),
      });
   }

   ngOnInit() {
      this.setFilter();
      this.subscribeToBusinessUnits();
      this.subscribeToDepartments();
      this.subscribeToDepartmentFunctions();
      if (this.config.filterChange) {
         this.onFilterChange = this.config.filterChange;
      } else {
         this.onFilterChange = () => {};
      }
      this.filterForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
         this.validate(value);
         this.filterChange.emit(value);
         this.onFilterChange(value);
      });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['config']) {
         this.setFilter();
      } else if (changes['filter']) {
         const change = changes['filter'];
         if (!isEqual(change.previousValue, change.currentValue)) {
            this.setFilter();
         }
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
   }

   getControl(name: string): UntypedFormControl {
      return this.filterForm.get(name) as UntypedFormControl;
   }

   subscribeToBusinessUnits() {
      this.orgBuilderFacade.businessUnits$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((businessUnits) => {
            this.businessUnits = businessUnits;
         });
   }

   subscribeToDepartments() {
      combineLatest([
         this.orgBuilderFacade.departments$,
         this.filterForm.get('businessUnits').valueChanges.pipe(startWith([])),
      ])
         .pipe(
            takeUntil(this.destroyed$),
            map(([departments, businessUnits]) => {
               if (businessUnits && businessUnits.length > 0) {
                  const businessUnitIds = businessUnits.map((b) => b.id);
                  departments = departments.filter((department) =>
                     businessUnitIds.includes(department.businessUnitId)
                  );
                  let selectedDepartments = this.filterForm.get('departments').value;
                  if (selectedDepartments) {
                     selectedDepartments = selectedDepartments.filter((dept) =>
                        businessUnitIds.includes(dept.businessUnitId)
                     );
                     this.filterForm.get('departments').setValue(selectedDepartments);
                  }
               }
               return departments.sort((a, b) => (a.name || '').localeCompare(b.name));
            })
         )
         .subscribe((departments) => {
            this.departments = departments;
         });

      combineLatest([this.orgBuilderFacade.businessUnits$, this.orgBuilderFacade.departments$])
         .pipe(
            takeUntil(this.destroyed$),
            map(([businessUnits, departments]) => {
               const grouped = [];
               businessUnits.forEach((businessUnit) => {
                  const group = {
                     id: businessUnit.id,
                     name: businessUnit.name,
                     isGroup: true,
                     departments: departments.filter((d) => d.businessUnitId == businessUnit.id),
                  };
                  grouped.push(group);
               });
               return grouped;
            })
         )
         .subscribe((groupedDepartments) => {
            this.groupedDepartments = groupedDepartments;
         });
   }

   subscribeToDepartmentFunctions() {
      combineLatest([
         this.orgBuilderFacade.departmentFunctions$.pipe(take(1)),
         this.filterForm.get('departments').valueChanges.pipe(startWith([])),
      ])
         .pipe(
            takeUntil(this.destroyed$),
            map(([functions, departments]) => {
               if (departments && departments.length > 0) {
                  const departmentIds = departments.map((d) => d.id);
                  functions = functions.filter((f) => departmentIds.includes(f.departmentId));
               }
               return functions.sort((a, b) => (a.name || '').localeCompare(b.name));
            })
         )
         .subscribe((departmentFunctions) => {
            this.departmentFunctions = departmentFunctions;
         });
   }

   setFilter() {
      if (this.filter) {
         this.filterForm.patchValue(this.filter);
         this.filterForm.markAsDirty();
         if (this.canEdit) {
            this.filterForm.enable();
         } else {
            this.filterForm.disable();
         }
      }
   }

   toggleShowFilters() {
      this.showFilters = !this.showFilters;
   }

   validate(value: FilterValues) {
      if (value.businessUnits && value.businessUnits.length > 0 && value.departments) {
         value.departments = value.departments.filter((dept) =>
            value.businessUnits.some((bu) => bu.id == dept.businessUnitId)
         );
      }
      if (value.departments && value.departments.length > 0 && value.departmentFunctions) {
         value.departmentFunctions = value.departmentFunctions.filter((deptFn) =>
            value.departments.some((dept) => dept.id == deptFn.departmentId)
         );
      }
   }

   getKeys(obj: any) {
      return Object.keys(obj)
         .map((k) => +k)
         .filter((k) => !isNaN(k));
   }

   idCompare(a: { id: string }, b: { id: string }) {
      return a && b && a.id == b.id;
   }

   selectGroup(group: any) {
      let selected = this.filterForm.get('groupedDepartments').value as any[];
      selected = selected.filter((d) => d != undefined);
      const groupSelected = selected.includes(group);
      let newSelected = selected.filter((d) => d.isGroup || d.businessUnitId != group.id);
      if (groupSelected) {
         newSelected = [...selected, ...group.departments];
      }
      this.filterForm.get('groupedDepartments').setValue(newSelected);
   }

   setTeamMembers(teamMembers: TeamMember[]) {
      this.filterForm.get('teamMembers').setValue(teamMembers);
   }

   filterEmpty() {
      return this.filter == undefined || isEmpty(this.filter);
   }
}
