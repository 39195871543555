import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { Sort } from '@angular/material/sort';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { PipeMap } from '@app/shared/services/table.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { documentationRouteNames } from '@app/documentation/documentation.routes.names';
import { DocumentationStatus } from '@entities/enums/documentation-status';
import { FilterValues, FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { TeamMemberNamePipe } from '@app/shared/pipes/team-member-name.pipe';
import { TeamMember } from '@entities/team-member';
import { RowMenuOption, TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { FunctionDocumentationViewModel } from '@app/documentation/state/documentation.models';
import { TableComponent, TableConfig } from '@app/shared/components/table/table.component';
import { FilterBarComponent } from '@app/shared/components/filter-bar/filter-bar.component';
import { getKeys } from '@app/utilities/getKeys';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RoleGuard } from '@app/admin/services/role.guard';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-documentation-function-list',
   templateUrl: './documentation-function-list.component.html',
   styleUrls: ['./documentation-function-list.component.scss'],
})
export class DocumentationFunctionListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent) table: TableComponent;

   readonly initialSort: Sort = {
      active: 'departmentFunction.name',
      direction: 'asc',
   };

   @ViewChild(FilterBarComponent) filterBar: FilterBarComponent;
   @ViewChild('documentationStatusTemplate') docStatusTemplate: TemplateRef<any>;

   data$: Observable<any[]>;
   filter$: Observable<FilterValues>;
   sort$: Observable<Sort>;

   documentationStatus = DocumentationStatus;
   docStatusKeys = getKeys(DocumentationStatus);

   docStatusValue: DocumentationStatus;
   selectedFunction: DepartmentFunction;

   canEdit = false;

   /* istanbul ignore next */
   filterBarConfig: FilterBarConfig = {
      businessUnits: true,
      departments: true,
      status: DocumentationStatus,
      teamMembers: true,
      teamMembersLabel: 'Lead',
      filterChange: (filter) => this.filterFormChanged(filter),
   };
   visibleColumns$ = this.documentationFacade.deptFnDisplayedColumns$;
   columns: TableColumn<FunctionDocumentationViewModel>[] = [
      {
         def: 'departmentFunction.name',
         label: 'Function',
         visible: true,
         value: (row) => (row.departmentFunction ? row.departmentFunction.name : ''),
         filter: 'departmentFunctions',
      },
      {
         def: 'department.name',
         label: 'Department',
         visible: true,
         value: (row) => (row.department ? row.department.name : ''),
         filter: 'departments',
      },
      {
         def: 'businessUnit.name',
         label: 'Business Unit',
         visible: true,
         value: (row) => (row.businessUnit ? row.businessUnit.name : ''),
         filter: 'businessUnits',
      },
      {
         def: 'departmentFunction.tasks.length',
         label: 'Tasks',
         visible: true,
         value: (row) => (row.departmentFunction ? row.departmentFunction.tasks.length : ''),
      },
      {
         def: 'departmentFunction.leadId',
         label: 'Lead',
         visible: true,
         value: (row) =>
            row.departmentFunction
               ? this.teamMemberNamePipe.transform(row.departmentFunction.leadId, this.teamMembers)
               : '',
      },
      {
         def: 'departmentFunction.documentationStatus',
         label: 'Documentation Status',
         visible: true,
         value: (row) =>
            row.departmentFunction
               ? DocumentationStatus[row.departmentFunction.documentationStatus]
               : '',
      },
   ];

   teamMembers: TeamMember[] = [];
   destroyed$ = new Subject<void>();

   tableConfig: TableConfig;

   constructor(
      private documentationFacade: DocumentationFacade,
      private route: ActivatedRoute,
      private router: Router,
      private teamMemberNamePipe: TeamMemberNamePipe,
      private dialog: MatDialog,
      private roleGuard: RoleGuard
   ) {}

   ngOnInit() {
      this.documentationFacade.teamMembers$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((teamMembers) => {
            this.teamMembers = teamMembers;
         });
      this.filter$ = this.documentationFacade.functionFilter$;
      this.data$ = this.documentationFacade.functionsViewModel$;
      this.sort$ = this.documentationFacade.functionSort$;
      this.tableConfig = {
         columns: this.columns,
         visibleColumns$: this.visibleColumns$,
         data$: this.data$,
         filter$: this.filter$,
         sort$: this.sort$,
         initialSort: { direction: 'asc', active: 'departmentFunction.name' },
         primaryObject: 'departmentFunction',
         statusField: 'documentationStatus',
         teamMemberField: 'leadId',
         rowClick: (row) => this.edit(row.departmentFunction),
         sortChange: (sort) => this.documentationFacade.setFunctionSort(sort),
         options: [
            {
               label: 'Edit',
               action: (row) => this.edit(row.departmentFunction),
               condition: (row) => this.canEdit,
            },
            {
               label: 'Details',
               action: (row) => this.edit(row.departmentFunction),
               condition: (row) => !this.canEdit,
            },
            {
               label: 'Update Status',
               action: (row) => this.updateDocStatus(row.departmentFunction),
               condition: (row) => this.canEdit,
            },
            { label: 'View Report', action: (row) => this.summary(row.departmentFunction) },
            { label: 'Delete Function', action: (row) => this.delete(row.departmentFunction) },
         ],
      };
      this.roleGuard
         .canEdit([], AppAreas.Documentation)
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
         });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   edit(deptFn: DepartmentFunction) {
      this.router.navigate([deptFn.id], {
         relativeTo: this.route,
         state: {
            source: this.router.url,
         },
      });
   }

   summary(deptFn: DepartmentFunction) {
      this.router.navigate([deptFn.id, documentationRouteNames.REPORT], {
         relativeTo: this.route,
         state: {
            source: this.router.url,
         },
      });
      this.documentationFacade.selectDepartmentFunction(deptFn.id);
   }

   delete(deptFn: DepartmentFunction) {
      this.documentationFacade.deleteDepartmentFunction(deptFn);
   }

   filterFormChanged(filter: FilterValues) {
      this.documentationFacade.updateFunctionsFilter(filter);
   }

   sortChanged(sort: Sort) {
      this.documentationFacade.setFunctionSort(sort);
   }

   updateDocStatus(departmentFunction: DepartmentFunction) {
      this.selectedFunction = departmentFunction;
      this.docStatusValue = departmentFunction.documentationStatus;
      this.dialog.open(this.docStatusTemplate);
   }

   saveDocStatus() {
      const toSave = {
         ...this.selectedFunction,
         documentationStatus: this.docStatusValue,
      };
      this.documentationFacade.saveDepartmentFunction(toSave);
      this.dialog.closeAll();
   }

   setDisplayedColumns(columns: TableColumn<DepartmentFunction>[]) {
      this.documentationFacade.setDeptFnDisplayedColumns(columns.map((col) => col.def));
   }

   export(filterData: boolean) {
      this.table.export(filterData);
   }
}
