import { EntityService } from '@app/shared/services/entity.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, from } from 'rxjs';
import { UserInvite } from '@entities/user-invite';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { FirestoreService } from '@app/shared/services/firestore.service';

@Injectable()
export class UserInviteService implements EntityService<UserInvite>, OnDestroy {
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.USER_INVITES;
   entities$: Observable<UserInvite[]>;

   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<UserInvite>();

   constructor(private store: Store<State>, private firestore: FirestoreService) {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }

   ngOnDestroy() {
      this.orgEntityService.destroy();
   }

   get(orgId: string, inviteId: string) {
      return from(
         this.firestore
            .getDoc(
               this.firestore.doc(
                  COLLECTION_NAMES.ORGANIZATIONS,
                  orgId,
                  this.COLLECTION_NAME,
                  inviteId
               )
            )
            .then((ref) => ref.data())
      ) as Observable<UserInvite>;
   }

   save(entity: UserInvite): Promise<UserInvite> {
      return this.orgEntityService.save(entity);
   }
   batchSave(entities: UserInvite[]): Promise<UserInvite[]> {
      return this.orgEntityService.batchSave(entities);
   }
   delete(entity: UserInvite): Promise<void> {
      return this.orgEntityService.delete(entity);
   }
}
