import { Injectable } from '@angular/core';
import { Storage, getDownloadURL, ref, uploadBytes } from '@angular/fire/storage';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class StorageService {
   constructor(private firebaseStorage: Storage) {}

   upload(path, data) {
      return from(
         uploadBytes(ref(this.firebaseStorage, path), data).then((result) =>
            getDownloadURL(result.ref)
         )
      );
   }

   get(path): Observable<string> {
      return from(getDownloadURL(ref(this.firebaseStorage, path)));
   }
}
