<h3 mat-dialog-title>{{ title }}</h3>
<mat-dialog-content>{{ message }}</mat-dialog-content>
<mat-dialog-actions>
   <button type="button" mat-raised-button [color]="confirmColor" (click)="yes()">
      {{ confirm }}
   </button>
   <button type="button" mat-stroked-button [color]="denyColor" (click)="no()">{{ deny }}</button>
   <div class="flex-spacer"></div>
   <button *ngIf="showCancel" type="button" mat-stroked-button color="primary" (click)="close()">
      {{ cancel }}
   </button>
</mat-dialog-actions>
