import { createAction, props } from '@ngrx/store';
import { User } from '@entities/user';
import { BillingInfo } from '@entities/billing-info';
import { UserInvite } from '@entities/user-invite';
import { PermissionGroup } from '@entities/permission-group';

const SaveBillingInfo = createAction(
   '[Admin] Save billing info',
   props<{ billingInfo: BillingInfo }>()
);

const UsersUpdated = createAction('[Admin] Users updated', props<{ users: User[] }>());
const AddUser = createAction('[Admin] Add user');
const EditUser = createAction('[Admin] Edit user', props<{ user: User }>());
const CloseEditUser = createAction('[Admin] Close edit user');
const SaveUser = createAction('[Admin] Save user', props<{ user: User }>());
const DeleteUser = createAction('[Admin] Delete user', props<{ user: User }>());
const SendInvite = createAction(
   '[Admin] Send invite',
   props<{ invite: UserInvite; createTeamMember: boolean }>()
);

const DeleteInvite = createAction('[Admin] Delete invite', props<{ invite: UserInvite }>());
const DeactivateUser = createAction('[Admin] Deactivate user', props<{ userAccountId: string }>());
const ReactivateUser = createAction('[Admin] Reactivate user', props<{ userAccountId: string }>());
const PermissionGroupsUpdated = createAction(
   '[Admin] Permission groups updated',
   props<{ permissionGroups: PermissionGroup[] }>()
);
const SetDefaultPermissionGroups = createAction('[Admin] Set default permission groups');
const SavePermissionGroup = createAction(
   '[Admin] Save permission group',
   props<{ permissionGroup: PermissionGroup }>()
);
const EditPermissionGroup = createAction(
   '[Admin] Edit permission group',
   props<{ permissionGroup: PermissionGroup }>()
);
const NewPermissionGroup = createAction('[Admin] New permission group');
const AssignUsersToPermissionGroup = createAction(
   '[Admin] Assign users to permission group',
   props<{ permissionGroup: PermissionGroup }>()
);
const AddUserToGroup = createAction(
   '[Admin] Add user to permission group',
   props<{ user: User }>()
);
const DeletePermissionGroup = createAction(
   '[Admin] Delete permission group',
   props<{ permissionGroup: PermissionGroup }>()
);

export const adminActions = {
   UsersUpdated,
   SaveBillingInfo,
   AddUser,
   EditUser,
   CloseEditUser,
   SaveUser,
   DeleteUser,
   SendInvite,
   DeleteInvite,
   DeactivateUser,
   ReactivateUser,
   PermissionGroupsUpdated,
   SetDefaultPermissionGroups,
   SavePermissionGroup,
   EditPermissionGroup,
   NewPermissionGroup,
   AssignUsersToPermissionGroup,
   AddUserToGroup,
   DeletePermissionGroup,
};
