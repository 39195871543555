import { Component, OnInit, OnDestroy } from '@angular/core';
import { RockStatus } from '@entities/enums/rock-status';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';
import { Observable, combineLatest, Subject } from 'rxjs';
import { map, takeUntil, startWith } from 'rxjs/operators';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import * as moment from 'moment';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ChartData } from 'chart.js';

@Component({
   selector: 'app-rocks-widget',
   templateUrl: './rocks-widget.component.html',
   styleUrls: ['./rocks-widget.component.scss'],
})
export class RocksWidgetComponent implements OnInit, OnDestroy {
   chartLabels = Object.keys(RockStatus).filter((s) => isNaN(+s));
   statusKeys = Object.keys(RockStatus)
      .map((s) => +s)
      .filter((s) => !isNaN(s));
   chartOptions = {
      title: {
         display: false,
         text: 'Rocks by status',
      },
   };

   colors: string[] = [
      '#9e9e9e', // Not Started
      '#236192', // On Track
      '#d32f2f', // Off Track
      '#ff6f00', // On Hold
      '#009b77', // Pending Approval
      '#00594f', // Completed
   ];

   data$: Observable<ChartData<'doughnut'>>;

   businessUnits: BusinessUnit[] = [];
   departments: Department[] = [];
   filteredDepartments: Department[] = [];
   form: UntypedFormGroup;

   private destroyed$ = new Subject<void>();

   constructor(private dashboardFacade: DashboardFacade) {}

   ngOnInit() {
      const now = moment();
      const quarter = `${now.year()}Q${now.quarter()}`;
      this.form = new UntypedFormGroup({
         businessUnits: new UntypedFormControl(),
         departments: new UntypedFormControl(),
         quarter: new UntypedFormControl([quarter]),
      });
      this.dashboardFacade.businessUnits$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((businessUnits) => {
            this.businessUnits = businessUnits;
         });
      this.dashboardFacade.departments$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((departments) => {
            this.departments = departments;
         });

      combineLatest([
         this.dashboardFacade.departments$,
         this.form.get('businessUnits').valueChanges.pipe(startWith([])),
      ])
         .pipe(
            takeUntil(this.destroyed$),
            map(([departments, businessUnits]) => {
               if (businessUnits && businessUnits.length > 0) {
                  const businessUnitIds = businessUnits.map((b) => b.id);
                  departments = departments.filter((department) =>
                     businessUnitIds.includes(department.businessUnitId)
                  );
                  let selectedDepartments = this.form.get('departments').value;
                  if (selectedDepartments) {
                     selectedDepartments = selectedDepartments.filter((dept) =>
                        businessUnitIds.includes(dept.businessUnitId)
                     );
                     this.form.get('departments').setValue(selectedDepartments);
                  }
               }
               return departments.sort((a, b) => (a.name || '').localeCompare(b.name));
            })
         )
         .subscribe((departments) => {
            this.filteredDepartments = departments;
         });

      this.data$ = combineLatest([
         this.dashboardFacade.rocks$,
         this.form.valueChanges.pipe(startWith(this.form.value)),
      ]).pipe(
         takeUntil(this.destroyed$),
         map(([rocks, formVal]) => {
            const data = [];
            if (rocks) {
               let filtered = [...rocks];
               if (formVal.departments && formVal.departments.length > 0) {
                  filtered = filtered.filter((rock) =>
                     formVal.departments.some((dept) => dept.id === rock.departmentId)
                  );
               } else if (formVal.businessUnits && formVal.businessUnits.length > 0) {
                  const matchingDepartments = this.departments.filter((dept) =>
                     formVal.businessUnits.some((bu) => bu.id === dept.businessUnitId)
                  );
                  filtered = filtered.filter((rock) =>
                     matchingDepartments.some((dept) => dept.id === rock.departmentId)
                  );
               }
               if (formVal.quarter && formVal.quarter.length > 0) {
                  filtered = filtered.filter((rock) => formVal.quarter.includes(rock.quarter));
               }
               this.statusKeys.forEach((key) => {
                  const rockCount = filtered.filter((rock) => rock.status == key).length;
                  data.push(rockCount);
               });
            }
            return {
               labels: this.chartLabels,
               datasets: [{ data, backgroundColor: this.colors }],
            };
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   goToRocks(event?: any) {
      let filter = null;
      if (event) {
         const target = event.active[0].index;
         filter = {
            ...this.form.value,
            status: [target],
         };
      }
      this.dashboardFacade.goToRocks(filter);
   }

   idCompare(a: { id: string }, b: { id: string }) {
      return a && b && a.id == b.id;
   }

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }
}
