import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { Observable, Subject } from 'rxjs';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { TeamMember } from '@entities/team-member';
import { takeUntil } from 'rxjs/operators';
import { Task } from '@entities/task';

@Component({
   selector: 'app-org-chart',
   templateUrl: './org-chart.component.html',
   styleUrls: ['./org-chart.component.scss'],
})
export class OrgChartComponent implements OnInit, OnDestroy {
   businessUnits$: Observable<BusinessUnit[]>;
   orgName$: Observable<string>;

   teamMembers: TeamMember[] = [];
   expandedBusinessUnits: string[] = [];
   expandedDepartments: string[] = [];
   expandedDepartmentFunctions: string[] = [];

   private destroyed$ = new Subject<void>();

   constructor(private facade: OrgBuilderFacade) {}

   ngOnInit(): void {
      this.businessUnits$ = this.facade.orgTree$;
      this.orgName$ = this.facade.orgName$;
      this.facade.teamMembers$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getWidth(businessUnit: BusinessUnit) {
      if (
         this.businessUnitExpanded(businessUnit) &&
         businessUnit.departments &&
         businessUnit.departments.length > 0
      ) {
         return 224 * businessUnit.departments.length;
      } else {
         return 224;
      }
   }

   toggleExpanded(departmentFunction: DepartmentFunction) {
      if (this.departmentFunctionExpanded(departmentFunction)) {
         this.expandedDepartmentFunctions = this.expandedDepartmentFunctions.filter(
            (id) => id != departmentFunction.id
         );
      } else {
         this.expandedDepartmentFunctions.push(departmentFunction.id);
      }
   }

   departmentFunctionExpanded(departmentFunction: DepartmentFunction) {
      return this.expandedDepartmentFunctions.includes(departmentFunction.id);
   }

   businessUnitExpanded(businessUnit: BusinessUnit) {
      return this.expandedBusinessUnits.includes(businessUnit.id);
   }

   toggleBusinessUnit(businessUnit: BusinessUnit) {
      if (this.businessUnitExpanded(businessUnit)) {
         this.expandedBusinessUnits = this.expandedBusinessUnits.filter(
            (id) => id != businessUnit.id
         );
      } else {
         this.expandedBusinessUnits.push(businessUnit.id);
      }
   }

   departmentExpanded(department: Department) {
      return this.expandedDepartments.includes(department.id);
   }

   toggleDepartment(department: Department) {
      if (this.departmentExpanded(department)) {
         this.expandedDepartments = this.expandedDepartments.filter((id) => id != department.id);
      } else {
         this.expandedDepartments.push(department.id);
      }
   }

   back() {
      history.back();
   }

   showBusinessUnitDetails(businessUnit: BusinessUnit) {
      this.facade.editBusinessUnit(businessUnit);
   }

   showDepartmentDetails(department: Department) {
      this.facade.editDepartment(department);
   }

   showDepartmentFunctionDetails(departmentFunction: DepartmentFunction) {
      this.facade.editDepartmentFunction(departmentFunction);
   }

   showTaskDetails(task: Task) {
      this.facade.editTask(task);
   }
}
