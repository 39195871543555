<div class="overview-container">
  <div class="tile-row">
    <div class="overview-tile">
      <div class="overview-heading">
        Guiding Beliefs
      </div>
      <div class="overview-content" [innerHTML]="overview.beliefs"></div>
    </div>
    <div class="overview-tile">
      <div class="overview-heading">
        Purpose
      </div>
      <div class="overview-content" [innerHTML]="overview.purpose"></div>
    </div>
  </div>
  <div class="tile-row">
    <div class="overview-tile">
      <div class="overview-heading">
        Core Values
      </div>
      <div class="overview-content" [innerHTML]="overview.coreValues"></div>
    </div>
    <div class="overview-tile">
      <div class="overview-heading">
        Vision Statement
      </div>
      <div class="overview-content" [innerHTML]="overview.visionStatement"></div>
    </div>
  </div>
</div>
