<div class="loading-overlay" *ngIf="uploading">
   <div class="uploading-message">
      <div>Uploading Image...</div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
   </div>
</div>
<app-sticky-header
   [title]="editAction + ' Task'"
   [saveDisabled]="form.pristine || !form.valid"
   [isNew]="!form.get('id').value"
   [report]="true"
   [canEdit]="canEdit"
   (save)="save()"
   (back)="back()"
   (delete)="delete()"
   (showReport)="print()"
></app-sticky-header>
<form [formGroup]="form">
   <mat-card>
      <mat-card-title>
         <div class="breadcrumbs">
            <ng-container *ngIf="businessUnitName">
               <div class="breadcrumb-text">
                  {{ businessUnitName }}
               </div>
               <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
            </ng-container>
            <ng-container *ngIf="departmentName">
               <div class="breadcrumb-text">
                  {{ departmentName }}
               </div>
               <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
            </ng-container>
            <ng-container *ngIf="departmentFunctionName">
               <div class="breadcrumb-text">
                  {{ departmentFunctionName }}
               </div>
               <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
            </ng-container>
         </div>
         <div class="task-name-container">
            <ng-container *ngIf="showEditName; else title">
               <div class="field-container">
                  <mat-form-field class="form-field">
                     <input appAutoFocus matInput required type="text" formControlName="name" />
                  </mat-form-field>
                  <button mat-icon-button (click)="updateName()">
                     <mat-icon>done</mat-icon>
                  </button>
                  <button mat-icon-button (click)="cancelUpdateName()">
                     <mat-icon>close</mat-icon>
                  </button>
               </div>
            </ng-container>
            <ng-template #title>
               <div class="task-name">
                  {{ form.value.name }}
               </div>
               <button mat-icon-button *ngIf="canEdit" (click)="editName()">
                  <mat-icon>edit</mat-icon>
               </button>
            </ng-template>
         </div>
      </mat-card-title>
      <mat-card-content>
         <div class="row">
            <div class="col-md-6">
               <mat-form-field>
                  <input
                     matInput
                     type="text"
                     placeholder="Purpose"
                     formControlName="purpose"
                     [readonly]="!canEdit"
                  />
               </mat-form-field>
            </div>
            <div class="col-md-6">
               <mat-form-field>
                  <input
                     matInput
                     type="text"
                     placeholder="KPI"
                     formControlName="kpi"
                     [readonly]="!canEdit"
                  />
               </mat-form-field>
            </div>
         </div>
         <div class="row">
            <div class="col-md-6">
               <mat-form-field>
                  <textarea
                     matInput
                     placeholder="Policies"
                     formControlName="policies"
                     [readonly]="!canEdit"
                  ></textarea>
               </mat-form-field>
            </div>
            <div class="col-md-6">
               <mat-form-field>
                  <textarea
                     matInput
                     placeholder="Benchmark"
                     formControlName="benchmark"
                     [readonly]="!canEdit"
                  ></textarea>
               </mat-form-field>
            </div>
         </div>
         <div class="row">
            <div class="col-md-6">
               <mat-form-field>
                  <textarea
                     matInput
                     placeholder="Competencies"
                     formControlName="competencies"
                     [readonly]="!canEdit"
                  ></textarea>
               </mat-form-field>
            </div>
         </div>

         <mat-tab-group>
            <mat-tab label="Procedure">
               <mat-list class="task-list" cdkDropList (cdkDropListDropped)="drop($event)">
                  <mat-card
                     class="list-item mat-elevation-z2"
                     *ngFor="
                        let step of stepControls();
                        let i = index;
                        first as isFirst;
                        last as isLast
                     "
                     formArrayName="steps"
                     cdkDrag
                     [cdkDragDisabled]="!canEdit"
                  >
                     <div class="placeholder" *cdkDragPlaceholder></div>
                     <div class="handle" cdkDragHandle *ngIf="canEdit">
                        <mat-icon svgIcon="drag-vertical"></mat-icon>
                     </div>
                     <div [formGroup]="stepControl(i)" class="list-item-content">
                        <div class="step-row">
                           <div class="step-number">{{ i + 1 }}</div>
                           <mat-form-field class="step-name-input">
                              <input
                                 matInput
                                 type="text"
                                 placeholder="Step name"
                                 formControlName="name"
                                 required
                                 #stepName
                                 [readonly]="!canEdit"
                              />
                           </mat-form-field>
                           <button
                              mat-icon-button
                              matTooltip="Remove Step"
                              *ngIf="canEdit"
                              (click)="remove(i)"
                           >
                              <mat-icon>delete</mat-icon>
                           </button>
                        </div>
                        <mat-expansion-panel #panel>
                           <mat-expansion-panel-header
                              class="instructions-panel-header"
                              (click)="focusInstructions(i, panel.expanded)"
                           >
                              <mat-panel-title>
                                 {{ panel.expanded ? 'Hide' : 'Show' }} Instructions
                              </mat-panel-title>
                              <mat-panel-description></mat-panel-description>
                           </mat-expansion-panel-header>
                           <textarea
                              [id]="'instructions-' + i"
                              class="instructions"
                              #instructions
                              [froalaEditor]="configs[i]"
                              (froalaInit)="initFroala($event, i)"
                              formControlName="instructions"
                              *ngIf="canEdit; else noEdit"
                           ></textarea>
                           <ng-template #noEdit>
                              <div
                                 class="instructions"
                                 [innerHTML]="stepControls()[i].get('instructions').value"
                              ></div>
                           </ng-template>
                        </mat-expansion-panel>
                     </div>
                  </mat-card>
               </mat-list>
               <div class="add-row">
                  <button
                     mat-icon-button
                     color="primary"
                     class="add-button"
                     *ngIf="canEdit"
                     (click)="addStep()"
                  >
                     <mat-icon>add</mat-icon>
                     Add step
                  </button>
               </div>
            </mat-tab>
            <mat-tab label="Assignees" *ngIf="taskId">
               <app-assigned-team-members
                  [teamMembers]="assignedTeamMembers$"
                  [canEdit]="canEdit"
                  (assign)="assignTeamMembers()"
                  (unassign)="unassignTeamMember($event)"
                  (view)="viewTeamMember($event)"
               ></app-assigned-team-members>
            </mat-tab>
         </mat-tab-group>
      </mat-card-content>
   </mat-card>
</form>
