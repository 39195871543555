import { Action, createReducer, on } from '@ngrx/store';
import { searchActions } from './search.actions';
import { SearchRequest } from '@entities/search-request';
import { state } from '@angular/animations';

export const searchFeatureKey = 'search';

export interface State {
   request: SearchRequest;
   results: any[];
   requestHistory: SearchRequest[];
   resultsHistory: any[];
   loaded: boolean;
   error?: any;
   term: string;
}

export const searchInitialState: State = {
   request: null,
   results: [],
   requestHistory: [],
   resultsHistory: [],
   loaded: false,
   error: null,
   term: null,
};

const searchReducer = createReducer(
   searchInitialState,
   on(searchActions.loadSearch, (state, { request }) => {
      const requestHistory = state.request
         ? [state.request, ...state.requestHistory].slice(0, 5)
         : state.requestHistory;
      return {
         ...state,
         request,
         requestHistory,
      };
   }),
   on(searchActions.searchLoaded, (state, { results }) => {
      const resultsHistory = state.results
         ? [state.results, ...state.resultsHistory].slice(0, 5)
         : state.resultsHistory;
      return {
         ...state,
         results,
         resultsHistory,
         loaded: true,
      };
   }),
   on(searchActions.searchError, (state, { error }) => {
      return {
         ...state,
         error,
      };
   }),
   on(searchActions.quickSearch, (state, { term }) => {
      return {
         ...state,
         term,
      };
   })
);

export function reducer(state: State | undefined, action: Action) {
   return searchReducer(state, action);
}
