<div class="list-container">
   <div class="title-container">
      <div class="h5 title">Departments</div>
      <button
         mat-icon-button
         color="primary"
         class="add-button"
         (click)="addDepartment()"
         matTooltip="New Department"
         matTooltipPosition="before"
         *ngIf="
            (canEdit$ | async) && (businessUnitId$ | async) && (departments$ | async).length != 0
         "
      >
         <mat-icon>add</mat-icon>
      </button>
   </div>

   <div class="card-container sidebar">
      <ng-container *ngIf="businessUnitId$ | async">
         <mat-card class="org-list-card">
            <mat-nav-list
               id="department-list"
               class="org-nav-list"
               cdkDropList
               (cdkDropListDropped)="drop($event)"
            >
               <ng-container
                  *ngFor="let dept of departments$ | async; first as isFirst; last as isLast"
               >
                  <div
                     class="list-item"
                     [ngClass]="{ first: isFirst, last: isLast }"
                     cdkDrag
                     [cdkDragDisabled]="!(canEdit$ | async)"
                     (cdkDragStarted)="dragStarted($event, dept)"
                     appDropTarget
                     (dropped)="itemDropped($event, dept)"
                  >
                     <div class="placeholder" *cdkDragPlaceholder></div>
                     <div class="handle" cdkDragHandle *ngIf="canEdit$ | async">
                        <mat-icon svgIcon="drag-vertical"></mat-icon>
                     </div>
                     <mat-list-item
                        [ngClass]="{ selected: dept.id == (selectedDepartmentId$ | async) }"
                        (click)="select(dept.id)"
                     >
                        <div matLine class="title-line">
                           <div class="list-item-title">
                              <span [matTooltip]="dept.name">
                                 {{ dept.name }}
                              </span>
                           </div>
                           <button
                              mat-icon-button
                              class="list-item-options"
                              [matMenuTriggerFor]="optionsMenu"
                           >
                              <mat-icon>more_vert</mat-icon>
                           </button>
                        </div>
                        <div matLine class="person-line">
                           <div class="person">
                              <mat-icon>person</mat-icon>
                              <div class="person-name">
                                 {{ dept.managerId | teamMemberName: teamMembers }}
                              </div>
                           </div>
                           <div class="units">
                              <mat-icon svgIcon="sitemap"></mat-icon>
                              <div class="person-name">
                                 {{ getDepartmentFunctions(dept) }}
                              </div>
                           </div>
                        </div>
                     </mat-list-item>
                  </div>
                  <mat-divider *ngIf="!isLast"></mat-divider>
                  <mat-menu #optionsMenu="matMenu">
                     <button mat-menu-item *ngIf="canEdit$ | async" (click)="edit(dept)">
                        Edit
                     </button>
                     <button mat-menu-item *ngIf="!(canEdit$ | async)" (click)="edit(dept)">
                        View Details
                     </button>
                     <button mat-menu-item (click)="viewSummary(dept)">View Summary</button>
                     <button mat-menu-item *ngIf="canEdit$ | async" (click)="delete(dept)">
                        Delete
                     </button>
                  </mat-menu>
               </ng-container>
            </mat-nav-list>
         </mat-card>
         <button
            mat-button
            color="primary"
            *ngIf="(businessUnitId$ | async) && (canEdit$ | async)"
            (click)="addDepartment()"
         >
            <mat-icon>add</mat-icon>
            New Department
         </button>
         <ng-container *ngIf="(departments$ | async).length == 0 && !(canEdit$ | async)">
            No departments to show
         </ng-container>
      </ng-container>
      <div *ngIf="!(businessUnitId$ | async)">Please select a business unit to see departments</div>
   </div>
</div>
