import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingsTemplateComponent } from './components/settings/settings-template.component';
import { BillingInfoComponent } from './components/billing-info/billing-info.component';
import { SharedModule } from '@app/shared/shared.module';
import { AdminEffects } from './state/admin.effects';
import { AdminFacade } from './state/admin.facade';
import { adminReducer, adminFeatureKey } from './state/admin.reducer';
import { UserInviteComponent } from './components/user-invite/user-invite.component';
import { UserInviteService } from './services/user-invite.service';
import { OrgSignupComponent } from './components/org-signup/org-signup.component';
import { OrgInfoComponent } from './components/org-info/org-info.component';
import { DeleteOrganizationComponent } from './components/delete-organization/delete-organization.component';
import { PlanSelectComponent } from './components/plan-select/plan-select.component';
import { CompetencyLevelsComponent } from './components/competency-levels/competency-levels.component';
import { ResetPerformanceRatingsComponent } from './components/reset-performance-ratings/reset-performance-ratings.component';
import { ManagePermissionsComponent } from './components/manage-permissions/manage-permissions.component';
import { PermissionLevelIconComponent } from './components/permission-level-icon/permission-level-icon.component';
import { PermissionGroupService } from './services/permission-group.service';
import { PermissionGroupEditComponent } from './components/permission-group-edit/permission-group-edit.component';
import { PermissionGroupAssignUsersComponent } from './components/permission-group-assign-users/permission-group-assign-users.component';
import { UnassignedUsersWarningComponent } from './components/unassigned-users-warning/unassigned-users-warning.component';
import { PermissionGroupMembershipTableComponent } from './components/permission-group-membership-table/permission-group-membership-table.component';

@NgModule({
   declarations: [
      UserListComponent,
      UserEditComponent,
      SettingsComponent,
      SettingsTemplateComponent,
      BillingInfoComponent,
      UserInviteComponent,
      OrgSignupComponent,
      OrgInfoComponent,
      DeleteOrganizationComponent,
      PlanSelectComponent,
      CompetencyLevelsComponent,
      ResetPerformanceRatingsComponent,
      ManagePermissionsComponent,
      PermissionLevelIconComponent,
      PermissionGroupEditComponent,
      PermissionGroupAssignUsersComponent,
      UnassignedUsersWarningComponent,
      PermissionGroupMembershipTableComponent,
   ],
   imports: [
      CommonModule,
      SharedModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      StoreModule.forFeature(adminFeatureKey, adminReducer),
      EffectsModule.forFeature([AdminEffects]),
   ],
   providers: [AdminFacade, PermissionGroupService, UserInviteService],
})
export class AdminModule {}
