import { Pipe, PipeTransform } from '@angular/core';
import { Rock } from '@entities/rock';

@Pipe({
   name: 'rockName',
   pure: true,
})
export class RockNamePipe implements PipeTransform {
   transform(value?: string, rocks: Rock[] = []): any {
      const rock = rocks.find((t) => t.id == value);
      return rock ? rock.name : '';
   }
}
