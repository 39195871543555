import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem('authToken');
    if (this.isApiRequest(req)) {
      const request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });
      return next.handle(request);
    } else {
      return next.handle(req);
    }
  }

  isApiRequest(req: HttpRequest<any>): boolean {
    return req.url.startsWith(this.configService.getConfig().apiUrl);
  }
}
