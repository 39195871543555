import { UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { DialogService } from './dialog.service';
import { map } from 'rxjs/operators';
import { UserCommentsComponent } from '../components/user-comments/user-comments.component';
import { State, navigationCancelled } from '@app/app.state';
import { Store } from '@ngrx/store';

export interface SaveChangesComponent {
   form: UntypedFormGroup;
   commentsComponent?: UserCommentsComponent;
   saving?: boolean;
   save: (onDeactivate: boolean) => any;
}

@Injectable()
export class SaveChangesGuard implements CanDeactivate<SaveChangesComponent> {
   constructor(private dialogService: DialogService, private store: Store<State>) {}

   canDeactivate(component: SaveChangesComponent) {
      if (
         !component.saving &&
         (component.form.dirty ||
            (component.commentsComponent && component.commentsComponent.dirty))
      ) {
         return this.dialogService
            .showConfirmDialog({
               title: 'Unsaved changes',
               message: 'Would you like to save your changes?',
               confirm: 'Yes',
               deny: 'No',
               cancel: 'Cancel',
               confirmColor: 'primary',
               denyColor: 'accent',
            })
            .afterClosed()
            .pipe(
               map((data) => {
                  if (data == null) {
                     this.store.dispatch(navigationCancelled());
                     return false;
                  }
                  if (data == true) {
                     if (component.commentsComponent) {
                        component.commentsComponent.saveAll();
                     }
                     component.save(true);
                  }
                  return true;
               })
            );
      } else {
         return true;
      }
   }
}
