import { Component, OnInit, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { Observable } from 'rxjs';
import { TeamMember } from '@entities/team-member';
import { Task } from '@entities/task';
import { getSelectedTask } from '@app/org-builder/state/organization.state';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, RequiredValidator, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';

@Component({
  selector: 'app-assign-team-member',
  templateUrl: './assign-team-member.component.html',
  styleUrls: ['./assign-team-member.component.scss']
})
export class AssignTeamMemberComponent implements OnInit {
  teamMembers$: Observable<TeamMember[]>;
  task: Task;
  selectedTeamMember: TeamMember;

  form: UntypedFormGroup;

  public get teamMemberControls() {
    return (<UntypedFormArray>this.form.get('teamMembers')).controls;
  }
  private orgBuilderService: OrgBuilderFacade;

  constructor(@Inject(MAT_DIALOG_DATA) data: { task: Task; service: OrgBuilderFacade }) {
    this.task = data.task;
    this.orgBuilderService = data.service;
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      teamMembers: new UntypedFormArray([])
    });
    this.addTeamMember();
  }

  setTeamMember(event: MatAutocompleteSelectedEvent, index: number) {
    if (event) {
      (<UntypedFormArray>this.form.get('teamMembers')).at(index).setValue(event.option.value);
    } else {
      this.selectedTeamMember = null;
    }
  }

  addTeamMember() {
    (<UntypedFormArray>this.form.get('teamMembers')).push(new UntypedFormControl(null, Validators.required));
  }

  removeTeamMember(index: number) {
    (<UntypedFormArray>this.form.get('teamMembers')).removeAt(index);
    if (this.teamMemberControls.length == 0) {
      this.addTeamMember();
    }
  }

  assign() {
    const formValue = this.form.value;
    this.orgBuilderService.saveTaskAssignments(this.task, formValue.teamMembers);
  }

  close() {
    this.orgBuilderService.cancelAssignTaskToTeamMember();
  }
}
