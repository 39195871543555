import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { Organization } from '@entities/organization';
import { AuthUser } from '@entities/auth-user';

import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { FunctionNames } from '@app/utilities/functionNames';
import { ErrorService } from '@app/shared/services/error.service';
import { BillingInfoComponent } from '../billing-info/billing-info.component';
import { FunctionsService } from '@app/shared/services/functions.service';

@Component({
   selector: 'app-org-signup',
   templateUrl: './org-signup.component.html',
   styleUrls: ['./org-signup.component.scss'],
})
export class OrgSignupComponent implements OnInit {
   orgForm: UntypedFormGroup;
   productForm: UntypedFormGroup;
   cardInfo: any;
   orgInfo: any;
   authUser$: Observable<AuthUser>;
   showSpinner = false;

   @ViewChild(MatStepper) stepper: MatStepper;
   @ViewChild(BillingInfoComponent) billingInfo: BillingInfoComponent;

   constructor(
      private userOrgFacade: UserOrgFacade,
      private functions: FunctionsService,
      private errorService: ErrorService
   ) {}

   ngOnInit() {
      this.orgForm = new UntypedFormGroup({
         valid: new UntypedFormControl(null, [Validators.required]),
      });
      this.productForm = new UntypedFormGroup({
         plans: new UntypedFormControl(null, [Validators.required]),
         coupon: new UntypedFormControl(),
         billingFrequency: new UntypedFormControl(),
      });
      this.authUser$ = this.userOrgFacade.authUser$;
   }

   setOrgInfo(event: any) {
      this.orgInfo = event;
      this.orgForm.get('valid').setValue(true);
      this.stepper.next();
   }

   setPlan(event: any) {
      this.productForm.patchValue(event);
      this.stepper.next();
   }

   setCardInfo(event: any) {
      if (event && event.card) {
         this.showSpinner = true;
         this.cardInfo = event.card;
         const products = this.productForm.value;
         this.functions
            .httpsCallable(FunctionNames.CREATE_CUSTOMER)({
               email: this.orgInfo.primaryContact.email,
               name: this.orgInfo.name,
               source: event.id,
               plans: products.plans,
               coupon: products.coupon || null,
            })
            .then((result: any) => {
               if (result.data.customer && result.data.subscription) {
                  this.createOrg(result.data);
               } else {
                  this.errorService.handleError(result.error);
                  this.billingInfo.submitted = false;
                  this.showSpinner = false;
               }
            });
      }
   }

   createOrg(result: { customer: any; subscription: any }) {
      const org = <Organization>this.orgInfo;
      org.billingInfo = {
         ...this.productForm.value,
         customerId: result.customer.id,
         subscriptionId: result.subscription.id,
         name: this.cardInfo.name,
         address: this.cardInfo.address_line1,
         city: this.cardInfo.address_city,
         state: this.cardInfo.address_state,
         zip: this.cardInfo.address_zip,
         last4: this.cardInfo.last4,
      };
      const items = result.subscription.items.data;
      items.forEach((item) => {
         const planDetails = org.billingInfo.plans.find((p) => p.planId == item.plan.id);
         if (planDetails) {
            planDetails.itemId = item.id;
         }
      });
      org.billingInfo.termsAccepted = moment().toISOString();
      org.billingInfo.coupon = org.billingInfo.coupon || null;

      this.userOrgFacade.createOrganization(org);
   }
}
