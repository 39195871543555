import { Injectable } from '@angular/core';
import { parse, unparse } from 'papaparse';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
   providedIn: 'root',
})
export class CsvService {
   constructor(private sanitizer: DomSanitizer) {}

   export(data: any[], filename: string = 'export.csv') {
      const csv = unparse(data);
      const toEncode = `data:text/csv;charset=utf-8,${csv}`;
      const uri = encodeURI(toEncode);
      const downloadLink = document.createElement('a');
      if (!filename.endsWith('.csv')) {
         filename = filename + '.csv';
      }
      downloadLink.setAttribute('href', uri as string);
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
   }
}
