import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TaskRatingTreeItem } from '@app/team/state/team.state';
import { CompetencyLevel } from '@entities/competency-level';
import { TaskRating } from '@entities/task-rating';
import { ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
   selector: 'app-competency-dashboard-donut-chart',
   templateUrl: './competency-dashboard-donut-chart.component.html',
   styleUrls: ['./competency-dashboard-donut-chart.component.scss'],
})
export class CompetencyDashboardDonutChartComponent implements OnInit, OnChanges {
   @Input() competencyLevels: CompetencyLevel[];
   @Input() items: Array<TaskRating | TaskRatingTreeItem>;

   @ViewChild(BaseChartDirective) chart: BaseChartDirective;

   chartLabels = [];
   chartOptions = {
      title: {
         display: false,
         text: 'Rocks by status',
      },
   };
   colors: any[] = [
      '#9e9e9e', // Not Started
      '#236192', // Pending
      '#71cc98', // Tasks Complete
      '#009b77', // Steps Complete
      '#00594f', // Approved
      '#ff6f00', // Needs Revision
   ];
   data: number[] = [];
   chartData: ChartData<'doughnut'> = {
      labels: this.chartLabels,
      datasets: [
         {
            data: this.data,
         },
      ],
   };
   noRating = { label: 'No Rating', value: 0, description: 'No rating set' } as CompetencyLevel;
   levels: CompetencyLevel[] = [this.noRating];

   constructor() {}

   ngOnInit(): void {
      if (this.competencyLevels) {
         this.levels = [this.noRating, ...this.competencyLevels];
         this.chartLabels = this.levels.map((level) => level.label);
         this.chartData.labels = this.chartLabels;
         this.refreshChart();
      }
      if (this.items) {
         this.setData();
      }
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['competencyLevels']) {
         this.levels = [this.noRating, ...this.competencyLevels];
         this.chartLabels = this.levels.map((level) => level.label);
      }
      if (changes['items']) {
         this.setData();
      }
   }

   setData() {
      const data = [];
      this.levels?.forEach((level) => {
         const matchingRatings = this.items.filter(
            (item) => this.getRatingValue(item.rating) === level.value
         ).length;
         data.push(matchingRatings);
      });
      this.data = data;
      this.chartData.datasets = [
         {
            data,
            backgroundColor: this.colors,
         },
      ];
      this.refreshChart();
   }

   getRatingValue(rating: number) {
      if (rating > 0) {
         return Math.round(rating);
      } else {
         return 0;
      }
   }

   refreshChart() {
      this.chart?.ngOnChanges({});
   }
}

