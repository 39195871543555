import {
   Component,
   OnInit,
   OnDestroy,
   ElementRef,
   AfterViewInit,
   ViewChildren,
   QueryList,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Overview } from '@entities/overview';
import { RoleGuard } from '@app/admin/services/role.guard';
import { OverviewService } from '@app/overview/overview.service';
import { froalaConfig } from '@app/shared/config/froala.config';
import { takeUntil } from 'rxjs/operators';
import { ProductType } from '@entities/enums/product-type';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { Router, ActivatedRoute } from '@angular/router';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-overview',
   templateUrl: './overview.component.html',
   styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, AfterViewInit, OnDestroy, SaveChangesComponent {
   @ViewChildren('editorContainer') editorContainers: QueryList<ElementRef>;
   // @HostListener('window:resize') onResize = this.resizeEditors;

   form: UntypedFormGroup;

   overview: Overview;
   canEdit$: Observable<boolean>;
   saving = false;

   editors: any[] = [];
   editorConfigs = [
      {
         ...froalaConfig,
         editorClass: 'editor',
         toolbarInline: true,
         inlineMode: false,
         minHeight: 100,
         maxHeight: 500,
      },
      {
         ...froalaConfig,
         editorClass: 'editor',
         toolbarInline: true,
         inlineMode: false,
         minHeight: 100,
         maxHeight: 500,
      },
      {
         ...froalaConfig,
         editorClass: 'editor',
         toolbarInline: true,
         inlineMode: false,
         minHeight: 100,
         maxHeight: 500,
      },
      {
         ...froalaConfig,
         editorClass: 'editor',
         toolbarInline: true,
         inlineMode: false,
         minHeight: 100,
         maxHeight: 500,
      },
   ];

   private destroyed$ = new Subject<void>();

   constructor(
      private roleGuard: RoleGuard,
      private overviewService: OverviewService,
      private router: Router,
      private route: ActivatedRoute
   ) {
      this.form = new UntypedFormGroup({
         overviewId: new UntypedFormControl(),
         organizationId: new UntypedFormControl(),
         beliefs: new UntypedFormControl(),
         purpose: new UntypedFormControl(),
         coreValues: new UntypedFormControl(),
         visionStatement: new UntypedFormControl(),
      });
   }

   ngOnInit() {
      this.canEdit$ = this.roleGuard.canEdit(
         [ProductType.Evolve, ProductType.Process],
         AppAreas.Overview
      );
      this.overviewService.overview$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
         this.overview = data;
         this.saving = false;
         if (data) {
            this.form.patchValue(data);
            this.form.markAsPristine();
         } else {
            this.form.reset();
         }
      });
   }

   ngAfterViewInit() {
      // this.resizeEditors();
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   resizeEditors() {
      const containers = this.editorContainers.toArray();
      for (let i = 0; i < containers.length; i++) {
         const container = containers[i];
         const editor = this.editors[i];
         if (container && editor) {
            editor.opts.height = container.nativeElement.clientHeight;
            // editor.size.refresh();
         }
      }
   }

   save() {
      const overview: Overview = this.form.value;
      this.saving = true;
      this.overviewService.saveOverview(overview);
   }

   initFroala(controls, index) {
      controls.initialize();
      this.editors[index] = controls.getEditor();
   }

   viewSummary() {
      this.router.navigate(['report'], { relativeTo: this.route });
   }
}
