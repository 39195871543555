import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { withLatestFrom, switchMap, map, catchError, mergeMap, tap } from 'rxjs/operators';

import { of, from } from 'rxjs';
import * as ActionItemActions from './action-item.actions';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { ActionItemService } from '@app/evolve/services/action-item.service';
import { ErrorService } from '@app/shared/services/error.service';
import { ActionItem } from '@entities/action-item';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { appRoutesNames } from '@app/app.routes.names';
import { evolveRouteNames } from '@app/evolve/evolve.routes.names';
import { Rock } from '@entities/rock';
import * as moment from 'moment';
import { Status } from '@entities/enums/status';
import { RockStatus } from '@entities/enums/rock-status';
import { RockService } from '@app/evolve/services/rock.service';
import { RockActions } from '../rock/rock.actions';
@Injectable()
export class ActionItemEffects {
   constructor(
      private actions$: Actions,
      private store: Store<State>,
      private actionItemService: ActionItemService,
      private errorService: ErrorService,
      private snackBar: MatSnackBar,
      private router: Router,
      private rockService: RockService
   ) {}

   editActionItem$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(ActionItemActions.AddActionItem, ActionItemActions.EditActionItem),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([action, orgId]) => {
               const id = action['actionItemId'] || 'new';
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.EVOLVE,
                  evolveRouteNames.ISSUES,
                  id,
               ]);
            })
         ),
      { dispatch: false }
   );

   saveActionItem$ = createEffect(() =>
      this.actions$.pipe(
         ofType(ActionItemActions.SaveActionItem),
         withLatestFrom(this.store.pipe(select(getOrganizationId))),
         mergeMap(([action, orgId]) => {
            return from(this.actionItemService.save(action.actionItem)).pipe(
               map((actionItem) => {
                  if (!action.hideSnackBar) {
                     this.snackBar.open('Action Saved');
                     if (action.actionItem.id == null && !action.preventNavigation) {
                        this.router.navigate([
                           appRoutesNames.ORGANIZATION,
                           orgId,
                           appRoutesNames.EVOLVE,
                           evolveRouteNames.ISSUES,
                           actionItem.id,
                        ]);
                     }
                  }
                  return ActionItemActions.SelectActionItem({ actionItemId: actionItem.id });
               }),
               catchError((err) => {
                  this.errorService.handleError(err);
                  return of();
               })
            );
         })
      )
   );

   deleteActionItem$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(ActionItemActions.DeleteActionItem),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([action, orgId]) => {
               this.actionItemService
                  .delete(action.actionItem)
                  .then(() => {
                     this.router.navigate([
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.EVOLVE,
                        evolveRouteNames.ISSUES,
                     ]);
                  })
                  .catch((err) => {
                     this.errorService.handleError(err);
                  });
            })
         ),
      { dispatch: false }
   );

   convertToRock$ = createEffect(() =>
      this.actions$.pipe(
         ofType(ActionItemActions.ConvertActionToRock),
         switchMap(({ action, deleteAction }) => {
            const quarter = action.targetDate
               ? `${moment(action.targetDate).year()}Q${moment(action.targetDate).quarter()}`
               : 'Pending';
            let status;
            switch (action.status) {
               case Status['Not Started']:
                  status = RockStatus['Not Started'];
                  break;
               case Status['In Progress']:
                  status = RockStatus['On Track'];
                  break;
               case Status.Completed:
                  status = RockStatus.Completed;
                  break;
            }
            const rock: Rock = {
               id: action.id,
               name: action.name,
               description: action.description,
               startDate: action.startDate,
               targetDate: action.targetDate,
               completedDate: action.completedDate,
               comments: action.comments,
               assigneeId: action.assigneeId,
               departmentId: action.departmentId,
               actions: [],
               quarter,
               status,
            } as Rock;

            return from(this.rockService.save(rock, { overwrite: true })).pipe(
               tap(() => {
                  if (deleteAction) {
                     this.actionItemService.delete(action);
                  }
               }),
               map(() => RockActions.EditRock({ rock }))
            );
         })
      )
   );
}
