import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'propertyDisplay',
})
export class PropertyDisplayPipe implements PipeTransform {
   transform(value: string, ...args: unknown[]): string {
      const idStripped = value.endsWith('Id') ? value.slice(0, value.length - 2) : value;
      const sentenceCase = idStripped.replace(/[A-Z]/g, ' $&');
      const result = sentenceCase.charAt(0).toUpperCase() + sentenceCase.slice(1);
      return result;
   }
}
