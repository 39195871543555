import { Organization } from './organization';
import { TeamMember } from './team-member';
import { Department } from './department';
import { OrgEntity } from './org-entity';

export class BusinessUnit implements OrgEntity {
   id: string;

   name: string;

   purpose: string;

   notes: string;

   organizationId: string;

   organization: Organization;

   directorId: string;

   director: TeamMember;

   departments: Department[];

   order: number;
}
