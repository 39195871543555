<h1 mat-dialog-title>Assign Users to {{ permissionGroup.name }}</h1>
<div mat-dialog-content>
   <div class="group-members">
      <div class="members-label">Group members</div>
      <mat-chip-list>
         <mat-chip
            color="primary"
            selected
            *ngFor="let member of permissionGroup.members"
            (removed)="removeUser(member)"
         >
            {{ member.firstName }} {{ member.lastName }}
            <button matChipRemove>
               <mat-icon>cancel</mat-icon>
            </button>
         </mat-chip>
      </mat-chip-list>
   </div>
   <app-user-autocomplete
      placeholder="Add user to group"
      (optionSelected)="addUser($event)"
   ></app-user-autocomplete>
</div>
<div mat-dialog-actions>
   <button mat-raised-button color="primary" [disabled]="!permissionGroup.name" (click)="assign()">
      Assign
   </button>
   <button mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
</div>

