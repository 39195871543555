<div class="title">Select an Organization</div>
<!-- <div>
   <div *ngFor="let org of orgs$ | async">
      <mat-card (click)="goToOrg(org)">
         <mat-card-content>{{ org.name }}</mat-card-content>
      </mat-card>
   </div>
</div> -->

<mat-grid-list [cols]="columns" rowHeight="100px" gutterSize="20">
   <mat-grid-tile *ngFor="let org of orgs$ | async">
      <mat-card class="org-tile" (click)="goToOrg(org)">
         <mat-card-header>
            <img *ngIf="org.logo" [src]="org.logo" mat-card-avatar />
            <mat-card-title>
               {{ org.name }}
            </mat-card-title>
         </mat-card-header>
      </mat-card>
   </mat-grid-tile>
</mat-grid-list>

<div class="button-container">
   <button mat-raised-button type="button" color="primary" (click)="createOrg()">
      Create New Organization
   </button>
</div>

<div class="spinner-backdrop" *ngIf="showSpinner">
   <mat-spinner></mat-spinner>
</div>

<ng-template #noOrgsTemplateRef>
   <mat-dialog-content>
      <div>
         You have no linked organizations. Would you like to create a new organization, or view a
         demo organization?
      </div>
   </mat-dialog-content>
   <mat-dialog-actions class="no-orgs">
      <button mat-raised-button color="primary" (click)="createOrg()">
         Create New Organization
      </button>
      <button mat-raised-button color="primary" (click)="demoOrg()">View Demo Organization</button>
   </mat-dialog-actions>
</ng-template>
