import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { PerformanceRating } from '@entities/enums/performance-rating';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { DepartmentFunction } from '@entities/department-function';
import { FormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PerformanceFacade } from '@app/performance/state/performance.facade';
import { TableService } from '@app/shared/services/table.service';
import { map } from 'rxjs/operators';
import { RoleGuard } from '@app/admin/services/role.guard';
import { TaskDocumentationViewModel } from '@app/documentation/state/documentation.models';
import { CsvService } from '@app/shared/services/csv.service';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { PerformanceTaskDetailsComponent } from '../performance-task-details/performance-task-details.component';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-performance-task-list',
   templateUrl: './performance-task-list.component.html',
   styleUrls: ['./performance-task-list.component.scss'],
})
export class PerformanceTaskListComponent implements OnInit, SaveChangesComponent {
   @ViewChildren(PerformanceTaskDetailsComponent)
   detailsComponents: QueryList<PerformanceTaskDetailsComponent>;
   filterBarConfig = {
      departments: true,
      businessUnits: true,
      departmentFunctions: true,
      rating: PerformanceRating,
   };
   filterChange$: BehaviorSubject<FilterValues> = new BehaviorSubject({});
   rating = PerformanceRating;
   ratingKeys = Object.keys(PerformanceRating)
      .map((key) => +key)
      .filter((key) => !isNaN(key));

   departmentFunctions: DepartmentFunction[];
   tasks$: Observable<TaskDocumentationViewModel[]>;
   form: UntypedFormGroup = new UntypedFormGroup({});
   allTasks: TaskDocumentationViewModel[] = [];
   filteredTasks: TaskDocumentationViewModel[] = [];

   canEdit$: Observable<boolean>;

   constructor(
      private performanceFacade: PerformanceFacade,
      private tableService: TableService,
      private roleGuard: RoleGuard,
      private csvService: CsvService
   ) {}

   ngOnInit(): void {
      this.canEdit$ = this.roleGuard.canEdit([], AppAreas.Team);
      this.tasks$ = combineLatest([
         this.performanceFacade.tasks$,
         this.filterChange$.asObservable(),
      ]).pipe(
         map(([tasks, filter]) => {
            this.allTasks = tasks;
            this.setTaskForms();
            filter = filter || {};
            if (filter.departmentFunctions && filter.departmentFunctions.length > 0) {
               tasks = tasks.filter(
                  (viewModel) =>
                     viewModel.departmentFunction &&
                     filter.departmentFunctions.some(
                        (deptFn) => deptFn.id == viewModel.departmentFunction.id
                     )
               );
            } else if (filter.departments && filter.departments.length > 0) {
               tasks = tasks.filter(
                  (viewModel) =>
                     viewModel.department &&
                     filter.departments.some(
                        (department) => department.id == viewModel.department.id
                     )
               );
            } else if (filter.businessUnits && filter.businessUnits.length > 0) {
               tasks = tasks.filter(
                  (viewModel) =>
                     viewModel.businessUnit &&
                     filter.businessUnits.some(
                        (businessUnit) => businessUnit.id == viewModel.businessUnit.id
                     )
               );
            }
            if (filter.rating && filter.rating.length > 0) {
               tasks = tasks.filter(
                  (viewModel) =>
                     (viewModel.task.rating != null &&
                        filter.rating.includes(viewModel.task.rating)) ||
                     (viewModel.task.rating == null &&
                        filter.rating.includes(PerformanceRating['No Value']))
               );
            }
            this.filteredTasks = this.tableService.filter(tasks, filter.search, ['task.name']);
            return this.filteredTasks;
         })
      );
   }

   filterFormChanged(filter: FilterValues) {
      this.filterChange$.next(filter);
   }

   setDirty(taskId: string, dirty: boolean) {
      const formControl = this.form.get(taskId);
      formControl.setValue(dirty);
      if (dirty) {
         formControl.markAsDirty();
      } else {
         formControl.markAsPristine();
      }
   }

   setTaskForms() {
      this.allTasks.forEach((viewModel) => {
         if (!this.form.get(viewModel.task.id)) {
            this.form.addControl(viewModel.task.id, new UntypedFormControl(false));
         }
      });
   }

   save(onDeactivate: boolean = false) {
      this.saveAll();
   }

   saveAll() {
      this.detailsComponents.forEach((component) => {
         if (component.form.dirty) {
            component.save();
         }
      });
   }

   trackByFn(index, item: TaskDocumentationViewModel) {
      return item.departmentFunction ? item.departmentFunction.id : index;
   }

   export(filterData: boolean) {
      const toExport = filterData ? this.mapData(this.filteredTasks) : this.mapData(this.allTasks);
      const filename = 'Task Performance';
      this.csvService.export(toExport, filename);
   }

   mapData(data: TaskDocumentationViewModel[]) {
      if (data) {
         return data.map((row) => {
            return {
               Task: row?.task.name,
               Function: row?.departmentFunction?.name,
               Department: row?.department?.name,
               'Business Unit': row?.businessUnit?.name,
               KPI: row?.task?.kpi,
               Benchmark: row?.task?.benchmark,
               Rating: PerformanceRating[row?.task?.rating],
               Notes: row?.task?.ratingNotes,
            };
         });
      } else {
         return [];
      }
   }
}
