import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromInternalTools from './state/internal-tools.reducer';
import { EffectsModule } from '@ngrx/effects';
import { InternalToolsEffects } from './state/internal-tools.effects';
import { FeatureFlagsComponent } from './components/feature-flags/feature-flags.component';
import { AdminCenterComponent } from './components/admin-center/admin-center.component';
import { SharedModule } from '@app/shared/shared.module';
import { AuthUsersInternalComponent } from './components/auth-users-internal/auth-users-internal.component';
import { OrganizationsInternalComponent } from './components/organizations-internal/organizations-internal.component';
import { InternalToolsFacade } from './state/internal-tools.facade';
import { RouterModule } from '@angular/router';

@NgModule({
   declarations: [
      FeatureFlagsComponent,
      AdminCenterComponent,
      AuthUsersInternalComponent,
      OrganizationsInternalComponent,
   ],
   imports: [
      CommonModule,
      SharedModule,
      RouterModule,
      StoreModule.forFeature(fromInternalTools.internalToolsFeatureKey, fromInternalTools.reducer),
      EffectsModule.forFeature([InternalToolsEffects]),
   ],
   providers: [InternalToolsFacade],
})
export class InternalToolsModule {}
