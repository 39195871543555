<mat-card>
   <form [formGroup]="form">
      <mat-card-title>
         <div class="name">
            <div class="breadcrumbs">
               <ng-container *ngIf="businessUnitName">
                  <div class="breadcrumb-text">
                     {{ businessUnitName }}
                  </div>
                  <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
               </ng-container>
               <ng-container *ngIf="departmentName">
                  <div class="breadcrumb-text">
                     {{ departmentName }}
                  </div>
                  <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
               </ng-container>
               <ng-container *ngIf="departmentFunctionName">
                  <div class="breadcrumb-text">
                     {{ departmentFunctionName }}
                  </div>
                  <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
               </ng-container>
            </div>
            {{ viewModel.task.name }}
         </div>
         <button
            mat-icon-button
            *ngIf="canEdit"
            [disabled]="form.pristine"
            (click)="save()"
            class="save-button"
            color="primary"
         >
            <mat-icon>save</mat-icon>
         </button>
      </mat-card-title>
      <mat-card-content>
         <div class="left-col">
            <mat-form-field>
               <input matInput placeholder="KPI" formControlName="kpi" name="kpi" />
            </mat-form-field>

            <mat-form-field>
               <textarea
                  matInput
                  placeholder="Benchmark"
                  formControlName="benchmark"
                  name="benchmark"
               ></textarea>
            </mat-form-field>
         </div>
         <div class="right-col">
            <mat-form-field>
               <mat-label>Rating</mat-label>
               <mat-select formControlName="rating" name="rating">
                  <mat-option *ngFor="let r of ratingKeys" [value]="r">
                     {{ rating[r] }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field>
               <textarea
                  matInput
                  placeholder="Rating notes"
                  formControlName="ratingNotes"
               ></textarea>
            </mat-form-field>
         </div>
      </mat-card-content>
   </form>
</mat-card>
