<div class="list-container">
   <div class="title-container">
      <div class="h5 title">Business Units</div>
      <button
         mat-icon-button
         color="primary"
         class="add-button"
         *ngIf="canEdit$ | async"
         (click)="addBusinessUnit()"
         matTooltip="New Business Unit"
         matTooltipPosition="before"
      >
         <mat-icon>add</mat-icon>
      </button>
   </div>

   <div class="card-container sidebar">
      <mat-card class="org-list-card">
         <mat-nav-list
            id="business-unit-list"
            class="org-nav-list"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
         >
            <ng-container
               *ngFor="let bu of businessUnits$ | async; first as isFirst; last as isLast"
            >
               <div
                  class="list-item"
                  [ngClass]="{ first: isFirst, last: isLast }"
                  cdkDrag
                  cdkDragBoundary="#business-unit-list"
                  [cdkDragDisabled]="!(canEdit$ | async)"
                  appDropTarget
                  (dropped)="itemDropped($event, bu)"
               >
                  <div class="placeholder" *cdkDragPlaceholder></div>
                  <div class="handle" cdkDragHandle *ngIf="canEdit$ | async">
                     <mat-icon svgIcon="drag-vertical"></mat-icon>
                  </div>
                  <mat-list-item
                     [ngClass]="{
                        selected: bu.id == selectedId,
                        first: isFirst,
                        last: isLast
                     }"
                     (click)="select(bu.id)"
                  >
                     <div matLine class="title-line">
                        <div class="list-item-title">
                           <span [matTooltip]="bu.name">
                              {{ bu.name }}
                           </span>
                        </div>
                        <button
                           mat-icon-button
                           class="list-item-options"
                           [matMenuTriggerFor]="optionsMenu"
                        >
                           <mat-icon>more_vert</mat-icon>
                        </button>
                     </div>
                     <div matLine class="person-line">
                        <div class="person">
                           <mat-icon>person</mat-icon>
                           <div class="person-name">
                              {{ bu.directorId | teamMemberName: teamMembers }}
                           </div>
                        </div>
                        <div class="units">
                           <mat-icon svgIcon="sitemap"></mat-icon>
                           <div class="person-name">
                              {{ getDepartments(bu) }}
                           </div>
                        </div>
                     </div>
                  </mat-list-item>
               </div>
               <mat-divider *ngIf="!isLast"></mat-divider>
               <mat-menu #optionsMenu="matMenu">
                  <button mat-menu-item *ngIf="canEdit$ | async" (click)="edit(bu)">Edit</button>
                  <button mat-menu-item *ngIf="!(canEdit$ | async)" (click)="edit(bu)">
                     View Details
                  </button>
                  <button mat-menu-item (click)="viewSummary(bu)">View Summary</button>
                  <button mat-menu-item *ngIf="canEdit$ | async" (click)="delete(bu)">
                     Delete
                  </button>
               </mat-menu>
            </ng-container>
         </mat-nav-list>
      </mat-card>
      <button mat-button color="primary" *ngIf="canEdit$ | async" (click)="addBusinessUnit()">
         <mat-icon>add</mat-icon>
         New Business Unit
      </button>
   </div>
</div>
