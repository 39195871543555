import { ActionItemsWidgetComponent } from '../components/action-items-widget/action-items-widget.component';
import { AssignedActionsWidgetComponent } from '../components/assigned-actions-widget/assigned-actions-widget.component';
import { AssignedItemsWidgetComponent } from '../components/assigned-items-widget/assigned-items-widget.component';
import { AssignedMilestonesWidgetComponent } from '../components/assigned-milestones-widget/assigned-milestones-widget.component';
import { AssignedTasksWidgetComponent } from '../components/assigned-tasks-widget/assigned-tasks-widget.component';
import { DocFunctionsWidgetComponent } from '../components/doc-functions-widget/doc-functions-widget.component';
import { DocumentationStatusWidgetComponent } from '../components/documentation-status-widget/documentation-status-widget.component';
import { OverviewWidgetComponent } from '../components/overview-widget/overview-widget.component';
import { PerformanceRatingWidgetComponent } from '../components/performance-rating-widget/performance-rating-widget.component';
import { QueryWidgetComponent } from '../components/query-widget/query-widget.component';
import { QuickActionsWidgetComponent } from '../components/quick-actions-widget/quick-actions-widget.component';
import { RocksWidgetComponent } from '../components/rocks-widget/rocks-widget.component';
import { UpcomingMilestonesWidgetComponent } from '../components/upcoming-milestones-widget/upcoming-milestones-widget.component';

export const widgetComponentMap = {
   RocksWidgetComponent: RocksWidgetComponent,
   ActionItemsWidgetComponent: ActionItemsWidgetComponent,
   DocFunctionsWidgetComponent: DocFunctionsWidgetComponent,
   AssignedTasksWidgetComponent: AssignedTasksWidgetComponent,
   OverviewWidgetComponent: OverviewWidgetComponent,
   AssignedActionsWidgetComponent: AssignedActionsWidgetComponent,
   AssignedItemsWidgetComponent: AssignedItemsWidgetComponent,
   AssignedMilestonesWidgetComponent: AssignedMilestonesWidgetComponent,
   DocumentationStatusWidgetComponent: DocumentationStatusWidgetComponent,
   PerformanceRatingWidgetComponent: PerformanceRatingWidgetComponent,
   UpcomingMilestonesWidgetComponent: UpcomingMilestonesWidgetComponent,
   QuickActionsWidgetComponent: QuickActionsWidgetComponent,
   QueryWidgetComponent: QueryWidgetComponent,
};
