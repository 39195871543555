import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { GridsterConfig } from 'angular-gridster2';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Dashboard } from '@entities/dashboard';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Widget } from '@entities/widget';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { deepCopy } from '@app/utilities/deep-copy';
import { DialogService } from '@app/shared/services/dialog.service';
import { widgetComponents, defaultDashboard } from '@app/dashboard/state/widget-components';

@Component({
   selector: 'app-dashboard',
   templateUrl: './dashboard.component.html',
   styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
   @ViewChild('addWidgetRef') addWidgetRef: TemplateRef<any>;

   dashboard: Dashboard = defaultDashboard;
   widgets = widgetComponents;
   previousDashboardId: string;

   newWidget: any;
   selectedDashboardId: string;
   oldWidgets: any[];
   savingNewDashboard = false;

   showEdit = false;
   columns = 12;
   gridsterOptions: GridsterConfig = {
      gridType: 'fixed',
      fixedColWidth: 100,
      fixedRowHeight: 100,
      draggable: {
         enabled: false,
         ignoreContent: false,
      },
      resizable: {
         enabled: false,
      },
      swap: true,
      pushItems: true,
      compactType: 'none',
   };
   dashboards$: Observable<Dashboard[]>;

   private destroyed$ = new Subject<void>();

   constructor(
      private dashboardFacade: DashboardFacade,
      private dialog: MatDialog,
      private dialogService: DialogService
   ) {}

   ngOnInit() {
      this.dashboardFacade.selectedDashboard$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((dashboard) => {
            if (dashboard) {
               this.dashboard = deepCopy(dashboard);
               this.selectedDashboardId = dashboard.id;
            } else {
               this.selectedDashboardId = null;
            }
         });
      this.dashboards$ = this.dashboardFacade.dashboards$;
      this.dashboards$.pipe(takeUntil(this.destroyed$)).subscribe((dashboards) => {
         if (dashboards && dashboards.length > 0 && this.selectedDashboardId == undefined) {
            if (this.savingNewDashboard) {
               const dash = dashboards.find((d) => d.name === this.dashboard.name);
               if (dash) {
                  this.dashboard = dash;
                  this.dashboardFacade.selectDashboard(this.dashboard.id);
                  this.savingNewDashboard = false;
               }
            } else {
               const defaultDashboard = dashboards.find((d) => d.default);
               const idToSelect = defaultDashboard ? defaultDashboard.id : dashboards[0].id;
               this.dashboardFacade.selectDashboard(idToSelect);
            }
         }
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   editGrid() {
      this.oldWidgets = [];
      this.dashboard.widgets.forEach((widget) => {
         this.oldWidgets.push({ ...widget });
      });
      this.showEdit = true;
      this.gridsterOptions.draggable.enabled = true;
      this.gridsterOptions.resizable.enabled = true;
      this.gridsterOptions.api.optionsChanged();
   }

   cancelEdit() {
      if (this.dashboard.id === null) {
         this.dashboardFacade.selectDashboard(this.previousDashboardId);
      } else {
         this.dashboard.widgets = this.oldWidgets;
      }
      this.showEdit = false;
      this.gridsterOptions.resizable.enabled = false;
      this.gridsterOptions.draggable.enabled = false;
      this.gridsterOptions.api.optionsChanged();
   }

   save() {
      this.showEdit = false;
      this.gridsterOptions.resizable.enabled = false;
      this.gridsterOptions.draggable.enabled = false;
      this.gridsterOptions.api.optionsChanged();
      this.dashboardFacade.saveDashboard(this.dashboard);
   }

   selectDashboard(event: MatSelectChange) {
      this.dashboardFacade.selectDashboard(event.value);
   }

   newDashboard() {
      this.previousDashboardId = this.dashboard.id;
      this.dashboard = {
         name: 'New Dashboard',
         id: null,
         userId: null,
         sharable: true,
         widgets: [],
         default: false,
      };
      this.savingNewDashboard = true;
      this.dashboardFacade.selectDashboard(null);
      this.editGrid();
   }

   deleteDashboard() {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Dashboard?',
            message: 'Are you sure you want to delete this dashboard?',
            confirm: 'Yes, delete',
            deny: 'No, go back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.dashboardFacade.deleteDashboard(this.dashboard);
            }
         });
   }

   deleteWidget(event, index: number) {
      event.preventDefault();
      event.stopPropagation();
      this.dashboard.widgets.splice(index, 1);
   }

   addWidget() {
      this.dialog
         .open(this.addWidgetRef, { minWidth: '300px' })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               const positioned = this.gridsterOptions.api.getFirstPossiblePosition(this.newWidget);
               this.dashboard.widgets.push(<Widget>positioned);
            }
            this.newWidget = null;
         });
   }
}
