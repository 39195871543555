<form autocomplete="off">
   <mat-form-field>
      <input
         matInput
         type="text"
         name="userAutocomplete"
         [readonly]="!canEdit"
         [formControl]="formCtrl"
         [placeholder]="placeholder"
         [matAutocomplete]="auto"
         (focus)="onFocus()"
         (keyup.enter)="selectFirstOption()"
         [required]="required"
         autocomplete="off"
         data-lpignore="true"
      />
      <span *ngIf="showClear && selected && canEdit" class="clear-selection" (click)="clear()">
         <mat-icon>close</mat-icon>
      </span>
      <mat-autocomplete
         #auto="matAutocomplete"
         [displayWith]="displayFn"
         (optionSelected)="onOptionSelect($event)"
      >
         <mat-option *ngFor="let user of filtered$ | async" [value]="user">
            {{ user.lastName }}, {{ user.firstName }} ({{ user.email }})
         </mat-option>
      </mat-autocomplete>
   </mat-form-field>
</form>

