<div class="list-container">
   <div class="title-container">
      <div class="h5 title">Tasks</div>
      <button
         mat-icon-button
         color="primary"
         class="add-button"
         *ngIf="visible && canEdit"
         (click)="addTask()"
         matTooltip="New Task"
         matTooltipPosition="after"
      >
         <mat-icon>add</mat-icon>
      </button>
   </div>
   <div class="card-container sidebar">
      <ng-container *ngIf="visible">
         <mat-card class="org-list-card">
            <mat-list
               id="task-list"
               class="org-nav-list"
               cdkDropList
               (cdkDropListDropped)="drop($event)"
            >
               <ng-container
                  *ngFor="let task of tasks; index as i; first as isFirst; last as isLast"
               >
                  <div
                     class="list-item"
                     [ngClass]="{ first: isFirst, last: isLast }"
                     cdkDrag
                     [cdkDragDisabled]="!canEdit"
                     (cdkDragStarted)="dragStarted($event, task)"
                  >
                     <div class="placeholder" *cdkDragPlaceholder></div>
                     <div class="handle" cdkDragHandle *ngIf="canEdit">
                        <mat-icon svgIcon="drag-vertical"></mat-icon>
                     </div>
                     <mat-list-item>
                        <div matLine class="title-line">
                           <div class="list-item-title">
                              <span [matTooltip]="task.name">
                                 {{ task.name }}
                              </span>
                           </div>
                           <button
                              mat-icon-button
                              class="list-item-options"
                              [matMenuTriggerFor]="optionsMenu"
                           >
                              <mat-icon>more_vert</mat-icon>
                           </button>
                        </div>
                        <div matLine class="person-line">
                           <div class="person">
                              <mat-icon>person</mat-icon>
                              <div class="person-name">
                                 {{ task.assignees ? task.assignees.length : 0 }} Assigned
                              </div>
                           </div>
                           <div
                              class="units"
                              [matTooltip]="
                                 (task.steps?.length || 0) +
                                 ' step' +
                                 (task.steps?.length === 1 ? '' : 's')
                              "
                           >
                              <mat-icon>list</mat-icon>
                              <div class="person-name">
                                 {{ task.steps?.length || 0 }}
                              </div>
                           </div>
                        </div>
                     </mat-list-item>
                     <mat-menu #optionsMenu="matMenu">
                        <button mat-menu-item (click)="edit(task)">
                           <ng-container *ngIf="canEdit">Edit</ng-container>
                           <ng-container *ngIf="!canEdit">View Details</ng-container>
                        </button>
                        <button mat-menu-item (click)="report(task)">View Report</button>
                        <!-- <button mat-menu-item (click)="assign(task)">Assign team member</button> -->
                        <button mat-menu-item *ngIf="canEdit" (click)="delete(task)">Delete</button>
                     </mat-menu>
                  </div>
                  <mat-divider *ngIf="!isLast"></mat-divider>
               </ng-container>
            </mat-list>
         </mat-card>
         <button mat-button color="primary" *ngIf="visible && canEdit" (click)="addTask()">
            <mat-icon>add</mat-icon>
            New Task
         </button>
         <ng-container *ngIf="tasks.length == 0 && !canEdit">No tasks to show</ng-container>
      </ng-container>
      <div *ngIf="!visible">Please select a function to see tasks</div>
   </div>
</div>
