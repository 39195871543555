import { Injectable, Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

export interface DialogOptions {
   title: string;
   message: string;
   confirm: string;
   deny: string;
   cancel?: string;
   confirmColor?: string;
   denyColor?: string;
   service?: DialogService;
}

@Injectable()
export class DialogService {
   private dialogRef: MatDialogRef<any>;

   constructor(private dialog: MatDialog) {}

   showConfirmDialog(dialogOptions: DialogOptions) {
      this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
         data: { ...dialogOptions, service: this },
         minWidth: '400px',
      });
      return this.dialogRef;
   }

   closeConfirmationDialog(result: boolean) {
      this.dialogRef.close(result);
   }
}
