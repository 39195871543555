import { BusinessUnit } from './business-unit';
import { TeamMember } from './team-member';
import { DepartmentFunction } from './department-function';
import { Rock } from './rock';
import { OrgEntity } from './org-entity';

export class Department implements OrgEntity {
   id: string;

   name: string;

   purpose: string;

   businessUnitId: string;

   businessUnit: BusinessUnit;

   organizationId: string;

   managerId: string;

   manager: TeamMember;

   teamMembers: TeamMember[];

   departmentFunctions: DepartmentFunction[];

   rocks: Rock[];

   order: number;

   notes: string;
}
