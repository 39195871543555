import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { froalaConfig } from '@app/shared/config/froala.config';
import { DialogService } from '@app/shared/services/dialog.service';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { TeamFacade } from '@app/team/state/team.facade';
import { getKeys } from '@app/utilities/getKeys';
import { PerformanceEvaluationStatus } from '@entities/enums/performance-evaluation-status';
import { PerformanceEvaluation } from '@entities/performance-evaluation';
import { PerformanceEvaluationItem } from '@entities/performance-evaluation-item';
import { Task } from '@entities/task';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
   selector: 'app-performance-evaluation-edit',
   templateUrl: './performance-evaluation-edit.component.html',
   styleUrls: ['./performance-evaluation-edit.component.scss'],
})
export class PerformanceEvaluationEditComponent implements OnInit, SaveChangesComponent {
   form: UntypedFormGroup;
   performanceEvaluation: PerformanceEvaluation;

   perfEvalItems: PerformanceEvaluationItem[] = [];
   updatedItems: PerformanceEvaluationItem[] = [];

   editorConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Notes',
      minHeight: 100,
   };
   editor: any;

   saving = false;

   status = PerformanceEvaluationStatus;
   statusKeys = getKeys(PerformanceEvaluationStatus);

   private destroyed$ = new Subject<void>();

   constructor(
      private teamFacade: TeamFacade,
      private route: ActivatedRoute,
      private dialogService: DialogService
   ) {
      this.route.params.subscribe((params) => {
         if (params['performanceEvaluationId']) {
            this.teamFacade.selectPerformanceEvaluation(params['performanceEvaluationId']);
         }
      });
   }

   ngOnInit(): void {
      this.form = new UntypedFormGroup({
         year: new UntypedFormControl(),
         teamMemberId: new UntypedFormControl(),
         notes: new UntypedFormControl(),
         status: new UntypedFormControl(),
      });
      this.teamFacade.selectedPerformanceEvaluation$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((performanceEvaluation) => {
            if (performanceEvaluation && (this.form.pristine || this.saving)) {
               this.performanceEvaluation = performanceEvaluation;
               this.perfEvalItems = [...performanceEvaluation.performanceEvaluationItems].sort(
                  (a, b) => {
                     if (a.competency.departmentName && b.competency.departmentName) {
                        const deptDiff = a.competency.departmentName.localeCompare(
                           b.competency.departmentName
                        );
                        if (deptDiff !== 0) {
                           return deptDiff;
                        }
                     }
                     return a.competency.name.localeCompare(b.competency.name);
                  }
               );
               this.form.patchValue(performanceEvaluation);
               this.form.markAsPristine();
               this.saving = false;
            }
         });
   }

   itemId = (item) => item.id;

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   save() {
      const toSave = {
         ...this.performanceEvaluation,
         ...this.form.value,
         performanceEvaluationItems: [
            ...this.performanceEvaluation.performanceEvaluationItems.filter(
               (item) => !this.updatedItems.some((i) => i.id === item.id)
            ),
            ...this.updatedItems,
         ],
      };
      this.teamFacade.savePerformanceEvaluation(toSave);
      this.saving = true;
   }

   deleteEval() {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Performance Evaluation?',
            message:
               'Are you sure you want to delete this performance evaluation? This operation cannot be undone.',
            confirm: 'Yes, delete',
            deny: 'No, go back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.teamFacade.deletePerformanceEvaluation(this.performanceEvaluation);
            }
         });
   }

   back() {
      history.back();
   }

   updateItem(item: PerformanceEvaluationItem) {
      this.updatedItems = [...this.updatedItems.filter((i) => i.id !== item.id), item];
      this.form.markAsDirty();
   }
}
