import { Component, OnInit } from '@angular/core';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { Observable } from 'rxjs';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';

import { Task } from '@entities/task';
import { DepartmentFunction } from '@entities/department-function';
import { Router, ActivatedRoute } from '@angular/router';
import { appRoutesNames } from '@app/app.routes.names';
import { documentationRouteNames } from '@app/documentation/documentation.routes.names';
import { orgBuilderRouteNames } from '@app/org-builder/org-builder.routes.names';

@Component({
   selector: 'app-organization',
   templateUrl: './organization.component.html',
   styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit {
   businessUnits$: Observable<BusinessUnit[]>;
   selectedBusinessUnitId$: Observable<string>;
   departments$: Observable<Department[]>;
   selectedDepartmentId$: Observable<string>;
   departmentFunctions$: Observable<DepartmentFunction[]>;
   selectedDepartmentFunctionId$: Observable<string>;
   tasks$: Observable<Task[]>;
   canEdit$: Observable<boolean>;

   constructor(
      public orgBuilderFacade: OrgBuilderFacade,
      private router: Router,
      private route: ActivatedRoute
   ) {}

   ngOnInit() {
      this.businessUnits$ = this.orgBuilderFacade.businessUnits$;
      this.selectedBusinessUnitId$ = this.orgBuilderFacade.selectedBusinessUnitId$;
      this.departments$ = this.orgBuilderFacade.visibleDepartments$;
      this.selectedDepartmentId$ = this.orgBuilderFacade.selectedDepartmentId$;
      this.departmentFunctions$ = this.orgBuilderFacade.visibleDepartmentFunctions$;
      this.selectedDepartmentFunctionId$ = this.orgBuilderFacade.selectedDepartmentFunctionId$;
      this.tasks$ = this.orgBuilderFacade.visibleTasks$;
      this.canEdit$ = this.orgBuilderFacade.canEdit$;
   }

   onEditTask(task: Task) {
      this.orgBuilderFacade.selectTask(task.id);
      this.router.navigate(
         ['..', appRoutesNames.DOCUMENTATION, documentationRouteNames.TASKS, task.id],
         {
            relativeTo: this.route.parent,
            state: {
               source: this.router.url,
            },
         }
      );
   }

   viewSummary() {
      this.router.navigate([orgBuilderRouteNames.ORG_SUMMARY], { relativeTo: this.route });
   }

   viewChart() {
      this.router.navigate([orgBuilderRouteNames.ORG_CHART], { relativeTo: this.route });
   }

   centerDepartments() {
      if (window.innerWidth >= 768) {
         const scrollContent = document.getElementById('scroll-content');
         try {
            scrollContent.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
         } catch (error) {
            // alternate approach for microsoft edge
            scrollContent.scrollTop = 0;
            scrollContent.scrollLeft = 0;
         }
      }
   }

   centerFunctions() {
      if (window.innerWidth >= 768) {
         const column = document.getElementById('tasks');
         column.scrollIntoView({ behavior: 'smooth', inline: 'end' });
      }
   }
}
