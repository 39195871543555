import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormControl } from '@angular/forms';
import { User } from '@entities/user';
import { Observable, Subject } from 'rxjs';
import { UserRole } from '@entities/enums/user-role';
import { AppAreas } from '@entities/enums/app-areas';
import { UserAccessType } from '@entities/enums/user-access-type';
import { Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { AdminFacade } from '@app/admin/state/admin.facade';
import { ProductType } from '@entities/enums/product-type';
import { PlanDetails } from '@entities/plan-details';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { PermissionsFacade } from '@app/shared/state/permissions.facade';

@Component({
   selector: 'app-user-edit',
   templateUrl: './user-edit.component.html',
   styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit, OnDestroy {
   form: UntypedFormGroup;
   user$: Observable<User>;
   userActive$: Observable<boolean>;
   AppAreas = AppAreas;
   UserAccessType = UserAccessType;
   UserRole = UserRole;
   ProductType = ProductType;
   appAreas: string[];
   userRoles: number[];
   userAccessTypes: number[];
   orgPlans$: Observable<PlanDetails[]>;
   user: User;

   usePermissionGroups$ = this.permissionsFacade.usePermissionGroups$;
   permissionGroups$ = this.permissionsFacade.permissionGroups$;

   private destroyed$ = new Subject<void>();

   constructor(
      private adminFacade: AdminFacade,
      private router: Router,
      private permissionsFacade: PermissionsFacade
   ) {
      this.userRoles = Object.keys(UserRole)
         .filter((k) => !isNaN(Number(k)))
         .map((n) => Number(n));
      this.appAreas = Object.keys(AppAreas).sort();
      this.userAccessTypes = Object.keys(UserAccessType)
         .filter((k) => !isNaN(Number(k)))
         .map((n) => Number(n));
   }

   ngOnInit() {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         firstName: new UntypedFormControl(),
         lastName: new UntypedFormControl(),
         email: new UntypedFormControl(),
         role: new UntypedFormControl(),
         userAccountId: new UntypedFormControl(),
         organizationId: new UntypedFormControl(),
         product: new UntypedFormControl(),
         isAdmin: new UntypedFormControl(),
         teamMemberId: new UntypedFormControl(),
         permissionGroupId: new FormControl(),
      });
      this.user$ = this.adminFacade.selectedUser$;
      this.user$.pipe(takeUntil(this.destroyed$)).subscribe((value: User) => {
         if (value) {
            const user = {
               ...value,
               product: value.product || value.products[0],
               isAdmin: value.isAdmin || value.role === UserRole.Admin,
            };
            this.user = user;
            this.form.patchValue(user);
         }
      });
      this.userActive$ = this.user$.pipe(map((user) => user?.active));
      this.orgPlans$ = this.adminFacade.orgPlans$;
      this.form
         .get('product')
         .valueChanges.pipe(takeUntil(this.destroyed$))
         .subscribe((value) => {
            if (value !== ProductType.Complete) {
               this.form.get('isAdmin').setValue(false);
               this.form.get('isAdmin').disable();
            }
         });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   save() {
      const user = this.parseUser();
      this.adminFacade.saveUser(user);
   }
   cancel() {
      this.adminFacade.cancelEditUser();
   }
   deactivate() {
      const user = this.parseUser();
      this.adminFacade.deactivateUser(user);
   }
   reactivate() {
      const user = this.parseUser();
      this.adminFacade.reactivateUser(user);
   }
   delete() {
      const user = this.parseUser();
      this.adminFacade.deleteUser(user);
   }

   private parseUser(): User {
      const user = {
         ...this.user,
         ...this.form.value,
      };
      user.role = user.isAdmin ? UserRole.Admin : UserRole.User;
      return user;
   }

   setTeamMember(event: MatAutocompleteSelectedEvent) {
      if (event && event.option && event.option.value) {
         this.form.get('teamMemberId').setValue(event.option.value.id);
      } else {
         this.form.get('teamMemberId').reset();
      }
   }

   productDisabled(plan: PlanDetails) {
      const productSelected = this.user.product === plan.productType;
      return plan.available <= 0 && !productSelected;
   }

   available(plan: PlanDetails) {
      const productAssigned = this.user.product === plan.productType;
      const productSelected = this.form.get('product').value === plan.productType;
      if (productAssigned === productSelected) {
         return plan.available;
      } else if (productSelected) {
         return plan.available - 1;
      } else {
         return plan.available + 1;
      }
   }
}
