import { Route } from '@angular/router';
import { documentationRouteNames } from './documentation.routes.names';
import { DocumentationTaskEditComponent } from './components/documentation-task-edit/documentation-task-edit.component';
import { DocumentationTaskReportComponent } from './components/documentation-task-report/documentation-task-report.component';
import { DocumentationTaskListComponent } from './components/documentation-list/documentation-list.component';
import { CanDeactivateGuard } from '@app/shared/services/can-deactivate-guard.service';
import { DocumentationFunctionListComponent } from './components/documentation-function-list/documentation-function-list.component';
import { DocumentationFunctionEditComponent } from './components/documentation-function-edit/documentation-function-edit.component';
import { DocumentationFunctionReportComponent } from './components/documentation-function-report/documentation-function-report.component';
import { SaveChangesGuard } from '@app/shared/services/save-changes.guard';

export const DOCUMENTATION_ROUTES: Route[] = [
   {
      path: documentationRouteNames.TASKS,
      children: [
         { path: '', component: DocumentationTaskListComponent },
         {
            path: ':taskId',
            component: DocumentationTaskEditComponent,
            canDeactivate: [SaveChangesGuard],
         },
         {
            path: `:taskId/${documentationRouteNames.REPORT}`,
            component: DocumentationTaskReportComponent,
         },
      ],
   },
   {
      path: documentationRouteNames.FUNCTIONS,
      children: [
         { path: '', component: DocumentationFunctionListComponent },
         {
            path: ':functionId',
            component: DocumentationFunctionEditComponent,
            canDeactivate: [SaveChangesGuard],
         },
         {
            path: `:functionId/${documentationRouteNames.REPORT}`,
            component: DocumentationFunctionReportComponent,
         },
      ],
   },
   { path: '', redirectTo: documentationRouteNames.FUNCTIONS, pathMatch: 'full' },
];
