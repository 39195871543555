import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { TeamFacade, TaskAssignTo } from '@app/team/state/team.facade';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Task } from '@entities/task';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface OrgTreeNode {
   id: string;
   name: string;
   isTask?: boolean;
   children?: OrgTreeNode[];
}

@Component({
   selector: 'app-assign-tasks',
   templateUrl: './assign-tasks.component.html',
   styleUrls: ['./assign-tasks.component.scss'],
})
export class AssignTasksComponent implements OnInit, OnDestroy {
   dataSource = new MatTreeNestedDataSource<OrgTreeNode>();
   treeControl = new NestedTreeControl<OrgTreeNode>((node) => node.children);
   alreadyAssigned: string[] = [];
   toBeAssigned: string[] = [];
   checked: string[] = [];
   form: UntypedFormGroup;
   assignFunctions = false;
   private teamFacade: TeamFacade;
   private assignTo: TaskAssignTo;
   private destroyed$ = new Subject<void>();

   constructor(
      @Inject(MAT_DIALOG_DATA)
      data: {
         facade: TeamFacade;
         assignTo: TaskAssignTo;
         assignFunctions?: boolean;
      }
   ) {
      this.teamFacade = data.facade;
      this.assignTo = data.assignTo;
      if (data.assignFunctions) {
         this.assignFunctions = data.assignFunctions;
      }
   }

   ngOnInit() {
      this.teamFacade.orgTree$.pipe(takeUntil(this.destroyed$)).subscribe((tree) => {
         this.dataSource.data = tree;
      });
      this.teamFacade.assignedTasks$.pipe(takeUntil(this.destroyed$)).subscribe((tasks) => {
         this.toBeAssigned = tasks.map((t) => t.task.id);
      });
      this.form = new UntypedFormGroup({
         tasks: new UntypedFormArray([new UntypedFormControl()]),
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   hasChild = (_: number, node: OrgTreeNode) => !!node.children && node.children.length > 0;

   isAssigned(node: OrgTreeNode) {
      return node.id && (this.toBeAssigned.includes(node.id) || this.checked.includes(node.id));
   }

   toggleAssigned(event: any, node: OrgTreeNode) {
      if (node.isTask) {
         if (event.checked) {
            this.toBeAssigned.push(node.id);
         } else {
            this.toBeAssigned = this.toBeAssigned.filter((n) => n != node.id);
         }
      } else {
         if (event.checked) {
            this.checked.push(node.id);
         } else {
            this.checked = this.checked.filter((id) => id !== node.id);
         }
         node.children.forEach((child) => {
            this.toggleAssigned(event, child);
         });
      }
   }

   assign() {
      this.teamFacade.saveTaskAssignments(this.toBeAssigned, this.assignTo);
   }

   close() {
      this.teamFacade.cancelAssignTasks();
   }
}
