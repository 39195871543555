<div class="container">
   <h3 mat-dialog-title>Assign Tasks</h3>
   <div mat-dialog-content>
      <form [formGroup]="form">
         <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="task-tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
               <li class="mat-tree-node">
                  <mat-checkbox
                     *ngIf="node.isTask"
                     color="primary"
                     class="task-checkbox"
                     [checked]="isAssigned(node)"
                     (change)="toggleAssigned($event, node)"
                  >
                     {{ node.name }}
                  </mat-checkbox>
                  <ng-container *ngIf="!node.isTask">
                     <button mat-icon-button disabled></button>
                     {{ node.name }}
                  </ng-container>
               </li>
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
               <li>
                  <div class="mat-tree-node">
                     <button
                        mat-icon-button
                        matTreeNodeToggle
                        [attr.aria-label]="'toggle ' + node.name"
                     >
                        <mat-icon class="mat-icon-rtl-mirror">
                           {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                     </button>
                     <mat-checkbox
                        color="primary"
                        class="node-checkbox"
                        [checked]="isAssigned(node)"
                        (change)="toggleAssigned($event, node)"
                     >
                        {{ node.name }}
                     </mat-checkbox>
                  </div>
                  <ul [class.mat-tree-invisible]="!treeControl.isExpanded(node)">
                     <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
               </li>
            </mat-nested-tree-node>
         </mat-tree>
      </form>
   </div>
   <div mat-dialog-actions>
      <div class="button-container">
         <button mat-raised-button color="primary" (click)="assign()">Assign</button>
         <button mat-raised-button (click)="close()">Cancel</button>
      </div>
   </div>
</div>
