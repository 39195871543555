import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';
import { WidgetEntities, widgetFilterConfigMap } from '@app/dashboard/state/widget-components';
import { TableComponent, TableConfig } from '@app/shared/components/table/table.component';
import { FilterBarConfig, FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { DepartmentNamePipe } from '@app/shared/pipes/department-name.pipe';
import { GoalNamePipe } from '@app/shared/pipes/goal.pipe';
import { TeamMemberNamePipe } from '@app/shared/pipes/team-member-name.pipe';
import { getKeys } from '@app/utilities/getKeys';
import { processEntity } from '@app/utilities/process-entity';
import { Department } from '@entities/department';
import { DocumentationStatus } from '@entities/enums/documentation-status';
import { ActionItemType } from '@entities/enums/action-item-type';
import { Priority } from '@entities/enums/priority';
import { RockStatus } from '@entities/enums/rock-status';
import { Status } from '@entities/enums/status';
import { TeamMemberStatus } from '@entities/enums/team-member-status';
import { Goal } from '@entities/goal';
import { Rock } from '@entities/rock';
import { TeamMember } from '@entities/team-member';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { WidgetInstance } from '../widget/widget-instance';

@Component({
   selector: 'app-query-widget',
   templateUrl: './query-widget.component.html',
   styleUrls: ['./query-widget.component.scss'],
})
export class QueryWidgetComponent implements OnInit, WidgetInstance {
   @ViewChild(TableComponent) tableComponent: TableComponent;

   id: string;
   data: any;
   form: UntypedFormGroup;
   widgetEntities = WidgetEntities;
   entityKeys = getKeys(WidgetEntities);
   tableConfig: TableConfig;
   showEditor = false;

   filter$ = new BehaviorSubject({});
   filter: FilterValues = {};

   departments: Department[] = [];
   teamMembers: TeamMember[] = [];
   goals: Goal[] = [];

   private destroyed$ = new Subject<void>();

   constructor(
      private dashboardFacade: DashboardFacade,
      private departmentNamePipe: DepartmentNamePipe,
      private teamMemberNamePipe: TeamMemberNamePipe,
      private datePipe: DatePipe,
      private goalNamePipe: GoalNamePipe
   ) {}

   ngOnInit(): void {
      this.form = new UntypedFormGroup({
         name: new UntypedFormControl(),
         entity: new UntypedFormControl(),
         filter: new UntypedFormControl(),
      });

      this.form.get('entity').valueChanges.subscribe((value) => {
         this.setTableConfig(value);
      });
      if (this.data) {
         this.form.patchValue(this.data);
         this.setTableConfig(this.data.entity);
         this.filter = this.data.filter;
         this.filter$.next(this.data.filter);
      } else {
         this.setTableConfig();
         this.showEditor = true;
      }
      this.dashboardFacade.departments$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((departments) => {
            this.departments = departments;
            this.setTableConfig(this.data?.entity);
         });
      this.dashboardFacade.teamMembers$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((teamMembers) => {
            this.teamMembers = teamMembers;
         });
      this.dashboardFacade.goals$.pipe(takeUntil(this.destroyed$)).subscribe((goals) => {
         this.goals = goals;
      });
   }

   setTableConfig(entity?: WidgetEntities) {
      if (this.entityKeys.includes(entity)) {
         this.tableConfig = {
            data$: this.dashboardFacade.getDataSource(entity),
            filter$: this.filter$,
            columns: this.widgetTableColumnsMap[entity] || [],
            visibleColumns$: of([]),
            initialSort: { active: 'name', direction: 'asc' },
            rowClick: () => {},
            statusField: this.getStatusField(entity),
            departments: this.departments,
         };
         if ((entity = WidgetEntities.Functions)) {
         }
      } else {
         this.tableConfig = {
            data$: of([]),
            filter$: this.filter$,
            columns: [],
            visibleColumns$: of([]),
            initialSort: { active: 'name', direction: 'asc' },
            rowClick: () => {},
         };
      }
   }

   getStatusField(entity: WidgetEntities) {
      if (entity === WidgetEntities.Milestones) {
         return 'completed';
      } else if (entity === WidgetEntities.Functions) {
         return 'documentationStatus';
      } else {
         return 'status';
      }
   }

   getFilterConfig() {
      const entity = this.form.value.entity;
      let defaultFilter: FilterBarConfig = {
         hideSearch: true,
         filterChange: (value) => this.updateFilter(value),
         disableToggle: true,
      };
      if (this.entityKeys.includes(entity)) {
         const filter = widgetFilterConfigMap[entity];
         return {
            ...filter,
            ...defaultFilter,
         };
      }
      return defaultFilter;
   }

   updateFilter(filter: FilterValues) {
      this.form.get('filter').setValue(filter);
      this.filter = filter;
      this.filter$.next(filter);
   }

   edit() {
      this.showEditor = true;
   }

   save() {
      this.data = this.form.value;
      this.showEditor = false;
      if (this.id) {
         this.dashboardFacade.saveWidgetData(this.id, this.data);
      }
   }

   cancel() {
      this.showEditor = false;
   }

   export() {
      if (this.tableComponent) {
         this.tableComponent.export(true);
      }
   }

   /* istanbul ignore next */
   // tslint:disable-next-line: member-ordering
   widgetTableColumnsMap: { [key: number]: TableColumn<any>[] } = {
      [WidgetEntities.Actions]: [
         { label: 'Name', def: 'name', visible: true, value: (row) => row.name },
         { label: 'Type', def: 'type', visible: true, value: (row) => ActionItemType[row.type] },
         {
            label: 'Department',
            def: 'departmentId',
            filter: 'departments',
            visible: true,
            value: (row) => this.departmentNamePipe.transform(row.departmentId, this.departments),
         },
         {
            label: 'Assigned To',
            def: 'assigneeId',
            visible: true,
            value: (row) => this.teamMemberNamePipe.transform(row.assigneeId, this.teamMembers),
         },
         {
            label: 'Status',
            def: 'status',
            filter: 'status',
            visible: true,
            value: (row) => Status[row.status],
         },
         {
            label: 'Start Date',
            def: 'startDate',
            isDate: true,
            visible: false,
            value: (row) => this.datePipe.transform(row.startDate),
         },
         {
            label: 'Target Date',
            def: 'targetDate',
            isDate: true,
            visible: true,
            value: (row) => this.datePipe.transform(row.targetDate),
         },
         {
            label: 'Completed Date',
            def: 'completedDate',
            isDate: true,
            visible: false,
            value: (row) => this.datePipe.transform(row.completedDate),
         },
         {
            label: 'Priority',
            def: 'priority',
            visible: true,
            value: (row) => Priority[row.priority],
         },
      ],
      [WidgetEntities['Business Units']]: [
         { label: 'Business Unit', def: 'name', visible: true, value: (row) => row.name },
         {
            label: 'Director',
            def: 'director',
            visible: true,
            value: (row) => this.teamMemberNamePipe.transform(row.directorId, this.teamMembers),
         },
      ],
      [WidgetEntities.Departments]: [
         { label: 'Department', def: 'name', visible: true, value: (row) => row.name },
         {
            label: 'Manager',
            def: 'manager',
            visible: true,
            value: (row) => this.teamMemberNamePipe.transform(row.managerId, this.teamMembers),
         },
      ],
      [WidgetEntities.Functions]: [
         { label: 'Function', def: 'name', visible: true, value: (row) => row.name },
         {
            label: 'Lead',
            def: 'lead',
            visible: true,
            value: (row) => this.teamMemberNamePipe.transform(row.leadId, this.teamMembers),
         },
         {
            label: 'Documentation Status',
            def: 'documentationStatus',
            visible: true,
            value: (row) => DocumentationStatus[row.documentationStatus],
         },
      ],
      [WidgetEntities.Milestones]: [
         { label: 'Milestone', def: 'name', visible: true, value: (row) => row.description },
         { label: 'Rock', def: 'rockName', visible: true, value: (row) => row.rockName },
         {
            label: 'Department',
            def: 'departmentId',
            visible: true,
            value: (row) => this.departmentNamePipe.transform(row.departmentId, this.departments),
            filter: 'departments',
         },
         {
            label: 'Quarter',
            def: 'quarter',
            visible: true,
            value: (row) => row.quarter,
            filter: 'quarter',
         },
         {
            label: 'Status',
            def: 'status',
            visible: true,
            value: (row) => (row.completed ? 'Complete' : 'Incomplete'),
            filter: 'status',
         },
         {
            label: 'Rock Status',
            def: 'rockStatus',
            visible: true,
            value: (row) => RockStatus[row.rockStatus],
         },
      ],
      [WidgetEntities.Rocks]: [
         { label: 'Rock', def: 'name', visible: true, value: (row) => row.name },
         {
            label: 'Department',
            def: 'departmentId',
            visible: true,
            value: (row) => this.departmentNamePipe.transform(row.departmentId, this.departments),
            filter: 'departments',
         },
         {
            label: 'Goal',
            def: 'goalId',
            visible: true,
            value: (row) => this.goalNamePipe.transform(row.goalId, this.goals),
         },
         {
            label: 'Quarter',
            def: 'quarter',
            visible: true,
            value: (row) => row.quarter,
            filter: 'quarter',
         },
         {
            label: 'Assigned To',
            def: 'assigneeId',
            visible: true,
            value: (row) => this.teamMemberNamePipe.transform(row.assigneeId, this.teamMembers),
         },
         {
            label: 'Status',
            def: 'status',
            visible: true,
            value: (row) => RockStatus[row.status],
            filter: 'status',
         },
         {
            label: 'Start Date',
            def: 'startDate',
            visible: false,
            isDate: true,
            value: (row) => this.datePipe.transform(row.startDate),
         },
         {
            label: 'Target Date',
            def: 'targetDate',
            visible: false,
            isDate: true,
            value: (row) => this.datePipe.transform(row.targetDate),
         },
         {
            label: 'Completed Date',
            def: 'completedDate',
            visible: false,
            isDate: true,
            value: (row) => this.datePipe.transform(row.completedDate),
         },
         {
            label: 'Approved Date',
            def: 'approvedDate',
            visible: false,
            isDate: true,
            value: (row) => this.datePipe.transform(row.approvedDate),
         },
         {
            label: 'Next Milestone',
            def: 'nextAction',
            visible: true,
            isDate: true,
            value: (row) => this.datePipe.transform(this.nextAction(row)),
         },
      ],
      [WidgetEntities.Tasks]: [
         { label: 'Task', def: 'name', visible: true, value: (row) => row.name },
      ],
      [WidgetEntities['Team Members']]: [
         { def: 'name', label: 'First Name', visible: true, value: (row) => row.firstName },
         { def: 'lastName', label: 'Last Name', visible: true, value: (row) => row.lastName },
         { def: 'title', label: 'Title', visible: true, value: (row) => row.title },
         {
            def: 'primaryDepartment.name',
            label: 'Department',
            visible: true,
            value: (row) =>
               this.departmentNamePipe.transform(row.primaryDepartmentId, this.departments),
            filter: 'departments',
         },
         {
            def: 'status',
            label: 'Status',
            visible: false,
            value: (row) => TeamMemberStatus[row.status],
         },
      ],
   };

   nextAction(rock: Rock) {
      if (rock.actions && rock.actions.length > 0) {
         const actionDates = rock.actions
            .filter((a) => !a.completed)
            .map((a) => a.targetDate)
            .sort((a, b) => (a || '').localeCompare(b));
         return actionDates[0];
      } else {
         return undefined;
      }
   }
}
