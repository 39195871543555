import { Route } from '@angular/router';
import { internalToolsRouteNames } from './internal-tools.routes.names';
import { FeatureFlagsComponent } from './components/feature-flags/feature-flags.component';
import { AuthUsersInternalComponent } from './components/auth-users-internal/auth-users-internal.component';
import { OrganizationsInternalComponent } from './components/organizations-internal/organizations-internal.component';

export const INTERNAL_TOOLS_ROUTES: Route[] = [
   { path: internalToolsRouteNames.FEATURE_FLAGS, component: FeatureFlagsComponent },
   { path: internalToolsRouteNames.AUTH_USERS, component: AuthUsersInternalComponent },
   { path: internalToolsRouteNames.ORGANIZATIONS, component: OrganizationsInternalComponent },
   { path: '', redirectTo: internalToolsRouteNames.FEATURE_FLAGS, pathMatch: 'full' },
];
