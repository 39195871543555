import { TeamState } from './team.state';
import { TeamMember } from '@entities/team-member';
import * as TeamActions from './team.actions';
import { on, createReducer, Action } from '@ngrx/store';
import * as AuthActions from '@app/auth/state/auth.actions';
import { CompetencyLevel } from '@entities/competency-level';

const defaultCompetencyLevels: CompetencyLevel[] = [
   { id: null, value: 0, label: 'No Experience' },
   { id: null, value: 1, label: 'In Training' },
   { id: null, value: 2, label: 'Proficient' },
   { id: null, value: 3, label: 'Expert' },
];

export const teamInitialState: TeamState = {
   teamMembers: [],
   selectedTeamMemberId: null,
   roles: [],
   selectedRoleId: null,
   competencies: [],
   selectedCompetencyId: null,
   performanceEvaluations: [],
   selectedPerformanceEvaluationId: null,
   competencyLevels: defaultCompetencyLevels,
   selectedTaskRatingTreeIds: [],
   tasksFilter: {},
   teamMembersDisplayedColumns: [],
};

export function reducer(state: TeamState | undefined, action: Action) {
   return createReducer(
      teamInitialState,
      on(TeamActions.TeamMembersUpdated, (state, { teamMembers }) => {
         return {
            ...state,
            teamMembers,
         };
      }),
      on(TeamActions.SelectTeamMember, TeamActions.EditTeamMember, (state, { teamMemberId }) => {
         return {
            ...state,
            selectedTeamMemberId: teamMemberId,
         };
      }),
      on(TeamActions.RolesUpdated, (state, { roles }) => {
         return {
            ...state,
            roles,
         };
      }),
      on(TeamActions.SelectRole, TeamActions.EditRole, (state, { roleId }) => {
         return {
            ...state,
            selectedRoleId: roleId,
         };
      }),
      on(TeamActions.CompetenciesUpdated, (state, { competencies }) => {
         return {
            ...state,
            competencies,
         };
      }),
      on(TeamActions.SelectCompetency, TeamActions.EditCompetency, (state, { competencyId }) => {
         return {
            ...state,
            selectedCompetencyId: competencyId,
         };
      }),
      on(TeamActions.PerformanceEvaluationsUpdated, (state, { performanceEvaluations }) => {
         return {
            ...state,
            performanceEvaluations,
         };
      }),
      on(
         TeamActions.EditPerformanceEvaluation,
         TeamActions.SelectPerformanceEvaluation,
         (state, { performanceEvaluationId }) => {
            return {
               ...state,
               selectedPerformanceEvaluationId: performanceEvaluationId,
            };
         }
      ),
      on(TeamActions.CompetencyLevelsUpdated, (state, { competencyLevels }) => {
         return { ...state, competencyLevels };
      }),
      on(TeamActions.SelectTaskRatingTreeItem, (state, { ids }) => {
         return { ...state, selectedTaskRatingTreeIds: ids };
      }),
      on(TeamActions.SetTeamTasksFilter, (state, { filter }) => {
         return { ...state, tasksFilter: filter };
      }),
      on(TeamActions.SetDisplayedColumns, (state, { columns }) => {
         return {
            ...state,
            teamMembersDisplayedColumns: columns,
         };
      }),
      on(AuthActions.AuthUserUpdated, (state, { authUser }) => {
         return authUser ? state : teamInitialState;
      })
   )(state, action);
}
