import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Task } from '@entities/task';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterValues, FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { TableComponent, TableConfig } from '@app/shared/components/table/table.component';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { TaskDocumentationViewModel } from '@app/documentation/state/documentation.models';
import { RoleGuard } from '@app/admin/services/role.guard';
import { takeUntil } from 'rxjs/operators';
import { TeamMember } from '@entities/team-member';

const initialSort: Sort = { active: 'task.name', direction: 'asc' };

@Component({
   selector: 'app-documentation-list',
   templateUrl: './documentation-list.component.html',
   styleUrls: ['./documentation-list.component.scss'],
})
export class DocumentationTaskListComponent implements OnInit {
   @ViewChild(TableComponent) table: TableComponent;
   tasks$: Observable<Task[]>;
   taskFilter$: Observable<FilterValues>;

   teamMembers: TeamMember[] = [];

   filterBarConfig: FilterBarConfig = {
      businessUnits: true,
      departments: true,
      departmentFunctions: true,
      filterChange: (filter) => this.filterFormChanged(filter),
   };

   displayedColumns = [
      'task.name',
      'departmentFunction.name',
      'department.name',
      'businessUnit.name',
      'options',
   ];

   displayedColumns$ = this.documentationFacade.taskDisplayedColumns$;

   /* istanbul ignore next */
   columns: TableColumn<TaskDocumentationViewModel>[] = [
      { def: 'task.name', label: 'Task', visible: true, value: (row) => row.task?.name },
      {
         def: 'departmentFunction.name',
         label: 'Function',
         visible: true,
         value: (row) => row.departmentFunction?.name,
         filter: 'departmentFunctions',
      },
      {
         def: 'department.name',
         label: 'Department',
         visible: true,
         value: (row) => row.department?.name,
         filter: 'departments',
      },
      {
         def: 'businessUnit.name',
         label: 'Business Unit',
         visible: true,
         value: (row) => row.businessUnit?.name,
         filter: 'businessUnits',
      },
      {
         def: 'task.assignees',
         label: 'Assigned To',
         visible: true,
         value: (row, csv) => this.getAssignees(row.task, csv),
      },
   ];

   tableConfig: TableConfig;

   canEdit = false;

   private destroyed$ = new Subject<void>();

   constructor(
      private documentationFacade: DocumentationFacade,
      private route: ActivatedRoute,
      private router: Router,
      private roleGuard: RoleGuard
   ) {}

   ngOnInit() {
      this.taskFilter$ = this.documentationFacade.taskFilter$;
      this.roleGuard
         .canEdit()
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
         });

      /* istanbul ignore next */
      this.tableConfig = {
         columns: this.columns,
         data$: this.documentationFacade.tasksViewModel$,
         filter$: this.documentationFacade.taskFilter$,
         sort$: this.documentationFacade.taskSort$,
         initialSort: { active: 'task.name', direction: 'asc' },
         rowClick: (row) => this.edit(row.task),
         sortChange: (sort) => this.documentationFacade.setTaskSort(sort),
         visibleColumns$: this.displayedColumns$,
         options: [
            {
               label: 'Edit',
               action: (row) => this.edit(row.task),
               condition: (row) => this.canEdit,
            },
            {
               label: 'Details',
               action: (row) => this.edit(row.task),
               condition: (row) => !this.canEdit,
            },
            { label: 'View Report', action: (row) => this.summary(row.task) },
            { label: 'Delete', action: (row) => this.delete(row.task) },
         ],
      };

      this.documentationFacade.teamMembers$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((teamMembers) => {
            this.teamMembers = teamMembers;
         });
   }

   filterFormChanged(filter: FilterValues) {
      this.documentationFacade.setTaskFilter(filter);
   }

   edit(task: Task) {
      this.router.navigate([task.id], {
         relativeTo: this.route,
         state: {
            source: this.router.url,
         },
      });
   }

   summary(task: Task) {
      this.documentationFacade.selectTask(task.id);
      this.router.navigate([task.id, 'report'], {
         relativeTo: this.route,
         state: {
            source: this.router.url,
         },
      });
   }

   delete(task: Task) {
      this.documentationFacade.deleteTask(task);
   }

   getAssignees(task: Task, csv: boolean) {
      let assignees = '';
      if (task.assignees) {
         for (let i = 0; i < task.assignees.length; i++) {
            const teamMember = this.teamMembers.find((t) => t.id === task.assignees[i]);
            if (teamMember) {
               if (i === task.assignees.length - 1) {
                  assignees += `${i > 0 ? 'and ' : ''}${teamMember.firstName} ${
                     teamMember.lastName
                  }`;
               } else if (i < 2 || csv) {
                  assignees += `${teamMember.firstName} ${teamMember.lastName}${
                     i < task.assignees.length - 2 ? ',' : ''
                  } `;
               } else {
                  assignees += `and ${task.assignees.length - i} others`;
                  break;
               }
            }
         }
      }
      return assignees;
   }

   setDisplayedColumns(columns: TableColumn<Task>[]) {
      this.documentationFacade.setTaskDisplayedColumns(columns.map((col) => col.def));
   }

   export(filterData: boolean) {
      this.table.export(filterData);
   }
}
