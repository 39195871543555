import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'nameById',
})
export class NameByIdPipe implements PipeTransform {
   transform(value: string, entities: { id: string; name: string }[]): string {
      const match = entities.find((e) => e.id === value);
      return match ? match.name : '';
   }
}
