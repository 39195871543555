import {
   Component,
   Input,
   OnChanges,
   OnDestroy,
   OnInit,
   SimpleChanges,
   TemplateRef,
   ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleGuard } from '@app/admin/services/role.guard';
import { TableConfig } from '@app/shared/components/table/table.component';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { DepartmentNamePipe } from '@app/shared/pipes/department-name.pipe';
import { TeamFacade } from '@app/team/state/team.facade';
import { teamRouteNames } from '@app/team/team.routes.names';
import { Department } from '@entities/department';
import { AppAreas } from '@entities/enums/app-areas';
import { TeamMember } from '@entities/team-member';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
   selector: 'app-direct-reports',
   templateUrl: './direct-reports.component.html',
   styleUrls: ['./direct-reports.component.scss'],
})
export class DirectReportsComponent implements OnInit, OnChanges, OnDestroy {
   @Input() teamMember: TeamMember;
   @Input() departments: Department[];

   @ViewChild('addDirectReportDialogTemplate') addDirectReportDialogTemplate: TemplateRef<any>;
   addDirectReportFormControl = new UntypedFormControl();
   idToAdd: string;

   teamMember$ = new BehaviorSubject(null);
   dataSource$: Observable<TeamMember[]>;
   filter$ = of({});
   visibleColumns$ = of([]);
   canEdit = false;

   columns: TableColumn<TeamMember>[] = [
      { def: 'firstName', label: 'First Name', value: (row) => row.firstName, visible: true },
      {
         def: 'lastName',
         label: 'Last Name',
         value: (row) => row.lastName,
         visible: true,
      },
      {
         def: 'department',
         label: 'Department',
         value: (row) =>
            this.departmentNamePipe.transform(row.primaryDepartmentId, this.departments),
         visible: true,
      },
   ];
   tableConfig: TableConfig;

   private destroyed$ = new Subject<void>();

   constructor(
      public departmentNamePipe: DepartmentNamePipe,
      private teamFacade: TeamFacade,
      private roleGuard: RoleGuard,
      private router: Router,
      private route: ActivatedRoute,
      private dialog: MatDialog
   ) {}

   ngOnInit(): void {
      this.roleGuard
         .canEdit([], AppAreas.Team, [this.teamMember?.id, this.teamMember?.managerId])
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
         });
      this.setDataSource();
      /* istanbul ignore next */
      this.tableConfig = {
         data$: this.dataSource$,
         filter$: this.filter$,
         columns: this.columns,
         visibleColumns$: this.visibleColumns$,
         initialSort: { active: 'lastName', direction: 'asc' },
         rowClick: (row) => this.edit(row),
         departments: this.departments,
         options: [
            { label: 'Edit', action: (row) => this.edit(row), condition: () => this.canEdit },
            { label: 'Details', action: (row) => this.edit(row), condition: () => !this.canEdit },
            { label: 'View Role Description', action: (row) => this.viewRoleDescription(row) },
            { label: 'Remove Direct Report', action: (row) => this.removeDirectReport(row) },
         ],
      };
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['teamMember']) {
         this.teamMember$.next(this.teamMember);
         this.setDataSource();
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   setDataSource() {
      this.dataSource$ = combineLatest([this.teamFacade.teamMembers$, this.teamMember$]).pipe(
         map(([teamMembers, teamMember]) => {
            return teamMembers.filter((t) => this.teamMember?.directReports?.includes(t.id));
         })
      );
   }

   edit(teamMember: TeamMember) {
      this.teamFacade.selectTeamMember(teamMember.id);
      this.router.navigate([teamMember.id], { relativeTo: this.route.parent });
   }

   viewRoleDescription(teamMember: TeamMember) {
      this.teamFacade.selectTeamMember(teamMember.id);
      this.router.navigate([teamMember.id, teamRouteNames.REPORT], {
         relativeTo: this.route.parent,
      });
   }

   addDirectReport() {
      this.dialog
         .open(this.addDirectReportDialogTemplate)
         .afterClosed()
         .subscribe((confirm) => {
            if (confirm) {
               if (this.idToAdd !== this.teamMember.id) {
                  if (!this.teamMember.directReports) {
                     this.teamMember = {
                        ...this.teamMember,
                        directReports: [this.idToAdd],
                     };
                  } else {
                     this.teamMember = {
                        ...this.teamMember,
                        directReports: [
                           ...this.teamMember.directReports.filter((id) => id! == this.idToAdd),
                           this.idToAdd,
                        ],
                     };
                  }
                  this.teamFacade.saveTeamMember(this.teamMember);
               }
            }
            this.idToAdd = null;
         });
   }

   setIdToAdd(event: MatAutocompleteSelectedEvent) {
      this.idToAdd = event?.option?.value?.id;
   }

   removeDirectReport(teamMember: TeamMember) {
      this.teamMember = {
         ...this.teamMember,
         directReports: this.teamMember.directReports.filter((id) => id !== teamMember.id),
      };
      this.teamFacade.saveTeamMember(this.teamMember);
   }
}
