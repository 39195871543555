import { Component, OnInit, Input, Inject } from '@angular/core';
import { DepartmentFunction } from '@entities/department-function';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Task } from '@entities/task';
import { Observable } from 'rxjs';
import { TeamMember } from '@entities/team-member';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Router } from '@angular/router';

@Component({
   selector: 'app-department-function-edit',
   templateUrl: './department-function-edit.component.html',
   styleUrls: ['./department-function-edit.component.scss'],
})
export class DepartmentFunctionEditComponent implements OnInit {
   form: UntypedFormGroup;
   tasks: Task[];
   canEdit$: Observable<boolean>;
   editAction: string;
   private orgBuilderFacade: OrgBuilderFacade;

   constructor(
      @Inject(MAT_DIALOG_DATA)
      data: {
         departmentFunction: DepartmentFunction;
         service: OrgBuilderFacade;
      },
      private router: Router
   ) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         purpose: new UntypedFormControl(),
         description: new UntypedFormControl(),
         benchmark: new UntypedFormControl(),
         departmentId: new UntypedFormControl(),
         leadId: new UntypedFormControl(),
         order: new UntypedFormControl(),
         policies: new UntypedFormControl(),
         kpi: new UntypedFormControl(),
         ratingNotes: new UntypedFormControl(),
      });
      this.form.patchValue(data.departmentFunction);
      if (data.departmentFunction.description && !data.departmentFunction.policies) {
         this.form.get('policies').setValue(data.departmentFunction.description);
      }
      this.orgBuilderFacade = data.service;
      this.editAction = data.departmentFunction.id ? 'Edit' : 'Add';
      this.canEdit$ = this.orgBuilderFacade.canEdit$;
   }

   ngOnInit() {}

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   save() {
      const departmentFunction: DepartmentFunction = this.form.value;
      this.orgBuilderFacade.saveDepartmentFunction(departmentFunction);
   }

   cancel() {
      this.orgBuilderFacade.cancelEditDepartmentFunction();
   }

   setLeadId(event: MatAutocompleteSelectedEvent) {
      if (event) {
         const id = event.option.value.id;
         this.form.get('leadId').setValue(id);
         this.form.markAsDirty();
      } else {
         this.form.get('leadId').reset();
      }
   }

   fullEditor() {
      const deptFnId = this.form.value.id;
      const departmentFunction = this.form.value;
      this.orgBuilderFacade.departmentFunctionFullEditor(departmentFunction, this.router.url);
   }
}
