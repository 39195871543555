import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { goBack } from './history.state';

@Injectable()
export class HistoryFacade {
   constructor(private store: Store) {}

   back() {
      this.store.dispatch(goBack());
   }
}
