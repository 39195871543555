import { Dashboard } from '@entities/dashboard';
import * as root from '@app/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';

export interface State extends root.State {
   dashboard: DashboardState;
}

export interface DashboardState {
   dashboards: Dashboard[];
   selectedDashboardId: string;
   organizationId: string;
   filters: { [key: string]: FilterValues };
}

const getDashboardState = createFeatureSelector<DashboardState>('dashboards');
const getDashboards = createSelector(getDashboardState, (state) => state.dashboards);
const getSelectedDashboard = createSelector(getDashboardState, (state) =>
   state.dashboards.find((d) => d.id == state.selectedDashboardId)
);
const getDashboardFilters = createSelector(getDashboardState, (state) => state.filters);

export const dashboardSelectors = {
   getDashboards,
   getSelectedDashboard,
   getDashboardFilters,
};
