<div class="widget-container">
   <div class="title">Quick Actions</div>

   <button mat-raised-button class="new-button" color="primary" [matMenuTriggerFor]="menu">
      New...
      <mat-icon>arrow_drop_down</mat-icon>
   </button>
   <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="newAction()" data-test-id="new-action">Action</button>
      <!-- <button mat-menu-item>Goal</button> -->
      <button mat-menu-item (click)="newRock()" data-test-id="new-rock">Rock</button>
   </mat-menu>
</div>
