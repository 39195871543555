import { Component, OnInit } from '@angular/core';
import { internalToolsRouteNames } from '@app/internal-tools/internal-tools.routes.names';

@Component({
   selector: 'app-admin-center',
   templateUrl: './admin-center.component.html',
   styleUrls: ['./admin-center.component.scss'],
})
export class AdminCenterComponent implements OnInit {
   routes = [
      { path: internalToolsRouteNames.FEATURE_FLAGS, label: 'Feature Flags' },
      { path: internalToolsRouteNames.AUTH_USERS, label: 'User Accounts' },
      { path: internalToolsRouteNames.ORGANIZATIONS, label: 'Organizations' },
      { path: internalToolsRouteNames.FAQS, label: 'Faqs' },
   ];

   constructor() {}

   ngOnInit(): void {}
}
