<div class="top-buttons">
   <mat-slide-toggle color="primary" (change)="showInactive($event)">
      Show inactive
   </mat-slide-toggle>

   <button
      mat-raised-button
      color="primary"
      class="new-button"
      *ngIf="canEdit"
      (click)="newTeamMember()"
   >
      <mat-icon>add</mat-icon>
      New Team Member
   </button>
</div>
<app-table-header
   [hideExport]="tableConfig.hideExport"
   [filterBar]="filterBarConfig"
   [filter$]="filter$"
   [columns]="columns"
   [displayedColumns]="displayedColumns$ | async"
   (columnsUpdated)="setDisplayedColumns($event)"
   (exportData)="export($event)"
></app-table-header>
<app-table [config]="tableConfig"></app-table>
