<div mat-dialog-title>Reset Password</div>
<form #form="ngForm">
  <mat-dialog-content>
    <ng-container *ngIf="!emailSent">
      <p>Please enter your email address</p>
      <mat-form-field>
        <input
          matInput
          required
          email
          type="email"
          placeholder="email"
          [(ngModel)]="email"
          name="email"
        />
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="emailSent">
      <p>A password reset email has been sent. Please check your email for instructions.</p>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      *ngIf="!emailSent"
      mat-raised-button
      color="primary"
      (click)="reset()"
      [disabled]="!form.valid"
    >
      Reset
    </button>
    <button mat-stroked-button color="primary" (click)="close()">Close</button>
  </mat-dialog-actions>
</form>
