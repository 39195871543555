<mat-form-field>
   <input
      matInput
      type="text"
      [formControl]="formCtrl"
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      [required]="required"
      [readonly]="!canEdit"
      [matAutocompleteDisabled]="!canEdit"
      (focus)="onFocus()"
      name="department"
   />
   <span *ngIf="selected && canEdit" class="clear-selection" (click)="clear()">
      <mat-icon>close</mat-icon>
   </span>
   <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="onOptionSelect($event)"
   >
      <mat-optgroup *ngFor="let businessUnit of filtered$ | async" [label]="businessUnit.name">
         <mat-option *ngFor="let department of businessUnit.departments" [value]="department">
            {{ department.name }}
         </mat-option>
      </mat-optgroup>
   </mat-autocomplete>
</mat-form-field>
