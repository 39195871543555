import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppAreas } from '@entities/enums/app-areas';
import { appRoutesNames } from '@app/app.routes.names';
import { LayoutFacade } from '@app/layout/state/layout.facade';
import { teamRouteNames } from '@app/team/team.routes.names';
import { ActivatedRoute } from '@angular/router';
import { documentationRouteNames } from '@app/documentation/documentation.routes.names';
import { evolveRouteNames } from '@app/evolve/evolve.routes.names';
import { navConfig } from '@app/layout/state/layout.config';
import { ProductType } from '@entities/enums/product-type';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
   selector: 'app-sidenav',
   templateUrl: './sidenav.component.html',
   styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
   loggedIn$: Observable<boolean>;
   orgSelected$: Observable<boolean>;
   internalUser$: Observable<boolean>;
   routeNames = appRoutesNames;
   appAreas = AppAreas;
   expanded$: Observable<boolean>;

   navConfig = navConfig;

   constructor(private layoutFacade: LayoutFacade, private currentRoute: ActivatedRoute) {}

   ngOnInit() {
      this.loggedIn$ = this.layoutFacade.loggedIn$;
      this.expanded$ = this.layoutFacade.sidenavOpen$;
      this.internalUser$ = this.layoutFacade.currentUser$.pipe(
         map((authUser) => (authUser ? authUser.isInternalUser : false))
      );
      this.orgSelected$ = this.layoutFacade.orgId$.pipe(map((orgId) => !!orgId));
   }

   toggleExpanded() {
      this.layoutFacade.toggleSidenav();
   }

   canView(config: any) {
      return this.layoutFacade.canView(config.products, config.area);
   }

   logout() {
      this.layoutFacade.logout();
   }

   hideSidenav() {
      this.layoutFacade.hideSidenav();
   }

   getRoute(route: string) {
      return this.layoutFacade.orgId$.pipe(map((orgId) => `/org/${orgId}/${route}`));
   }
   isActive(route: string) {
      try {
         const url = this.currentRoute.snapshot.firstChild.firstChild.url[0];
         return url.path == route;
      } catch {
         return false;
      }
   }
}
