import { Component, OnInit } from '@angular/core';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';
import { Observable } from 'rxjs';
import { RockAction } from '@entities/rock-action';
import { map } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { TeamMember } from '@entities/team-member';

@Component({
   selector: 'app-assigned-milestones-widget',
   templateUrl: './assigned-milestones-widget.component.html',
   styleUrls: ['./assigned-milestones-widget.component.scss'],
})
export class AssignedMilestonesWidgetComponent implements OnInit {
   assignedActions$: Observable<any[]>;
   teamMemberLinked$: Observable<boolean>;
   displayedColumns = ['name', 'targetDate'];
   teamMember: TeamMember;

   constructor(private facade: DashboardFacade) {}

   ngOnInit(): void {
      this.assignedActions$ = this.facade.assignedMilestones$;
      this.teamMemberLinked$ = this.facade.user$.pipe(
         map((user) => user && user.teamMemberId != null)
      );
   }

   goToItem(row: any) {
      this.facade.goToRock(row.rockId);
   }

   setLinkedTeamMember(event: MatAutocompleteSelectedEvent) {
      if (event && event.option && event.option.value) {
         this.teamMember = event.option.value;
      } else {
         this.teamMember = null;
      }
   }

   linkTeamMember() {
      this.facade.linkTeamMember(this.teamMember);
   }
}
