<h3 mat-dialog-title>
   {{ newTask ? 'Add' : 'Edit' }} Task
   <button class="float-right" (click)="fullEditor()" mat-button color="primary">
      View Full Editor
   </button>
</h3>

<form [formGroup]="form" (ngSubmit)="save()">
   <mat-dialog-content>
      <mat-form-field>
         <input
            appAutoFocus
            matInput
            type="text"
            required
            placeholder="Name"
            formControlName="name"
         />
      </mat-form-field>
      <mat-form-field>
         <input matInput type="text" placeholder="Purpose" formControlName="purpose" />
      </mat-form-field>
   </mat-dialog-content>
   <mat-dialog-actions>
      <div class="button-container">
         <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">
            Save
         </button>
         <button type="button" mat-raised-button (click)="cancel()">Cancel</button>
      </div>
   </mat-dialog-actions>
</form>
