import { createFeatureSelector, createSelector, combineReducers, Action } from '@ngrx/store';
import * as goal from './goal/goal.reducer';
import * as rock from './rock/rock.reducer';
import * as actionItem from './action-item/action-item.reducer';
import { GoalEffects } from './goal/goal.effects';
import { RockEffects } from './rock/rock.effects';

import * as AuthActions from '@app/auth/state/auth.actions';
import { ActionItemEffects } from './action-item/action-item.effects';
import { GoalLevel } from '@entities/enums/goal-level';
import { stat } from 'fs';

export interface State {
   evolve: EvolveState;
}

export interface EvolveState {
   goals: goal.GoalState;
   rocks: rock.RockState;
   actionItems: actionItem.ActionItemState;
}

export const evolveInitialState: EvolveState = {
   goals: goal.initialState,
   rocks: rock.initialState,
   actionItems: actionItem.initialState,
};

export function reducers(state: EvolveState | undefined, action: Action) {
   if (action.type == AuthActions.AuthUserUpdated.type) {
      return (<any>action).authUser ? state : evolveInitialState;
   } else {
      return combineReducers({
         goals: goal.reducer,
         rocks: rock.reducer,
         actionItems: actionItem.reducer,
      })(state, action);
   }
}

export const effects = [GoalEffects, RockEffects, ActionItemEffects];

const getEvolveState = createFeatureSelector<EvolveState>('evolve');
const getRockState = createSelector(getEvolveState, (state) => state.rocks);
const getRocks = createSelector(getRockState, (state) => state.entities);
const getSelectedRock = createSelector(getRockState, (state) =>
   state.entities.find((r) => r.id == state.selectedRockId)
);
const getRocksFilter = createSelector(getRockState, (state) => state.filter);
const getRocksActiveTab = createSelector(getRockState, (state) => state.activeTab);
const getRocksVisibleColumns = createSelector(getRockState, (state) => state.visibleColumns);
const getRocksSort = createSelector(getRockState, (state) => state.sort);

const getGoalState = createSelector(getEvolveState, (state) => state.goals);
const getGoals = createSelector(getGoalState, (state) => state.entities);
const getSelectedGoal = createSelector(getGoalState, (state) =>
   state.entities.find((g) => g.id === state.selectedGoalId)
);
const getGoalsEditIds = createSelector(getGoalState, (state) => state.editRows.map((g) => g.id));
const getGoalsEditRows = createSelector(getGoalState, (state) => state.editRows);
const getOrganizationGoals = createSelector(getGoals, (goals) =>
   goals.filter((g) => g.level == undefined || g.level === GoalLevel.Organization)
);
const getBusinessUnitGoals = createSelector(getGoals, (goals) =>
   goals.filter((g) => g.level === GoalLevel['Business Unit'])
);
const getDepartmentGoals = createSelector(getGoals, (goals) =>
   goals.filter((g) => g.level === GoalLevel.Department)
);
const getOrganizationGoalsFilter = createSelector(getGoalState, (state) => state.orgFilter);
const getBusinessUnitGoalsFilter = createSelector(getGoalState, (state) => state.buFilter);
const getDepartmentGoalsFilter = createSelector(getGoalState, (state) => state.deptFilter);
const getGoalsActiveTab = createSelector(getGoalState, (state) => state.activeTab);

const getActionItemState = createSelector(getEvolveState, (state) => state.actionItems);
const getActionItems = createSelector(getActionItemState, (state) => state.entities);
const getSelectedActionItem = createSelector(getActionItemState, (state) =>
   state.entities.find((i) => i.id == state.selectedActionItemId)
);
const getActionItemsFilter = createSelector(getActionItemState, (state) => state.filter);
const getActionItemsVisibleColumns = createSelector(
   getActionItemState,
   (state) => state.visibleColumns
);
const getActionsSort = createSelector(getActionItemState, (state) => state.sort);

export const evolveSelectors = {
   getRocks,
   getSelectedRock,
   getRocksActiveTab,
   getRocksVisibleColumns,
   getRocksSort,
   getGoals,
   getSelectedGoal,
   getGoalsEditIds,
   getGoalsEditRows,
   getActionItems,
   getSelectedActionItem,
   getRocksFilter,
   getActionItemsFilter,
   getOrganizationGoals,
   getBusinessUnitGoals,
   getDepartmentGoals,
   getOrganizationGoalsFilter,
   getBusinessUnitGoalsFilter,
   getDepartmentGoalsFilter,
   getGoalsActiveTab,
   getActionItemsVisibleColumns,
   getActionsSort,
};
