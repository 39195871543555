export enum AppAreas {
   Admin = 'Admin',
   Documentation = 'Documentation',
   Evolve = 'Evolve',
   Organization = 'Organization',
   Overview = 'Overview',
   Team = 'Team',
   Performance = 'Performance',
   Dashboard = 'Dashboard',
   Meetings = 'Meetings',
}
