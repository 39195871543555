import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { User } from '@entities/user';
import { UserService } from '@app/user-org/services/user.service';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { adminSelectors } from './admin.selectors';
import { UserInvite } from '@entities/user-invite';
import { UserInviteService } from '../services/user-invite.service';
import { Organization } from '@entities/organization';
import { FunctionNames } from '@app/utilities/functionNames';
import { BillingInfo } from '@entities/billing-info';
import * as moment from 'moment';
import { DialogService } from '@app/shared/services/dialog.service';
import { takeUntil, map } from 'rxjs/operators';
import { Product } from '@entities/product';
import { PlanDetails } from '@entities/plan-details';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { adminActions } from './admin.actions';
import { stringify } from 'flatted';
import { environment } from '@src/environments/environment';
import { FunctionsService } from '@app/shared/services/functions.service';
import { PermissionGroup } from '@entities/permission-group';
import { defaultPermissionGroups } from '../components/manage-permissions/default-permission-groups';
import { PermissionsFacade } from '@app/shared/state/permissions.facade';

@Injectable()
export class AdminFacade implements OnDestroy {
   users$: Observable<User[]> = this.store.pipe(select(adminSelectors.getOrgUsers));
   selectedUser$: Observable<User> = this.store.pipe(select(adminSelectors.getEditUser));
   permissionGroups$ = this.permissionsFacade.permissionGroups$;
   unassignedUsers$ = this.permissionsFacade.unassignedUsers$;

   orgPlans$: Observable<PlanDetails[]>;

   invites$: Observable<UserInvite[]>;
   products$: Observable<Product[]>;
   billingInfo$: Observable<BillingInfo>;
   orgId$: Observable<string>;

   private destroyed$ = new Subject<void>();

   constructor(
      private store: Store<State>,
      private userService: UserService,
      private functions: FunctionsService,
      private userInviteService: UserInviteService,
      private dialogService: DialogService,
      private userOrgFacade: UserOrgFacade,
      private permissionsFacade: PermissionsFacade
   ) {
      this.userService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((users) => {
         this.store.dispatch(adminActions.UsersUpdated({ users }));
      });
      this.invites$ = this.userInviteService.entities$;

      this.orgPlans$ = this.userOrgFacade.selectedOrg$.pipe(
         map((org) => {
            if (org && org.billingInfo && org.billingInfo.plans) {
               return org.billingInfo.plans;
            } else {
               return [];
            }
         })
      );
      this.billingInfo$ = this.userOrgFacade.selectedOrg$.pipe(
         map((org) => {
            if (org && org.billingInfo) {
               return org.billingInfo;
            } else {
               return null;
            }
         })
      );
      this.orgId$ = this.userOrgFacade.selectedOrgId$;
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   addUser() {
      this.store.dispatch(adminActions.AddUser());
   }
   editUser(user: User) {
      this.store.dispatch(adminActions.EditUser({ user }));
   }
   saveUser(user: User) {
      this.store.dispatch(adminActions.SaveUser({ user }));
   }
   deactivateUser(user: User) {
      user.active = false;
      this.store.dispatch(adminActions.CloseEditUser());
      this.store.dispatch(adminActions.SaveUser({ user }));
      this.store.dispatch(adminActions.DeactivateUser({ userAccountId: user.userAccountId }));
   }

   reactivateUser(user: User) {
      user.active = true;
      this.store.dispatch(adminActions.CloseEditUser());
      this.store.dispatch(adminActions.SaveUser({ user }));
      this.store.dispatch(adminActions.ReactivateUser({ userAccountId: user.userAccountId }));
   }

   cancelEditUser() {
      this.store.dispatch(adminActions.CloseEditUser());
   }
   deleteUser(user: User) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete User?',
            message: 'Are you sure you want to delete this user? This action cannot be undone.',
            confirm: 'Yes, delete',
            deny: 'No, go back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(adminActions.DeleteUser({ user }));
            }
         });
   }

   sendInvite(invite: any, createTeamMember = false) {
      this.store.dispatch(adminActions.SendInvite({ invite, createTeamMember }));
   }

   saveOrgInfo(org: Organization) {}

   updateBillingInfo(customerId: string, email: string, token: any) {
      return from(
         this.functions.httpsCallable(FunctionNames.UPDATE_BILLING_INFO)({
            customerId,
            email,
            token,
         })
      );
   }

   saveBillingInfo(billingInfo: BillingInfo) {
      this.store.dispatch(adminActions.SaveBillingInfo({ billingInfo }));
   }

   resendInvite(invite: UserInvite) {
      invite.sent = moment().toISOString();
      this.userInviteService.save(invite).then(() => {
         this.functions.httpsCallable(FunctionNames.RESEND_INVITE)({
            inviteId: invite.id,
            organizationId: invite.organizationId,
         });
      });
   }

   deleteInvite(invite: UserInvite) {
      this.store.dispatch(adminActions.DeleteInvite({ invite }));
   }

   checkPromoCode(promoCode: string) {
      return from(this.functions.httpsCallable(FunctionNames.GET_PROMO_CODE)({ promoCode })).pipe(
         map((response: any) => {
            if (response?.data?.discount) {
               return response.data.discount;
            } else {
               let message = 'We could not apply that promo code to this organization. ';
               if (environment.name != 'PROD' && response.error && response.error.raw) {
                  message += response.error.raw.message;
               }
               throw new Error(message);
            }
         })
      );
   }

   updatePlans(billingInfo: BillingInfo, orgId: string) {
      return from(
         this.functions.httpsCallable(FunctionNames.UPDATE_PLANS)({
            subscriptionId: billingInfo.subscriptionId,
            plans: billingInfo.plans,
            coupon: billingInfo.coupon,
            orgId,
         })
      ).subscribe((response: any) => {
         if (!response?.subscriptionId) {
            throw new Error('Error: ' + stringify(response.error));
         }
      });
   }

   deleteOrganization() {}

   addDefaultPermissionGroups() {
      this.store.dispatch(adminActions.SetDefaultPermissionGroups());
   }

   editPermissionGroup(permissionGroup: PermissionGroup) {
      this.store.dispatch(adminActions.EditPermissionGroup({ permissionGroup }));
   }

   newPermissionGroup() {
      this.store.dispatch(adminActions.NewPermissionGroup());
   }

   savePermissionGroup(permissionGroup: PermissionGroup) {
      this.store.dispatch(adminActions.SavePermissionGroup({ permissionGroup }));
   }

   assignUsersToPermissionGroup(permissionGroup: PermissionGroup) {
      this.store.dispatch(adminActions.AssignUsersToPermissionGroup({ permissionGroup }));
   }

   addUserToGroup(user: User) {
      this.store.dispatch(adminActions.AddUserToGroup({ user }));
   }

   deletePermissionGroup(permissionGroup: PermissionGroup) {
      this.store.dispatch(adminActions.DeletePermissionGroup({ permissionGroup }));
   }
}
