import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { environment } from '@src/environments/environment';
import { AdminFacade } from '@app/admin/state/admin.facade';
import { BillingInfo } from '@entities/billing-info';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { PlanInterval } from '@entities/enums/plan-interval';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { cloneDeep } from 'lodash';

@Component({
   selector: 'app-plan-select',
   templateUrl: './plan-select.component.html',
   styleUrls: ['./plan-select.component.scss'],
})
export class PlanSelectComponent implements OnInit, OnDestroy {
   showAnnual = false;
   process = 0;
   evolve = 0;
   complete = 1;
   products = environment.stripe.products;
   quantities: number[] = Array(environment.stripe.products.length).fill(0);
   assigned: number[] = Array(environment.stripe.products.length).fill(0);
   promoCode: string;
   coupon: string;
   discount: any;
   editPromo = false;
   checkingPromo = false;
   pristine = true;
   showError: boolean[] = [false, false, false];
   promoError: string;
   billingInfo: BillingInfo;
   orgId: string;

   @Input() signup = false;
   @Output() planSelected = new EventEmitter<any>();

   private destroyed$ = new Subject<void>();

   constructor(private adminFacade: AdminFacade) {}

   ngOnInit() {
      if (this.signup) {
         this.quantities[0] = 1;
      } else {
         combineLatest([this.adminFacade.billingInfo$, this.adminFacade.users$])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([billingInfo, users]) => {
               if (billingInfo && billingInfo.plans) {
                  this.billingInfo = cloneDeep(billingInfo);
                  this.showAnnual = billingInfo.billingFrequency == PlanInterval.YEARLY;
                  this.promoCode = billingInfo.coupon;
                  this.checkPromoCode();
                  for (let i = 0; i < this.products.length; i++) {
                     const product = this.products[i];
                     const planDetails = cloneDeep(
                        this.billingInfo.plans.find((p) => p.productType == product.type)
                     );
                     if (planDetails) {
                        const assigned = users.filter(
                           (user) => user.active && user.product === planDetails.productType
                        ).length;
                        planDetails.assigned = assigned;
                        planDetails.available = planDetails.quantity - assigned;
                        this.quantities[i] = planDetails.quantity;
                        this.assigned[i] = planDetails.assigned;
                     }
                  }
               }
            });
         this.adminFacade.orgId$.pipe(takeUntil(this.destroyed$)).subscribe((orgId) => {
            this.orgId = orgId;
         });
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   changeValue(event: MatSliderChange, index) {
      this.pristine = false;
      const newValue = event.value;
      if (newValue < this.assigned[index]) {
         this.showError[index] = true;
         setTimeout(() => {
            this.quantities[index] = this.assigned[index];
         }, 100);
      } else {
         this.showError[index] = false;
      }
   }

   totalUsers() {
      return this.quantities.reduce((a, b) => a + b);
   }

   saveDisabled() {
      return (
         (!this.signup && this.pristine) ||
         this.totalUsers() == 0 ||
         (this.promoCode && !this.coupon)
      );
   }

   billingAmount() {
      return this.quantities
         .map((val, index) => val * this.getPrice(index))
         .reduce((a, b) => a + b);
   }

   getPrice(index: number) {
      const product = this.products[index];
      if (product) {
         if (this.showAnnual) {
            return product.plans[1].price;
         } else {
            return product.plans[0].price;
         }
      } else {
         return 0;
      }
   }

   applyDiscount() {
      let price = this.billingAmount();
      if (this.discount) {
         if (this.discount.percentOff) {
            price = +((1 - this.discount.percentOff / 100) * price).toFixed(2);
         } else if (this.discount.amountOff) {
            price = Math.max(0, price - this.discount.amountOff);
         }
      }
      return price;
   }

   selectPlans() {
      const plans = [];
      this.pristine = true;
      const billingFrequency = this.showAnnual ? PlanInterval.YEARLY : PlanInterval.MONTHLY;
      for (let index = 0; index < this.products.length; index++) {
         const val = this.products[index];
         if (this.signup) {
            const plan = this.showAnnual
               ? this.products[index].plans[1]
               : this.products[index].plans[0];
            plans.push({
               productId: val.id,
               productType: val.type,
               planId: plan.id,
               quantity: this.quantities[index],
               available: this.quantities[index],
            });
         } else {
            const planDetails = cloneDeep(
               this.billingInfo.plans.find((p) => p.productType == val.type)
            );
            if (planDetails) {
               const updatedPlanDetails = {
                  ...planDetails,
                  quantity: this.quantities[index],
                  available: this.quantities[index] - this.assigned[index],
               };
               plans.push(updatedPlanDetails);
            } else {
               const plan = this.showAnnual
                  ? this.products[index].plans[1]
                  : this.products[index].plans[0];
               plans.push({
                  productId: val.id,
                  productType: val.type,
                  planId: plan.id,
                  quantity: this.quantities[index],
                  available: this.quantities[index],
               });
            }
         }
      }
      if (this.signup) {
         this.planSelected.emit({
            plans,
            coupon: this.coupon,
            billingFrequency,
         });
      } else {
         const billingInfo = {
            ...this.billingInfo,
            plans,
            billingFrequency,
            coupon: this.coupon,
         };
         this.adminFacade.saveBillingInfo(billingInfo);
         this.adminFacade.updatePlans(billingInfo, this.orgId);
      }
   }

   updatePromoCode() {
      this.editPromo = true;
   }

   clearPromoCode() {
      this.promoCode = null;
   }

   checkPromoCode() {
      if (this.promoCode) {
         this.checkingPromo = true;
         this.promoError = null;
         this.adminFacade.checkPromoCode(this.promoCode).subscribe(
            (discount) => {
               this.discount = discount;
               this.coupon = this.promoCode;
               this.checkingPromo = false;
               if (!this.signup) {
                  this.editPromo = false;
                  this.pristine = false;
               }
            },
            (error: Error) => {
               this.promoError = error.message;
               this.checkingPromo = false;
               this.promoCode = null;
            }
         );
      } else {
         this.promoCode = null;
         this.coupon = null;
         this.discount = null;
         this.editPromo = false;
      }
   }
}
