import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { BillingInfoComponent } from '../billing-info/billing-info.component';
import { OrgInfoComponent } from '../org-info/org-info.component';
import { DeleteOrganizationComponent } from '../delete-organization/delete-organization.component';
import { PlanSelectComponent } from '../plan-select/plan-select.component';
import { CompetencyLevelsComponent } from '../competency-levels/competency-levels.component';
import { ResetPerformanceRatingsComponent } from '../reset-performance-ratings/reset-performance-ratings.component';
import { Subject, takeUntil } from 'rxjs';
import { FeatureFlagService } from '@app/internal-tools/services/feature-flag.service';
import { FeatureFlagKeys } from '@app/internal-tools/state/feature-flag-keys';

interface AppArea {
   title: string;
   settings: { title: string; component?: any; link?: string }[];
}
@Component({
   selector: 'app-settings',
   templateUrl: './settings.component.html',
   styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
   areas: AppArea[] = [
      {
         title: 'Organization Settings',
         settings: [
            { title: 'Organization Information', component: OrgInfoComponent },
            { title: 'Billing Information', component: BillingInfoComponent },
            { title: 'Manage Licenses', component: PlanSelectComponent },
            { title: 'Manage Users', link: 'users' },
            // { title: 'Delete Organization', component: DeleteOrganizationComponent }
         ],
      },
      {
         title: 'Performance Settings',
         settings: [
            { title: 'Competency Levels', component: CompetencyLevelsComponent },
            { title: 'Reset Performance Ratings', component: ResetPerformanceRatingsComponent },
         ],
      },
   ];

   permissionGroupSettings = { title: 'Manage Permissions', link: 'permissions' };

   featureFlagService = inject(FeatureFlagService);
   permissionGroupsEnabled = false;

   private _destroyed$ = new Subject<void>();

   ngOnInit() {
      this.featureFlagService
         .isEnabled(FeatureFlagKeys.PERMISSION_GROUPS)
         .pipe(takeUntil(this._destroyed$))
         .subscribe((enabled) => {
            this.permissionGroupsEnabled = enabled;
            if (this.permissionGroupsEnabled) {
               this.areas[0].settings.push(this.permissionGroupSettings);
            } else {
               this.areas[0].settings = this.areas[0].settings.filter(
                  (setting) => setting.title !== this.permissionGroupSettings.title
               );
            }
         });
   }

   ngOnDestroy() {
      this._destroyed$.next();
      this._destroyed$.complete();
   }
}
