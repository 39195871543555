import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@app/app.state';
import * as UserOrgActions from '@app/user-org/state/user-org.actions';
import { take, catchError, map } from 'rxjs/operators';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { AuthUserService } from '@app/auth/services/auth-user.service';
import { combineLatest, of } from 'rxjs';

@Component({
   selector: 'app-invite-accept',
   templateUrl: './invite-accept.component.html',
})
export class InviteAcceptComponent implements OnInit {
   inviteAccepted = false;
   error = null;
   constructor(
      private route: ActivatedRoute,
      private userOrg: UserOrgFacade,
      private authUserService: AuthUserService
   ) {}

   ngOnInit() {
      combineLatest([
         this.route.params.pipe(take(1)),
         this.authUserService.currentUser$.pipe(take(1)),
      ]).subscribe(([params, authUser]) => {
         const organizationId = params['orgId'];
         const inviteId = params['inviteId'];
         if (organizationId && inviteId && authUser && !this.inviteAccepted) {
            this.inviteAccepted = true;
            this.userOrg
               .acceptInvite(organizationId, inviteId, authUser.uid)
               .pipe(
                  take(1),
                  catchError(() => {
                     this.error = true;
                     return of();
                  })
               )
               .subscribe((result: any) => {
                  if (result) {
                     if (result.authUser) {
                        this.userOrg.inviteAccepted(result.authUser, organizationId);
                     } else if (result.error) {
                        this.error = result.error;
                     }
                  }
               });
         }
      });
   }
}
