import {
   Component,
   OnInit,
   ViewChild,
   OnDestroy,
   Output,
   EventEmitter,
   AfterViewInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Goal } from '@entities/goal';

import { Rock } from '@entities/rock';

import { UserComment } from '@entities/user-comment';

import { RockAction } from '@entities/rock-action';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';

import { MeetingsFacade } from '@app/meetings/state/meetings.facade';
import { RockInfoComponent } from '@app/evolve/components/rock-info/rock-info.component';
import { TeamMember } from '@entities/team-member';
import { UserCommentsComponent } from '@app/shared/components/user-comments/user-comments.component';
import { RockActionsComponent } from '@app/evolve/components/rock-actions/rock-actions.component';

@Component({
   selector: 'app-meetings-rock-detail',
   templateUrl: './meetings-rock-detail.component.html',
   styleUrls: ['./meetings-rock-detail.component.scss'],
})
export class MeetingsRockDetailComponent
   implements OnInit, AfterViewInit, OnDestroy, SaveChangesComponent
{
   @ViewChild(RockInfoComponent) rockInfoComponent: RockInfoComponent;
   @ViewChild(RockActionsComponent) rockActionsComponent: RockActionsComponent;
   @ViewChild(UserCommentsComponent) userCommentsComponent: UserCommentsComponent;
   @Output() commentAdded = new EventEmitter<UserComment>();
   @Output() actionAdded = new EventEmitter<RockAction>();

   form: UntypedFormGroup;

   rock$: Observable<Rock>;
   goals$: Observable<Goal[]>;
   teamMembers$: Observable<TeamMember[]>;

   comments: UserComment[] = [];
   actions: RockAction[] = [];
   actions$: Observable<RockAction[]>;

   private oldComments: UserComment[] = [];
   private destroyed$ = new Subject<void>();

   constructor(private meetingsFacade: MeetingsFacade) {}

   ngOnInit() {
      this.form = new UntypedFormGroup({});

      this.rock$ = this.meetingsFacade.selectedRock$;
      this.rock$.pipe(takeUntil(this.destroyed$)).subscribe((rock) => {
         if (rock) {
            if (rock.actions) {
               this.actions = [...rock.actions];
            } else {
               this.actions = [];
            }
            if (rock.comments) {
               this.comments = [...rock.comments]
                  .sort((a, b) => a.timestamp.localeCompare(b.timestamp))
                  .map((x) => {
                     return { ...x };
                  });
               this.oldComments = [...this.comments];
            } else {
               this.comments = [];
            }
            this.form.markAsPristine();
         } else {
            return [];
         }
      });

      this.goals$ = this.meetingsFacade.goals$;
      this.teamMembers$ = this.meetingsFacade.teamMembers$;
   }

   ngAfterViewInit() {
      if (this.rockInfoComponent) {
         this.form = this.rockInfoComponent.form;
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   save() {
      const rock: Rock = {
         ...this.form.value,
         actions: this.actions,
         comments: this.comments,
      };
      this.meetingsFacade.saveRock(rock);
   }

   updateActions(updatedActions: RockAction[]) {
      const newActions = updatedActions.filter(
         (a) => !this.actions.some((b) => b.created == a.created)
      );
      newActions.forEach((action) => {
         this.actionAdded.emit(action);
      });
      this.actions = updatedActions;
      this.form.markAsDirty();
   }

   updateComments(updatedComments: UserComment[]) {
      const newComments = updatedComments.filter(
         (a) => !this.oldComments.some((b) => b.timestamp == a.timestamp)
      );
      newComments.forEach((comment) => {
         this.commentAdded.emit(comment);
      });
      this.comments = updatedComments;
      this.oldComments = [...updatedComments];
      this.form.markAsDirty();
   }
}
