import { Injectable, inject } from '@angular/core';
import { PermissionGroupService } from '@app/admin/services/permission-group.service';
import { adminActions } from '@app/admin/state/admin.actions';
import { adminSelectors } from '@app/admin/state/admin.selectors';
import { State } from '@app/app.state';
import { FeatureFlagService } from '@app/internal-tools/services/feature-flag.service';
import { FeatureFlagKeys } from '@app/internal-tools/state/feature-flag-keys';
import { getCurrentUserTeamMemberId } from '@app/user-org/state/user-org.selectors';
import { PermissionGroup } from '@entities/permission-group';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest, map, takeUntil } from 'rxjs';

@Injectable()
export class PermissionsFacade {
   store = inject(Store<State>);
   permissionGroupService = inject(PermissionGroupService);
   featureFlagService = inject(FeatureFlagService);

   permissionGroups$: Observable<PermissionGroup[]> = this.store.pipe(
      select(adminSelectors.getPermissionGroups)
   );
   currentUserPermissionGroup$ = this.store.pipe(
      select(adminSelectors.getCurrentUserPermissionGroup)
   );
   currentUserTeamMemberId$ = this.store.pipe(select(getCurrentUserTeamMemberId));
   unassignedUsers$ = this.store.pipe(select(adminSelectors.getUnassignedUsers));
   usePermissionGroups$: Observable<boolean>;

   private destroyed$ = new Subject<void>();

   constructor() {
      this.permissionGroupService.entities$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((permissionGroups) => {
            this.store.dispatch(adminActions.PermissionGroupsUpdated({ permissionGroups }));
         });
      this.usePermissionGroups$ = combineLatest([
         this.permissionGroups$,
         this.featureFlagService.isEnabled(FeatureFlagKeys.PERMISSION_GROUPS),
      ]).pipe(
         map(([permissionGroups, isPermissionGroupsEnabled]) => {
            return (
               isPermissionGroupsEnabled &&
               permissionGroups?.length > 0 &&
               permissionGroups
                  ?.map((group) => group.memberIds?.length || 0)
                  .reduce((total, current) => total + current, 0) > 0
            );
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }
}
