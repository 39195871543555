<ng-container [ngSwitch]="accessLevel">
   <ng-container *ngSwitchCase="accessLevels.ALL">
      <mat-icon class="all" [matTooltip]="accessLevels.ALL" matTooltipPosition="right">
         check_circle
      </mat-icon>
   </ng-container>
   <ng-container *ngSwitchCase="accessLevels.ASSIGNED">
      <mat-icon class="assigned" [matTooltip]="accessLevels.ASSIGNED" matTooltipPosition="right">
         person
      </mat-icon>
   </ng-container>
   <ng-container *ngSwitchCase="accessLevels.NONE">
      <mat-icon class="none" [matTooltip]="accessLevels.NONE" matTooltipPosition="right">
         cancel
      </mat-icon>
   </ng-container>
</ng-container>
