import { Route } from '@angular/router';
import { performanceRouteNames } from './performance.routes.names';
import { PerformanceFunctionListComponent } from './components/performance-function-list/performance-function-list.component';
import { PerformanceTaskListComponent } from './components/performance-task-list/performance-task-list.component';
import { SaveChangesGuard } from '@app/shared/services/save-changes.guard';

export const PERFORMANCE_ROUTES: Route[] = [
   {
      path: performanceRouteNames.FUNCTIONS,
      component: PerformanceFunctionListComponent,
      canDeactivate: [SaveChangesGuard],
   },
   {
      path: performanceRouteNames.TASKS,
      component: PerformanceTaskListComponent,
      canDeactivate: [SaveChangesGuard],
   },
   { path: '**', redirectTo: performanceRouteNames.FUNCTIONS },
];
