<div class="widget-container">
   <div class="title" (click)="goToRocks()">Upcoming Milestones</div>
   <app-filter-bar
      [config]="filterConfig"
      [filter]="filter$ | async"
      (filterChange)="filterChanged($event)"
   ></app-filter-bar>
   <div class="scroll-container">
      <table mat-table [dataSource]="data$">
         <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Milestone</th>
            <td mat-cell *matCellDef="let row">{{ row.description }}</td>
         </ng-container>
         <ng-container matColumnDef="rock">
            <th mat-header-cell *matHeaderCellDef>Rock</th>
            <td mat-cell *matCellDef="let row">{{ row.rockName }}</td>
         </ng-container>
         <ng-container matColumnDef="assignee">
            <th mat-header-cell *matHeaderCellDef>Assigned To</th>
            <td mat-cell *matCellDef="let row">
               {{ row.assigneeId | teamMemberName: teamMembers }}
            </td>
         </ng-container>
         <ng-container matColumnDef="targetDate">
            <th mat-header-cell *matHeaderCellDef>Target Date</th>
            <td mat-cell *matCellDef="let row">{{ row.targetDate | date }}</td>
         </ng-container>
         <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
         <tr
            mat-row
            *matRowDef="let row; columns: columnsToDisplay"
            (click)="goToRock(row)"
            [ngClass]="{ overdue: row.overdue }"
         ></tr>
      </table>
   </div>
</div>
