import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State as BaseState } from '@app/app.state';
import { DepartmentFunction } from '@entities/department-function';
import { Department } from '@entities/department';
import { BusinessUnit } from '@entities/business-unit';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { Task } from '@entities/task';
import { Sort } from '@angular/material/sort';
import { getOrganizationState } from '@app/org-builder/state/organization.state';

export interface State extends BaseState {
   documentation: DocumentationState;
}

export interface DocumentationState {
   tasks: Task[];
   taskFilter: FilterValues;
   taskSort: Sort;
   selectedTaskId: string;
   departmentFunctions: DepartmentFunction[];
   functionFilter: FilterValues;
   functionSort: Sort;
   selectedFunctionId: string;
   organizationId: string;
   deptFnDisplayedColumns: string[];
   taskDisplayedColumns: string[];
}

const getDocumentationState = createFeatureSelector<DocumentationState>('documentation');
const getDocumentationTasks = createSelector(getDocumentationState, (state) => state.tasks);
const getTaskFilter = createSelector(getDocumentationState, (state) => state.taskFilter);
const getDocumentationFunctions = createSelector(
   getDocumentationState,
   (state) => state.departmentFunctions
);
const getFunctionFilter = createSelector(getDocumentationState, (state) => state.functionFilter);
const getSelectedDepartmentFunctionId = createSelector(
   getDocumentationState,
   (state) => state.selectedFunctionId
);
const getSelectedFunction = createSelector(getDocumentationState, (state) => {
   const selectedFunction = state.departmentFunctions.find((f) => f.id == state.selectedFunctionId);
   const tasks = state.tasks
      .filter((t) => t.departmentFunctionId == state.selectedFunctionId)
      .sort((a, b) => a.order - b.order);
   return {
      departmentFunction: selectedFunction,
      tasks: tasks,
   };
});

const getSelectedTask = createSelector(getDocumentationState, (state) =>
   state.tasks.find((t) => t.id == state.selectedTaskId)
);

const getTaskSort = createSelector(getDocumentationState, (state) => state.taskSort);
const getFunctionSort = createSelector(getDocumentationState, (state) => state.functionSort);
const getDeptFnDisplayedColumns = createSelector(
   getDocumentationState,
   (state) => state.deptFnDisplayedColumns
);
const getTaskDisplayedColumns = createSelector(
   getDocumentationState,
   (state) => state.taskDisplayedColumns
);

const getTasksViewModel = createSelector(
   getOrganizationState,
   getDocumentationState,
   (orgState, docState) => {
      const viewModels = [];
      docState.tasks.forEach((task) => {
         let departmentFunction, department, businessUnit;
         departmentFunction = docState.departmentFunctions.find(
            (f) => f.id == task.departmentFunctionId
         );
         if (departmentFunction) {
            department = orgState.departments.find((d) => d.id == departmentFunction.departmentId);
            if (department) {
               businessUnit = orgState.businessUnits.find((b) => b.id == department.businessUnitId);
            }
         }
         const viewModel = {
            task,
            departmentFunction,
            department,
            businessUnit,
         };
         viewModels.push(viewModel);
      });
      return viewModels;
   }
);

const getSelectedTaskViewModel = createSelector(
   getSelectedTask,
   getTasksViewModel,
   (task, viewModels) => viewModels.find((vm) => vm?.task?.id === task?.id)
);

const getFunctionsViewModel = createSelector(
   getOrganizationState,
   getDocumentationState,
   (orgState, docState) => {
      const viewModels = [];
      docState.departmentFunctions.forEach((deptFn) => {
         let department, businessUnit;
         const departmentFunction = { ...deptFn };
         departmentFunction.tasks = docState.tasks
            .filter((t) => t.departmentFunctionId == departmentFunction.id)
            .sort((a, b) => a.order - b.order);
         department = orgState.departments.find((d) => d.id == departmentFunction.departmentId);
         if (department) {
            businessUnit = orgState.businessUnits.find((b) => b.id == department.businessUnitId);
         }
         const viewModel = {
            departmentFunction,
            department,
            businessUnit,
         };
         viewModels.push(viewModel);
      });
      return viewModels;
   }
);

export const documentationSelectors = {
   getDocumentationTasks,
   getTaskFilter,
   getDocumentationFunctions,
   getFunctionFilter,
   getSelectedFunction,
   getSelectedTask,
   getSelectedDepartmentFunctionId,
   getTaskSort,
   getFunctionSort,
   getDeptFnDisplayedColumns,
   getTaskDisplayedColumns,
   getTasksViewModel,
   getSelectedTaskViewModel,
   getFunctionsViewModel,
};

export interface DepartmentFunctionViewModel {
   departmentFunction: DepartmentFunction;
   department: Department;
   businessUnit: BusinessUnit;
}
