import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { TeamFacade } from '@app/team/state/team.facade';
import { Observable, combineLatest } from 'rxjs';
import { TeamMember } from '@entities/team-member';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { TableService } from '@app/shared/services/table.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
   selector: 'app-team-member-multiselect',
   templateUrl: './team-member-multiselect.component.html',
   styleUrls: ['./team-member-multiselect.component.scss'],
})
export class TeamMemberMultiselectComponent implements OnInit {
   @Input() label = 'Team Member';
   @Input() canEdit = true;
   @Input() selected: TeamMember[] = [];
   @Output() selectionChange = new EventEmitter<TeamMember[]>();

   teamMembers$: Observable<TeamMember[]>;
   filteredTeamMembers$: Observable<TeamMember[]>;

   form: UntypedFormGroup;

   constructor(private teamFacade: TeamFacade, private tableService: TableService) {}

   ngOnInit(): void {
      this.form = new UntypedFormGroup({
         filter: new UntypedFormControl(),
         teamMembers: new UntypedFormControl(this.selected),
      });
      this.teamMembers$ = this.teamFacade.teamMembers$;
      this.filteredTeamMembers$ = combineLatest([
         this.teamMembers$,
         this.form.get('filter').valueChanges.pipe(startWith('')),
      ]).pipe(
         map(([teamMembers, filter]) => {
            return this.tableService.filterAndSort(teamMembers, filter, ['firstName', 'lastName'], {
               active: 'lastName',
               direction: 'asc',
            });
         })
      );
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['canEdit']) {
         if (this.form) {
            if (this.canEdit) {
               this.form.enable();
            } else {
               this.form.disable();
            }
         }
      }
      if (changes['selected']) {
         if (this.form && this.selected) {
            this.form.patchValue({
               teamMembers: this.selected,
            });
         }
      }
   }

   teamMembersUpdated(event: MatSelectChange) {
      this.selectionChange.emit(event.value);
   }

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   compareById = (a, b) => a?.id === b?.id;
}
