import { createAction, props } from '@ngrx/store';
import { Organization } from '@entities/organization';
import { User } from '@entities/user';
import { AuthUser } from '@entities/auth-user';

export const CreateOrganization = createAction(
   '[Admin] Create organization',
   props<{ organization: Organization }>()
);
export const OrganizationCreated = createAction(
   '[Admin] Organization created',
   props<{ organization: Organization; adminUser: User }>()
);
export const CreateUser = createAction('[Admin] Create user');

export const OrganizationsUpdated = createAction(
   '[User Orgs] Organizations updated',
   props<{ orgs: Organization[] }>()
);

export const UsersUpdated = createAction('[User Orgs] Users updated', props<{ users: User[] }>());

export const SelectOrganization = createAction(
   '[Admin] Select organization',
   props<{ organizationId: string }>()
);
export const SetOrganization = createAction(
   '[Admin] Set organization',
   props<{ organizationId: string }>()
);
export const SaveOrgInfo = createAction(
   '[Admin]  Save org info',
   props<{ organization: Organization }>()
);

export const AcceptInvite = createAction(
   '[Admin] Accept invite',
   props<{ inviteId: string; organizationId: string }>()
);

export const SaveAuthUser = createAction(
   '[User Account] Save user account',
   props<{ authUser: AuthUser }>()
);

export const LeaveOrganization = createAction(
   '[User Account] Leave organization',
   props<{ organization: Organization }>()
);

export const UserOrgActions = {
   CreateOrganization,
   CreateUser,
   OrganizationsUpdated,
   UsersUpdated,
   SelectOrganization,
   SetOrganization,
   SaveOrgInfo,
   AcceptInvite,
   SaveAuthUser,
   LeaveOrganization,
   OrganizationCreated,
};
