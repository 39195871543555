import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from '@app/auth/state/auth.actions';
import { adminActions } from './admin.actions';
import * as root from '@app/layout/state/layout.state';
import { User } from '@entities/user';
import { PermissionGroup } from '@entities/permission-group';

export const adminFeatureKey = 'admin';

export interface State extends root.State {
   admin: AdminState;
}

export interface AdminState {
   users: User[];
   selectedUserId: string;
   permissionGroups: PermissionGroup[];
}

export const adminInitialState: AdminState = {
   users: [],
   selectedUserId: null,
   permissionGroups: [],
};

const reducer = createReducer(
   adminInitialState,
   on(adminActions.UsersUpdated, (state, { users }) => {
      return {
         ...state,
         users,
      };
   }),
   on(adminActions.AddUser, (state) => {
      const user = new User();
      user.id = '';
      return {
         ...state,
         users: [...state.users, user],
         selectedUserId: user.id,
      };
   }),
   on(adminActions.EditUser, (state, { user }) => {
      return {
         ...state,
         selectedUserId: user.id,
      };
   }),
   on(adminActions.CloseEditUser, (state) => {
      return {
         ...state,
         selectedUserId: null,
      };
   }),
   on(adminActions.PermissionGroupsUpdated, (state, { permissionGroups }) => {
      return {
         ...state,
         permissionGroups,
      };
   })
);

export function adminReducer(state: AdminState | undefined, action: Action) {
   if (action.type === AuthActions.AuthUserUpdated.type) {
      return (<any>action).authUser ? state : adminInitialState;
   } else {
      return reducer(state, action);
   }
}
