<div class="comment">
   <div class="comment-text" [innerHTML]="comment.text"></div>
   <div>
      <div class="comment-details">
         <span class="commenter">{{ comment.commenter }}</span>
         |
         {{ comment.timestamp | date: 'short' }}
      </div>
   </div>
</div>
