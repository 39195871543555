import { Injectable, OnDestroy } from '@angular/core';
import { WhereFilterOp, where } from '@angular/fire/firestore';
import { State } from '@app/app.state';
import { PermissionedEntity } from '@app/shared/interfaces/permissioned-entity.interface';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { CompetencyLevel } from '@entities/competency-level';
import { AppAreas } from '@entities/enums/app-areas';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class CompetencyLevelService implements PermissionedEntity, OnDestroy {
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.COMPETENCY_LEVELS;
   entities$: Observable<CompetencyLevel[]>;

   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<CompetencyLevel>();

   constructor(private store: Store<State>) {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }

   save(entity: CompetencyLevel, overwrite: boolean = false): Promise<CompetencyLevel> {
      return this.orgEntityService.save(entity, { overwrite });
   }
   batchSave(entities: CompetencyLevel[]) {
      return this.orgEntityService.batchSave(entities);
   }

   delete(entity: CompetencyLevel) {
      return this.orgEntityService.delete(entity);
   }

   assigneeFilter(teamMemberId: string): Array<[string, WhereFilterOp, string]> {
      return [['assigneeIds', 'array-contains', teamMemberId]];
   }
}
