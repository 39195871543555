import { Component, OnInit } from '@angular/core';
import { environment } from '@src/environments/environment';

@Component({
   selector: 'app-status',
   templateUrl: './status.component.html',
   styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
   env = environment;

   constructor() {}

   ngOnInit(): void {}
}
