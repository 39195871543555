import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { getAuthUser } from '@app/auth/state/auth.state';
import { map } from 'rxjs/operators';

@Injectable({
   providedIn: 'root',
})
export class InternalUserGuard implements CanActivate {
   constructor(private store: Store<State>) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.store.pipe(
         select(getAuthUser),
         map((authUser) => {
            return authUser && authUser.isInternalUser;
         })
      );
   }
}
