import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as authStore from '@app/auth/state/auth.state';

@Component({
  selector: 'app-callback',
  template: 'Loading...'
})
export class CallbackComponent implements OnInit {
  constructor(private store: Store<authStore.State>) {}

  ngOnInit() {
    // this.store.dispatch(new LoginComplete());
  }
}
