import { Injectable } from '@angular/core';
import {
   MatLegacyDialogRef as MatDialogRef,
   MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { Store, select } from '@ngrx/store';
import { ErrorService } from '@app/shared/services/error.service';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { tap, switchMap, map, catchError, withLatestFrom, concatMap } from 'rxjs/operators';
import { UserEditComponent } from '@app/admin/components/user-edit/user-edit.component';
import { of, from } from 'rxjs';
import { State } from '@app/app.state';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
   getCurrentUser,
   getCurrentUserId,
   getOrganization,
   getOrgsForUser,
} from '@app/user-org/state/user-org.selectors';
import { UserService } from '@app/user-org/services/user.service';
import { User } from '@entities/user';
import { UserInviteComponent } from '@app/admin/components/user-invite/user-invite.component';
import { UserInviteService } from '@app/admin/services/user-invite.service';
import { Organization } from '@entities/organization';
import { SaveOrgInfo } from '@app/user-org/state/user-org.actions';
import { UserInvite } from '@entities/user-invite';
import { TeamMember } from '@entities/team-member';
import { TeamMemberService } from '@app/team/services/team-member.service';
import { TeamMemberStatus } from '@entities/enums/team-member-status';
import { BillingInfo } from '@entities/billing-info';
import { OrganizationService } from '@app/user-org/services/organization.service';
import { adminSelectors } from './admin.selectors';
import { adminActions } from './admin.actions';
import { UserRole } from '@entities/enums/user-role';
import { PlanDetails } from '@entities/plan-details';
import { PermissionGroupService } from '../services/permission-group.service';
import { PermissionGroupEditComponent } from '../components/permission-group-edit/permission-group-edit.component';
import {
   defaultPermissionGroups,
   emptyPermissionGroup,
} from '../components/manage-permissions/default-permission-groups';
import { PermissionGroupAssignUsersComponent } from '../components/permission-group-assign-users/permission-group-assign-users.component';
import { cloneDeep } from 'lodash';

@Injectable()
export class AdminEffects {
   dialogRef: MatDialogRef<any>;

   constructor(
      private actions$: Actions,
      private store: Store<State>,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private errorService: ErrorService,
      private userService: UserService,
      private userInviteService: UserInviteService,
      private teamMemberService: TeamMemberService,
      private orgService: OrganizationService,
      private permissionGroupService: PermissionGroupService
   ) {}

   editUser$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.EditUser),
            tap(() => {
               this.dialogRef = this.dialog.open(UserEditComponent);
            })
         ),
      { dispatch: false }
   );

   addUser$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.AddUser),
            tap(() => {
               this.dialogRef = this.dialog.open(UserInviteComponent, {
                  width: '500px',
                  maxWidth: '100%',
               });
            })
         ),
      { dispatch: false }
   );

   saveUser$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.SaveUser),
            withLatestFrom(
               this.store.pipe(select(getOrganization)),
               this.store.pipe(select(getOrgsForUser)),
               this.store.pipe(select(adminSelectors.getOrgUsers))
            ),
            tap(([action, activeOrg, userOrgs, users]) => {
               let userOrg = userOrgs.find(
                  (org) => org.organizationId === action.user.organizationId
               );
               if (userOrg == undefined) {
                  userOrg = activeOrg;
               }
               const user: User = {
                  ...action.user,
                  organizationId: userOrg.organizationId,
                  products: action.user.active ? action.user.products : [],
                  isAdmin:
                     action.user.isAdmin !== null
                        ? action.user.isAdmin
                        : action.user.role === UserRole.Admin,
               };
               if (user.active && user.products.length === 0) {
                  const error = new Error('Active users must have at least one product assigned');
                  this.errorService.handleError(error);
               } else {
                  if (userOrg.organizationId === activeOrg.organizationId) {
                     const updatedUsers = [...users.filter((u) => u.id != user.id), user];
                     if (activeOrg.billingInfo && activeOrg.billingInfo.plans) {
                        const plans = [];
                        activeOrg.billingInfo.plans.forEach((plan) => {
                           const assigned = updatedUsers.filter(
                              (user) =>
                                 user.active &&
                                 user.products &&
                                 user.products.includes(plan.productType)
                           ).length;
                           const available = Math.max(plan.quantity - assigned, 0);
                           const newPlan = {
                              ...plan,
                              assigned,
                              available,
                           };
                           plans.push(newPlan);
                        });
                        const billingInfo = {
                           ...activeOrg.billingInfo,
                           plans,
                        };
                        this.store.dispatch(adminActions.SaveBillingInfo({ billingInfo }));
                     }
                  }
                  this.userService
                     .save(user, user.organizationId)
                     .then(() => {
                        this.dialog.closeAll();
                     })
                     .catch((err) => {
                        this.errorService.handleError(err);
                     });
               }
            })
         ),
      { dispatch: false }
   );

   deactivateUser$ = createEffect(() =>
      this.actions$.pipe(
         ofType(adminActions.DeactivateUser),
         withLatestFrom(this.store.pipe(select(getOrganization))),
         map(([{ userAccountId }, organization]) => {
            const authUsers = organization.authUsers.filter((uid) => uid != userAccountId);
            const updatedOrgInfo = {
               ...organization,
               authUsers,
            };
            return SaveOrgInfo({ organization: updatedOrgInfo });
         })
      )
   );

   reactivateUser$ = createEffect(() =>
      this.actions$.pipe(
         ofType(adminActions.ReactivateUser),
         withLatestFrom(this.store.pipe(select(getOrganization))),
         map(([{ userAccountId }, organization]) => {
            const authUsers = [...organization.authUsers, userAccountId];
            const updatedOrgInfo = {
               ...organization,
               authUsers,
            };
            return SaveOrgInfo({ organization: updatedOrgInfo });
         })
      )
   );

   deleteUser$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.DeleteUser),
            withLatestFrom(this.store.pipe(select(getOrganization))),
            tap(([{ user }, organization]) => {
               if (organization.billingInfo && organization.billingInfo.plans) {
                  const plans = [];
                  organization.billingInfo.plans.forEach((plan) => {
                     if (user.products.includes(plan.productType)) {
                        const updatedPlan: PlanDetails = {
                           ...plan,
                           available: plan.available + 1,
                        };
                        plans.push(updatedPlan);
                     } else {
                        plans.push(plan);
                     }
                  });
                  const billingInfo = {
                     ...organization.billingInfo,
                     plans,
                  };
                  this.store.dispatch(adminActions.SaveBillingInfo({ billingInfo }));
               }
               this.userService
                  .delete(user)
                  .then(() => {
                     this.dialog.closeAll();
                  })
                  .catch((err) => {
                     this.errorService.handleError(err);
                  });
            })
         ),
      { dispatch: false }
   );

   closeUserDialog$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.CloseEditUser),
            tap((action) => {
               this.dialog.closeAll();
            })
         ),
      { dispatch: false }
   );

   sendInvite$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.SendInvite),
            withLatestFrom(this.store.pipe(select(getOrganization))),
            switchMap(([action, org]) => {
               const invite: UserInvite = {
                  ...action.invite,
                  organizationId: org.organizationId,
                  organizationName: org.name,
               };
               if (action.createTeamMember) {
                  const teamMember: TeamMember = {
                     firstName: invite.firstName,
                     lastName: invite.lastName,
                     status: TeamMemberStatus.Active,
                     id: null,
                     memos: [],
                     assignedTasks: [],
                     performanceEvaluations: [],
                     growthPlans: [],
                     trainingPlans: [],
                  };
                  return from(this.teamMemberService.save(teamMember)).pipe(
                     map((teamMember) => {
                        invite.teamMemberId = teamMember.id;
                        return { invite, org };
                     })
                  );
               } else {
                  return of({ invite, org });
               }
            }),
            switchMap(({ invite, org }) => {
               const billingInfo: BillingInfo = org.billingInfo;
               if (billingInfo) {
                  const updatedPlans = [];
                  billingInfo.plans.forEach((plan) => {
                     const available =
                        invite.product === plan.productType ? plan.available - 1 : plan.available;
                     const updatedPlan = {
                        ...plan,
                        available,
                     };
                     updatedPlans.push(updatedPlan);
                  });
                  const updatedBillingInfo: BillingInfo = {
                     ...billingInfo,
                     plans: updatedPlans,
                  };
                  const updatedOrg = {
                     ...org,
                     billingInfo: updatedBillingInfo,
                  };
                  return from(this.orgService.save(updatedOrg)).pipe(
                     map((updatedOrg) => {
                        return invite;
                     })
                  );
               } else {
                  return of(invite);
               }
            }),
            switchMap((invite) => {
               return from(this.userInviteService.save(invite)).pipe(
                  tap(() => {
                     this.snackBar.open('Invite Sent');
                  }),
                  catchError((err) => {
                     this.errorService.handleError(err);
                     return of();
                  })
               );
            })
         ),
      { dispatch: false }
   );

   saveBillingInfo$ = createEffect(() =>
      this.actions$.pipe(
         ofType(adminActions.SaveBillingInfo),
         withLatestFrom(this.store.pipe(select(getOrganization))),
         map(([{ billingInfo }, org]) => {
            const updatedOrg: Organization = {
               ...org,
               billingInfo,
            };
            return SaveOrgInfo({ organization: updatedOrg });
         })
      )
   );

   deleteInvite$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.DeleteInvite),
            withLatestFrom(this.store.pipe(select(getOrganization))),
            tap(([{ invite }, org]) => {
               const billingInfo: BillingInfo = org.billingInfo;
               if (billingInfo) {
                  const updatedPlans = [];
                  billingInfo.plans.forEach((plan) => {
                     const available =
                        invite.product && invite.product === plan.productType
                           ? Math.min(plan.available + 1, plan.quantity)
                           : plan.available;
                     const updatedPlan = {
                        ...plan,
                        available,
                     };
                     updatedPlans.push(updatedPlan);
                  });
                  const updatedBillingInfo: BillingInfo = {
                     ...billingInfo,
                     plans: updatedPlans,
                  };
                  const updatedOrg = {
                     ...org,
                     billingInfo: updatedBillingInfo,
                  };
                  this.userInviteService.delete(invite).then(() => {
                     this.orgService.save(updatedOrg);
                  });
               }
            })
         ),
      { dispatch: false }
   );

   savePermissionGroup$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.SavePermissionGroup),
            withLatestFrom(
               this.store.pipe(select(adminSelectors.getOrgUsers)),
               this.store.pipe(select(adminSelectors.getPermissionGroups))
            ),
            tap(([{ permissionGroup }, users, permissionGroups]) => {
               const otherPermissionGroups = permissionGroups
                  .filter((group) => group.id !== permissionGroup.id)
                  .map((group) => cloneDeep(group));
               const usersToAdd = users
                  .filter(
                     (user) =>
                        user.permissionGroupId !== permissionGroup.id &&
                        permissionGroup.memberIds.includes(user.id)
                  )
                  .map((user) => {
                     const oldPermissionGroup = otherPermissionGroups.find(
                        (group) => group.id === user.permissionGroupId
                     );
                     if (oldPermissionGroup) {
                        oldPermissionGroup.memberIds = oldPermissionGroup.memberIds.filter(
                           (id) => id !== user.id
                        );
                     }
                     return { ...cloneDeep(user), permissionGroupId: permissionGroup.id };
                  });
               const usersToRemove = users
                  .filter(
                     (user) =>
                        user.permissionGroupId === permissionGroup.id &&
                        !permissionGroup.memberIds.includes(user.id)
                  )
                  .map((user) => ({ ...cloneDeep(user), permissionGroupId: null }));
               const usersToSave: User[] = [...usersToAdd, ...usersToRemove];

               const toSave = [cloneDeep(permissionGroup), ...otherPermissionGroups].map(
                  (group) => {
                     delete group.members;
                     return group;
                  }
               );
               this.permissionGroupService
                  .batchSave(toSave)
                  .then(() => this.userService.batchSave(usersToSave))
                  .then(() => {
                     this.dialog.closeAll();
                     this.snackBar.open('Permission group saved');
                  })
                  .catch((error) => {
                     this.errorService.handleError(error, 'Error saving permission group');
                  });
            })
         ),
      { dispatch: false }
   );

   setDefaultPermissionGroups$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.SetDefaultPermissionGroups),
            withLatestFrom(this.store.pipe(select(getCurrentUser))),
            concatMap(([_, user]) => {
               const permissionGroups = cloneDeep(defaultPermissionGroups);
               // set current user as admin
               permissionGroups[0].memberIds.push(user.id);
               return from(this.permissionGroupService.batchSave(permissionGroups)).pipe(
                  tap((saved) => {
                     const userPermissionGroup = saved.find((group) =>
                        group.memberIds.includes(user.id)
                     );
                     this.userService.save({
                        ...user,
                        permissionGroupId: userPermissionGroup?.id,
                     });
                  })
               );
            })
         ),
      { dispatch: false }
   );

   editPermissionGroup$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.EditPermissionGroup),
            tap(({ permissionGroup }) => {
               this.dialog.open(PermissionGroupEditComponent, { data: { permissionGroup } });
            })
         ),
      { dispatch: false }
   );

   newPermissionGroup$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.NewPermissionGroup),
            withLatestFrom(this.store.pipe(select(adminSelectors.getPermissionGroups))),
            tap(([_, permissionGroups]) => {
               this.dialog.open(PermissionGroupEditComponent, {
                  data: {
                     permissionGroup: { ...emptyPermissionGroup, index: permissionGroups.length },
                  },
               });
            })
         ),
      { dispatch: false }
   );

   assignUsersToPermissionGroup$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.AssignUsersToPermissionGroup),
            tap(({ permissionGroup }) => {
               this.dialog.open(PermissionGroupAssignUsersComponent, { data: { permissionGroup } });
            })
         ),
      { dispatch: false }
   );

   addUserToGroup$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.AddUserToGroup),
            withLatestFrom(this.store.pipe(select(adminSelectors.getPermissionGroups))),
            tap(([{ user }, permissionGroups]) => {
               const permissionGroup = permissionGroups.find(
                  (group) => group.id === user.permissionGroupId
               );
               const toSave = cloneDeep(permissionGroup);
               if (!toSave.memberIds) {
                  toSave.memberIds = [];
               }
               toSave.memberIds.push(user.id);
               delete toSave.members;
               this.userService
                  .save(user)
                  .then(() => this.permissionGroupService.save(toSave))
                  .then(() => {
                     this.snackBar.open('User assigned');
                  })
                  .catch((error) => {
                     this.errorService.handleError(error, 'Error assigning user');
                  });
            })
         ),
      { dispatch: false }
   );

   deletePermissionGroup$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(adminActions.DeletePermissionGroup),
            withLatestFrom(this.store.pipe(select(adminSelectors.getOrgUsers))),
            tap(([{ permissionGroup }, users]) => {
               const usersToUpdate = users
                  .filter((user) => user.permissionGroupId === permissionGroup.id)
                  .map((user) => ({ ...user, permissionGroupId: null }));
               this.permissionGroupService
                  .delete(permissionGroup)
                  .then(() => this.userService.batchSave(usersToUpdate));
            })
         ),
      { dispatch: false }
   );
}
