import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverviewComponent } from './overview.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OverviewService } from './overview.service';
import { SharedModule } from '@app/shared/shared.module';
import { VisionReportComponent } from './vision-report/vision-report.component';

@NgModule({
  declarations: [OverviewComponent, VisionReportComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
  providers: [OverviewService]
})
export class OverviewModule {}
