<div class="header">
   <div class="title">Actions</div>
   <button mat-raised-button class="add-button" color="primary" *ngIf="canEdit" (click)="add()">
      <mat-icon>add</mat-icon>
      New Action
   </button>
</div>
<div class="table-container">
   <app-table-header
      [hideExport]="tableConfig.hideExport"
      [filterBar]="filterBarConfig"
      [filter$]="filter$"
      [columns]="columns"
      [displayedColumns]="displayedColumns$ | async"
      (columnsUpdated)="setDisplayedColumns($event)"
      (exportData)="export($event)"
   ></app-table-header>
   <app-table [config]="tableConfig"></app-table>
</div>
