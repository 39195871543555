import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { Task } from '@entities/task';

import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { ActivatedRoute, Router } from '@angular/router';
import { documentationRouteNames } from '@app/documentation/documentation.routes.names';
import { appRoutesNames } from '@app/app.routes.names';
import { TaskFacade } from '@app/shared/interfaces/task-facade.interface';

@Component({
   selector: 'app-task-list',
   templateUrl: './task-list.component.html',
   styleUrls: ['./task-list.component.scss', '../organization/organization.component.scss'],
})
export class TaskListComponent implements OnInit {
   @Input() tasks: Task[] = [];
   @Input() visible: boolean;
   @Input() canEdit = true;
   @Input() facade: TaskFacade;
   @Output() editTask: EventEmitter<Task> = new EventEmitter();
   constructor() {}

   ngOnInit() {}

   addTask() {
      this.facade.addTask();
   }

   edit(task: Task) {
      this.editTask.emit(task);
   }

   assign(task: Task) {
      this.facade.assignTaskToTeamMember(task);
   }

   delete(task: Task) {
      this.facade.deleteTask(task);
   }

   dragStarted(event: CdkDragStart, task: Task) {
      this.facade.dragStarted(task);
   }

   drop(event: CdkDragDrop<Task>) {
      if (event.isPointerOverContainer) {
         this.facade.reorderTasks(event.previousIndex, event.currentIndex);
      }
   }
   report(task: Task) {
      this.facade.taskReport(task);
   }
}
