import {
   Component,
   OnInit,
   ViewChild,
   ElementRef,
   ChangeDetectorRef,
   AfterViewInit,
   OnDestroy,
   Output,
   EventEmitter,
   Input,
} from '@angular/core';
import { StripeService } from 'ngx-stripe';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AdminFacade } from '@app/admin/state/admin.facade';
import { BillingInfo } from '@entities/billing-info';
import * as moment from 'moment';
import { ErrorService } from '@app/shared/services/error.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
   selector: 'app-billing-info',
   templateUrl: './billing-info.component.html',
   styleUrls: ['./billing-info.component.scss'],
})
export class BillingInfoComponent implements OnInit, OnDestroy {
   @ViewChild('cardElement', { static: true }) cardElement: ElementRef;

   @Output('cardSubmitted') cardSubmitted: EventEmitter<any> = new EventEmitter();
   @Input() signup = false;
   card;
   cardErrors;

   loading = false;
   confirmation;
   submitted = false;
   infoForm: UntypedFormGroup;
   showStripe = false;
   last4: string;
   billingInfo: any = {};

   private _destroyed$ = new Subject<void>();

   constructor(
      private stripeService: StripeService,
      private adminFacade: AdminFacade,
      private errorService: ErrorService
   ) {}

   ngOnInit() {
      this.infoForm = new UntypedFormGroup({
         customerId: new UntypedFormControl(),
         subscriptionId: new UntypedFormControl(),
         name: new UntypedFormControl(),
         address_line1: new UntypedFormControl(),
         address_city: new UntypedFormControl(),
         address_state: new UntypedFormControl(),
         address_zip: new UntypedFormControl(),
         termsAccepted: new UntypedFormControl(false, Validators.requiredTrue),
      });

      this.createStripeElements();
      if (this.signup) {
         this.showStripe = true;
      } else {
         this.adminFacade.billingInfo$
            .pipe(takeUntil(this._destroyed$))
            .subscribe((billingInfo) => {
               if (billingInfo) {
                  this.last4 = billingInfo.last4;
                  this.billingInfo = {
                     ...billingInfo,
                     address_line1: billingInfo.address,
                     address_city: billingInfo.city,
                     address_state: billingInfo.state,
                     address_zip: billingInfo.zip,
                  };
                  this.infoForm.patchValue(this.billingInfo);
                  if (this.billingInfo.termsAccepted) {
                     this.infoForm.get('termsAccepted').setValue(true);
                  }
               } else {
                  this.billingInfo = {};
               }
            });
      }
   }

   ngOnDestroy() {
      this._destroyed$.next();
   }

   createStripeElements() {
      this.stripeService
         .elements()
         .pipe(takeUntil(this._destroyed$))
         .subscribe((elements) => {
            if (elements) {
               this.card = elements.create('card', {
                  style: {
                     base: {
                        fontSize: '16px',
                     },
                  },
               });
               this.card.mount(this.cardElement.nativeElement);
               this.card.addEventListener('change', ({ error }) => {
                  this.cardErrors = error && error.message;
               });
            }
         });
   }

   updateBilling() {
      this.showStripe = true;
   }

   submit() {
      if (!this.submitted && this.infoForm.valid) {
         this.billingInfo = {
            ...this.billingInfo,
            ...this.infoForm.value,
            termsAccepted: this.billingInfo.termsAccepted || moment().toISOString(),
         };
         this.loading = true;
         this.submitted = true;
         if (!this.signup && !this.showStripe) {
            const billingInfo: BillingInfo = {
               ...this.billingInfo,
               address: this.billingInfo.address_line1,
               city: this.billingInfo.address_city,
               state: this.billingInfo.address_state,
               zip: this.billingInfo.address_zip,
            };
            this.adminFacade.saveBillingInfo(billingInfo);
            this.loading = false;
            this.submitted = false;
            this.infoForm.markAsPristine();
         } else {
            this.stripeService
               .createToken(this.card, this.infoForm.value)
               .pipe(take(1))
               .subscribe((result) => {
                  this.loading = false;
                  if (result.token) {
                     if (this.signup) {
                        this.cardSubmitted.emit(result.token);
                     } else {
                        // update customer on backend
                        this.adminFacade
                           .updateBillingInfo(
                              this.billingInfo.customerId,
                              this.billingInfo.email,
                              result.token
                           )
                           .pipe(take(1))
                           .subscribe((response) => {
                              this.submitted = false;
                              this.showStripe = false;
                              const billingInfo: BillingInfo = {
                                 ...this.billingInfo,
                                 address: this.billingInfo.address_line1,
                                 city: this.billingInfo.address_city,
                                 state: this.billingInfo.address_state,
                                 zip: this.billingInfo.address_zip,
                                 last4: result.token.card.last4,
                                 termsAccepted: this.billingInfo.termsAccepted,
                              };
                              this.adminFacade.saveBillingInfo(billingInfo);
                           });
                     }
                  } else {
                     this.submitted = false;
                     if (result.error) {
                        this.errorService.handleError(result.error);
                     }
                  }
               });
         }
      }
   }

   cancel() {
      this.showStripe = false;
      this.infoForm.patchValue(this.billingInfo);
   }
}
