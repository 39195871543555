import { Component, OnInit } from '@angular/core';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';
import { Observable } from 'rxjs';
import { TaskDocumentationViewModel } from '@app/documentation/state/documentation.models';
import { Task } from '@entities/task';

@Component({
  selector: 'app-assigned-tasks-widget',
  templateUrl: './assigned-tasks-widget.component.html',
  styleUrls: ['./assigned-tasks-widget.component.scss']
})
export class AssignedTasksWidgetComponent implements OnInit {
  tasks$: Observable<TaskDocumentationViewModel[]>;
  displayedColumns = ['task', 'departmentFunction', 'department', 'businessUnit'];
  constructor(private dashboardFacade: DashboardFacade) {}

  ngOnInit() {
    this.tasks$ = this.dashboardFacade.assignedTasks$;
  }

  goToTask(task: Task) {
    this.dashboardFacade.goToTask(task);
  }
}
