import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerformanceFunctionListComponent } from './components/performance-function-list/performance-function-list.component';
import { PerformanceFacade } from './state/performance.facade';
import { SharedModule } from '@app/shared/shared.module';
import { PerformanceFunctionDetailsComponent } from './components/performance-function-details/performance-function-details.component';
import { PerformanceTaskListComponent } from './components/performance-task-list/performance-task-list.component';
import { PerformanceTaskDetailsComponent } from './components/performance-task-details/performance-task-details.component';

@NgModule({
  declarations: [PerformanceFunctionListComponent, PerformanceFunctionDetailsComponent, PerformanceTaskListComponent, PerformanceTaskDetailsComponent],
  imports: [CommonModule, SharedModule],
  providers: [PerformanceFacade]
})
export class PerformanceModule {}
