import { Route } from '@angular/router';
import { orgBuilderRouteNames } from './org-builder.routes.names';
import { BusinessUnitSummaryComponent } from './components/business-unit-summary/business-unit-summary.component';
import { OrganizationComponent } from './components/organization/organization.component';
import { OrgSummaryReportComponent } from './components/org-summary-report/org-summary-report.component';
import { DepartmentSummaryComponent } from './components/department-summary/department-summary.component';
import { OrgChartComponent } from './components/org-chart/org-chart.component';

export const ORG_BUILDER_ROUTES: Route[] = [
   { path: '', component: OrganizationComponent },
   { path: orgBuilderRouteNames.ORG_SUMMARY, component: OrgSummaryReportComponent },
   {
      path: `${orgBuilderRouteNames.BUSINESS_UNIT_SUMMARY}/:businessUnitId`,
      component: BusinessUnitSummaryComponent,
   },
   {
      path: `${orgBuilderRouteNames.DEPARTMENT_SUMMARY}/:departmentId`,
      component: DepartmentSummaryComponent,
   },
   { path: orgBuilderRouteNames.ORG_CHART, component: OrgChartComponent },
];
