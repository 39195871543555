import { on, createReducer } from '@ngrx/store';
import { ActionItem } from '@entities/action-item';
import * as ActionItemActions from './action-item.actions';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { UserOrgActions } from '@app/user-org/state/user-org.actions';
import { Sort } from '@angular/material/sort';

export interface ActionItemState {
   entities: ActionItem[];
   selectedActionItemId: string;
   filter: FilterValues;
   organizationId: string;
   visibleColumns: string[];
   sort: Sort;
}

export const initialState: ActionItemState = {
   entities: [],
   selectedActionItemId: null,
   filter: {},
   organizationId: null,
   visibleColumns: [],
   sort: { active: 'name', direction: 'asc' },
};

export const reducer = createReducer<ActionItemState>(
   initialState,
   on(ActionItemActions.ActionItemsUpdated, (state, { actionItems }) => {
      return {
         ...state,
         entities: actionItems,
      };
   }),
   on(ActionItemActions.AddActionItem, (state) => {
      return {
         ...state,
         selectedActionItemId: null,
      };
   }),
   on(ActionItemActions.SelectActionItem, (state, { actionItemId }) => {
      return {
         ...state,
         selectedActionItemId: actionItemId,
      };
   }),
   on(ActionItemActions.ActionItemsFilterUpdated, (state, { filter }) => {
      return {
         ...state,
         filter,
      };
   }),
   on(ActionItemActions.SetVisibleColumns, (state, { columns }) => {
      return {
         ...state,
         visibleColumns: columns,
      };
   }),
   on(ActionItemActions.SetSort, (state, { sort }) => {
      return {
         ...state,
         sort,
      };
   }),
   on(
      UserOrgActions.SelectOrganization,
      UserOrgActions.SetOrganization,
      (state, { organizationId }) => {
         if (state.organizationId != organizationId) {
            return {
               ...initialState,
               organizationId,
            };
         } else {
            return state;
         }
      }
   )
);
