import { DocumentationState } from './documentation.state';
import { createReducer, on, Action } from '@ngrx/store';
import * as DocumentationActions from './documentation.actions';
import * as AuthActions from '@app/auth/state/auth.actions';
import { UserOrgActions } from '@app/user-org/state/user-org.actions';

export const documentationInitialState: DocumentationState = {
   tasks: [],
   taskFilter: {},
   departmentFunctions: [],
   functionFilter: {},
   selectedFunctionId: null,
   selectedTaskId: null,
   organizationId: null,
   taskSort: { direction: 'asc', active: 'task.name' },
   functionSort: { direction: 'asc', active: 'departmentFunction.name' },
   deptFnDisplayedColumns: [],
   taskDisplayedColumns: [],
};

export function reducer(state: DocumentationState, action: Action) {
   return createReducer(
      documentationInitialState,
      on(DocumentationActions.DocumentationTasksUpdated, (state, { tasks }) => {
         return {
            ...state,
            tasks,
         };
      }),
      on(DocumentationActions.DocumentationFunctionsUpdated, (state, { functions }) => {
         return {
            ...state,
            departmentFunctions: functions,
         };
      }),
      on(DocumentationActions.SetTaskFilter, (state, { taskFilter }) => {
         return {
            ...state,
            taskFilter,
         };
      }),
      on(DocumentationActions.SetFunctionFilter, (state, { functionFilter }) => {
         return {
            ...state,
            functionFilter,
         };
      }),
      on(DocumentationActions.SelectDepartmentFunction, (state, { departmentFunctionId }) => {
         return {
            ...state,
            selectedFunctionId: departmentFunctionId,
         };
      }),
      on(DocumentationActions.SelectTask, (state, { taskId }) => {
         return {
            ...state,
            selectedTaskId: taskId,
         };
      }),
      on(DocumentationActions.SetTaskSort, (state, { sort }) => {
         return {
            ...state,
            taskSort: sort,
         };
      }),
      on(DocumentationActions.SetFunctionSort, (state, { sort }) => {
         return {
            ...state,
            functionSort: sort,
         };
      }),
      on(DocumentationActions.DepartmentFunctionReport, (state, { departmentFunction }) => {
         return {
            ...state,
            selectedFunctionId: departmentFunction.id,
         };
      }),
      on(DocumentationActions.TaskReport, (state, { task }) => {
         return {
            ...state,
            selectedTaskId: task.id,
         };
      }),
      on(DocumentationActions.SetDeptFnDisplayedColumns, (state, { columns }) => {
         return {
            ...state,
            deptFnDisplayedColumns: columns,
         };
      }),
      on(DocumentationActions.SetTaskDisplayedColumns, (state, { columns }) => {
         return {
            ...state,
            taskDisplayedColumns: columns,
         };
      }),
      on(AuthActions.AuthUserUpdated, (state, { authUser }) => {
         return authUser ? state : documentationInitialState;
      }),
      on(
         UserOrgActions.SelectOrganization,
         UserOrgActions.SetOrganization,
         (state, { organizationId }) => {
            if (state.organizationId != organizationId) {
               return {
                  ...documentationInitialState,
                  organizationId,
               };
            } else {
               return state;
            }
         }
      )
   )(state, action);
}
