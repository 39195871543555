import { AccessLevel } from '@entities/enums/access-level';
import { PermissionGroup } from '@entities/permission-group';

export const defaultPermissionGroups: PermissionGroup[] = [
   {
      id: '',
      index: 0,
      name: 'Admin',
      memberIds: [],
      isAdmin: true,
      accessMap: {
         Dashboard: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Documentation: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Evolve: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Organization: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Overview: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Performance: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Team: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Meetings: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
      },
   },
   {
      id: '',
      index: 1,
      name: 'Manager',
      memberIds: [],
      accessMap: {
         Dashboard: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Documentation: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Evolve: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Organization: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Overview: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Performance: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Team: {
            edit: AccessLevel.ALL,
            view: AccessLevel.ALL,
         },
         Meetings: {
            edit: AccessLevel.ASSIGNED,
            view: AccessLevel.ALL,
         },
      },
   },
   {
      id: '',
      index: 2,
      name: 'Lead',
      memberIds: [],
      accessMap: {
         Dashboard: {
            edit: AccessLevel.ASSIGNED,
            view: AccessLevel.ASSIGNED,
         },
         Documentation: {
            edit: AccessLevel.ASSIGNED,
            view: AccessLevel.ASSIGNED,
         },
         Evolve: {
            edit: AccessLevel.ASSIGNED,
            view: AccessLevel.ASSIGNED,
         },
         Organization: {
            edit: AccessLevel.ASSIGNED,
            view: AccessLevel.ALL,
         },
         Overview: {
            edit: AccessLevel.NONE,
            view: AccessLevel.ALL,
         },
         Performance: {
            edit: AccessLevel.ASSIGNED,
            view: AccessLevel.ASSIGNED,
         },
         Team: {
            edit: AccessLevel.ASSIGNED,
            view: AccessLevel.ALL,
         },
         Meetings: {
            edit: AccessLevel.ASSIGNED,
            view: AccessLevel.ASSIGNED,
         },
      },
   },
   {
      id: '',
      index: 3,
      name: 'Member',
      memberIds: [],
      accessMap: {
         Dashboard: {
            edit: AccessLevel.NONE,
            view: AccessLevel.ALL,
         },
         Documentation: {
            edit: AccessLevel.NONE,
            view: AccessLevel.ALL,
         },
         Evolve: {
            edit: AccessLevel.NONE,
            view: AccessLevel.ALL,
         },
         Organization: {
            edit: AccessLevel.NONE,
            view: AccessLevel.ALL,
         },
         Overview: {
            edit: AccessLevel.NONE,
            view: AccessLevel.ALL,
         },
         Performance: {
            edit: AccessLevel.NONE,
            view: AccessLevel.ALL,
         },
         Team: {
            edit: AccessLevel.NONE,
            view: AccessLevel.ALL,
         },
         Meetings: {
            edit: AccessLevel.NONE,
            view: AccessLevel.ASSIGNED,
         },
      },
   },
];

export const emptyPermissionGroup: PermissionGroup = {
   id: '',
   name: '',
   index: 0,
   memberIds: [],
   accessMap: {
      Dashboard: {
         edit: AccessLevel.NONE,
         view: AccessLevel.NONE,
      },
      Documentation: {
         edit: AccessLevel.NONE,
         view: AccessLevel.NONE,
      },
      Evolve: {
         edit: AccessLevel.NONE,
         view: AccessLevel.NONE,
      },
      Organization: {
         edit: AccessLevel.NONE,
         view: AccessLevel.NONE,
      },
      Overview: {
         edit: AccessLevel.NONE,
         view: AccessLevel.NONE,
      },
      Performance: {
         edit: AccessLevel.NONE,
         view: AccessLevel.NONE,
      },
      Team: {
         edit: AccessLevel.NONE,
         view: AccessLevel.NONE,
      },
      Meetings: {
         edit: AccessLevel.NONE,
         view: AccessLevel.NONE,
      },
   },
};
