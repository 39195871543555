import { Injectable, OnDestroy } from '@angular/core';
import { State } from '@app/app.state';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { EntityService } from '@app/shared/services/entity.service';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { PermissionGroup } from '@entities/permission-group';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable()
export class PermissionGroupService implements EntityService<PermissionGroup>, OnDestroy {
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.PERMISSION_GROUPS;

   entities$: Observable<PermissionGroup[]>;
   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<PermissionGroup>();

   constructor(private store: Store<State>) {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }

   ngOnDestroy() {
      this.orgEntityService.destroy();
   }

   save(entity: PermissionGroup): Promise<PermissionGroup> {
      return this.orgEntityService.save(entity);
   }
   batchSave(entities: PermissionGroup[]): Promise<PermissionGroup[]> {
      return this.orgEntityService.batchSave(entities);
   }
   delete(entity: PermissionGroup): Promise<void> {
      return this.orgEntityService.delete(entity);
   }
}
