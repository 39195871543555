import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TeamMemberNamePipe } from '@app/shared/pipes/team-member-name.pipe';
import { TaskRatingTreeItem } from '@app/team/state/team.state';
import { CompetencyLevel } from '@entities/competency-level';
import { TaskRating } from '@entities/task-rating';
import { TeamMember } from '@entities/team-member';
import { ChartConfiguration, ChartData } from 'chart.js';

// TODO: fix charts
@Component({
   selector: 'app-competency-dashboard-bar-chart',
   templateUrl: './competency-dashboard-bar-chart.component.html',
   styleUrls: ['./competency-dashboard-bar-chart.component.scss'],
})
export class CompetencyDashboardBarChartComponent implements OnInit, OnChanges {
   @Input() competencyLevels: CompetencyLevel[] = [];
   @Input() items: Array<TaskRating | TaskRatingTreeItem>;
   @Input() mode: 'org' | 'individual' = 'org';
   @Input() teamMembers: TeamMember[] = [];

   zeroValue = false;
   noRating = { label: 'No Rating', value: 0, description: 'No rating set' } as CompetencyLevel;
   levels: CompetencyLevel[] = [this.noRating];
   chartLabels = [];
   dataSets = [];
   chartOptions: ChartConfiguration['options'] = {
      plugins: {
         title: {
            display: false,
            text: 'Rocks by status',
         },
         legend: {
            display: false,
         },
      },
      scales: {
         y: {
            suggestedMax: 5,
            suggestedMin: -1,
            ticks: {
               stepSize: 1,
               callback: (val, index) => {
                  const competencyLevel = this.levels.find(
                     (level) => level.value === (this.zeroValue ? +val - 1 : +val)
                  );
                  return competencyLevel?.label ?? '';
               },
            },
         },
      },
   };
   colors: any[] = [
      {
         backgroundColor: [
            '#236192', // Pending
         ],
      },
   ];
   data: any[] = [];
   chartData: ChartData<'bar'>;

   constructor(private teamMemberNamePipe: TeamMemberNamePipe) {}

   ngOnInit(): void {
      if (this.competencyLevels) {
         this.setLevels();
      }
      if (this.items) {
         this.setData();
      }
   }

   ngOnChanges(changes: SimpleChanges) {
      if (
         changes['competencyLevels'] &&
         this.levelsChanged(
            changes['competencyLevels'].previousValue,
            changes['competencyLevels'].currentValue
         )
      ) {
         this.setLevels();
      }
      if (
         changes['items'] &&
         this.itemsChanged(changes['items'].previousValue, changes['items'].currentValue)
      ) {
         this.setData();
      }
   }

   levelsChanged(oldLevels: CompetencyLevel[], newLevels: CompetencyLevel[]) {
      if (oldLevels && newLevels) {
         newLevels.forEach((level, index) => {
            if (level.label !== oldLevels[index].label || level.value !== oldLevels[index].value) {
               return true;
            }
         });
         return false;
      } else {
         return !!newLevels;
      }
   }

   itemsChanged(
      oldItems: Array<TaskRating | TaskRatingTreeItem>,
      newItems: Array<TaskRating | TaskRatingTreeItem>
   ) {
      if (oldItems && newItems) {
         let changed = false;
         newItems.forEach((item, index) => {
            if (this.mode === 'org') {
               if (
                  (item as TaskRatingTreeItem)?.id !== (oldItems[index] as TaskRatingTreeItem)?.id
               ) {
                  changed = true;
               }
            } else {
               if (
                  (item as TaskRating)?.teamMemberId !==
                  (oldItems[index] as TaskRating)?.teamMemberId
               ) {
                  changed = true;
               }
            }
         });
         return changed;
      } else {
         return !!newItems;
      }
   }

   setLevels() {
      this.levels = [this.noRating, ...this.competencyLevels];
      const values = this.levels.map((level) => level.value);
      const minValue = Math.min(...values);
      const maxValue = Math.max(...values);
      if (minValue === 0) {
         this.zeroValue = true;
         this.chartOptions.scales.y.min = 0;
         this.chartOptions.scales.y.max = maxValue + 1;
      } else {
         this.zeroValue = false;
         this.chartOptions.scales.y.min = 0;
         this.chartOptions.scales.y.max = maxValue;
      }
   }

   setData() {
      const sorted = this.items.sort((a, b) => this.getLabel(a).localeCompare(this.getLabel(b)));
      this.data = sorted.map((item) => (this.zeroValue ? item.rating + 1 : item.rating));
      this.chartLabels = sorted.map((item) => this.getLabel(item));
      this.chartData = {
         datasets: [
            {
               data: this.data,
               backgroundColor: '#71cc98',
               borderColor: '#009b77',
               hoverBackgroundColor: '#009b77',
               hoverBorderColor: '#009b77',
            },
         ],
         labels: this.chartLabels,
      };
   }

   getLabel(item: TaskRating | TaskRatingTreeItem) {
      if (this.mode === 'org') {
         return (item as TaskRatingTreeItem).name;
      } else {
         return this.teamMemberNamePipe.transform(
            (item as TaskRating).teamMemberId,
            this.teamMembers
         );
      }
   }
}

