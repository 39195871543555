import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { User } from '@entities/user';
import { UserRole } from '@entities/enums/user-role';
import { combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AdminFacade } from '@app/admin/state/admin.facade';
import { UserInvite } from '@entities/user-invite';
import { ProductType } from '@entities/enums/product-type';
import { Router, ActivatedRoute } from '@angular/router';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { Sort } from '@angular/material/sort';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { TableConfig, TableComponent } from '@app/shared/components/table/table.component';
import { PermissionGroup } from '@entities/permission-group';
import { PermissionsFacade } from '@app/shared/state/permissions.facade';

@Component({
   selector: 'app-user-list',
   templateUrl: './user-list.component.html',
   styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
   @ViewChild(TableComponent) tableComponent: TableComponent;

   users$: Observable<User[]>;
   invites$: Observable<UserInvite[]>;
   showInactive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
   dataSource$: Observable<User[]>;
   filter$: BehaviorSubject<FilterValues> = new BehaviorSubject({ search: '' });
   initialSort: Sort = { active: 'lastName', direction: 'asc' };
   UserRole = UserRole;
   ProductType = ProductType;

   displayedColumns = ['firstName', 'lastName', 'email', 'role', 'products'];
   displayedColumns$ = new BehaviorSubject(this.displayedColumns);
   inviteColumns = ['email', 'role', 'products', 'sent', 'options'];

   usePermissionGroups = false;
   permissionGroups: PermissionGroup[] = [];

   /* istanbul ignore next */
   get columns(): TableColumn<User>[] {
      return [
         { def: 'firstName', label: 'First Name', visible: true, value: (row) => row.firstName },
         { def: 'lastName', label: 'Last Name', visible: true, value: (row) => row.lastName },
         { def: 'email', label: 'Email', visible: true, value: (row) => row.email },
         {
            def: 'role',
            label: 'Role',
            visible: !this.usePermissionGroups,
            value: (row) => this.getRole(row),
         },
         {
            def: 'products',
            label: 'Products',
            visible: !this.usePermissionGroups,
            value: (row) => this.getProducts(row),
         },
         {
            def: 'permissionGroup',
            label: 'Permission Group',
            visible: this.usePermissionGroups,
            value: (row) => this.getPermissionGroup(row),
         },
         {
            def: 'active',
            label: 'Status',
            visible: false,
            value: (row) => (row.active ? 'Active' : 'Inactive'),
         },
      ];
   }

   tableConfig: TableConfig;

   private destroyed$ = new Subject<void>();

   constructor(private adminFacade: AdminFacade, private permissionsFacade: PermissionsFacade) {}

   ngOnInit() {
      this.users$ = this.adminFacade.users$;
      this.invites$ = this.adminFacade.invites$;
      this.dataSource$ = combineLatest([this.users$, this.showInactive$.asObservable()]).pipe(
         map(([users, showInactive]) => {
            if (!showInactive) {
               users = users.filter((u) => u.active);
            }
            return users;
         })
      );
      this.permissionsFacade.usePermissionGroups$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((usePermissionGroups) => {
            this.usePermissionGroups = usePermissionGroups;
            if (this.usePermissionGroups) {
               this.displayedColumns$.next(['firstName', 'lastName', 'email', 'permissionGroup']);
            } else {
               this.displayedColumns$.next(['firstName', 'lastName', 'email', 'role', 'products']);
            }
         });
      this.permissionsFacade.permissionGroups$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((permissionGroups) => {
            this.permissionGroups = permissionGroups;
         });

      this.tableConfig = {
         columns: this.columns,
         visibleColumns$: this.displayedColumns$,
         data$: this.dataSource$,
         filter$: this.filter$,
         initialSort: { direction: 'asc', active: 'lastName' },
         statusField: 'status',
         rowClick: (row) => this.edit(row),
         options: [
            { label: 'Edit', action: (row) => this.edit(row) },
            { label: 'Delete', action: (row) => this.deleteUser(row) },
         ],
      };
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   edit(user: any) {
      this.adminFacade.editUser(user);
   }

   addUser() {
      this.adminFacade.addUser();
   }

   deleteUser(user: User) {
      this.adminFacade.deleteUser(user);
   }

   showInactive(event: any) {
      this.showInactive$.next(event.checked);
      const activeColumn = this.columns.find((col) => col.def === 'active');
      activeColumn.visible = event.checked;
      this.tableComponent.setDisplayedColumns();
   }

   triggerFilter(event: any) {
      const filter = event.target.value;
      this.filter$.next({ search: filter });
   }

   resend(invite: UserInvite) {
      this.adminFacade.resendInvite(invite);
   }

   deleteInvite(invite: UserInvite) {
      this.adminFacade.deleteInvite(invite);
   }

   getProducts(user: User) {
      return user.product
         ? ProductType[user.product]
         : user.products && user.products.length > 0
         ? ProductType[user.products[0]]
         : '';
   }

   getRole(user: User) {
      if (user.isAdmin != null) {
         return user.isAdmin ? 'Admin' : 'User';
      } else {
         return user.role === UserRole.Admin ? 'Admin' : 'User';
      }
   }

   getPermissionGroup(user: User) {
      const permissionGroup = this.permissionGroups.find(
         (permissionGroup) => permissionGroup.id === user.permissionGroupId
      );
      return permissionGroup?.name || '';
   }

   back() {
      history.back();
   }
}
