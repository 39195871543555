import { Rock } from '@entities/rock';
import * as RockActions from './rock.actions';
import { createReducer, on } from '@ngrx/store';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { UserOrgActions } from '@app/user-org/state/user-org.actions';
import { Sort } from '@angular/material/sort';

export interface RockState {
   entities: Rock[];
   selectedRockId: string;
   filter: FilterValues;
   organizationId: string;
   activeTab: number;
   visibleColumns: string[];
   sort: Sort;
}

export const initialState: RockState = {
   entities: [],
   selectedRockId: null,
   filter: {},
   organizationId: null,
   activeTab: 0,
   visibleColumns: [],
   sort: { active: 'name', direction: 'asc' },
};

export const reducer = createReducer<RockState>(
   initialState,
   on(RockActions.RocksUpdated, (state, { rocks }) => {
      return {
         ...state,
         entities: rocks,
      };
   }),
   on(RockActions.SelectRock, (state, { rockId }) => {
      return {
         ...state,
         selectedRockId: rockId,
      };
   }),
   on(RockActions.UpdateRockFilterValues, (state, { filter }) => {
      return {
         ...state,
         filter,
      };
   }),
   on(RockActions.SetActiveTab, (state, { activeTab }) => {
      return {
         ...state,
         activeTab,
      };
   }),
   on(
      UserOrgActions.SetOrganization,
      UserOrgActions.SelectOrganization,
      (state, { organizationId }) => {
         if (state.organizationId != organizationId) {
            return { ...initialState, organizationId };
         } else {
            return state;
         }
      }
   ),
   on(RockActions.SetVisibleColumns, (state, { columns }) => {
      return {
         ...state,
         visibleColumns: columns,
      };
   }),
   on(RockActions.SetSort, (state, { sort }) => {
      return {
         ...state,
         sort,
      };
   })
);
