import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';

import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { Rock } from '@entities/rock';
import { Sort } from '@angular/material/sort';
import { RoleGuard } from '@app/admin/services/role.guard';
import { map, takeUntil } from 'rxjs/operators';
import { RockStatus } from '@entities/enums/rock-status';
import { DepartmentFunction } from '@entities/department-function';

import { EvolveFacade } from '@app/evolve/state/evolve.facade';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { DepartmentNamePipe } from '@app/shared/pipes/department-name.pipe';
import { GoalNamePipe } from '@app/shared/pipes/goal.pipe';
import { TeamMemberNamePipe } from '@app/shared/pipes/team-member-name.pipe';
import { FilterBarConfig, FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { Department } from '@entities/department';
import { Goal } from '@entities/goal';
import { TeamMember } from '@entities/team-member';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { DatePipe } from '@angular/common';
import { TableConfig, TableComponent } from '@app/shared/components/table/table.component';
import { BusinessUnit } from '@entities/business-unit';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import * as moment from 'moment';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-rocks-list',
   templateUrl: './rocks-list.component.html',
   styleUrls: ['./rocks-list.component.scss'],
})
export class RocksListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent) table: TableComponent;
   displayedColumns: string[];

   filterBarConfig: FilterBarConfig = {
      businessUnits: true,
      departments: true,
      quarter: true,
      status: RockStatus,
      teamMembers: true,
      teamMembersLabel: 'Assigned To',
      filterChange: (filter) => this.filterFormChanged(filter),
   };

   /* istanbul ignore next */
   columns: TableColumn<Rock>[] = [
      { label: 'Name', def: 'name', visible: true, value: (row) => row.name },
      {
         label: 'Department',
         def: 'departmentId',
         visible: true,
         value: (row) => this.departmentNamePipe.transform(row.departmentId, this.departments),
         filter: 'departments',
      },
      {
         label: 'Goal',
         def: 'goalId',
         visible: true,
         value: (row) => this.goalNamePipe.transform(row.goalId, this.goals),
      },
      {
         label: 'Quarter',
         def: 'quarter',
         visible: true,
         value: (row) => row.quarter,
         filter: 'quarter',
      },
      {
         label: 'Assigned To',
         def: 'assigneeId',
         visible: true,
         value: (row) => this.teamMemberNamePipe.transform(row.assigneeId, this.teamMembers),
      },
      {
         label: 'Status',
         def: 'status',
         visible: true,
         value: (row) => RockStatus[row.status],
         filter: 'status',
         icon: (row) => this.getIcon(row),
      },
      {
         label: 'Start Date',
         def: 'startDate',
         visible: false,
         isDate: true,
         value: (row) => this.datePipe.transform(row.startDate),
      },
      {
         label: 'Target Date',
         def: 'targetDate',
         visible: false,
         isDate: true,
         value: (row) => this.datePipe.transform(row.targetDate),
      },
      {
         label: 'Completed Date',
         def: 'completedDate',
         visible: false,
         isDate: true,
         value: (row) => this.datePipe.transform(row.completedDate),
      },
      {
         label: 'Approved Date',
         def: 'approvedDate',
         visible: false,
         isDate: true,
         value: (row) => this.datePipe.transform(row.approvedDate),
      },
      {
         label: 'Next Milestone',
         def: 'nextAction',
         visible: true,
         isDate: false,
         value: (row) => this.nextMilestoneDescription(row),
         preventSort: true,
      },
      {
         label: 'Milestone Due Date',
         def: 'milestoneDate',
         visible: true,
         isDate: true,
         value: (row) => this.datePipe.transform(this.nextMilestoneDueDate(row)),
      },
      {
         label: 'Last Note',
         def: 'lastNote',
         visible: false,
         isDate: false,
         isHtml: true,
         value: (row) => this.lastNote(row),
         tooltip: (row) => this.lastNote(row),
         icon: (row) => {
            if (this.lastNote(row)) {
               return { svg: false, icon: 'description' };
            } else {
               return null;
            }
         },
         preventSort: true,
      },
   ];

   activeTab$: Observable<number>;
   rocks$: Observable<Rock[]>;
   dataSource$: Observable<Rock[]>;
   filter$: Observable<FilterValues>;
   activeSort$: Observable<string>;
   direction$: Observable<string>;
   visibleColumns$ = this.evolveFacade.rocksVisibleColumns$;

   rockStatus = RockStatus;
   businessUnits: BusinessUnit[];
   departments: Department[];
   quarters: string[];
   goals: Goal[];
   teamMembers: TeamMember[];

   tableConfig: TableConfig;

   filteredRocks: Rock[] = [];

   canEdit = false;

   private _destroyed$ = new Subject<void>();

   constructor(
      private evolveFacade: EvolveFacade,
      private roleGuard: RoleGuard,
      private departmentNamePipe: DepartmentNamePipe,
      private goalNamePipe: GoalNamePipe,
      private teamMemberNamePipe: TeamMemberNamePipe,
      private datePipe: DatePipe
   ) {}

   ngOnInit() {
      this.rocks$ = this.evolveFacade.rocks$;
      this.filter$ = this.evolveFacade.rocksFilter$;
      this.activeTab$ = this.evolveFacade.rocksActiveTab$;
      this.roleGuard
         .canEdit([], AppAreas.Evolve)
         .pipe(takeUntil(this._destroyed$))
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
         });
      this.evolveFacade.businessUnits$
         .pipe(takeUntil(this._destroyed$))
         .subscribe((businessUnits) => {
            this.businessUnits = businessUnits;
         });
      this.evolveFacade.departments$.pipe(takeUntil(this._destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.evolveFacade.goals$.pipe(takeUntil(this._destroyed$)).subscribe((goals) => {
         this.goals = goals;
      });
      this.evolveFacade.teamMembers$.pipe(takeUntil(this._destroyed$)).subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });
      this.filter$.pipe(takeUntil(this._destroyed$)).subscribe((filter) => {
         this.quarters = filter.quarter || [];
      });

      /* istanbul ignore next */
      this.tableConfig = {
         data$: this.evolveFacade.rocks$,
         filter$: this.evolveFacade.rocksFilter$,
         columns: this.columns,
         visibleColumns$: this.visibleColumns$,
         defaultColumns: this.columns.filter((col) => col.visible).map((col) => col.def),
         sort$: this.evolveFacade.rocksSort$,
         initialSort: { direction: 'asc', active: 'name' },
         sortChange: (sort) => this.sortChange(sort),
         teamMemberField: 'assigneeId',
         rowClick: (row) => this.edit(row),
         options: [
            { label: 'Edit', action: (row) => this.edit(row), condition: (row) => this.canEdit },
            {
               label: 'Details',
               action: (row) => this.edit(row),
               condition: (row) => !this.canEdit,
            },
            {
               label: 'Delete',
               action: (row) => this.delete(row),
               condition: (row) => this.canEdit,
            },
         ],
         departments: this.departments,
         exportFilename: 'Rocks',
      };
   }

   ngOnDestroy() {
      this._destroyed$.next();
   }

   setFilteredRocks(data: Rock[]) {
      this.filteredRocks = data;
   }

   addRock() {
      this.evolveFacade.addRock();
   }

   edit(rock: Rock) {
      this.evolveFacade.editRock(rock);
   }

   delete(rock: Rock) {
      this.evolveFacade.deleteRock(rock);
   }

   filterFormChanged(filter: FilterValues) {
      this.evolveFacade.updateRocksFilter(filter);
   }

   setActiveTab(event: MatTabChangeEvent) {
      this.evolveFacade.setRocksActiveTab(event.index);
   }

   nextMilestoneDueDate(rock: Rock) {
      const nextMilestone = this.nextMilestone(rock);
      return nextMilestone?.targetDate;
   }

   nextMilestoneDescription(rock: Rock) {
      const nextMilestone = this.nextMilestone(rock);
      return nextMilestone?.description;
   }

   nextMilestone(rock: Rock) {
      if (rock.actions && rock.actions.length > 0) {
         const actions = rock.actions
            .filter((a) => !a.completed && a.targetDate)
            .sort((a, b) => {
               const aDate = moment(a.targetDate);
               const bDate = moment(b.targetDate);
               return aDate.isSameOrBefore(bDate) ? -1 : 1;
            });
         return actions[0];
      } else {
         return undefined;
      }
   }

   getIcon(rock: Rock) {
      switch (rock.status) {
         case RockStatus['On Track']:
            return { svg: true, icon: 'sign-direction' };
         case RockStatus['Off Track']:
            return { svg: false, icon: 'warning' };
         case RockStatus['On Hold']:
            return { svg: false, icon: 'schedule' };
         case RockStatus['Pending Approval']:
            return { svg: false, icon: 'how_to_reg' };
         case RockStatus['Completed']:
            return { svg: false, icon: 'check_circle' };
         case RockStatus['Not Started']:
            return { svg: false, icon: 'pending' };
         default:
            return { svg: false, icon: '' };
      }
   }

   save(rock: Rock) {
      this.evolveFacade.saveRock(rock);
   }

   setDisplayedColumns(columns: TableColumn<Rock>[]) {
      this.evolveFacade.setRocksDisplayedColumns(columns.map((col) => col.def));
   }

   export(filterData: boolean) {
      this.table.export(filterData);
   }

   mostRecentComment(rock: Rock) {
      const comments = [...rock.comments].sort((a, b) => {
         const aDate = moment(a.timestamp);
         const bDate = moment(b.timestamp);
         return aDate.isSameOrBefore(bDate) ? 1 : -1;
      });
      return comments[0];
   }

   lastNote(rock: Rock) {
      const comment = this.mostRecentComment(rock);
      return this.getText(comment?.text);
   }

   getText(htmlText: string) {
      if (htmlText) {
         const doc = new DOMParser().parseFromString(htmlText, 'text/html');
         return doc.body.textContent;
      } else {
         return '';
      }
   }

   sortChange(sort: Sort) {
      this.evolveFacade.setRocksSort(sort);
   }
}
