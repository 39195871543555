<div class="topnav-container flex-row">
   <div class="logo" (click)="logoClick()">
      <img class="logo-image" src="assets/TrellisToolsIconWhite.svg" />
   </div>
   <span
      class="no-flex title"
      [ngClass]="{ 'search-hidden': showSearch }"
      *ngIf="loggedIn$ | async"
      (click)="goToOrg(selectedOrgId)"
   >
      {{ title$ | async }}
   </span>
   <ng-container *ngIf="orgSelected$ | async">
      <ng-container *ngIf="!showSearch">
         <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; first as isFirst">
            <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
            <a class="breadcrumb-text" [routerLink]="getRoute(breadcrumb)">
               {{ breadcrumb.label }}
            </a>
         </ng-container>
      </ng-container>
   </ng-container>
   <span class="flex-spacer"></span>
   <div class="search-container">
      <ng-container *ngIf="orgSelected$ | async">
         <div *ngIf="showSearch" class="search-input">
            <app-search-input></app-search-input>
         </div>
         <button mat-icon-button (click)="toggleSearch()">
            <mat-icon *ngIf="!showSearch">search</mat-icon>
            <mat-icon *ngIf="showSearch">close</mat-icon>
         </button>
      </ng-container>
   </div>
   <div class="no-flex">
      <ng-container *ngIf="loggedIn$ | async">
         <button mat-button class="dropdown-button" [matMenuTriggerFor]="accountMenu">
            <mat-icon>account_circle</mat-icon>
            <span class="user-name">
               {{ userDisplayName$ | async }}
            </span>
            <mat-icon>arrow_drop_down</mat-icon>
         </button>
         <mat-menu #accountMenu="matMenu">
            <button class="header" mat-menu-item (click)="goToUserAccount()">User Account</button>
            <button class="header" mat-menu-item (click)="goToOrgs()">Organizations</button>

            <div class="header" mat-menu-item disabled *ngIf="showRecent$ | async">
               Recent Organizations
            </div>
            <button
               mat-menu-item
               *ngFor="let org of recentOrgs$ | async"
               (click)="goToOrg(org.organizationId)"
               data-test-id="recent-org"
            >
               {{ org.name }}
            </button>

            <hr />
            <button mat-menu-item (click)="logout()">
               <mat-icon svgIcon="logout"></mat-icon>
               Log out
            </button>
         </mat-menu>
      </ng-container>
      <button
         *ngIf="helpFeatureFlag$ | async"
         mat-icon-button
         data-test-id="help-button"
         class="dropdown-button"
         (click)="toggleHelp()"
      >
         <mat-icon>help_circle</mat-icon>
      </button>
   </div>
</div>
