import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { DepartmentFunction } from '@entities/department-function';
import { EntityService } from '@app/shared/services/entity.service';
import { select, Store } from '@ngrx/store';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { OrgEntityService } from '@app/shared/services/org-entity.service';

import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { State } from '@app/app.state';
import { PermissionedEntity } from '@app/shared/interfaces/permissioned-entity.interface';
import { WhereFilterOp, where } from '@angular/fire/firestore';
import { PermissionsFacade } from '@app/shared/state/permissions.facade';
import { AppAreas } from '@entities/enums/app-areas';

@Injectable()
export class DepartmentFunctionService
   implements EntityService<DepartmentFunction>, PermissionedEntity, OnDestroy
{
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.DEPARTMENT_FUNCTIONS;
   entities$: Observable<DepartmentFunction[]>;

   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<DepartmentFunction>();
   private teamMemberId$ = this.permissionsFacade.currentUserTeamMemberId$;
   private permissionGroup$ = this.permissionsFacade.currentUserPermissionGroup$;

   constructor(private store: Store<State>, private permissionsFacade: PermissionsFacade) {
      this.orgEntityService.init(
         this.orgId$,
         this.COLLECTION_NAME,
         AppAreas.Documentation,
         this.permissionGroup$,
         this.assigneeFilter,
         this.teamMemberId$
      );
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }
   save(entity: DepartmentFunction, overwrite: boolean = false): Promise<DepartmentFunction> {
      return this.orgEntityService.save(entity, { overwrite });
   }
   batchSave(entities: DepartmentFunction[]): Promise<DepartmentFunction[]> {
      return this.orgEntityService.batchSave(entities);
   }
   delete(entity: DepartmentFunction): Promise<void> {
      return this.orgEntityService.delete(entity);
   }
   assigneeFilter(teamMemberId: string): Array<[string, WhereFilterOp, string]> {
      return [['leadId', '==', teamMemberId]];
   }
}
