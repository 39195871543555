import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { stringify } from 'flatted';
import { environment } from '@src/environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
   constructor() {}
   handleError(error) {
      if (environment.production) {
         const err: Error = error.originalError || error;
         const stringified = stringify(err);
         if (
            stringified.includes('Missing or insufficient permissions') ||
            (stringified.includes('FirebaseError') && stringified.includes('permission-denied'))
         ) {
            return;
         }
         const eventId = Sentry.captureException(err);
         const state = localStorage.getItem('state');
         const stateObj = JSON.parse(state);
         if (stateObj && stateObj.userOrg) {
            Sentry.setExtra('orgId', stateObj.userOrg.selectedOrgId);
         }
         Sentry.setExtra('originalError', stringified);
         // Sentry.showReportDialog({ eventId });
      }
      console.error(error);
   }
}
