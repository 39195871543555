import { Component, OnInit } from '@angular/core';
import { FeatureFlagService } from '@app/internal-tools/services/feature-flag.service';
import { FeatureFlags, FeatureFlag } from '@app/internal-tools/state/internal-tools.reducer';
import { Observable } from 'rxjs';

@Component({
   selector: 'app-feature-flags',
   templateUrl: './feature-flags.component.html',
   styleUrls: ['./feature-flags.component.scss'],
})
export class FeatureFlagsComponent implements OnInit {
   featureFlags$: Observable<FeatureFlag[]>;

   displayedColumns = ['key', 'description', 'enabled'];

   constructor(private featureFlagService: FeatureFlagService) {}

   ngOnInit(): void {
      this.featureFlags$ = this.featureFlagService.featureList$;
   }

   toggleFeatureFlag(key: string) {
      this.featureFlagService.toggleFeatureFlag(key);
   }
}
