import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   OnDestroy,
   SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DepartmentService } from '@app/org-builder/services/department.service';
import { Department } from '@entities/department';
import { BusinessUnit } from '@entities/business-unit';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { getDepartments, getBusinessUnits } from '@app/org-builder/state/organization.state';

@Component({
   selector: 'app-department-autocomplete',
   templateUrl: './department-autocomplete.component.html',
   styleUrls: ['./department-autocomplete.component.scss'],
})
export class DepartmentAutocompleteComponent implements OnInit, OnDestroy {
   @Input() class: any;
   @Input() placeholder: string;
   @Input() formCtrl: UntypedFormControl;
   @Input() idFormControl: UntypedFormControl;
   @Input() required = false;
   @Input() canEdit = true;
   @Output() optionSelected: EventEmitter<MatAutocompleteSelectedEvent> = new EventEmitter();

   departments$: Observable<Department[]> = this.store.pipe(select(getDepartments));
   businessUnits$: Observable<BusinessUnit[]> = this.store.pipe(select(getBusinessUnits));
   filtered$: Observable<BusinessUnit[]>;
   focus$ = new BehaviorSubject<boolean>(false);
   filter$ = new BehaviorSubject<any>('');
   selected = false;
   departments: Department[];

   private destroyed$ = new Subject<void>();

   constructor(private store: Store<State>) {}

   ngOnInit() {
      if (!this.formCtrl) {
         this.formCtrl = new UntypedFormControl();
         if (this.canEdit) {
            this.formCtrl.enable();
         } else {
            this.formCtrl.disable();
         }
      }
      this.formCtrl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
         this.filter$.next(val);
      });
      if (!this.idFormControl) {
         this.idFormControl = new UntypedFormControl();
      }
      this.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
         this.setDepartment();
      });
      this.idFormControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
         this.setDepartment();
      });
      this.filtered$ = combineLatest([
         this.departments$,
         this.businessUnits$,
         this.filter$.asObservable(),
         this.focus$.asObservable(),
      ]).pipe(
         map(([departments, businessUnits, filter, focus]) => {
            if (departments && businessUnits) {
               const filteredBusinessUnits = [];
               let filtered = departments;
               if (filter && !filter.id) {
                  filtered = departments.filter((t) =>
                     t.name.toLowerCase().includes(filter.toLowerCase())
                  );
               }
               businessUnits.forEach((bu) => {
                  const businessUnit = { ...bu };
                  businessUnit.departments = filtered.filter(
                     (dept) => dept.businessUnitId === businessUnit.id
                  );
                  if (businessUnit.departments.length > 0) {
                     filteredBusinessUnits.push(businessUnit);
                  }
               });
               return filteredBusinessUnits;
            } else {
               return [];
            }
         })
      );
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['canEdit']) {
         if (this.canEdit) {
            this.formCtrl?.enable();
         } else {
            this.formCtrl?.disable();
         }
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   setDepartment() {
      if (this.idFormControl.value && this.departments) {
         const department = this.departments.find((t) => t.id == this.idFormControl.value);
         this.formCtrl.setValue(department);
         this.selected = true;
      } else {
         this.formCtrl.reset();
         this.selected = false;
      }
   }

   displayFn(department?: Department): string | undefined {
      return department ? department.name : undefined;
   }

   onOptionSelect(event: MatAutocompleteSelectedEvent) {
      this.optionSelected.emit(event);
      this.selected = true;
   }

   onFocus() {
      this.focus$.next(true);
   }

   clear() {
      this.formCtrl.reset();
      this.selected = false;
      this.optionSelected.emit(null);
   }
}
