import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { Router } from '@angular/router';
import { MeetingsActions } from './meetings.actions';
import { tap, withLatestFrom, switchMap, map, catchError } from 'rxjs/operators';
import { Meeting } from '@entities/meeting';
import * as moment from 'moment';
import { MeetingService } from '../services/meeting.service';
import { ErrorService } from '@app/shared/services/error.service';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { appRoutesNames } from '@app/app.routes.names';
import { meetingRouteNames } from '../meetings.route.names';
import { from, of } from 'rxjs';
import { meetingsSelectors } from './meetings.state';
import { MeetingStatus } from '@entities/enums/meeting-status';
import { Rock } from '@entities/rock';
import { RockService } from '@app/evolve/services/rock.service';
import { ActionItem } from '@entities/action-item';
import { Status } from '@entities/enums/status';
import { RockStatus } from '@entities/enums/rock-status';
import { ActionItemService } from '@app/evolve/services/action-item.service';

@Injectable()
export class MeetingsEffects {
   constructor(
      private actions$: Actions,
      private store: Store<State>,
      private router: Router,
      private meetingService: MeetingService,
      private errorService: ErrorService,
      private rockService: RockService,
      private actionItemService: ActionItemService
   ) {}

   createMeeting$ = createEffect(() =>
      this.actions$.pipe(
         ofType(MeetingsActions.CreateMeeting),
         withLatestFrom(this.store.pipe(select(getOrganizationId))),
         switchMap(([{ meeting }, orgId]) => {
            return from(this.meetingService.save(meeting)).pipe(
               map((savedMeeting) => {
                  return MeetingsActions.StartMeeting({ meetingId: savedMeeting.id });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   startMeeting$ = createEffect(() =>
      this.actions$.pipe(
         ofType(MeetingsActions.StartMeeting),
         withLatestFrom(
            this.store.pipe(select(getOrganizationId)),
            this.store.pipe(select(meetingsSelectors.getSelectedMeeting))
         ),
         switchMap(([{ meetingId }, orgId, meeting]) => {
            const updatedMeeting: Meeting = {
               ...meeting,
               startTime: moment().toISOString(),
               status: MeetingStatus['In Progress'],
            };
            return from(this.meetingService.save(updatedMeeting)).pipe(
               map((savedMeeting) => {
                  this.router.navigate([
                     appRoutesNames.ORGANIZATION,
                     orgId,
                     appRoutesNames.MEETINGS,
                     savedMeeting.id,
                     meetingRouteNames.ACTIVE,
                  ]);
                  if (meeting.rocks.length > 0) {
                     return MeetingsActions.SelectRock({ selectedRockId: meeting.rocks[0] });
                  } else {
                     return MeetingsActions.SelectActionItem({
                        selectedActionItemId: meeting.actionItems[0],
                     });
                  }
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   saveMeeting$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(MeetingsActions.SaveMeeting),
            tap(({ meeting }) => {
               this.meetingService.save(meeting).catch((error) => {
                  this.errorService.handleError(error);
               });
            })
         ),
      { dispatch: false }
   );

   endMeeting$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(MeetingsActions.EndMeeting),
            withLatestFrom(
               this.store.pipe(select(meetingsSelectors.getSelectedMeeting)),
               this.store.pipe(select(getOrganizationId))
            ),
            tap(([action, meeting, orgId]) => {
               const toSave = {
                  ...meeting,
                  endTime: moment().toISOString(),
                  status: MeetingStatus.Completed,
               };
               this.meetingService
                  .save(toSave)
                  .then((saved) => {
                     this.router.navigate([
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.MEETINGS,
                        saved.id,
                        meetingRouteNames.SUMMARY,
                     ]);
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   viewMeeting$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(MeetingsActions.ViewMeeting),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ id }, orgId]) => {
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.MEETINGS,
                  id,
                  meetingRouteNames.SUMMARY,
               ]);
            })
         ),
      { dispatch: false }
   );

   createDraftMeeting$ = createEffect(() =>
      this.actions$.pipe(
         ofType(MeetingsActions.CreateDraftMeeting),
         withLatestFrom(this.store.pipe(select(getOrganizationId))),
         switchMap(([{ meeting }, orgId]) => {
            return from(this.meetingService.save(meeting)).pipe(
               map(() => {
                  this.router.navigate([
                     appRoutesNames.ORGANIZATION,
                     orgId,
                     appRoutesNames.MEETINGS,
                  ]);
                  return MeetingsActions.SelectMeeting({ selectedMeetingId: null });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   deleteMeeting$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(MeetingsActions.DeleteMeeting),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([meeting, orgId]) => {
               this.meetingService
                  .delete(meeting)
                  .then(() => {
                     this.router.navigate([
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.MEETINGS,
                     ]);
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   addRockToMeeting$ = createEffect(() =>
      this.actions$.pipe(
         ofType(MeetingsActions.AddRockToMeeting),
         withLatestFrom(
            this.store.pipe(select(meetingsSelectors.getSelectedMeeting)),
            this.store.pipe(select(meetingsSelectors.getSelectedRockId))
         ),
         switchMap(([action, meeting, rockId]) => {
            const rock = {
               name: 'New Rock',
               status: RockStatus['Not Started'],
               quarter: 'Pending',
            } as Rock;
            const index = meeting.rocks.indexOf(rockId) + 1;

            return from(this.rockService.save(rock)).pipe(
               map((savedRock) => {
                  return { meeting, index, rockId: savedRock.id };
               })
            );
         }),
         switchMap(({ meeting, index, rockId }) => {
            const rocks = [...meeting.rocks];
            rocks.splice(index, 0, rockId);
            const updatedMeeting: Meeting = {
               ...meeting,
               rocks,
            };
            return from(this.meetingService.save(updatedMeeting)).pipe(
               map(() => {
                  return MeetingsActions.SelectRock({ selectedRockId: rockId });
               })
            );
         })
      )
   );

   addActionItemToMeeting$ = createEffect(() =>
      this.actions$.pipe(
         ofType(MeetingsActions.AddActionItemToMeeting),
         withLatestFrom(
            this.store.pipe(select(meetingsSelectors.getSelectedMeeting)),
            this.store.pipe(select(meetingsSelectors.getSelectedActionItemId))
         ),
         switchMap(([action, meeting, actionItemId]) => {
            const actionItem = { name: 'New Action', status: Status['Not Started'] } as ActionItem;
            const index = meeting.actionItems.indexOf(actionItemId) + 1;

            return from(this.actionItemService.save(actionItem)).pipe(
               map((savedActionItem) => {
                  return { meeting, index, actionItemId: savedActionItem.id };
               })
            );
         }),
         switchMap(({ meeting, index, actionItemId }) => {
            const actionItems = [...meeting.actionItems];
            actionItems.splice(index, 0, actionItemId);
            const updatedMeeting: Meeting = {
               ...meeting,
               actionItems,
            };
            return from(this.meetingService.save(updatedMeeting)).pipe(
               map(() => {
                  return MeetingsActions.SelectActionItem({ selectedActionItemId: actionItemId });
               })
            );
         })
      )
   );
}
