import { Component, OnInit, OnDestroy } from '@angular/core';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { Subject } from 'rxjs';
import { DepartmentFunction } from '@entities/department-function';
import { Task } from '@entities/task';
import { froalaConfig } from '@app/shared/config/froala.config';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
   selector: 'app-documentation-function-report',
   templateUrl: './documentation-function-report.component.html',
   styleUrls: ['./documentation-function-report.component.scss'],
})
export class DocumentationFunctionReportComponent implements OnInit, OnDestroy {
   options = {
      ...froalaConfig,
      documentReady: true,
      toolbarButtons: ['print'],
   };

   deptFn: DepartmentFunction;
   tasks: Task[];
   businessUnit: BusinessUnit;
   department: Department;
   orgName: string;
   logoUrl: any;

   private destroyed$ = new Subject<void>();

   constructor(private facade: DocumentationFacade, private sanitizer: DomSanitizer) {}

   ngOnInit() {
      this.facade.selectedDepartmentFunction$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((viewModel) => {
            if (viewModel) {
               this.deptFn = viewModel.departmentFunction;
               this.businessUnit = viewModel.businessUnit;
               this.department = viewModel.department;
               this.tasks = this.deptFn.tasks;
               this.tasks.sort((a, b) => a.order - b.order);
            }
         });

      this.facade.org$.pipe(takeUntil(this.destroyed$)).subscribe((org) => {
         this.orgName = org.name;
         this.logoUrl = org.logo;
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   back() {
      history.back();
   }

   sanitize(instructions) {
      return this.sanitizer.bypassSecurityTrustHtml(instructions);
   }
}
