import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetingsComponent } from './components/meetings/meetings.component';
import { MeetingService } from './services/meeting.service';
import { MeetingDetailsComponent } from './components/meeting-details/meeting-details.component';
import { ActiveMeetingComponent } from './components/active-meeting/active-meeting.component';
import { MeetingsFacade } from './state/meetings.facade';
import { SharedModule } from '@app/shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/meetings.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MeetingsEffects } from './state/meetings.effects';
import { MeetingsRockDetailComponent } from './components/meetings-rock-detail/meetings-rock-detail.component';
import { MeetingActionItemDetailsComponent } from './components/meeting-action-item-details/meeting-action-item-details.component';
import { MeetingAgendaComponent } from './components/meeting-agenda/meeting-agenda.component';

import { EvolveModule } from '@app/evolve/evolve.module';
import { FieldChangeComponent } from './components/field-change/field-change.component';

@NgModule({
   declarations: [
      MeetingsComponent,
      MeetingDetailsComponent,
      ActiveMeetingComponent,
      MeetingsRockDetailComponent,
      MeetingActionItemDetailsComponent,
      MeetingAgendaComponent,
      FieldChangeComponent,
   ],
   imports: [
      CommonModule,
      SharedModule,
      StoreModule.forFeature('meetings', reducer),
      EffectsModule.forFeature([MeetingsEffects]),
      EvolveModule,
   ],
   providers: [MeetingService, MeetingsFacade],
})
export class MeetingsModule {}
