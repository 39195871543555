import { Pipe, PipeTransform } from '@angular/core';
import { Department } from '@entities/department';

@Pipe({
   name: 'departmentName',
   pure: true,
})
export class DepartmentNamePipe implements PipeTransform {
   transform(value: string, departments: Department[] = []): any {
      const department = departments.find((dept) => dept.id == value);
      return department ? department.name : '';
   }
}
