import { Pipe, PipeTransform } from '@angular/core';
import { TeamMember } from '@entities/team-member';

@Pipe({
   name: 'teamMemberName',
   pure: true,
})
export class TeamMemberNamePipe implements PipeTransform {
   transform(value?: string, teamMembers: TeamMember[] = []): any {
      const teamMember = teamMembers.find((t) => t.id == value);
      return teamMember ? `${teamMember.firstName.trim()} ${teamMember.lastName.trim()}` : '';
   }
}
