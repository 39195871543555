import { Pipe, PipeTransform } from '@angular/core';
import { Goal } from '@entities/goal';

@Pipe({
  name: 'goalName',
  pure: true
})
export class GoalNamePipe implements PipeTransform {
  transform(value?: string, goals?: Goal[]): any {
    const goal = goals.find((t) => t.id == value);
    return goal ? goal.name : '';
  }
}
