import { Goal } from '@entities/goal';
import { GoalActions } from './goal.actions';
import { on, createReducer } from '@ngrx/store';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { filter } from 'rxjs/operators';

export interface GoalState {
   entities: Goal[];
   editRows: Goal[];
   selectedGoalId: string;
   orgFilter: FilterValues;
   buFilter: FilterValues;
   deptFilter: FilterValues;
   activeTab: number;
}

export const initialState: GoalState = {
   entities: [],
   editRows: [],
   selectedGoalId: null,
   orgFilter: {},
   buFilter: {},
   deptFilter: {},
   activeTab: 0,
};

export const reducer = createReducer<GoalState>(
   initialState,
   on(GoalActions.GoalsUpdated, (state, { goals }) => {
      return {
         ...state,
         entities: goals,
      };
   }),
   on(GoalActions.AddGoal, (state) => {
      const goal = new Goal();
      const entities = [...state.entities, goal];
      return {
         ...state,
         entities,
      };
   }),
   on(GoalActions.EditGoal, (state, { goal }) => {
      const editRows = [...state.editRows, goal];
      return {
         ...state,
         editRows,
         selectedGoalId: goal.id,
      };
   }),
   on(GoalActions.SelectGoal, (state, { goalId }) => {
      return {
         ...state,
         selectedGoalId: goalId,
      };
   }),
   on(GoalActions.HideEditGoal, (state, { goalId }) => {
      const editRows = state.editRows.filter((r) => r.id != goalId);
      return {
         ...state,
         editRows,
      };
   }),
   on(GoalActions.SetOrganizationGoalsFilter, (state, { filterValues }) => {
      return {
         ...state,
         orgFilter: filterValues,
      };
   }),
   on(GoalActions.SetBusinessUnitGoalsFilter, (state, { filterValues }) => {
      return {
         ...state,
         buFilter: filterValues,
      };
   }),
   on(GoalActions.SetDepartmentGoalsFilter, (state, { filterValues }) => {
      return {
         ...state,
         deptFilter: filterValues,
      };
   }),
   on(GoalActions.SetGoalsActiveTab, (state, { activeTab }) => {
      return {
         ...state,
         activeTab,
      };
   })
);
