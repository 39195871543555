import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   OnDestroy,
   ViewChild,
   inject,
} from '@angular/core';
import { User } from '@entities/user';
import { Observable, combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import {
   MatLegacyAutocomplete as MatAutocomplete,
   MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import { AdminFacade } from '@app/admin/state/admin.facade';
import { cloneDeep } from 'lodash';

@Component({
   selector: 'app-user-autocomplete',
   templateUrl: './user-autocomplete.component.html',
   styleUrls: ['./user-autocomplete.component.scss'],
})
export class UserAutocompleteComponent implements OnInit, OnDestroy {
   @Input() class: any;
   @Input() placeholder: string;
   @Input() idFormControl: UntypedFormControl;
   @Input() showClear = true;
   @Input() canEdit = true;
   @Input() required = false;
   @Input() value: string;
   @Input() validate: Observable<any>;
   @Input() showInactive = false;
   @Output() optionSelected: EventEmitter<MatAutocompleteSelectedEvent> = new EventEmitter();
   @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;

   adminFacade = inject(AdminFacade);

   formCtrl: UntypedFormControl = new UntypedFormControl();
   users: User[];
   filtered$: Observable<User[]>;
   focus$ = new BehaviorSubject<boolean>(false);
   filter$ = new BehaviorSubject<any>('');
   selected = false;

   private destroyed$ = new Subject<void>();

   constructor() {}

   ngOnInit() {
      if (!this.idFormControl) {
         this.idFormControl = new UntypedFormControl();
      }
      this.adminFacade.users$
         .pipe(
            takeUntil(this.destroyed$),
            map((users) => {
               return cloneDeep(users).sort((a, b) => {
                  const diff = a.lastName?.localeCompare(b.lastName);
                  return diff || a.firstName?.localeCompare(b.firstName);
               });
            })
         )
         .subscribe((users) => {
            this.users = users;
            this.setUser();
         });
      this.formCtrl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
         this.filter$.next(val);
      });
      this.idFormControl.valueChanges
         .pipe(takeUntil(this.destroyed$))
         .subscribe(() => this.setUser());

      if (this.validate) {
         this.validate.subscribe(() => {
            if (this.idFormControl.dirty && this.idFormControl.invalid) {
               this.formCtrl.markAsTouched();
               this.formCtrl.markAsDirty();
               this.formCtrl.updateValueAndValidity();
               this.formCtrl.setErrors({ error: true });
            }
         });
      }

      this.filtered$ = combineLatest([
         this.filter$.asObservable(),
         this.focus$.asObservable(),
      ]).pipe(
         map(([filter, focus]) => {
            if (this.canEdit) {
               if (this.users && filter && !filter.id) {
                  return this.users.filter(
                     (t) =>
                        t.firstName?.toLowerCase().includes(filter.toLowerCase()) ||
                        t.lastName?.toLowerCase().includes(filter.toLowerCase())
                  );
               } else {
                  return this.users;
               }
            } else {
               return [];
            }
         })
      );

      if (this.value) {
         this.idFormControl.setValue(this.value);
         this.setUser();
      }
   }

   setUser() {
      if (this.idFormControl.value && this.users) {
         const user = this.users.find((t) => t.id == this.idFormControl.value);
         this.formCtrl.setValue(user);
         this.selected = true;
      } else {
         this.formCtrl.reset();
         this.selected = false;
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   displayFn(user?: User): string | undefined {
      return user ? `${user.lastName}, ${user.firstName}` : undefined;
   }

   onOptionSelect(event: MatAutocompleteSelectedEvent) {
      this.optionSelected.emit(event);
      this.selected = true;
   }

   selectFirstOption() {
      this.autocomplete.options.first.select();
   }

   onFocus() {
      this.focus$.next(true);
   }

   clear() {
      this.formCtrl.reset();
      this.selected = false;
      this.optionSelected.emit(null);
   }
}

