import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromInternalTools from './internal-tools.reducer';

export const selectInternalToolsState = createFeatureSelector<fromInternalTools.State>(
   fromInternalTools.internalToolsFeatureKey
);

const getFeatureFlags = createSelector(selectInternalToolsState, (state) => state.featureFlags);

const isEnabled = createSelector(getFeatureFlags, (featureFlags, props) => {
   const value: fromInternalTools.FeatureFlag = featureFlags[props.flag];
   return value ? value.enabled : true;
});

export const featureFlagSelectors = {
   getFeatureFlags,
   isEnabled,
};
