<div class="sidenav-content" [ngClass]="{ expanded: expanded$ | async }">
   <mat-nav-list>
      <ng-container *ngFor="let config of navConfig">
         <app-subnav
            *ngIf="canView(config) | async"
            [route]="getRoute(config.route) | async"
            [config]="config"
            [active]="isActive(config.route)"
            [expanded]="expanded$ | async"
            (click)="hideSidenav()"
         ></app-subnav>
      </ng-container>
      <ng-container *ngIf="internalUser$ | async">
         <a mat-list-item routerLink="/internal" (click)="hideSidenav()" *ngIf="expanded$ | async">
            Admin Center
         </a>
      </ng-container>
      <a mat-list-item class="expand-button" (click)="toggleExpanded()">
         <ng-container *ngIf="expanded$ | async; else compact">
            <mat-icon svgIcon="chevron-double-left"></mat-icon>
            <div class="compact-text">
               Compact Menu
            </div>
         </ng-container>
         <ng-template #compact>
            <mat-icon svgIcon="chevron-double-right" matTooltip="Expanded Menu"></mat-icon>
         </ng-template>
      </a>
   </mat-nav-list>
</div>
