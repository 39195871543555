export const froalaConfig = {
   attribution: false,
   charCounterCount: false,
   quickInsertEnabled: false,
   key: 'yDC5hG4H4C7C6A5D4gKTRe1CD1PGb1DESAb1Kd1EBH1Pd1TKoD6C5G5G4G2D3A3B4C5A4==',
   imageManagerLoadURL: '',
   // events: {
   //    'image.beforeUpload': function (e, editor, images) {
   //       const reader = new FileReader();
   //       reader.onload = function (e) {
   //          const result = e.target.result;
   //          editor.image.insert(result, null, null, editor.image.get());
   //       };
   //       reader.readAsDataURL(images[0]);
   //       editor.popups.hideAll();
   //       return false;
   //    },
   // },
};
