<ng-container *ngIf="signup">
   <div class="mat-title">How many licenses do you need?</div>
   <p class="mat-subheading-2">
      You may update your number of licenses at any time from the settings page.
   </p>
   <div class="billing-toggle">
      <div class="toggle-container">
         <div class="annual" (click)="showAnnual = false">Monthly Billing</div>
         <mat-slide-toggle color="primary" [(ngModel)]="showAnnual">
            Annual Billing
         </mat-slide-toggle>
      </div>
   </div>
</ng-container>
<div class="sliders">
   <ng-container *ngFor="let product of products; index as i">
      <div class="slider-row">
         <div class="product-name">{{ product.name }}</div>
         <div class="slider">
            <mat-slider
               thumbLabel
               color="primary"
               min="0"
               max="20"
               step="1"
               [(ngModel)]="quantities[i]"
               (change)="changeValue($event, i)"
            ></mat-slider>
         </div>
         <div class="quantity">
            {{ quantities[i] }} @ ${{ getPrice(i) }}/{{ showAnnual ? 'year' : 'month' }}
            <ng-container *ngIf="!signup">({{ assigned[i] }} assigned)</ng-container>
         </div>
      </div>
      <div class="row error-row" *ngIf="showError[i]">
         <div class="col-12">
            <mat-error>
               You must have at least {{ assigned[i] }} licenses for {{ product.name }}. If you wish
               to have fewer licenses, you must first unassign licenses from users.
            </mat-error>
         </div>
      </div>
   </ng-container>

   <div class="row">
      <div class="col-9 total">Total Users</div>
      <div class="col-3 billing-amount">
         <div class="row">
            <div class="col-12" [ngClass]="{ discount: discount }">
               {{ totalUsers() }} @ {{ billingAmount() | currency : 'USD' }}/{{
                  showAnnual ? 'year' : 'month'
               }}
            </div>
         </div>
         <div class="row" *ngIf="discount">
            <div class="col-12">
               {{ totalUsers() }} @ {{ applyDiscount() | currency : 'USD' }}/{{
                  showAnnual ? 'year' : 'month'
               }}
               <br />
               <ng-container *ngIf="discount.duration">
                  for first {{ showAnnual ? 'year' : discount.duration + ' months' }}
               </ng-container>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="row" *ngIf="signup || editPromo; else displayPromo">
   <mat-form-field class="col-md-4">
      <input matInput type="text" placeholder="Promo Code" [(ngModel)]="promoCode" />
      <span *ngIf="promoCode" class="clear-selection" (click)="clearPromoCode()">
         <mat-icon>close</mat-icon>
      </span>
   </mat-form-field>
   <div class="col-md-3">
      <button
         class="promo-button"
         mat-button
         color="primary"
         (click)="checkPromoCode()"
         [disabled]="checkingPromo"
      >
         <ng-container *ngIf="checkingPromo; else apply">Applying...</ng-container>
         <ng-template #apply>Apply</ng-template>
      </button>
   </div>
</div>
<ng-template #displayPromo>
   <div class="display-promo">
      <div class="promo-text">
         Promo Code:
         <span class="promo-code">{{ coupon }}</span>
      </div>
      <button mat-button color="primary" (click)="updatePromoCode()">Update</button>
   </div>
</ng-template>
<div class="row" *ngIf="signup && promoError">
   <mat-error class="col-md-12">
      {{ promoError }}
   </mat-error>
</div>

<button
   class="next-button"
   mat-raised-button
   color="primary"
   (click)="selectPlans()"
   [disabled]="saveDisabled()"
>
   <ng-container *ngIf="signup">Next</ng-container>
   <ng-container *ngIf="!signup">Save</ng-container>
</button>
