<div class="breadcrumbs">
   <ng-container>
      <div
         class="breadcrumb-text"
         [ngClass]="{ active: selectedIds.length === 0 }"
         (click)="goToLevel(0)"
      >
         Organization
      </div>
   </ng-container>
   <ng-container *ngIf="selectedIds[0]">
      <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
      <div
         class="breadcrumb-text"
         [ngClass]="{ active: selectedIds.length === 1 }"
         (click)="goToLevel(1)"
      >
         {{ selectedIds[0] | nameById : businessUnits }}
      </div>
   </ng-container>
   <ng-container *ngIf="selectedIds[1]">
      <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
      <div
         class="breadcrumb-text"
         [ngClass]="{ active: selectedIds.length === 2 }"
         (click)="goToLevel(2)"
      >
         {{ selectedIds[1] | nameById : departments }}
      </div>
   </ng-container>
   <ng-container *ngIf="selectedIds[2]">
      <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
      <div
         class="breadcrumb-text"
         [ngClass]="{ active: selectedIds.length === 3 }"
         (click)="goToLevel(3)"
      >
         {{ selectedIds[2] | nameById : departmentFunctions }}
      </div>
   </ng-container>
   <ng-container *ngIf="selectedIds[3]">
      <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
      <div class="breadcrumb-text active">
         {{ selectedIds[3] | nameById : tasks }}
      </div>
   </ng-container>
</div>
<div class="charts">
   <div class="chart">
      <app-competency-dashboard-donut-chart
         [items]="taskRatingTree$ | async"
         [competencyLevels]="competencyLevels$ | async"
      ></app-competency-dashboard-donut-chart>
   </div>
   <div class="chart">
      <app-competency-dashboard-bar-chart
         [items]="taskRatingTree$ | async"
         [competencyLevels]="competencyLevels$ | async"
         [teamMembers]="teamMembers$ | async"
         [mode]="mode"
      ></app-competency-dashboard-bar-chart>
   </div>
</div>
<!-- <button mat-button color="primary" *ngIf="selectedIds?.length > 0" (click)="viewParent()">
   Go up a level
</button> -->
<table mat-table [dataSource]="taskRatingTree$">
   <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ getHeader() }}</th>
      <td mat-cell *matCellDef="let row" (click)="rowClick(row)">
         {{ getName(row) }}
      </td>
   </ng-container>
   <ng-container matColumnDef="children">
      <th mat-header-cell *matHeaderCellDef>{{ getChildHeader() }}</th>
      <td mat-cell *matCellDef="let row" (click)="rowClick(row)">
         {{ row.children?.length || row.individualRatings?.length }}
      </td>
   </ng-container>
   <ng-container matColumnDef="rating">
      <th mat-header-cell *matHeaderCellDef>Average Rating</th>
      <td mat-cell *matCellDef="let row" (click)="rowClick(row)">
         {{ row.rating | number : '1.0-3' }}
      </td>
   </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
   <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

