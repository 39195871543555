<div class="title" (click)="goToPerformance()">Performance Rating</div>
<form [formGroup]="form">
   <div class="filters">
      <mat-form-field>
         <mat-label>Business Unit</mat-label>
         <mat-select multiple formControlName="businessUnits" [compareWith]="idCompare">
            <mat-option *ngFor="let option of businessUnits" [value]="option">
               {{ option.name }}
            </mat-option>
         </mat-select>
      </mat-form-field>
      <mat-form-field>
         <mat-label>Department</mat-label>
         <mat-select multiple formControlName="departments" [compareWith]="idCompare">
            <mat-option *ngFor="let option of filteredDepartments" [value]="option">
               {{ option.name }}
            </mat-option>
         </mat-select>
      </mat-form-field>
   </div>
</form>
<div style="display: block">
   <canvas
      baseChart
      height="200"
      width="200"
      type="doughnut"
      [data]="data$ | async"
      [labels]="chartLabels"
   ></canvas>
</div>
