import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchFacade } from '@app/search/state/search.facade';
import { TeamMember } from '@entities/team-member';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
   selector: 'app-search-results',
   templateUrl: './search-results.component.html',
   styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
   searchTerm$ = this.searchFacade.searchTerm$;
   searchResults$ = this.searchFacade.searchResults$;
   teamMembers: TeamMember[] = [];
   private _destroyed$ = new Subject<void>();

   constructor(private searchFacade: SearchFacade, private route: ActivatedRoute) {}

   ngOnInit(): void {
      combineLatest([
         this.route.params.pipe(map((params) => params['searchTerm'])),
         this.searchTerm$,
      ])
         .pipe(takeUntil(this._destroyed$))
         .subscribe(([fromUrl, fromState]) => {
            if (!fromState || fromUrl !== fromState) {
               this.searchFacade.quickSearch(fromUrl);
            }
         });

      this.searchFacade.teamMembers$.pipe(takeUntil(this._destroyed$)).subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });
   }

   ngOnDestroy(): void {
      this._destroyed$.next();
      this._destroyed$.complete();
   }

   goToItem(item: any, category: string) {
      this.searchFacade.goToItem(item, category);
   }
}

