import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';

declare function loadConfig();

@Injectable()
export class ConfigService {
  private config: Environment;

  constructor() {
    this.config = environment;
  }

  getConfig(): Environment {
    if (!this.config) {
      const config = loadConfig();
      this.config = JSON.parse(config);
    }
    return this.config;
  }
}

export interface Environment {
  apiUrl: string;
  auth0: {
    client: string;
    domain: string;
    redirectUri: string;
    realm: string;
    logoutUrl: string;
  };
  baseUrl: string;
  production: boolean;
}
