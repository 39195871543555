<div class="search-container">
   <mat-form-field>
      <mat-label>Search</mat-label>
      <span matPrefix>
         <mat-icon class="search-icon">search</mat-icon>
      </span>
      <input
         #searchInput
         matInput
         [ngModel]="term"
         (ngModelChange)="quickSearch($event)"
         [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
         <ng-container *ngIf="term && term.length > 2; else searchMinLength">
            <mat-optgroup
               *ngFor="let category of searchResults$ | async"
               class="search-result-category"
               [label]="category.label"
            >
               <ng-container *ngFor="let item of category.items; index as index">
                  <mat-option
                     class="search-result"
                     *ngIf="index < 3 || category.items.length === 4 || showAll[category.label]"
                     (click)="goToItem(item, category.label)"
                  >
                     {{ item.name }}
                  </mat-option>
               </ng-container>
               <mat-option
                  class="search-result see-all"
                  *ngIf="category.items.length > 4 && !showAll[category.label]"
                  [value]="term"
               >
                  <div (click)="seeAll(category.label, $event)">
                     See all {{ category.items.length }} {{ category.label }}
                  </div>
               </mat-option>
            </mat-optgroup>
            <mat-option [disabled]="true" *ngIf="noResults">No results found</mat-option>
         </ng-container>
         <ng-template #searchMinLength>
            <mat-option [disabled]="true">Start typing to search...</mat-option>
         </ng-template>
      </mat-autocomplete>
   </mat-form-field>
   <!-- <button mat-icon-button class="search-button" (click)="search()">
      <mat-icon>search</mat-icon>
   </button> -->
</div>
