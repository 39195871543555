<div class="wrapper">
   <button
      mat-flat-button
      class="dropdown-button-l"
      color="primary"
      [disabled]="disabled"
      matTooltip="Save"
      (click)="save()"
   >
      <mat-icon>save</mat-icon>
      Save
   </button>
   <button
      mat-flat-button
      color="primary"
      class="dropdown-button-r"
      [disabled]="disabled"
      [matMenuTriggerFor]="saveMenu"
   >
      <mat-icon>arrow_drop_down</mat-icon>
   </button>
   <mat-menu #saveMenu="matMenu" xPosition="before">
      <button mat-menu-item (click)="save()">Save</button>
      <button mat-menu-item (click)="saveAndNew()">Save and New</button>
      <button mat-menu-item (click)="saveAndClose()">Save and Close</button>
   </mat-menu>
</div>
