import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Department } from '@entities/department';
import { EntityService } from '@app/shared/services/entity.service';
import { select, Store } from '@ngrx/store';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { State } from '@app/app.state';

@Injectable()
export class DepartmentService implements EntityService<Department>, OnDestroy {
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.DEPARTMENTS;
   entities$: Observable<Department[]>;

   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<Department>();

   constructor(private store: Store<State>) {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }
   save(entity: Department, overwrite: boolean = false): Promise<Department> {
      return this.orgEntityService.save(entity, { overwrite });
   }
   batchSave(entities: Department[]): Promise<Department[]> {
      return this.orgEntityService.batchSave(entities);
   }
   delete(entity: Department): Promise<void> {
      return this.orgEntityService.delete(entity);
   }
}
