<button mat-button color="primary" (click)="back()">
   <mat-icon>arrow_back</mat-icon>
   Back
</button>
<div [froalaEditor]="options" id="pdf-source">
   <ng-container *ngIf="task">
      <app-report-header
         *ngIf="orgName"
         [orgName]="orgName"
         [logo]="logo"
         category="Task Overview"
         [title]="task?.name"
         subtitle="{{ businessUnit?.name }} &#8250; {{ department?.name }} &#8250; {{
            deptFn?.name
         }}"
      ></app-report-header>
      <div
         style="
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid black;
            margin-bottom: 8px;
            padding-bottom: 8px;
         "
      >
         <div>
            <div style="font-size: 12pt; font-weight: bold; color: #757575">Purpose</div>
            <div>{{ task.purpose }}</div>
         </div>
         <div>
            <div style="font-size: 12pt; font-weight: bold; color: #757575">Policies</div>
            <div>{{ task.policies }}</div>
         </div>
         <div>
            <div style="font-size: 12pt; font-weight: bold; color: #757575">KPI</div>
            <div>{{ task.kpi }}</div>
         </div>
         <div>
            <div style="font-size: 12pt; font-weight: bold; color: #757575">Benchmark</div>
            <div>{{ task.benchmark }}</div>
         </div>
         <div>
            <div style="font-size: 12pt; font-weight: bold; color: #757575">Competencies</div>
            <div [innerHTML]="task.competencies"></div>
         </div>
      </div>
      <h5>Steps</h5>
      <ng-container *ngFor="let step of task.steps">
         <div style="font-size: 12pt; font-weight: bold">{{ step.order + 1 }}: {{ step.name }}</div>
         <div style="margin-left: 16px">
            <div class="instructions" [innerHTML]="sanitize(step.instructions)"></div>
         </div>
      </ng-container>
   </ng-container>
</div>
