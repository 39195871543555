<div class="meeting-container">
   <div class="header-container">
      <div class="header">
         <div class="header-left">
            <div class="header-text">
               {{ meeting.name }}
            </div>
            <button
               mat-button
               color="primary"
               class="toggle-notes-button"
               (click)="toggleNotes()"
               matTooltipPosition="after"
               data-test-id="notes-toggle"
            >
               <mat-icon>notes</mat-icon>
               {{ showNotes ? 'Hide meeting notes' : 'Show meeting notes' }}
            </button>
         </div>

         <app-timer
            class="timer"
            [start]="timePerItem"
            [countDown]="true"
            [overtime]="true"
         ></app-timer>

         <div class="header-buttons">
            <button
               mat-raised-button
               color="primary"
               *ngIf="showEndMeeting; else nextItemButton"
               (click)="nextItem(mode.END_MEETING)"
            >
               End Meeting
            </button>
            <ng-template #nextItemButton>
               <button
                  mat-flat-button
                  color="primary"
                  (click)="nextItem()"
                  data-test-id="next-item-button"
               >
                  {{ nextItemText() }}
               </button>
            </ng-template>
            <button mat-icon-button [matMenuTriggerFor]="menu">
               <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
               <!-- <button mat-menu-item (click)="nextItem(mode.CREATE_ITEM)">
            {{ showRocks ? 'New Rock' : 'New Action' }}
         </button> -->
               <button mat-menu-item (click)="newRock()">New Rock</button>
               <button mat-menu-item (click)="newAction()">New Action</button>
               <button *ngIf="!showEndMeeting" mat-menu-item (click)="nextItem(mode.END_MEETING)">
                  End Meeting
               </button>
            </mat-menu>
         </div>
      </div>
      <mat-card class="notes" *ngIf="showNotes">
         <mat-form-field>
            <mat-label>General meeting notes</mat-label>
            <textarea matInput cdkTextareaAutosize [(ngModel)]="meeting.notes"></textarea>
         </mat-form-field>
      </mat-card>
      <mat-progress-bar mode="determinate" [value]="getProgress()"></mat-progress-bar>
      <div class="item-title">
         <ng-container *ngIf="showRocks$ | async; else showActionName">
            <app-title-edit
               [formCtrl]="rockNameFormControl"
               (titleChange)="setRockTitle($event)"
            ></app-title-edit>
         </ng-container>
         <ng-template #showActionName>
            <app-title-edit
               [formCtrl]="actionNameFormControl"
               (titleChange)="setActionTitle($event)"
            ></app-title-edit>
         </ng-template>
      </div>
   </div>

   <ng-container *ngIf="showRocks$ | async">
      <app-meetings-rock-detail
         (commentAdded)="addComment($event)"
         (actionAdded)="addAction($event)"
      ></app-meetings-rock-detail>
   </ng-container>
   <ng-container *ngIf="showActionItems$ | async">
      <app-meeting-action-item-details
         (commentAdded)="addComment($event)"
      ></app-meeting-action-item-details>
   </ng-container>
</div>

<ng-template #newRockDialog>
   <form [formGroup]="newRockForm">
      <div mat-dialog-title>New Rock</div>
      <mat-dialog-content>
         <mat-form-field>
            <input formControlName="name" matInput placeholder="Name" required />
         </mat-form-field>

         <app-quarterpicker
            [formCtrl]="getControl(newRockForm, 'quarter')"
            label="Quarter"
            [required]="true"
         ></app-quarterpicker>
         <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select formControlName="status" required>
               <mat-option [value]="status" *ngFor="let status of rockStatusKeys">
                  {{ rockStatus[status] }}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <app-department-autocomplete
            [idFormControl]="getControl(newRockForm, 'departmentId')"
            placeholder="Department"
            (optionSelected)="setNewRockDepartment($event)"
         ></app-department-autocomplete>
         <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea class="editor" matInput type="text" formControlName="description"></textarea>
         </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
         <button
            mat-raised-button
            [disabled]="!newRockForm.valid"
            [matDialogClose]="true"
            color="primary"
         >
            Save
         </button>
         <button mat-stroked-button matDialogClose color="primary">Cancel</button>
      </mat-dialog-actions>
   </form>
</ng-template>

<ng-template #newActionDialog>
   <form [formGroup]="newActionForm">
      <div mat-dialog-title>New Action</div>
      <mat-dialog-content>
         <mat-form-field>
            <input formControlName="name" matInput placeholder="Name" required />
         </mat-form-field>
         <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select formControlName="type" required>
               <mat-option [value]="type" *ngFor="let type of actionTypeKeys">
                  {{ actionType[type] }}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <mat-form-field>
            <mat-label>Priority</mat-label>
            <mat-select formControlName="priority" required>
               <mat-option [value]="priority" *ngFor="let priority of actionPriorityKeys">
                  {{ actionPriority[priority] }}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select formControlName="status" required>
               <mat-option [value]="status" *ngFor="let status of actionStatusKeys">
                  {{ actionStatus[status] }}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <app-department-autocomplete
            [idFormControl]="getControl(newActionForm, 'departmentId')"
            placeholder="Department"
            (optionSelected)="setNewActionDepartment($event)"
         ></app-department-autocomplete>
         <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea class="editor" matInput type="text" formControlName="description"></textarea>
         </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
         <button
            mat-raised-button
            [disabled]="!newRockForm.valid"
            [matDialogClose]="true"
            color="primary"
         >
            Save
         </button>
         <button mat-stroked-button matDialogClose color="primary">Cancel</button>
      </mat-dialog-actions>
   </form>
</ng-template>
