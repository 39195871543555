import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { withLatestFrom, switchMap, map, catchError, mergeMap, tap } from 'rxjs/operators';

import { of, from } from 'rxjs';
import { GoalActions } from './goal.actions';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { GoalService } from '@app/evolve/services/goal.service';
import { ErrorService } from '@app/shared/services/error.service';
import { Goal } from '@entities/goal';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { appRoutesNames } from '@app/app.routes.names';
import { evolveRouteNames } from '@app/evolve/evolve.routes.names';
import { evolveSelectors } from '../evolve.state';
import { Rock } from '@entities/rock';
import { RockService } from '@app/evolve/services/rock.service';

@Injectable()
export class GoalEffects {
   constructor(
      private actions$: Actions,
      private store: Store<State>,
      private goalService: GoalService,
      private errorService: ErrorService,
      private snackBar: MatSnackBar,
      private router: Router,
      private rockService: RockService
   ) {}

   saveGoal$ = createEffect(() =>
      this.actions$.pipe(
         ofType(GoalActions.SaveGoal),
         withLatestFrom(this.store.pipe(select(evolveSelectors.getRocks))),
         mergeMap(([{ goal, featureFlag }, rocks]) => {
            return from(this.goalService.save(goal)).pipe(
               switchMap((goal) => {
                  const rocksToLink = rocks.filter(
                     (rock) => goal.rocks && goal.rocks.includes(rock.id) && rock.goalId !== goal.id
                  );
                  const rocksToUnlink = rocks.filter(
                     (rock) =>
                        goal.rocks && !goal.rocks.includes(rock.id) && rock.goalId === goal.id
                  );
                  const rocksToSave = [];
                  rocksToLink.forEach((rock) => {
                     const toSave: Rock = {
                        ...rock,
                        goalId: goal.id,
                     };
                     rocksToSave.push(toSave);
                  });
                  rocksToUnlink.forEach((rock) => {
                     const toSave: Rock = {
                        ...rock,
                        goalId: null,
                     };
                     rocksToSave.push(toSave);
                  });
                  return from(this.rockService.batchSave(rocksToSave)).pipe(map(() => goal));
               }),
               map((goal) => {
                  this.snackBar.open('Goal Saved');
                  if (featureFlag) {
                     return GoalActions.EditGoal({ goal, featureFlag: true });
                  } else {
                     return GoalActions.HideEditGoal({ goalId: goal.id });
                  }
               }),
               catchError((err) => {
                  this.errorService.handleError(err);
                  return of();
               })
            );
         })
      )
   );

   addGoal$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(GoalActions.AddGoal),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ featureFlag }, orgId]) => {
               if (featureFlag) {
                  this.router.navigate([
                     appRoutesNames.ORGANIZATION,
                     orgId,
                     appRoutesNames.EVOLVE,
                     evolveRouteNames.GOALS,
                     'new',
                  ]);
               }
            })
         ),
      { dispatch: false }
   );

   editGoal$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(GoalActions.EditGoal),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ goal, featureFlag }, orgId]) => {
               if (featureFlag) {
                  this.router.navigate([
                     appRoutesNames.ORGANIZATION,
                     orgId,
                     appRoutesNames.EVOLVE,
                     evolveRouteNames.GOALS,
                     goal.id,
                  ]);
               }
            })
         ),
      { dispatch: false }
   );

   deleteGoal$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(GoalActions.DeleteGoal),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([action, orgId]) => {
               this.goalService
                  .delete(action.goal)
                  .then(() => {
                     this.router.navigate([
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.EVOLVE,
                        evolveRouteNames.GOALS,
                     ]);
                  })
                  .catch((err) => {
                     this.errorService.handleError(err);
                  });
            })
         ),
      { dispatch: false }
   );
}
