import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DashboardFacade } from '@app/dashboard/state/dashboard.facade';
import { Observable } from 'rxjs';

@Component({
   selector: 'app-action-items-widget',
   templateUrl: './action-items-widget.component.html',
   styleUrls: ['./action-items-widget.component.scss'],
})
export class ActionItemsWidgetComponent implements OnInit {
   @ViewChild('actionItemCount') actionItemCount: ElementRef<HTMLDivElement>;

   actionItemCount$: Observable<number>;

   constructor(private dashboardFacade: DashboardFacade) {}

   ngOnInit() {
      this.actionItemCount$ = this.dashboardFacade.actionItemCount$;
   }

   goToActionItems() {
      this.dashboardFacade.goToActionItems();
   }
}
