import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
   selector: 'app-edit-columns',
   templateUrl: './edit-columns.component.html',
   styleUrls: ['./edit-columns.component.scss'],
})
export class EditColumnsComponent implements OnInit {
   @Input() columns: Array<{ label: string; def: string; visible: boolean }>;
   @Input() displayedColumns: string[] = [];
   @Output() columnsUpdated: EventEmitter<string[]> = new EventEmitter();

   constructor() {}

   ngOnInit() {
      this.updateColumns();
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['displayedColumns']) {
         this.setDisplayedColumns();
      }
   }

   setDisplayedColumns() {
      if (this.displayedColumns?.length) {
         this.columns.forEach((col) => (col.visible = this.displayedColumns.includes(col.def)));
      }
   }

   updateColumn(event: any, column: any) {
      event.stopPropagation();
      event.preventDefault();
      column.visible = !column.visible;
      this.updateColumns();
   }

   updateColumns() {
      this.displayedColumns = this.columns.filter((c) => c.visible).map((c) => c.def);
      this.columnsUpdated.next(this.displayedColumns);
   }
}
