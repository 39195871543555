<button class="back-button" mat-button color="primary" (click)="backToSettings()">
   <mat-icon class="icon-18">arrow_back</mat-icon>
   Back to Settings
</button>
<div class="title-container">
   <div class="h5 title">Manage Permission Groups</div>
   <button mat-raised-button color="primary" (click)="newPermissionGroup()">
      <mat-icon>add</mat-icon>
      New Permission Group
   </button>
</div>

<ng-container
   *ngIf="(permissionGroups$ | async).length > 0; else addDefaultPermissionGroupsTemplate"
>
   <app-unassigned-users-warning
      [users]="unassignedUsers$ | async"
      (assignUser)="assignUser($event)"
   ></app-unassigned-users-warning>

   <mat-accordion multi="true">
      <mat-expansion-panel *ngFor="let group of permissionGroups$ | async">
         <mat-expansion-panel-header>
            <mat-panel-title>
               {{ group.name }}
               <ng-container *ngIf="group.isAdmin">
                  <mat-icon
                     class="admin-icon"
                     svgIcon="account-cog"
                     matTooltip="This group has admin permissions"
                     matTooltipPosition="after"
                  ></mat-icon>
               </ng-container>
            </mat-panel-title>
            <mat-panel-description>
               {{ group.memberIds?.length || 0 }} members
            </mat-panel-description>
         </mat-expansion-panel-header>
         <div class="panel-content">
            <div class="access-levels">
               <div class="access-levels-header">
                  <h5>Access Levels</h5>
                  <button mat-button color="primary" (click)="editPermissionGroup(group)">
                     Edit Access Levels
                  </button>
               </div>
               <div class="access-levels-grid">
                  <div class="access-levels-grid-row">
                     <div class="access-area"></div>
                     <div class="access-level">Read</div>
                     <div class="access-level">Edit</div>
                  </div>
                  <div class="access-levels-grid-row" *ngFor="let config of permissionConfigs">
                     <div class="access-area">{{ config.area }}</div>
                     <div class="access-level">
                        <app-permission-level-icon
                           [accessLevel]="group.accessMap[config.area]?.view"
                        ></app-permission-level-icon>
                     </div>
                     <div class="access-level">
                        <app-permission-level-icon
                           [accessLevel]="group.accessMap[config.area]?.edit"
                        ></app-permission-level-icon>
                     </div>
                  </div>
               </div>
            </div>
            <div class="group-members">
               <div class="group-members-header">
                  <h5>Group Members</h5>
                  <button mat-button color="primary" (click)="assignMembers(group)">
                     Edit Group Membership
                  </button>
               </div>
               <app-permission-group-membership-table
                  [users]="group.members"
                  [permissionGroupId]="group.id"
                  (assignUser)="assignUser($event)"
               ></app-permission-group-membership-table>
            </div>
         </div>
         <div class="delete-group">
            <button
               mat-stroked-button
               color="warn"
               (click)="deletePermissionGroup(group)"
               [disabled]="!canDeleteGroup(group)"
            >
               <mat-icon>delete</mat-icon>
               Delete Permission Group
            </button>
         </div>
      </mat-expansion-panel>
   </mat-accordion>
</ng-container>

<ng-template #addDefaultPermissionGroupsTemplate>
   <div class="add-default-groups">
      <div class="add-default-groups-message">You have no permission groups created</div>
      <button
         class="add-default-groups-button"
         mat-raised-button
         color="primary"
         (click)="addDefaultPermissionGroups()"
      >
         Add Default Permission Groups
      </button>
   </div>
</ng-template>

<ng-template #assignUserTemplate>
   <h3 mat-dialog-title>Assign User to PermissionGroup</h3>
   <mat-dialog-content>
      <div class="user-to-assign">
         {{ userToAssign.firstName }} {{ userToAssign.lastName }} {{ userToAssign.email }}
      </div>
      <mat-form-field>
         <mat-label>Permission Group</mat-label>
         <mat-select [(ngModel)]="userToAssign.permissionGroupId">
            <mat-option *ngFor="let group of permissionGroups$ | async" [value]="group.id">
               {{ group.name }}
            </mat-option>
         </mat-select>
      </mat-form-field>
   </mat-dialog-content>
   <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="saveAssignedUser()">Assign</button>
      <button mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
   </mat-dialog-actions>
</ng-template>
