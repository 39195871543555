import { Component, OnInit } from '@angular/core';
import { FirebaseAuthService } from '@app/auth/services/firebase-auth.service';

@Component({
   selector: 'app-signup',
   templateUrl: './signup.component.html',
   styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
   email: string;
   password: string;
   firstName: string;
   lastName: string;
   termsAccepted: boolean;

   constructor(private authService: FirebaseAuthService) {}

   ngOnInit() {}

   signUp() {
      this.authService.signup(this.email, this.password, this.firstName, this.lastName);
   }
}
