<button class="back-button" mat-button color="primary" (click)="back()">
   <mat-icon class="icon-18">arrow_back</mat-icon>
   Back to Settings
</button>
<div class="title-container">
   <div class="h5 title">Manage Users</div>
   <button mat-button color="primary" class="add-button" (click)="addUser()">
      <mat-icon>email</mat-icon>
      Invite Users
   </button>
</div>
<div>
   <mat-form-field class="filter">
      <input matInput (keyup)="triggerFilter($event)" placeholder="Filter" />
   </mat-form-field>
   <mat-slide-toggle class="toggle" color="primary" (change)="showInactive($event)">
      Show inactive
   </mat-slide-toggle>
</div>

<app-table [config]="tableConfig" (rowClick)="edit($event)"></app-table>

<div class="invites-table">
   <div class="title-container">
      <div class="h5 title">Pending Invites</div>
   </div>

   <table mat-table [dataSource]="invites$">
      <ng-container matColumnDef="email">
         <th mat-header-cell *matHeaderCellDef>Email</th>
         <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>
      <ng-container matColumnDef="role">
         <th mat-header-cell *matHeaderCellDef>Role</th>
         <td mat-cell *matCellDef="let element">{{ UserRole[element.role] }}</td>
      </ng-container>
      <ng-container matColumnDef="products">
         <th mat-header-cell *matHeaderCellDef>Product(s)</th>
         <td mat-cell *matCellDef="let element">{{ getProducts(element) }}</td>
      </ng-container>
      <ng-container matColumnDef="sent">
         <th mat-header-cell *matHeaderCellDef>Sent on</th>
         <td mat-cell *matCellDef="let element">{{ element.sent | date }}</td>
      </ng-container>
      <ng-container matColumnDef="options">
         <th mat-header-cell *matHeaderCellDef></th>
         <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="optionsMenu">
               <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #optionsMenu="matMenu">
               <button mat-menu-item (click)="resend(element)">Resend</button>
               <button mat-menu-item (click)="deleteInvite(element)">Delete</button>
            </mat-menu>
         </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="inviteColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: inviteColumns"></tr>
   </table>
</div>
