import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { BusinessUnit } from '@entities/business-unit';
import { EntityService } from '@app/shared/services/entity.service';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';

@Injectable()
export class BusinessUnitService implements EntityService<BusinessUnit>, OnDestroy {
   entities$: Observable<BusinessUnit[]>;
   readonly COLLECTION_NAME = COLLECTION_NAMES.BUSINESS_UNITS;
   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<BusinessUnit>();

   constructor(private store: Store<State>) {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }
   save(businessUnit: BusinessUnit, overwrite: boolean = false) {
      return this.orgEntityService.save(businessUnit, { overwrite });
   }

   batchSave(businessUnits: BusinessUnit[]) {
      return this.orgEntityService.batchSave(businessUnits);
   }

   delete(businessUnit: BusinessUnit) {
      return this.orgEntityService.delete(businessUnit);
   }
}
