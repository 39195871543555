import { EffectsModule } from '@ngrx/effects';
import { DocumentationEffects } from './state/documentation.effects';
import * as fromDocumentation from './state/documentation.reducer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { DocumentationTaskEditComponent } from './components/documentation-task-edit/documentation-task-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DocumentationFacade } from './state/documentation.facade';
import { SharedModule } from '@app/shared/shared.module';
import { DocumentationTaskReportComponent } from './components/documentation-task-report/documentation-task-report.component';
import { DocumentationTaskListComponent } from './components/documentation-list/documentation-list.component';
import { RouterModule } from '@angular/router';
import { DocumentationFunctionListComponent } from './components/documentation-function-list/documentation-function-list.component';
import { DocumentationFunctionEditComponent } from './components/documentation-function-edit/documentation-function-edit.component';
import { DocumentationFunctionReportComponent } from './components/documentation-function-report/documentation-function-report.component';
import { OrgBuilderModule } from '@app/org-builder/org-builder.module';

@NgModule({
   declarations: [
      DocumentationTaskEditComponent,
      DocumentationTaskReportComponent,
      DocumentationTaskListComponent,
      DocumentationFunctionListComponent,
      DocumentationFunctionEditComponent,
      DocumentationFunctionReportComponent,
   ],
   imports: [
      CommonModule,
      ReactiveFormsModule,
      RouterModule,
      StoreModule.forFeature('documentation', fromDocumentation.reducer),
      EffectsModule.forFeature([DocumentationEffects]),
      SharedModule,
      OrgBuilderModule,
   ],
   providers: [DocumentationFacade],
})
export class DocumentationModule {}
