import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '@app/shared/shared.module';

// Components
import { CallbackComponent } from './components/callback/callback.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';

// Services
import { FirebaseAuthService } from './services/firebase-auth.service';
import { AuthUserService } from './services/auth-user.service';

// State
import { reducer } from './state/auth.reducer';
import { AuthEffects } from './state/auth.effects';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

@NgModule({
   declarations: [CallbackComponent, LoginComponent, SignupComponent, ResetPasswordComponent],
   imports: [
      CommonModule,
      RouterModule,
      SharedModule,
      FormsModule,
      ReactiveFormsModule,
      StoreModule.forFeature('auth', reducer),
      EffectsModule.forFeature([AuthEffects])
   ],
   providers: [FirebaseAuthService, AuthUserService]
})
export class AuthModule {}
