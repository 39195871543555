<h3 mat-dialog-title>Invite User</h3>
<mat-dialog-content>
   <ng-container *ngIf="plansAvailable$ | async; else noPlans">
      <form [formGroup]="form">
         <div class="row">
            <mat-form-field class="col-md-12">
               <input matInput placeholder="Email" formControlName="email" required email />
            </mat-form-field>
         </div>
         <div class="row">
            <mat-form-field class="col-md-6">
               <input matInput placeholder="First Name" formControlName="firstName" required />
            </mat-form-field>
            <mat-form-field class="col-md-6">
               <input matInput placeholder="Last Name" formControlName="lastName" required />
            </mat-form-field>
         </div>
         <div class="row">
            <mat-form-field class="col-md-6">
               <mat-label>Product</mat-label>
               <mat-select #productSelect required formControlName="product">
                  <div class="product-option" *ngFor="let plan of orgPlans$ | async">
                     <mat-option [disabled]="plan.available == 0" [value]="plan.productType">
                        {{ ProductType[plan.productType] }}
                        <span class="mat-hint" *ngIf="productSelect.panelOpen">
                           ({{ plan.available }} of {{ plan.quantity }} available)
                        </span>
                     </mat-option>
                  </div>
               </mat-select>
            </mat-form-field>
            <!-- <mat-form-field class="col-md-6">
               <mat-label>Role</mat-label>
               <mat-select formControlName="role" required>
                  <mat-option
                     *ngFor="let role of userRoleKeys"
                     [value]="role"
                     [disabled]="roleDisabled(role)"
                  >
                     {{ userRole[role] }}
                  </mat-option>
               </mat-select>
            </mat-form-field> -->
            <div class="col-md-6 admin-toggle-container">
               <ng-container *ngIf="form.get('product').value === ProductType.Complete">
                  <mat-slide-toggle
                     color="primary"
                     class="admin-toggle"
                     formControlName="isAdmin"
                     matTooltip="Admins can add or remove users and manage organization settings"
                     matTooltipPosition="after"
                  >
                     Admin
                  </mat-slide-toggle>
               </ng-container>
            </div>
         </div>
      </form>
      <div class="row">
         <div class="col-md-6 team-checkbox">
            <mat-checkbox color="primary" [(ngModel)]="createTeamMember">
               Create team member
            </mat-checkbox>
         </div>
      </div>
   </ng-container>
   <ng-template #noPlans>
      No licenses available. To invite more users, either delete a pending invitation, remove a
      license from an existing user, or add more licenses from the User Licenses section of the
      settings page.
   </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
   <div class="button-container">
      <ng-container *ngIf="plansAvailable$ | async">
         <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="sendInvite()">
            Send Invite
         </button>
         <button mat-raised-button [disabled]="!form.valid" (click)="inviteAnother()">
            Send and Invite Another
         </button>
         <button type="button" mat-raised-button matDialogClose>Cancel</button>
      </ng-container>
      <ng-container *ngIf="!(plansAvailable$ | async)">
         <button type="button" mat-raised-button color="primary" matDialogClose>Close</button>
      </ng-container>
   </div>
</mat-dialog-actions>
