<div class="list-container">
   <div class="title-container">
      <div class="h5 title">Functions</div>
      <button
         mat-icon-button
         color="primary"
         class="add-button"
         (click)="addDepartmentFunction()"
         matTooltip="New Function"
         matTooltipPosition="before"
         *ngIf="
            (departmentFunctions$ | async).length != 0 &&
            (departmentId$ | async) &&
            (canEdit$ | async)
         "
      >
         <mat-icon>add</mat-icon>
      </button>
   </div>
   <div class="card-container sidebar">
      <ng-container *ngIf="departmentId$ | async">
         <mat-card class="org-list-card">
            <mat-nav-list
               *ngIf="departmentId$ | async"
               id="department-function-list"
               class="org-nav-list"
               cdkDropList
               (cdkDropListDropped)="drop($event)"
            >
               <ng-container
                  *ngFor="
                     let deptFn of departmentFunctions$ | async;
                     first as isFirst;
                     last as isLast
                  "
               >
                  <div
                     class="list-item"
                     [ngClass]="{ first: isFirst, last: isLast }"
                     cdkDrag
                     [cdkDragDisabled]="!(canEdit$ | async)"
                     (cdkDragStarted)="dragStarted($event, deptFn)"
                     appDropTarget
                     (dropped)="itemDropped($event, deptFn)"
                  >
                     <div class="placeholder" *cdkDragPlaceholder></div>
                     <div class="handle" cdkDragHandle *ngIf="canEdit$ | async">
                        <mat-icon svgIcon="drag-vertical"></mat-icon>
                     </div>
                     <mat-list-item
                        [ngClass]="{ selected: deptFn.id == (selectedFunctionId$ | async) }"
                        (click)="select(deptFn.id)"
                     >
                        <div matLine class="title-line">
                           <div class="list-item-title">
                              <span [matTooltip]="deptFn.name">
                                 {{ deptFn.name }}
                              </span>
                           </div>
                           <button
                              mat-icon-button
                              class="list-item-options"
                              [matMenuTriggerFor]="optionsMenu"
                           >
                              <mat-icon>more_vert</mat-icon>
                           </button>
                        </div>
                        <div matLine class="person-line">
                           <div class="person">
                              <mat-icon>person</mat-icon>
                              <div class="person-name">
                                 {{ deptFn.leadId | teamMemberName: teamMembers }}
                              </div>
                           </div>
                           <div class="units">
                              <mat-icon>list</mat-icon>
                              <div class="person-name">
                                 {{ getTasks(deptFn) }}
                              </div>
                           </div>
                        </div>
                     </mat-list-item>
                     <mat-menu #optionsMenu="matMenu">
                        <button mat-menu-item *ngIf="canEdit$ | async" (click)="edit(deptFn)">
                           Edit
                        </button>
                        <button mat-menu-item *ngIf="!(canEdit$ | async)" (click)="edit(deptFn)">
                           View Details
                        </button>
                        <button mat-menu-item (click)="report(deptFn)">View Report</button>
                        <button mat-menu-item *ngIf="canEdit$ | async" (click)="delete(deptFn)">
                           Delete
                        </button>
                     </mat-menu>
                  </div>
                  <mat-divider *ngIf="!isLast"></mat-divider>
               </ng-container>
            </mat-nav-list>
         </mat-card>
         <button
            mat-button
            color="primary"
            *ngIf="(departmentId$ | async) && (canEdit$ | async)"
            (click)="addDepartmentFunction()"
         >
            <mat-icon>add</mat-icon>
            New Function
         </button>
         <ng-container *ngIf="(departmentFunctions$ | async).length == 0 && !(canEdit$ | async)">
            No functions to show
         </ng-container>
      </ng-container>
      <div *ngIf="!(departmentId$ | async)">Please select a department to see functions</div>
   </div>
</div>
