<div class="timer-container">
   <div class="timer-count" [ngClass]="{ overtime: isOver }">{{ display }}</div>
   <ng-container *ngIf="paused; else showPauseButton">
      <button mat-icon-button (click)="resumeTimer()" matTooltip="Start Timer">
         <mat-icon>play_arrow</mat-icon>
      </button>
   </ng-container>
   <ng-template #showPauseButton>
      <button mat-icon-button (click)="pauseTimer()" matTooltip="Pause Timer">
         <mat-icon>pause</mat-icon>
      </button>
   </ng-template>
</div>
