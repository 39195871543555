import {
   Component,
   OnInit,
   Input,
   ViewChild,
   TemplateRef,
   Output,
   EventEmitter,
} from '@angular/core';
import { Observable, BehaviorSubject, of, combineLatest, Subject } from 'rxjs';
import { Rock } from '@entities/rock';
import { TableConfig } from '@app/shared/components/table/table.component';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { RockStatus } from '@entities/enums/rock-status';
import { DepartmentNamePipe } from '@app/shared/pipes/department-name.pipe';
import { TeamMemberNamePipe } from '@app/shared/pipes/team-member-name.pipe';
import { TeamMember } from '@entities/team-member';
import { Department } from '@entities/department';
import { EvolveFacade } from '@app/evolve/state/evolve.facade';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil, map, startWith, take } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';

@Component({
   selector: 'app-linked-rocks',
   templateUrl: './linked-rocks.component.html',
   styleUrls: ['./linked-rocks.component.scss'],
})
export class LinkedRocksComponent implements OnInit {
   @ViewChild('linkRockDialogRef') linkRockDialogRef: TemplateRef<any>;
   @Input() canEdit = true;
   @Output() rockLinked = new EventEmitter<Rock>();
   @Output() rockUnlinked = new EventEmitter<Rock>();

   linkedRocks: Rock[] = [];
   teamMembers: TeamMember[] = [];
   departments: Department[] = [];

   rockToLink: Rock;
   rockToLinkSelected = false;
   rockToLinkFormControl = new UntypedFormControl();
   filteredRocks$: Observable<Rock[]>;

   dataSource$ = new BehaviorSubject<Rock[]>([]);
   filter$ = new BehaviorSubject({});

   tableConfig: TableConfig;
   /* istanbul ignore next */
   columns: TableColumn<Rock>[] = [
      { label: 'Name', def: 'name', visible: true, value: (row) => row.name },
      {
         label: 'Department',
         def: 'departmentId',
         visible: true,
         value: (row) => this.departmentNamePipe.transform(row.departmentId, this.departments),
         filter: 'departments',
      },
      {
         label: 'Quarter',
         def: 'quarter',
         visible: true,
         value: (row) => row.quarter,
         filter: 'quarter',
      },
      {
         label: 'Assigned To',
         def: 'assigneeId',
         visible: true,
         value: (row) => this.teamMemberNamePipe.transform(row.assigneeId, this.teamMembers),
      },
      {
         label: 'Status',
         def: 'status',
         visible: true,
         value: (row) => RockStatus[row.status],
         filter: 'status',
         icon: (row) => this.getIcon(row),
      },
   ];

   private destroyed$ = new Subject<void>();

   constructor(
      private evolveFacade: EvolveFacade,
      private dialog: MatDialog,
      private departmentNamePipe: DepartmentNamePipe,
      private teamMemberNamePipe: TeamMemberNamePipe
   ) {}

   ngOnInit(): void {
      combineLatest([this.evolveFacade.selectedGoal$, this.evolveFacade.rocks$])
         .pipe(takeUntil(this.destroyed$))
         .subscribe(([goal, rocks]) => {
            if (goal && rocks && goal.rocks) {
               this.linkedRocks = rocks.filter((rock) => goal.rocks.includes(rock.id));
               this.dataSource$.next(this.linkedRocks);
            }
         });

      this.evolveFacade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.evolveFacade.teamMembers$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });

      this.tableConfig = {
         data$: this.dataSource$,
         filter$: this.filter$,
         columns: this.columns,
         visibleColumns$: of(this.columns.map((col) => col.def)),
         initialSort: { active: 'name', direction: 'asc' },
         rowClick: (row) => this.editRock(row),
         options: [
            {
               label: 'Details',
               action: (row) => this.editRock(row),
               condition: (row) => !this.canEdit,
            },
            {
               label: 'Edit',
               action: (row) => this.editRock(row),
               condition: (row) => this.canEdit,
            },
            {
               label: 'Unlink',
               action: (row) => this.unlinkRock(row),
               condition: (row) => this.canEdit,
            },
         ],
      };

      this.filteredRocks$ = combineLatest([
         this.evolveFacade.rocks$,
         this.rockToLinkFormControl.valueChanges.pipe(startWith('')),
      ]).pipe(
         takeUntil(this.destroyed$),
         map(([rocks, filterValue]) => {
            const sorted = [...rocks].sort((a, b) => a.name.localeCompare(b.name));
            if (typeof filterValue === 'string') {
               return sorted.filter((rock) =>
                  rock.name.toLowerCase().includes(filterValue.toLowerCase())
               );
            } else {
               return sorted;
            }
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getIcon(rock: Rock) {
      switch (rock.status) {
         case RockStatus['On Track']:
            return { svg: true, icon: 'sign-direction' };
         case RockStatus['Off Track']:
            return { svg: false, icon: 'warning' };
         case RockStatus['On Hold']:
            return { svg: false, icon: 'schedule' };
         case RockStatus['Pending Approval']:
            return { svg: false, icon: 'how_to_reg' };
         case RockStatus['Completed']:
            return { svg: false, icon: 'check_circle' };
         default:
            return { svg: false, icon: '' };
      }
   }

   editRock(rock: Rock) {
      this.evolveFacade.editRock(rock);
   }

   linkRock() {
      this.dialog.open(this.linkRockDialogRef, { minWidth: 300 });
   }

   addLinkedRock() {
      this.dialog.closeAll();
      const rock = this.rockToLinkFormControl.value;
      this.clear();
      if (rock) {
         this.rockLinked.emit(rock);
         this.linkedRocks.push(rock);
         this.dataSource$.next(this.linkedRocks);
      }
   }

   unlinkRock(rock: Rock) {
      this.linkedRocks = this.linkedRocks.filter((r) => r.id !== rock.id);
      this.dataSource$.next(this.linkedRocks);
      this.rockUnlinked.emit(rock);
   }

   displayFn = (rock: Rock) => (rock && rock.name ? rock.name : '');

   onOptionSelect(event: MatAutocompleteSelectedEvent) {
      this.rockToLinkSelected = true;
   }

   clear() {
      this.rockToLinkFormControl.reset();
      this.rockToLinkSelected = false;
   }
}
