<div mat-dialog-title>Edit Task Rating</div>
<mat-dialog-content>
   <div class="breadcrumbs">
      <div class="breadcrumb-text">
         {{ selectedTask?.businessUnit?.name }}
      </div>
      <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>

      <div class="breadcrumb-text">
         {{ selectedTask?.department?.name }}
      </div>
      <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>

      <div class="breadcrumb-text">
         {{ selectedTask?.departmentFunction?.name }}
      </div>
      <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
   </div>
   <div class="task-name">{{ selectedTask?.task.name }}</div>
   <div class="rating-row">
      <div class="task-rating">
         <mat-form-field class="rating-select">
            <mat-label>Rating</mat-label>
            <mat-select [(ngModel)]="selectedTask.taskRating.rating">
               <mat-option *ngFor="let level of competencyLevels" [value]="level.value">
                  {{ level.label }}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <mat-form-field class="rating-description">
            <mat-label>Rating Level Description</mat-label>
            <textarea matInput readonly>{{ ratingDescription() }}</textarea>
         </mat-form-field>
      </div>
      <div class="task-competencies">
         <mat-form-field class="fill-height">
            <mat-label>Task Competencies Description</mat-label>
            <textarea matInput readonly [ngModel]="selectedTask.task.competencies"></textarea>
         </mat-form-field>
      </div>
   </div>
   <div class="notes">
      <mat-form-field class="fill-height">
         <mat-label>Notes</mat-label>
         <textarea matInput [(ngModel)]="selectedTask.taskRating.notes"></textarea>
      </mat-form-field>
   </div>
</mat-dialog-content>
<mat-dialog-actions>
   <button mat-raised-button color="primary" (click)="save()">Save</button>
   <button mat-stroked-button color="primary" (click)="cancel()">Cancel</button>
</mat-dialog-actions>
