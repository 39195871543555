import { Organization } from './organization';

export class Overview {
   overviewId: number;

   beliefs: string;

   purpose: string;

   coreValues: string;

   visionStatement: string;

   organizationId: string;

   organization: Organization;
}
