<div class="chart-container">
   <canvas
      baseChart
      width="300"
      height="300"
      type="bar"
      [data]="chartData"
      [options]="chartOptions"
   ></canvas>
</div>

