<div class="mega-container">
   <div class="row top-bar">
      <div class="col-12">
         <div class="buttons">
            <button
               mat-icon-button
               color="primary"
               matTooltip="View Org Chart"
               (click)="viewChart()"
            >
               <mat-icon svgIcon="sitemap"></mat-icon>
            </button>
            <button
               mat-icon-button
               color="accent"
               matTooltip="View Org Summary"
               (click)="viewSummary()"
            >
               <mat-icon svgIcon="file-document"></mat-icon>
            </button>
         </div>
      </div>
   </div>
   <div id="scroll-content" class="org-builder-container">
      <div class="content">
         <div class="row">
            <div class="col-md-3 list-column" id="business-units">
               <app-business-unit-list (selected)="centerDepartments()"></app-business-unit-list>
            </div>
            <div class="col-md-3 list-column" id="departments">
               <app-department-list (selected)="centerDepartments()"></app-department-list>
            </div>
            <div class="col-md-3 list-column" id="dept-functions">
               <app-department-function-list
                  (selected)="centerFunctions()"
               ></app-department-function-list>
            </div>
            <div class="col-md-3 list-column" id="tasks">
               <app-task-list
                  [tasks]="tasks$ | async"
                  [visible]="
                     !!(selectedDepartmentFunctionId$ | async) &&
                     !!(selectedDepartmentId$ | async) &&
                     !!(selectedBusinessUnitId$ | async)
                  "
                  [canEdit]="canEdit$ | async"
                  [facade]="orgBuilderFacade"
                  (editTask)="onEditTask($event)"
               ></app-task-list>
            </div>
         </div>
      </div>
   </div>
</div>
