import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { AuthUser } from '@entities/auth-user';
import { InternalToolsFacade } from '@app/internal-tools/state/internal-tools.facade';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { TableConfig } from '@app/shared/components/table/table.component';

@Component({
   selector: 'app-auth-users-internal',
   templateUrl: './auth-users-internal.component.html',
   styleUrls: ['./auth-users-internal.component.scss'],
})
export class AuthUsersInternalComponent implements OnInit {
   authUsers$: Observable<AuthUser[]>;

   /* istanbul ignore next */
   columns: TableColumn<AuthUser>[] = [
      { def: 'name', label: 'Display Name', value: (row) => row.displayName, visible: true },
      { def: 'email', label: 'Email', value: (row) => row.email, visible: true },
      {
         def: 'orgs',
         label: 'Organizations',
         value: (row) => (row.orgs ? row.orgs.length : 0),
         visible: true,
      },
      {
         def: 'internalUser',
         label: 'Internal',
         value: (row) => (row.isInternalUser ? 'Yes' : 'No'),
         visible: true,
      },
   ];

   tableConfig: TableConfig;

   constructor(private facade: InternalToolsFacade) {}

   ngOnInit(): void {
      this.authUsers$ = this.facade.authUsers$;

      this.tableConfig = {
         data$: this.authUsers$,
         columns: this.columns,
         filter$: new BehaviorSubject({}),
         visibleColumns$: of([]),
         rowClick: null,
         initialSort: { active: 'name', direction: 'asc' },
         options: [
            {
               label: 'Make admin',
               action: (row) => this.setInternalAdmin(row, true),
               condition: (row) => !row.isInternalUser,
            },
            {
               label: 'Remove admin',
               action: (row) => this.setInternalAdmin(row, false),
               condition: (row) => row.isInternalUser,
            },
         ],
      };
   }

   setInternalAdmin(authUser: AuthUser, admin: boolean) {
      const updated = {
         ...authUser,
         isInternalUser: admin,
      };
      this.facade.saveAuthUser(updated);
   }
}
