import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
   selector: 'app-multi-save',
   templateUrl: './multi-save.component.html',
   styleUrls: ['./multi-save.component.scss']
})
export class MultiSaveComponent implements OnInit {
   @Input() disabled: boolean;

   @Output('save') saveEmitter = new EventEmitter();
   @Output('saveAndNew') saveAndNewEmitter = new EventEmitter();
   @Output('saveAndClose') saveAndCloseEmitter = new EventEmitter();

   constructor() {}

   ngOnInit(): void {}

   save() {
      this.saveEmitter.emit();
   }

   saveAndNew() {
      this.saveAndNewEmitter.emit();
   }

   saveAndClose() {
      this.saveAndCloseEmitter.emit();
   }
}
