import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FilterBarConfig, FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { RockStatus } from '@entities/enums/rock-status';
import { Rock } from '@entities/rock';
import { ActionItem } from '@entities/action-item';
import { MeetingsFacade } from '@app/meetings/state/meetings.facade';
import { takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import * as moment from 'moment';
import { Meeting } from '@entities/meeting';
import { Status } from '@entities/enums/status';
import { MeetingStatus } from '@entities/enums/meeting-status';
import { TableConfig, TableComponent } from '@app/shared/components/table/table.component';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { DepartmentNamePipe } from '@app/shared/pipes/department-name.pipe';
import { FilterBarComponent } from '@app/shared/components/filter-bar/filter-bar.component';
import { ActivatedRoute } from '@angular/router';
import { MeetingOptions } from '@entities/enums/meeting-options';
import { deepCopy } from '@app/utilities/deep-copy';
import { CsvService } from '@app/shared/services/csv.service';
import { ActionItemType } from '@entities/enums/action-item-type';
import { RoleGuard } from '@app/admin/services/role.guard';
import { TeamMember } from '@entities/team-member';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { UntypedFormControl } from '@angular/forms';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-meeting-agenda',
   templateUrl: './meeting-agenda.component.html',
   styleUrls: ['./meeting-agenda.component.scss'],
})
export class MeetingAgendaComponent implements OnInit {
   @ViewChild('rocksTable') rocksTable: TableComponent;
   @ViewChild('actionItemsTable') actionItemsTable: TableComponent;
   @ViewChild(FilterBarComponent) filterBar: FilterBarComponent;

   filterBarConfig: FilterBarConfig = {
      businessUnits: true,
      departments: true,
      quarter: true,
      hideSearch: true,
      status: RockStatus,
   };

   headerText = 'New';

   meetingOptions = MeetingOptions;

   meeting: Meeting = {} as Meeting;
   timePerItem = 2;

   rocksData: Rock[] = [];
   actionItemsData: ActionItem[] = [];
   attendees: TeamMember[] = [];
   teamMembers: TeamMember[] = [];
   addAttendeeFormControl = new UntypedFormControl();
   isPrivate = false;

   rocksFilter$ = new BehaviorSubject<FilterValues>({});
   actionItemsFilter$ = new BehaviorSubject<FilterValues>({});
   filterValues: FilterValues = {};

   /* istanbul ignore next */
   rocksColumns: TableColumn<Rock>[] = [
      { def: 'name', label: 'Rock', visible: true, value: (row) => row.name },
      {
         def: 'businessUnitId',
         label: 'BusinessUnit',
         visible: true,
         value: (row) => this.getBusinessUnitName(row),
      },
      {
         def: 'departmentId',
         label: 'Department',
         visible: true,
         value: (row) => this.departmentNamePipe.transform(row.departmentId, this.departments),
         filter: 'departments',
      },
      {
         def: 'quarter',
         label: 'Quarter',
         visible: true,
         value: (row) => row.quarter,
         filter: 'quarter',
      },
      {
         def: 'status',
         label: 'Status',
         visible: true,
         value: (row) => RockStatus[row.status],
      },
   ];

   /* istanbul ignore next */
   actionItemColumns: TableColumn<ActionItem>[] = [
      { def: 'name', label: 'ActionItem', visible: true, value: (row) => row.name },
      {
         def: 'businessUnitId',
         label: 'BusinessUnit',
         visible: true,
         value: (row) => this.getBusinessUnitName(row),
      },
      {
         def: 'departmentId',
         label: 'Department',
         visible: true,
         value: (row) => this.departmentNamePipe.transform(row.departmentId, this.departments),
         filter: 'departments',
      },
      { def: 'type', label: 'Type', visible: true, value: (row) => ActionItemType[row.type] },
      { def: 'status', label: 'Status', visible: true, value: (row) => Status[row.status] },
   ];

   businessUnits: BusinessUnit[] = [];
   departments: Department[] = [];

   selectedRocks: string[] = [];
   selectedActionItems: string[] = [];

   rocksTableConfig: TableConfig;
   actionItemsTableConfig: TableConfig;

   canEdit = false;

   private destroyed$ = new Subject<void>();

   constructor(
      private meetingsFacade: MeetingsFacade,
      private departmentNamePipe: DepartmentNamePipe,
      private route: ActivatedRoute,
      private csvService: CsvService,
      private roleGuard: RoleGuard,
      private userOrgFacade: UserOrgFacade
   ) {
      this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
         if (params['meetingId'] && params['meetingId'] !== 'new') {
            this.headerText = 'Edit';
            this.meetingsFacade.selectMeeting(params['meetingId']);
         } else {
            this.headerText = 'New';
            this.meeting = {
               id: null,
               name: `${moment().format('MMM DD YYYY')} Meeting`,
               notes: null,
               itemsToDiscuss: MeetingOptions.BOTH,
               timePerItem: 2,
               departments: [],
               rocks: [],
               actionItems: [],
               discussionItems: [],
               status: MeetingStatus['Not Started'],
            };
         }
      });
   }

   ngOnInit(): void {
      this.roleGuard
         .canEdit([], AppAreas.Meetings, this.meeting?.attendees)
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
         });

      this.meetingsFacade.selectedMeeting$.pipe(takeUntil(this.destroyed$)).subscribe((meeting) => {
         if (meeting) {
            this.meeting = deepCopy(meeting);
            this.timePerItem = meeting.timePerItem ? meeting.timePerItem / 60 : 2;
            if (this.meeting && this.meeting.filters) {
               this.filterFormChanged(this.meeting.filters);
               this.setFilterBar();
            }
         }
      });

      this.meetingsFacade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.meetingsFacade.businessUnits$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((businessUnits) => {
            this.businessUnits = businessUnits;
         });

      this.rocksTableConfig = {
         data$: this.meetingsFacade.rocks$,
         filter$: this.rocksFilter$,
         columns: this.rocksColumns,
         visibleColumns$: new BehaviorSubject([]),
         initialSort: { active: 'name', direction: 'asc' },
         rowClick: () => {},
         departments: this.departments,
         noHover: true,
         checkboxes: true,
      };

      this.actionItemsTableConfig = {
         data$: this.meetingsFacade.actionItems$,
         filter$: this.actionItemsFilter$,
         columns: this.actionItemColumns,
         visibleColumns$: new BehaviorSubject([]),
         initialSort: { active: 'name', direction: 'asc' },
         rowClick: () => {},
         departments: this.departments,
         noHover: true,
         checkboxes: true,
      };

      this.meetingsFacade.rocks$.pipe(takeUntil(this.destroyed$)).subscribe((rocks) => {
         this.rocksData = deepCopy(rocks);
      });
      this.meetingsFacade.actionItems$.pipe(takeUntil(this.destroyed$)).subscribe((actionItems) => {
         this.actionItemsData = deepCopy(actionItems);
      });
      combineLatest([
         this.meetingsFacade.teamMembers$,
         this.meetingsFacade.selectedMeeting$,
         this.userOrgFacade.currentUser$,
      ])
         .pipe(takeUntil(this.destroyed$))
         .subscribe(([teamMembers, meeting, currentUser]) => {
            this.teamMembers = teamMembers;
            if (meeting) {
               this.attendees = this.teamMembers.filter((teamMember) =>
                  meeting.attendees?.includes(teamMember.id)
               );
            } else {
               this.attendees = [];
            }
            if (this.attendees.length === 0) {
               const currentTeamMember = this.teamMembers.find(
                  (teamMember) => teamMember.id === currentUser.teamMemberId
               );
               if (currentTeamMember) {
                  this.attendees.push(currentTeamMember);
               }
            }
         });
   }

   meetingValid() {
      return (
         this.meeting?.name &&
         (this.meeting?.itemsToDiscuss === MeetingOptions.ACTIONS ||
            this.selectedRocks.length > 0) &&
         (this.meeting?.itemsToDiscuss === MeetingOptions.ROCKS ||
            this.selectedActionItems.length > 0)
      );
   }

   getBusinessUnitName(item: { departmentId: string }) {
      const department = this.departments.find((d) => d.id === item.departmentId);
      if (department) {
         const businessUnit = this.businessUnits.find((b) => b.id === department.businessUnitId);
         if (businessUnit) {
            return businessUnit.name;
         }
      }
      return '';
   }

   filterFormChanged(filter: FilterValues) {
      this.filterValues = filter;
      if (this.meeting.itemsToDiscuss === MeetingOptions.ACTIONS) {
         this.actionItemsFilter$.next(filter);
         const status = this.mapActionItemStatusToRockStatus(filter.status);
         const rockFilterValues = {
            ...filter,
            status,
         };
         this.rocksFilter$.next(rockFilterValues);
      } else {
         const status = this.mapRockStatusToActionStatus(filter.status);
         const actionItemsFilterValues = {
            ...filter,
            status,
         };
         this.actionItemsFilter$.next(actionItemsFilterValues);
         this.rocksFilter$.next(filter);
      }
   }

   setFilterBar() {
      if (this.meeting && this.meeting.filters) {
         if (this.filterBar) {
            this.filterBar.filter = this.meeting.filters;
         } else {
            setTimeout(() => {
               this.filterBar.filter = this.meeting.filters;
               this.filterBar.setFilter();
            }, 100);
         }
      }
   }

   setSelectedRocks(ids: string[]) {
      this.selectedRocks = ids;
   }

   setSelectedActionItems(ids: string[]) {
      this.selectedActionItems = ids;
   }

   getSelectedRocks() {
      return this.selectedRocks
         .map((id) => this.rocksData.find((rock) => rock.id === id))
         .filter((x) => !!x);
   }

   getSelectedActionItems() {
      return this.selectedActionItems
         .map((id) => this.actionItemsData.find((actionItem) => actionItem.id === id))
         .filter((x) => !!x);
   }

   updateFilterBar(option: MeetingOptions) {
      if (
         option === MeetingOptions.ACTIONS &&
         this.meeting.itemsToDiscuss !== MeetingOptions.ACTIONS
      ) {
         this.filterBarConfig.status = Status;
         const status = this.mapRockStatusToActionStatus(this.filterValues.status);
         this.filterValues = {
            ...this.filterValues,
            status,
         };
         this.meeting.itemsToDiscuss = option;
         this.filterBar.filter = this.filterValues;
         this.filterBar.setFilter();
      } else if (
         option !== MeetingOptions.ACTIONS &&
         this.meeting.itemsToDiscuss === MeetingOptions.ACTIONS
      ) {
         this.filterBarConfig.status = RockStatus;
         const status = this.mapActionItemStatusToRockStatus(this.filterValues.status);
         this.filterValues = {
            ...this.filterValues,
            status,
         };
         this.meeting.itemsToDiscuss = option;
         this.filterBar.filter = this.filterValues;
         this.filterBar.setFilter();
      }
      this.meeting.itemsToDiscuss = option;
   }

   mapRockStatusToActionStatus(values: RockStatus[]) {
      const status = [];
      if (values && values.length > 0) {
         values.forEach((rockStatus) => {
            switch (rockStatus) {
               case RockStatus['Not Started']:
                  if (!status.includes(Status['Not Started'])) {
                     status.push(Status['Not Started']);
                  }
                  break;
               case RockStatus.Completed:
                  if (!status.includes(Status.Completed)) {
                     status.push(Status.Completed);
                  }
                  break;
               case RockStatus['On Track']:
               case RockStatus['Off Track']:
               case RockStatus['On Hold']:
               case RockStatus['Pending Approval']:
                  if (!status.includes(Status['In Progress'])) {
                     status.push(Status['In Progress']);
                  }
                  break;
            }
         });
      }
      return status;
   }

   mapActionItemStatusToRockStatus(values: Status[]) {
      const status = [];
      if (values && values.length > 0) {
         values.forEach((rockStatus) => {
            switch (rockStatus) {
               case Status['Not Started']:
                  if (!status.includes(RockStatus['Not Started'])) {
                     status.push(RockStatus['Not Started']);
                  }
                  break;
               case Status.Completed:
                  if (!status.includes(RockStatus.Completed)) {
                     status.push(RockStatus.Completed);
                  }
                  break;
               case Status['In Progress']:
                  if (!status.includes(RockStatus['On Track'])) {
                     status.push(RockStatus['On Track']);
                  }
                  if (!status.includes(RockStatus['Off Track'])) {
                     status.push(RockStatus['Off Track']);
                  }
                  if (!status.includes(RockStatus['On Hold'])) {
                     status.push(RockStatus['On Hold']);
                  }
                  if (!status.includes(RockStatus['Pending Approval'])) {
                     status.push(RockStatus['Pending Approval']);
                  }
                  break;
            }
         });
      }
      return status;
   }

   getDepartments() {
      const departments = [
         ...this.rocksData.map((r) => r.departmentId),
         ...this.actionItemsData.map((i) => i.departmentId),
      ];
      return departments.filter(
         (id, index) => id != undefined && departments.indexOf(id) === index
      );
   }

   saveMeeting() {
      this.setMeetingValues();
      if (this.meeting.id) {
         this.meetingsFacade.saveMeeting(this.meeting);
      } else {
         this.meetingsFacade.createDraftMeeting(this.meeting);
      }
   }

   startMeeting() {
      this.setMeetingValues();
      if (this.meeting.id) {
         this.meetingsFacade.startMeeting(this.meeting.id);
      } else {
         this.meetingsFacade.createMeeting(this.meeting);
      }
   }

   setMeetingValues() {
      const rocks =
         this.meeting.itemsToDiscuss !== MeetingOptions.ACTIONS
            ? this.sortByBusinessUnit(this.getSelectedRocks()).map((r) => r.id)
            : [];
      const actionItems =
         this.meeting.itemsToDiscuss !== MeetingOptions.ROCKS
            ? this.sortByBusinessUnit(this.getSelectedActionItems()).map((r) => r.id)
            : [];
      this.meeting.rocks = rocks;
      this.meeting.actionItems = actionItems;
      this.meeting.departments = this.getDepartments();
      this.meeting.filters = this.filterValues;
      this.meeting.timePerItem = this.timePerItem * 60;
      this.meeting.attendees = this.attendees.map((attendee) => attendee.id);
      this.meeting.isPrivate = this.isPrivate;
   }

   sortByBusinessUnit<T extends { departmentId: string }>(items: T[]) {
      return items.sort((a, b) => {
         const departmentA = this.departments.find((d) => d.id === a.departmentId);
         const departmentB = this.departments.find((d) => d.id === b.departmentId);
         let businessUnitA: BusinessUnit;
         let businessUnitB: BusinessUnit;
         if (departmentA) {
            businessUnitA = this.businessUnits.find((bu) => bu.id === departmentA.businessUnitId);
         } else {
            return 1;
         }
         if (departmentB) {
            businessUnitB = this.businessUnits.find((bu) => bu.id === departmentB.businessUnitId);
         } else {
            return -1;
         }
         if (businessUnitA) {
            const buDiff = businessUnitA.name.localeCompare(businessUnitB.name);
            if (buDiff === 0) {
               return departmentA.name.localeCompare(departmentB.name);
            } else {
               return buDiff;
            }
         } else {
            return -1;
         }
      });
   }

   exportAgenda() {
      const rocks = this.sortByBusinessUnit(this.rocksData).map((rock) => ({
         Name: rock.name,
         Department: this.departmentNamePipe.transform(rock.departmentId, this.departments),
         Quarter: rock.quarter,
         Status: RockStatus[rock.status],
      }));
      const actionItems = this.sortByBusinessUnit(this.actionItemsData).map((actionItem) => ({
         Name: actionItem.name,
         Department: this.departmentNamePipe.transform(actionItem.departmentId, this.departments),
         Status: Status[actionItem.status],
      }));
      let data = [];
      if (this.meeting.itemsToDiscuss !== MeetingOptions.ACTIONS) {
         data = [...rocks];
      }
      if (this.meeting.itemsToDiscuss !== MeetingOptions.ROCKS) {
         data = [...data, ...actionItems];
      }
      this.csvService.export(data, `${this.meeting.name} Agenda`);
   }

   back() {
      history.back();
   }

   addAttendee(event: MatAutocompleteSelectedEvent) {
      const teamMember = event.option.value;
      if (!this.attendees.some((attendee) => attendee.id === teamMember.id)) {
         this.attendees.push(teamMember);
         this.attendees.sort((a, b) =>
            `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
         );
      }
      this.addAttendeeFormControl.reset();
   }

   removeAttendee(attendee: TeamMember) {
      this.attendees = this.attendees.filter((a) => a.id !== attendee.id);
   }
}
