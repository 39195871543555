<app-sticky-header
   [title]="title$ | async"
   [isNew]="isNew$ | async"
   [saveDisabled]="form.pristine || !form.valid"
   [titleFormControl]="getControl('name')"
   [canEdit]="canEdit"
   (save)="save()"
   (delete)="deleteGoal()"
   (back)="back()"
   (titleChange)="updateName($event)"
></app-sticky-header>
<form [formGroup]="form">
   <mat-card class="goal-details-card">
      <mat-card-content>
         <div class="form-fields">
            <app-quarterpicker
               [label]="'Target'"
               [formCtrl]="getControl('targetQuarter')"
               [required]="true"
               [canEdit]="canEdit"
            ></app-quarterpicker>
            <mat-form-field>
               <mat-label>Status</mat-label>
               <mat-select formControlName="status" required>
                  <mat-option *ngFor="let key of goalStatusKeys" [value]="key">
                     {{ goalStatus[key] }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field>
               <mat-label>Level</mat-label>
               <mat-select formControlName="level" required>
                  <mat-option *ngFor="let key of goalLevelKeys" [value]="key">
                     {{ goalLevel[key] }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <ng-container *ngIf="form.value.level === goalLevel['Business Unit']">
               <mat-form-field>
                  <mat-label>Business Unit</mat-label>
                  <mat-select formControlName="linkedEntityId">
                     <mat-option
                        *ngFor="let businessUnit of businessUnits$ | async"
                        [value]="businessUnit.id"
                     >
                        {{ businessUnit.name }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
            </ng-container>
            <ng-container *ngIf="form.value.level === goalLevel.Department">
               <mat-form-field>
                  <mat-label>Department</mat-label>
                  <mat-select formControlName="linkedEntityId">
                     <mat-optgroup
                        *ngFor="let group of groupedDepartments$ | async"
                        [label]="group.name"
                     >
                        <mat-option
                           *ngFor="let department of group.departments"
                           [value]="department.id"
                        >
                           {{ department.name }}
                        </mat-option>
                     </mat-optgroup>
                  </mat-select>
               </mat-form-field>
            </ng-container>
         </div>
         <div class="description">
            <mat-label *ngIf="form.value.description" class="description-label">
               Description
            </mat-label>
            <ng-container *ngIf="canEdit; else noEdit">
               <div class="editor" (click)="focusEditor()">
                  <textarea
                     matInput
                     type="text"
                     formControlName="description"
                     [froalaEditor]="editorConfig"
                     (froalaInit)="initFroala($event)"
                  ></textarea>
               </div>
            </ng-container>
            <ng-template #noEdit>
               <div class="editor" [innerHTML]="getControl('description').value"></div>
            </ng-template>
         </div>
      </mat-card-content>
   </mat-card>
</form>
<app-linked-goals
   [canEdit]="canEdit"
   (goalLinked)="linkGoal($event)"
   (goalUnlinked)="unlinkGoal($event)"
></app-linked-goals>
<app-linked-rocks
   [canEdit]="canEdit"
   (rockLinked)="linkRock($event)"
   (rockUnlinked)="unlinkRock($event)"
></app-linked-rocks>
<app-user-comments
   [canEdit]="canEdit"
   [comments]="comments"
   (commentsUpdated)="updateComments($event)"
></app-user-comments>
