import { createReducer, on, Action } from '@ngrx/store';
import { UserOrgActions } from './user-org.actions';
import { User } from '@entities/user';
import { Organization } from '@entities/organization';
import * as AuthActions from '@app/auth/state/auth.actions';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { adminActions } from '@app/admin/state/admin.actions';

export interface UserOrgState {
   usersForAccount: User[];
   currentUserId: string;
   selectedOrgId: string;
   userOrganizations: Organization[];
}

export const userOrgInitialState: UserOrgState = {
   currentUserId: null,
   selectedOrgId: null,
   userOrganizations: [],
   usersForAccount: [],
};

export function reducer(state: UserOrgState | undefined, action: Action): UserOrgState {
   return createReducer<UserOrgState>(
      userOrgInitialState,
      on(UserOrgActions.OrganizationsUpdated, (state, { orgs }) => {
         return {
            ...state,
            userOrganizations: orgs,
         };
      }),
      on(UserOrgActions.UsersUpdated, (state, { users }) => {
         const currentUser = users.find((u) => u.organizationId == state.selectedOrgId);
         let currentUserId = null;
         if (currentUser) {
            currentUserId = currentUser.id;
         }
         return {
            ...state,
            currentUserId,
            usersForAccount: users,
         };
      }),
      on(UserOrgActions.OrganizationCreated, (state, { organization, adminUser }) => {
         return {
            ...state,
            userOrganizations: [...state.userOrganizations, organization],
            usersForAccount: [...state.usersForAccount, adminUser],
         };
      }),
      on(
         UserOrgActions.SelectOrganization,
         UserOrgActions.SetOrganization,
         (state, { organizationId }) => {
            const user = state.usersForAccount.find((u) => u.organizationId == organizationId);
            const currentUserId = user ? user.id : null;
            return {
               ...state,
               currentUserId: currentUserId,
               selectedOrgId: organizationId,
            };
         }
      ),
      on(UserOrgActions.LeaveOrganization, (state, { organization }) => {
         const userOrganizations = state.userOrganizations.filter(
            (org) => org.organizationId != organization.organizationId
         );
         return {
            ...state,
            userOrganizations,
         };
      }),
      on(adminActions.UsersUpdated, (state, { users }) => {
         const updatedUsers = users.filter((user) =>
            state.usersForAccount.some((u) => u.id === user.id)
         );
         const notUpdatedUsers = state.usersForAccount.filter(
            (user) => !users.some((u) => u.id === user.id)
         );
         const usersForAccount = [...updatedUsers, ...notUpdatedUsers];
         return {
            ...state,
            usersForAccount,
         };
      }),
      on(AuthActions.AuthUserUpdated, (state, { authUser }) => {
         return authUser ? state : userOrgInitialState;
      })
   )(state, action);
}
