<button mat-button color="primary" *ngIf="canEdit" (click)="assignTeamMembers()">
   <mat-icon>add</mat-icon>
   Assign Team Members
</button>
<table
   mat-table
   [dataSource]="teamMemberDataSource$"
   matSort
   matSortDisableClear="true"
   (matSortChange)="triggerSort($event)"
>
   <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
      <td mat-cell *matCellDef="let row">{{ row.firstName }}</td>
   </ng-container>
   <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
      <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
   </ng-container>
   <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let row">{{ row.title }}</td>
   </ng-container>
   <ng-container matColumnDef="primaryDepartment.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
      <td mat-cell *matCellDef="let row">
         {{ row.primaryDepartmentId | departmentName: departments }}
      </td>
   </ng-container>
   <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
         <button mat-icon-button [matMenuTriggerFor]="optionsMenu">
            <mat-icon>more_vert</mat-icon>
         </button>
         <mat-menu #optionsMenu="matMenu">
            <button mat-menu-item *ngIf="canEdit" (click)="unassignTeamMember(row)">
               Unassign
            </button>
            <button mat-menu-item (click)="viewTeamMember(row)">View Team Member</button>
         </mat-menu>
      </td>
   </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
   <tr mat-row *matRowDef="let row; columns: displayedColumns" class="team-member"></tr>
</table>
