import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DepartmentFunction } from '@entities/department-function';
import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { takeUntil } from 'rxjs/operators';
import { TeamMember } from '@entities/team-member';
import { Task } from '@entities/task';

@Component({
   selector: 'app-department-function-list',
   templateUrl: './department-function-list.component.html',
   styleUrls: [
      './department-function-list.component.scss',
      '../organization/organization.component.scss',
   ],
})
export class DepartmentFunctionListComponent implements OnInit {
   @Output() selected = new EventEmitter();

   departmentFunctions$: Observable<DepartmentFunction[]>;
   selectedFunctionId$: Observable<string>;
   departmentId$: Observable<string>;
   canEdit$: Observable<boolean>;

   tasks: Task[] = [];
   teamMembers: TeamMember[] = [];

   private destroyed$ = new Subject<void>();

   constructor(private orgBuilderFacade: OrgBuilderFacade) {}

   ngOnInit() {
      this.selectedFunctionId$ = this.orgBuilderFacade.selectedDepartmentFunctionId$;
      this.departmentFunctions$ = this.orgBuilderFacade.visibleDepartmentFunctions$;
      this.departmentId$ = this.orgBuilderFacade.selectedDepartmentId$;
      this.canEdit$ = this.orgBuilderFacade.canEdit$;
      this.orgBuilderFacade.teamMembers$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((teamMembers) => {
            this.teamMembers = teamMembers;
         });
      this.orgBuilderFacade.tasks$.pipe(takeUntil(this.destroyed$)).subscribe((tasks) => {
         this.tasks = tasks;
      });
   }

   addDepartmentFunction() {
      this.orgBuilderFacade.addDepartmentFunction();
   }

   select(departmentFunctionId: string) {
      this.orgBuilderFacade.selectDepartmentFunction(departmentFunctionId);
      this.selected.emit(departmentFunctionId);
   }

   edit(departmentFunction: DepartmentFunction) {
      this.orgBuilderFacade.editDepartmentFunction(departmentFunction);
   }

   delete(departmentFunction: DepartmentFunction) {
      this.orgBuilderFacade.deleteDepartmentFunction(departmentFunction);
   }

   dragStarted(event: CdkDragStart, departmentFunction: DepartmentFunction) {
      this.orgBuilderFacade.dragStarted(departmentFunction);
   }

   drop(event: CdkDragDrop<any>) {
      if (event.isPointerOverContainer) {
         this.orgBuilderFacade.reorderDepartmentFunctions(event.previousIndex, event.currentIndex);
      }
   }

   getTeamMember(teamMemberId: string) {
      return this.orgBuilderFacade.getTeamMember(teamMemberId);
   }

   itemDropped(event: any, departmentFunction: DepartmentFunction) {
      this.orgBuilderFacade.itemDropped(departmentFunction, DepartmentFunction);
   }

   getTasks(deptFn) {
      return this.tasks.filter((t) => t.departmentFunctionId === deptFn.id).length;
   }

   report(deptFn) {
      this.orgBuilderFacade.departmentFunctionReport(deptFn);
   }
}
