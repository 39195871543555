import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { State, NoOpAction } from '@app/app.state';
import { Router } from '@angular/router';
import { ErrorService } from '@app/shared/services/error.service';
import { UserOrgActions, OrganizationCreated } from './user-org.actions';
import { withLatestFrom, map, catchError, tap, switchMap } from 'rxjs/operators';
import { getAuthUser } from '@app/auth/state/auth.state';
import { Organization } from '@entities/organization';
import { of, from } from 'rxjs';
import { User } from '@entities/user';
import { OrganizationService } from '../services/organization.service';
import { UserService } from '../services/user.service';
import { UserRole } from '@entities/enums/user-role';
import { appRoutesNames } from '@app/app.routes.names';
import { FirebaseAuthService } from '@app/auth/services/firebase-auth.service';
import { AuthUser } from '@entities/auth-user';
import { AuthUserService } from '@app/auth/services/auth-user.service';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { FunctionNames } from '@app/utilities/functionNames';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ProductType } from '@entities/enums/product-type';
import { AuthUserUpdated } from '@app/auth/state/auth.actions';
import { deepCopy } from '@app/utilities/deep-copy';
import { FunctionsService } from '@app/shared/services/functions.service';
import { adminActions } from '@app/admin/state/admin.actions';
import { FeatureFlagService } from '@app/internal-tools/services/feature-flag.service';
import { FeatureFlagKeys } from '@app/internal-tools/state/feature-flag-keys';

@Injectable()
export class UserOrgEffects {
   invitesAccepted: string[] = [];

   constructor(
      private actions$: Actions,
      private store: Store<State>,
      private router: Router,
      private errorService: ErrorService,
      private authService: FirebaseAuthService,
      private organizationService: OrganizationService,
      private userService: UserService,
      private authUserService: AuthUserService,
      private functions: FunctionsService,
      private snackBar: MatSnackBar,
      private featureFlagService: FeatureFlagService
   ) {}

   createOrganization$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.CreateOrganization),
            withLatestFrom(
               this.store.pipe(select(getAuthUser)),
               this.featureFlagService.isEnabled(FeatureFlagKeys.PERMISSION_GROUPS)
            ),
            tap(([action, authUser, permissionGroupsEnabled]) => {
               const organization: Organization = {
                  ...deepCopy(action.organization),
                  authUsers: [authUser.uid],
               };
               let products = organization.billingInfo.plans.map((p) => p.productType);
               if (products.includes(ProductType.Complete)) {
                  products = [ProductType.Complete];
                  const plan = organization.billingInfo.plans.find(
                     (p) => p.productType == ProductType.Complete
                  );
                  plan.available = plan.available - 1;
               } else {
                  organization.billingInfo.plans.forEach((p) => {
                     p.available = p.available - 1;
                  });
               }
               this.organizationService.save(organization).then((org) => {
                  const adminUser: User = {
                     id: null,
                     displayName: authUser.displayName,
                     email: authUser.email,
                     organizationId: org.organizationId,
                     userAccountId: authUser.uid,
                     firstName: authUser.firstName,
                     lastName: authUser.lastName,
                     role: UserRole.Admin,
                     isAdmin: true,
                     products: products,
                     product: ProductType.Complete,
                     active: true,
                  };
                  this.userService
                     .save(adminUser, org.organizationId)
                     .then((savedUser) => {
                        adminUser.id = savedUser.id;
                        const updatedAuthUser: AuthUser = {
                           ...authUser,
                           orgs: [...authUser.orgs, org.organizationId],
                        };
                        return this.authUserService.save(updatedAuthUser);
                     })
                     .then((authUser) => {
                        this.store.dispatch(AuthUserUpdated({ authUser }));
                        this.store.dispatch(OrganizationCreated({ organization, adminUser }));
                        this.store.dispatch(
                           UserOrgActions.SelectOrganization({ organizationId: org.organizationId })
                        );
                        if (permissionGroupsEnabled) {
                           this.store.dispatch(adminActions.SetDefaultPermissionGroups());
                        }
                     });
               });
            })
         ),
      { dispatch: false }
   );

   saveOrganization$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.SaveOrgInfo),
            tap((action) => {
               this.organizationService.save(action.organization).catch((error) => {
                  this.errorService.handleError(error);
               });
            })
         ),
      { dispatch: false }
   );

   selectOrganization$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.SelectOrganization),
            tap((action) => {
               const url = localStorage.getItem('redirect');
               if (url) {
                  localStorage.removeItem('redirect');
                  this.router.navigateByUrl(url);
               } else {
                  this.router.navigate([
                     appRoutesNames.ORGANIZATION,
                     action.organizationId,
                     appRoutesNames.DASHBOARD,
                  ]);
               }
            })
         ),
      { dispatch: false }
   );

   routerNavigation$ = createEffect(() =>
      this.actions$.pipe(
         ofType(ROUTER_NAVIGATION),
         map((action: RouterNavigationAction) => {
            if (
               action.payload &&
               action.payload.routerState &&
               action.payload.routerState.root &&
               action.payload.routerState.root.firstChild &&
               action.payload.routerState.root.firstChild.params
            ) {
               const organizationId = action.payload.routerState.root.firstChild.params['orgId'];
               if (organizationId) {
                  return UserOrgActions.SetOrganization({ organizationId });
               }
            }
            return new NoOpAction();
         })
      )
   );

   saveAuthUser$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.SaveAuthUser),
            tap((action) => {
               this.authService
                  .updateEmail(action.authUser.email)
                  .then(() => {
                     return this.authUserService.save(action.authUser);
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   leaveOrganization$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.LeaveOrganization),
            switchMap((action) => {
               return from(
                  this.functions.httpsCallable(FunctionNames.LEAVE_ORG)({
                     organizationId: action.organization.organizationId,
                  })
               ).pipe(
                  map(() => {
                     this.snackBar.open(`You have left ${action.organization.name}`);
                  }),
                  catchError((error) => {
                     this.errorService.handleError(error);
                     return of();
                  })
               );
            })
         ),
      { dispatch: false }
   );
}
