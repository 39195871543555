import { Component, OnInit, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Task } from '@entities/task';
import { UntypedFormGroup, UntypedFormControl, FormArray } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskFacade } from '@app/shared/interfaces/task-facade.interface';
import { takeUntil } from 'rxjs/operators';
import { DepartmentFunctionViewModel } from '@app/documentation/state/documentation.state';

@Component({
   selector: 'app-task-add',
   templateUrl: './task-add.component.html',
   styleUrls: ['./task-add.component.scss'],
})
export class TaskAddComponent implements OnInit {
   selectedTask$: Observable<Task>;
   editAction: string;
   form: UntypedFormGroup;
   taskId: string;
   newTask = true;
   viewModel: DepartmentFunctionViewModel;

   private service: TaskFacade;

   private destroyed$ = new Subject<void>();

   constructor(
      @Inject(MAT_DIALOG_DATA) data: { service: TaskFacade; task?: Task },
      private router: Router
   ) {
      this.form = new UntypedFormGroup({
         name: new UntypedFormControl(),
         purpose: new UntypedFormControl(),
      });
      this.service = data.service;
      if (data.task) {
         this.newTask = false;
         this.form.patchValue(data.task);
      }
   }

   ngOnInit() {
      this.service.selectedDepartmentFunctionViewModel$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((viewModel) => {
            this.viewModel = viewModel;
         });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   save(onDeactivate: boolean = false) {
      const task: Task = this.form.value;
      this.service.saveTask(task, onDeactivate);
   }

   cancel() {
      this.service.cancelAddTask();
   }

   fullEditor() {
      const task = this.form.value;
      this.service.taskFullEditor(this.router.url, task, this.viewModel);
   }
}
