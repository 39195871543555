import { Directive, HostListener, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { getDragItem } from '../state/organization.state';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
   selector: '[appDropTarget]',
})
export class DropTargetDirective implements OnDestroy {
   private targeted = false;
   private dragging = false;
   private destroyed$ = new Subject<void>();

   @Output() public dropped: EventEmitter<any>;

   constructor(private store: Store<State>) {
      this.dropped = new EventEmitter();
      this.store
         .pipe(select(getDragItem))
         .pipe(takeUntil(this.destroyed$))
         .subscribe((dragItem) => {
            this.dragging = !!dragItem;
         });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   @HostListener('mouseenter')
   onMouseEnter() {
      if (this.dragging) {
         this.targeted = true;
      }
   }

   @HostListener('mouseleave')
   onMouseLeave() {
      this.targeted = false;
   }

   @HostListener('mouseup')
   onMouseUp() {
      if (this.targeted) {
         this.dropped.emit();
      }
   }
}
