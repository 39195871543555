import { Injectable, OnDestroy } from '@angular/core';
import { EntityService } from '@app/shared/services/entity.service';
import { Role } from '@entities/role';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { Observable } from 'rxjs';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { select, Store } from '@ngrx/store';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { State } from '@app/app.state';

@Injectable({
   providedIn: 'root',
})
export class RoleService implements EntityService<Role>, OnDestroy {
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.ROLES;
   entities$: Observable<Role[]>;

   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<Role>();

   constructor(private store: Store<State>) {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }
   save(entity: Role, overwrite: boolean = false): Promise<Role> {
      return this.orgEntityService.save(entity, { overwrite });
   }
   batchSave(entities: Role[]) {
      return this.orgEntityService.batchSave(entities);
   }

   delete(entity: Role) {
      return this.orgEntityService.delete(entity);
   }
}
