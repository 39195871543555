import { Injectable, inject } from '@angular/core';
import { Organization } from '@entities/organization';
import { Observable } from 'rxjs';
import { AuthUser } from '@entities/auth-user';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { FirestoreService } from '@app/shared/services/firestore.service';

@Injectable()
export class InternalToolsFacade {
   organizations$: Observable<Organization[]>;
   authUsers$: Observable<AuthUser[]>;
   private firestore = inject(FirestoreService);

   constructor() {
      this.organizations$ = this.firestore.collectionData(
         COLLECTION_NAMES.ORGANIZATIONS
      ) as Observable<Organization[]>;
      this.authUsers$ = this.firestore.collectionData(COLLECTION_NAMES.AUTH_USERS) as Observable<
         AuthUser[]
      >;
   }

   saveAuthUser(authUser: AuthUser) {
      this.firestore.updateDoc(
         this.firestore.doc(COLLECTION_NAMES.AUTH_USERS, authUser.uid),
         authUser
      );
   }
}
