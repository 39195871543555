import { createAction, props } from '@ngrx/store';
import { BusinessUnit } from '@entities/business-unit';
import { Task } from '@entities/task';
import { DepartmentFunction } from '@entities/department-function';
import { Department } from '@entities/department';
import { TaskAssignment } from '@entities/task-assignment';
import { DepartmentFunctionViewModel } from '@app/documentation/state/documentation.state';

export const BusinessUnitsUpdated = createAction(
   '[Organization] Business units updated',
   props<{ businessUnits: BusinessUnit[] }>()
);
export const SelectBusinessUnit = createAction(
   '[Organization] Select business unit',
   props<{ businessUnitId: string }>()
);
export const SaveBusinessUnit = createAction(
   '[Organization] Save business unit',
   (businessUnit: BusinessUnit, overwrite: boolean = false) => ({ businessUnit, overwrite })
);
export const DeleteBusinessUnit = createAction(
   '[Organization] Delete business unit',
   (businessUnit: BusinessUnit, showSnackBar: boolean = true) => ({ businessUnit, showSnackBar })
);
export const DeleteBusinessUnitChildren = createAction(
   '[Organization] Delete business unit children',
   props<{ businessUnitId: string }>()
);
export const ReorderBusinessUnits = createAction(
   '[Organization] Reorder business units',
   props<{ oldIndex: number; newIndex: number }>()
);

export const DepartmentsUpdated = createAction(
   '[Organization] Departments updated',
   props<{ departments: Department[] }>()
);
export const SelectDepartment = createAction(
   '[Organization] Select department',
   props<{ departmentId: string }>()
);
export const SaveDepartment = createAction(
   '[Organization] Save department',
   (department: Department, overwrite: boolean = false) => ({ department, overwrite })
);
export const DeleteDepartment = createAction(
   '[Organization] Delete department',
   (department: Department, showSnackBar: boolean = true) => ({ department, showSnackBar })
);
export const DeleteDepartmentChildren = createAction(
   '[Organization] Delete department children',
   props<{ departmentId: string }>()
);
export const ReorderDepartments = createAction(
   '[Organization] Reorder departments',
   props<{ oldIndex: number; newIndex: number }>()
);

export const DepartmentFunctionsUpdated = createAction(
   '[Organization] Department functions updated',
   props<{ departmentFunctions: DepartmentFunction[] }>()
);
export const SelectDepartmentFunction = createAction(
   '[Organization] Select department function',
   props<{ departmentFunctionId: string }>()
);
export const GoToDepartmentFunctionFullEditor = createAction(
   '[Organization] Go to department function full editor',
   props<{ departmentFunction: Partial<DepartmentFunction>; url: string }>()
);
export const SaveDepartmentFunction = createAction(
   '[Organization] Save department function',
   (departmentFunction: DepartmentFunction, overwrite: boolean = false) => ({
      departmentFunction,
      overwrite,
   })
);
export const DeleteDepartmentFunction = createAction(
   '[Organization] Delete department function',
   (departmentFunction: DepartmentFunction, showSnackBar: boolean = true) => ({
      departmentFunction,
      showSnackBar,
   })
);
export const DeleteDepartmentFunctionChildren = createAction(
   '[Organization] Delete department function children',
   props<{ departmentFunctionId: string }>()
);
export const ReorderDepartmentFunctions = createAction(
   '[Organization] Reorder department functions',
   props<{ oldIndex: number; newIndex: number }>()
);

export const TasksUpdated = createAction(
   '[Organization] Tasks updated',
   props<{ tasks: Task[] }>()
);
export const SelectTask = createAction('[Organization] Select task', props<{ taskId: string }>());
export const SaveTask = createAction(
   '[Organization] Save task',
   (task: Task, overwrite: boolean = false) => ({ task, overwrite })
);
export const DeleteTask = createAction(
   '[Organization] Delete task',
   (task: Task, showSnackBar: boolean = true) => ({ task, showSnackBar })
);
export const ReorderTasks = createAction(
   '[Organization] Reorder tasks',
   props<{ oldIndex: number; newIndex: number }>()
);
export const AssignTaskToTeamMember = createAction(
   '[Organization] Assign task to team member',
   props<{ taskAssignment: TaskAssignment }>()
);
export const GoToTaskFullEditor = createAction(
   '[Organization] Go to full editor for task',
   props<{ url: string; task: Partial<Task>; viewModel: DepartmentFunctionViewModel }>()
);
export const DraggingStarted = createAction(
   '[Org Builder] Dragging started',
   props<{ dragItem: any }>()
);
export const ItemDropped = createAction(
   '[Org Builder] Item dropped',
   props<{ dropTarget: any; dropTargetType: any }>()
);
export const DraggingStopped = createAction('[Org Builder] Dragging stopped');

export const BusinessUnitSummary = createAction(
   '[Org Builder] Business unit summary',
   props<{ businessUnitId: string }>()
);

export const DepartmentSummary = createAction(
   '[Org Builder] Department summary',
   props<{ departmentId: string }>()
);

export const ResetRatings = createAction('[Performance] Reset ratings');
