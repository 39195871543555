import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-org-shell',
  templateUrl: './org-shell.component.html',
  styleUrls: ['./org-shell.component.scss']
})
export class OrgShellComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
