<h1 mat-dialog-title>Edit Permissions Group</h1>
<div mat-dialog-content>
   <div class="group-edit-header">
      <mat-form-field class="group-name">
         <input matInput [(ngModel)]="permissionGroup.name" placeholder="Name" />
      </mat-form-field>
      <div class="admin-toggle">
         <mat-slide-toggle
            color="primary"
            [(ngModel)]="permissionGroup.isAdmin"
            matTooltip="Admins can add or remove users and manage organization settings"
            matTooltipPosition="after"
         >
            Admin
         </mat-slide-toggle>
      </div>
   </div>
   <div class="access-levels">
      <div class="access-level-titles">
         <div class="access-area"></div>
         <div class="access-level">Read</div>
         <div class="access-level">Edit</div>
      </div>
      <div class="access-level-values set-all">
         <div class="access-area">Set All</div>
         <div class="access-level">
            <mat-select
               placeholder="Select access level"
               [(ngModel)]="setAllView"
               (valueChange)="setAll($event, 'view')"
            >
               <mat-select-trigger>
                  <app-permission-level-icon [accessLevel]="setAllView"></app-permission-level-icon>
                  {{ setAllView }}
               </mat-select-trigger>
               <mat-option [value]="accessLevel.ALL">
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.ALL"
                  ></app-permission-level-icon>
                  {{ accessLevel.ALL }}
               </mat-option>
               <mat-option [value]="accessLevel.ASSIGNED">
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.ASSIGNED"
                  ></app-permission-level-icon>
                  {{ accessLevel.ASSIGNED }}
               </mat-option>
               <mat-option [value]="accessLevel.NONE">
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.NONE"
                  ></app-permission-level-icon>
                  {{ accessLevel.NONE }}
               </mat-option>
            </mat-select>
         </div>
         <div class="access-level">
            <mat-select
               placeholder="Select access level"
               [(ngModel)]="setAllEdit"
               (valueChange)="setAll($event, 'edit')"
            >
               <mat-select-trigger>
                  <app-permission-level-icon [accessLevel]="setAllEdit"></app-permission-level-icon>
                  {{ setAllEdit }}
               </mat-select-trigger>
               <mat-option [value]="accessLevel.ALL">
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.ALL"
                  ></app-permission-level-icon>
                  {{ accessLevel.ALL }}
               </mat-option>
               <mat-option [value]="accessLevel.ASSIGNED">
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.ASSIGNED"
                  ></app-permission-level-icon>
                  {{ accessLevel.ASSIGNED }}
               </mat-option>
               <mat-option [value]="accessLevel.NONE">
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.NONE"
                  ></app-permission-level-icon>
                  {{ accessLevel.NONE }}
               </mat-option>
            </mat-select>
         </div>
      </div>
      <div *ngFor="let config of permissionConfigs" class="access-level-values">
         <div class="access-area">{{ config.name }}</div>
         <div class="access-level">
            <mat-select
               [(ngModel)]="permissionGroup.accessMap[config.area].view"
               (ngModelChange)="canViewUpdated(config.area)"
            >
               <mat-select-trigger>
                  <app-permission-level-icon
                     [accessLevel]="permissionGroup.accessMap[config.area]?.view"
                  ></app-permission-level-icon>
                  {{ permissionGroup.accessMap[config.area]?.view }}
               </mat-select-trigger>
               <mat-option *ngFor="let option of config.accessOptions" [value]="option">
                  <app-permission-level-icon [accessLevel]="option"></app-permission-level-icon>
                  {{ option }}
               </mat-option>
               <!-- <mat-option [value]="accessLevel.ALL">
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.ALL"
                  ></app-permission-level-icon>
                  {{ accessLevel.ALL }}
               </mat-option>
               <mat-option [value]="accessLevel.ASSIGNED">
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.ASSIGNED"
                  ></app-permission-level-icon>
                  {{ accessLevel.ASSIGNED }}
               </mat-option>
               <mat-option [value]="accessLevel.NONE">
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.NONE"
                  ></app-permission-level-icon>
                  {{ accessLevel.NONE }}
               </mat-option> -->
            </mat-select>
         </div>
         <div class="access-level">
            <mat-select
               [(ngModel)]="permissionGroup.accessMap[config.area].edit"
               (ngModelChange)="checkSetAll()"
            >
               <mat-select-trigger>
                  <app-permission-level-icon
                     [accessLevel]="permissionGroup.accessMap[config.area]?.edit"
                  ></app-permission-level-icon>
                  {{ permissionGroup.accessMap[config.area]?.edit }}
               </mat-select-trigger>
               <mat-option
                  *ngFor="let option of config.accessOptions"
                  [value]="option"
                  [disabled]="!isEditOptionEnabled(option, config.area)"
               >
                  <app-permission-level-icon [accessLevel]="option"></app-permission-level-icon>
                  {{ option }}
               </mat-option>
               <!-- <mat-option
                  [value]="accessLevel.ALL"
                  [disabled]="permissionGroup.accessMap[config.area].view !== accessLevel.ALL"
               >
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.ALL"
                  ></app-permission-level-icon>
                  {{ accessLevel.ALL }}
               </mat-option>
               <mat-option
                  [value]="accessLevel.ASSIGNED"
                  [disabled]="permissionGroup.accessMap[config.area].view === accessLevel.NONE"
               >
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.ASSIGNED"
                  ></app-permission-level-icon>
                  {{ accessLevel.ASSIGNED }}
               </mat-option>
               <mat-option [value]="accessLevel.NONE">
                  <app-permission-level-icon
                     [accessLevel]="accessLevel.NONE"
                  ></app-permission-level-icon>
                  {{ accessLevel.NONE }}
               </mat-option> -->
            </mat-select>
         </div>
      </div>
   </div>
</div>
<div mat-dialog-actions>
   <button mat-raised-button color="primary" [disabled]="!permissionGroup.name" (click)="save()">
      Save
   </button>
   <button mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
</div>
