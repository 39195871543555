import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import { Router } from '@angular/router';
import { appRoutesNames } from '@app/app.routes.names';
import { WindowService } from '@app/utilities/window.service';

@Injectable()
export class AuthEffects {
   constructor(
      private actions$: Actions,
      private router: Router,
      private windowService: WindowService
   ) {}

   authUserUpdated$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(AuthActions.AuthUserUpdated),
            tap(({ authUser }) => {
               const _window = this.windowService.getWindow();
               if (authUser && _window.location.pathname == `/${appRoutesNames.LOGIN}`) {
                  this.router.navigate([appRoutesNames.LANDING]);
               } else if (!authUser && _window.location.pathname != `/${appRoutesNames.SIGNUP}`) {
                  this.router.navigate([appRoutesNames.LOGIN]);
               }
            })
         ),
      { dispatch: false }
   );
}
