import { Injectable, OnDestroy } from '@angular/core';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { Meeting } from '@entities/meeting';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { EntityService } from '@app/shared/services/entity.service';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';

@Injectable()
export class MeetingService implements EntityService<Meeting>, OnDestroy {
   entities$: Observable<Meeting[]>;

   readonly COLLECTION_NAME = COLLECTION_NAMES.MEETINGS;

   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<Meeting>();

   constructor(private store: Store<State>) {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }
   save(entity: Meeting): Promise<Meeting> {
      return this.orgEntityService.save(entity);
   }
   batchSave(entities: Meeting[]): Promise<Meeting[]> {
      return this.orgEntityService.batchSave(entities);
   }
   delete(entity: Meeting): Promise<void> {
      return this.orgEntityService.delete(entity);
   }
}
