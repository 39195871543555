import { Route } from '@angular/router';
import { teamRouteNames } from './team.routes.names';
import { TeamMemberComponent } from './components/team-member/team-member.component';
import { TeamMemberListComponent } from './components/team-member-list/team-member-list.component';
import { SaveChangesGuard } from '@app/shared/services/save-changes.guard';
import { TeamMemberReportComponent } from './components/team-member-report/team-member-report.component';
import { CompentencyListComponent } from './components/compentency-list/compentency-list.component';
import { CompetencyEditComponent } from './components/competency-edit/competency-edit.component';
import { PerformanceEvaluationEditComponent } from './components/performance-evaluation-edit/performance-evaluation-edit.component';
import { CompetencyDashboardComponent } from './components/competency-dashboard/competency-dashboard.component';

export const TEAM_ROUTES: Route[] = [
   {
      path: teamRouteNames.TEAM_MEMBERS,
      children: [
         { path: '', component: TeamMemberListComponent },
         {
            path: ':teamMemberId',
            component: TeamMemberComponent,
            canDeactivate: [SaveChangesGuard],
         },
         { path: `:teamMemberId/${teamRouteNames.REPORT}`, component: TeamMemberReportComponent },
      ],
   },
   {
      path: teamRouteNames.COMPETENCIES,
      component: CompetencyDashboardComponent,
   },
   {
      path: teamRouteNames.PERFORMANCE_EVALUATIONS,
      children: [
         {
            path: ':performanceEvaluationId',
            component: PerformanceEvaluationEditComponent,
            canDeactivate: [SaveChangesGuard],
         },
      ],
   },
   { path: '', redirectTo: teamRouteNames.TEAM_MEMBERS, pathMatch: 'full' },
];
