import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTeam from './state/team.reducer';
import { TeamFacade } from './state/team.facade';
import { TeamMemberComponent } from './components/team-member/team-member.component';
import { AssignedTasksComponent } from './components/assigned-tasks/assigned-tasks.component';

import { AssignTasksComponent } from './components/assign-tasks/assign-tasks.component';
import { TeamMemberService } from './services/team-member.service';
import { TeamEffects } from './state/team.effects';
import { SharedModule } from '@app/shared/shared.module';
import { TeamMemberListComponent } from './components/team-member-list/team-member-list.component';
import { RouterModule } from '@angular/router';
import { AssignRoleComponent } from './components/assign-role/assign-role.component';
import { TeamMemberReportComponent } from './components/team-member-report/team-member-report.component';
import { CompentencyListComponent } from './components/compentency-list/compentency-list.component';
import { CompetencyEditComponent } from './components/competency-edit/competency-edit.component';
import { CompetencyLinkedTasksComponent } from './components/competency-linked-tasks/competency-linked-tasks.component';
import { CompetencyLinkTasksComponent } from './components/competency-link-tasks/competency-link-tasks.component';
import { AssignedCompetenciesComponent } from './components/assigned-competencies/assigned-competencies.component';
import { TrainingPlansComponent } from './components/training-plans/training-plans.component';
import { PerformanceEvaluationListComponent } from './components/performance-evaluation-list/performance-evaluation-list.component';
import { PerformanceEvaluationEditComponent } from './components/performance-evaluation-edit/performance-evaluation-edit.component';
import { PerformanceEvaluationItemEditComponent } from './components/performance-evaluation-item-edit/performance-evaluation-item-edit.component';
import { DirectReportsComponent } from './components/direct-reports/direct-reports.component';
import { TaskRatingsComponent } from './components/task-ratings/task-ratings.component';
import { CompetencyDashboardComponent } from './components/competency-dashboard/competency-dashboard.component';
import { CompetencyDashboardDonutChartComponent } from './components/competency-dashboard-donut-chart/competency-dashboard-donut-chart.component';
import { CompetencyDashboardBarChartComponent } from './components/competency-dashboard-bar-chart/competency-dashboard-bar-chart.component';
import { TaskRatingEditComponent } from './components/task-rating-edit/task-rating-edit.component';

@NgModule({
   declarations: [
      TeamMemberComponent,
      AssignedTasksComponent,
      AssignTasksComponent,
      TeamMemberListComponent,
      AssignRoleComponent,
      TeamMemberReportComponent,
      CompentencyListComponent,
      CompetencyEditComponent,
      CompetencyLinkedTasksComponent,
      CompetencyLinkTasksComponent,
      AssignedCompetenciesComponent,
      TrainingPlansComponent,
      PerformanceEvaluationListComponent,
      PerformanceEvaluationEditComponent,
      PerformanceEvaluationItemEditComponent,
      DirectReportsComponent,
      TaskRatingsComponent,
      CompetencyDashboardComponent,
      CompetencyDashboardDonutChartComponent,
      CompetencyDashboardBarChartComponent,
      TaskRatingEditComponent,
   ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      StoreModule.forFeature('team', fromTeam.reducer),
      EffectsModule.forFeature([TeamEffects]),
      SharedModule,
   ],
   providers: [TeamFacade, TeamMemberService],
})
export class TeamModule {}
