import { Injectable } from '@angular/core';
import { EntityService } from '@app/shared/services/entity.service';
import { Competency } from '@entities/competency';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { Observable } from 'rxjs';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { select, Store } from '@ngrx/store';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { State } from '@app/app.state';

@Injectable({
   providedIn: 'root',
})
export class CompetencyService implements EntityService<Competency> {
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.COMPETENCIES;
   entities$: Observable<Competency[]>;

   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<Competency>();

   constructor(private store: Store<State>) {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }
   save(entity: Competency, overwrite: boolean = false): Promise<Competency> {
      return this.orgEntityService.save(entity, { overwrite });
   }
   batchSave(entities: Competency[]) {
      return this.orgEntityService.batchSave(entities);
   }

   delete(entity: Competency) {
      return this.orgEntityService.delete(entity);
   }
}
