<div class="info">
   <app-rock-info [rock]="rock$ | async" [goals]="goals$ | async"></app-rock-info>
</div>
<div class="actions">
   <app-rock-actions
      [actions]="actions"
      [teamMembers]="teamMembers$ | async"
      [canEdit]="true"
      (actionsUpdated)="updateActions($event)"
   ></app-rock-actions>
</div>
<div class="comments">
   <app-user-comments
      [comments]="comments"
      (commentsUpdated)="updateComments($event)"
   ></app-user-comments>
</div>
