import {
   Component,
   OnInit,
   ViewChild,
   TemplateRef,
   Output,
   EventEmitter,
   Input,
} from '@angular/core';
import { Department } from '@entities/department';
import { UntypedFormControl } from '@angular/forms';
import { Observable, BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { TableConfig } from '@app/shared/components/table/table.component';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { EvolveFacade } from '@app/evolve/state/evolve.facade';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DepartmentNamePipe } from '@app/shared/pipes/department-name.pipe';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { BusinessUnitNamePipe } from '@app/shared/pipes/business-unit-name.pipe';
import { BusinessUnit } from '@entities/business-unit';
import { Goal } from '@entities/goal';
import { Status } from '@entities/enums/status';

@Component({
   selector: 'app-linked-goals',
   templateUrl: './linked-goals.component.html',
   styleUrls: ['./linked-goals.component.scss'],
})
export class LinkedGoalsComponent implements OnInit {
   @ViewChild('linkGoalDialogRef') linkGoalDialogRef: TemplateRef<any>;
   @Input() canEdit = true;
   @Output() goalLinked = new EventEmitter<Goal>();
   @Output() goalUnlinked = new EventEmitter<Goal>();

   linkedGoals: Goal[] = [];
   departments: Department[] = [];
   businessUnits: BusinessUnit[] = [];

   goalToLink: Goal;
   goalToLinkSelected = false;
   goalToLinkFormControl = new UntypedFormControl();
   filteredGoals$: Observable<Goal[]>;

   dataSource$ = new BehaviorSubject<Goal[]>([]);
   filter$ = new BehaviorSubject({});

   tableConfig: TableConfig;
   /* istanbul ignore next */
   columns: TableColumn<Goal>[] = [
      { label: 'Name', def: 'name', visible: true, value: (row) => row.name },
      {
         label: 'Department',
         def: 'departmentId',
         visible: false,
         value: (row) => this.departmentNamePipe.transform(row.departmentId, this.departments),
         filter: 'departments',
      },
      {
         label: 'Business Unit',
         def: 'businessUnitId',
         visible: false,
         value: (row) =>
            this.businessUnitNamePipe.transform(row.linkedEntityId, this.businessUnits),
         filter: 'departments',
      },
      {
         label: 'Target',
         def: 'target',
         visible: true,
         value: (row) => row.targetQuarter,
         filter: 'quarter',
      },
      {
         label: 'Status',
         def: 'status',
         visible: true,
         value: (row) => Status[row.status],
         filter: 'status',
      },
   ];

   private destroyed$ = new Subject<void>();

   constructor(
      private evolveFacade: EvolveFacade,
      private dialog: MatDialog,
      private departmentNamePipe: DepartmentNamePipe,
      private businessUnitNamePipe: BusinessUnitNamePipe
   ) {}

   ngOnInit(): void {
      combineLatest([this.evolveFacade.selectedGoal$, this.evolveFacade.goals$])
         .pipe(takeUntil(this.destroyed$))
         .subscribe(([goal, goals]) => {
            if (goal && goals) {
               this.linkedGoals = goal.linkedGoals
                  ? goals.filter((g) => goal.linkedGoals.includes(g.id))
                  : [];
               this.dataSource$.next(this.linkedGoals);
            }
         });

      this.evolveFacade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
      });
      this.evolveFacade.businessUnits$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((businessUnits) => {
            this.businessUnits = businessUnits;
         });

      /* istanbul ignore next */
      this.tableConfig = {
         data$: this.dataSource$,
         filter$: this.filter$,
         columns: this.columns,
         visibleColumns$: of(this.columns.map((col) => col.def)),
         initialSort: { active: 'name', direction: 'asc' },
         rowClick: (row) => this.editGoal(row),
         options: [
            {
               label: 'Details',
               action: (row) => this.editGoal(row),
               condition: (row) => !this.canEdit,
            },
            {
               label: 'Edit',
               action: (row) => this.editGoal(row),
               condition: (row) => this.canEdit,
            },
            {
               label: 'Unlink',
               action: (row) => this.unlinkGoal(row),
               condition: (row) => this.canEdit,
            },
         ],
      };

      this.filteredGoals$ = combineLatest([
         this.evolveFacade.goals$,
         this.evolveFacade.selectedGoal$,
         this.goalToLinkFormControl.valueChanges.pipe(startWith('')),
      ]).pipe(
         takeUntil(this.destroyed$),
         map(([goals, selectedGoal, filterValue]) => {
            const sorted = [...goals]
               .sort((a, b) => a.name.localeCompare(b.name))
               .filter((goal) => goal.id !== selectedGoal.id);

            if (typeof filterValue === 'string') {
               return sorted.filter((goal) =>
                  goal.name.toLowerCase().includes(filterValue.toLowerCase())
               );
            } else {
               return sorted;
            }
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   editGoal(goal: Goal) {
      this.evolveFacade.editGoal(goal);
   }

   linkGoal() {
      this.dialog.open(this.linkGoalDialogRef, { minWidth: 300 });
   }

   addLinkedGoal() {
      this.dialog.closeAll();
      const goal = this.goalToLinkFormControl.value;
      this.clear();
      if (goal) {
         this.goalLinked.emit(goal);
         this.linkedGoals.push(goal);
         this.dataSource$.next(this.linkedGoals);
      }
   }

   unlinkGoal(goal: Goal) {
      this.linkedGoals = this.linkedGoals.filter((r) => r.id !== goal.id);
      this.dataSource$.next(this.linkedGoals);
      this.goalUnlinked.emit(goal);
   }

   displayFn = (goal: Goal) => (goal && goal.name ? goal.name : '');

   onOptionSelect(event: MatAutocompleteSelectedEvent) {
      this.goalToLinkSelected = true;
   }

   clear() {
      this.goalToLinkFormControl.reset();
      this.goalToLinkSelected = false;
   }
}
