<form [formGroup]="orgForm" (ngSubmit)="save()">
   <ng-template matStepLabel>Organization Info</ng-template>
   <div class="row">
      <mat-form-field class="col-md-6">
         <input
            matInput
            type="text"
            placeholder="Organization Name"
            formControlName="name"
            required
         />
      </mat-form-field>
   </div>
   <div *ngIf="!signup">
      <div>Organization Logo</div>
      <input hidden type="file" #logoUpload accept="image/*" (change)="setLogo($event)" />
      <ng-container *ngIf="logoData; else upload">
         <img class="logo" [src]="logoData" />
         <div>
            <button mat-button color="primary" (click)="logoUpload.click()">Change</button>
         </div>
      </ng-container>
      <ng-template #upload>
         <button mat-button color="primary" (click)="logoUpload.click()">Choose File</button>
      </ng-template>
      <ng-container *ngIf="fileTooLarge">
         <mat-error>File too large. Please select a file 1MB or smaller</mat-error>
      </ng-container>
   </div>
   <div class="mat-title primary-contact">
      Primary Contact
   </div>
   <div class="mat-subheading-2">
      The primary contact will receive account and billing notifications
   </div>
   <div class="row" formGroupName="primaryContact">
      <mat-form-field class="col-md-6 col-sm-12">
         <input
            matInput
            type="text"
            placeholder="First Name"
            formControlName="firstName"
            required
         />
      </mat-form-field>
      <mat-form-field class="col-md-6 col-sm-12">
         <input matInput type="text" placeholder="Last Name" formControlName="lastName" required />
      </mat-form-field>
      <mat-form-field class="col-12">
         <input matInput type="email" placeholder="Email" formControlName="email" required />
      </mat-form-field>
   </div>
   <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!orgForm.valid || orgForm.pristine"
   >
      {{ signup ? 'Next' : 'Save' }}
   </button>
</form>
