<button mat-button color="primary" (click)="back()">
   <mat-icon>arrow_back</mat-icon>
   Back
</button>
<div class="editor" [froalaEditor]="options">
   <div style="width: 100%; min-height: 10in;">
      <ng-container *ngIf="orgName">
         <div style="display: flex; justify-content: space-between;">
            <div style="display: flex;">
               <img *ngIf="logo" [src]="logo" style="height: 50px;" />
               <div style="font-size: 24pt; line-height: 60px;">{{ orgName }}</div>
            </div>
            <div
               style="
                  font-size: 10pt;
                  font-weight: bold;
                  text-transform: uppercase;
                  padding-top: 12pt;
                  text-align: right;
               "
            >
               Department Summary
            </div>
         </div>
      </ng-container>
      <ng-container *ngIf="department">
         <div style="font-size: 20pt; font-weight: bold; margin-bottom: 12pt; margin-top: -4pt;">
            {{ department.name }}
         </div>
         <div>
            <div
               style="
                  font-size: 10pt;
                  font-weight: bold;
                  text-transform: uppercase;
                  color: #757575;
               "
            >
               Manager
            </div>
            <div>{{ department.managerId | teamMemberName: teamMembers }}</div>
         </div>
         <div>
            <div
               style="
                  font-size: 10pt;
                  font-weight: bold;
                  text-transform: uppercase;
                  color: #757575;
               "
            >
               Purpose
            </div>
            <div [innerHTML]="department.purpose"></div>
         </div>
         <div
            style="font-size: 10pt; font-weight: bold; text-transform: uppercase; color: #757575;"
         >
            Functions
         </div>
         <div style="padding-left: 12pt;">
            <ng-container *ngFor="let deptFn of department.departmentFunctions">
               <div style="font-size: 16pt;">
                  {{ deptFn.name }}
               </div>
               <div style="padding-left: 12pt;">
                  <div
                     style="
                        font-size: 10pt;
                        font-weight: bold;
                        text-transform: uppercase;
                        color: #757575;
                     "
                  >
                     Lead
                  </div>
                  <div>{{ deptFn.leadId | teamMemberName: teamMembers }}</div>

                  <div
                     style="
                        font-size: 10pt;
                        font-weight: bold;
                        text-transform: uppercase;
                        color: #757575;
                     "
                  >
                     Purpose
                  </div>
                  <div>{{ deptFn.purpose }}</div>
                  <div style="display: flex;">
                     <div
                        style="
                           flex: 1 1 25%;
                           font-size: 10pt;
                           font-weight: bold;
                           text-transform: uppercase;
                           color: #757575;
                        "
                     >
                        Task
                     </div>
                     <div
                        style="
                           flex: 1 1 25%;
                           font-size: 10pt;
                           font-weight: bold;
                           text-transform: uppercase;
                           color: #757575;
                        "
                     >
                        Assignees
                     </div>
                     <div
                        style="
                           flex: 2 2 50%;
                           font-size: 10pt;
                           font-weight: bold;
                           text-transform: uppercase;
                           color: #757575;
                        "
                     >
                        Purpose
                     </div>
                  </div>
                  <ng-container *ngFor="let task of deptFn.tasks">
                     <div style="display: flex;">
                        <div style="flex: 1 1 25%;">{{ task.name }}</div>
                        <div style="flex: 1 1 25%;">
                           {{ task.assignees ? task.assignees.length : 0 }}
                        </div>
                        <div style="flex: 2 2 50%;">{{ task.purpose }}</div>
                     </div>
                  </ng-container>
               </div>
            </ng-container>
         </div>
      </ng-container>
   </div>
</div>
