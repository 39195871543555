import * as OrganizationActions from './organization.actions';
import { OrganizationState } from './organization.state';
import { createReducer, on, Action } from '@ngrx/store';
import * as AuthActions from '@app/auth/state/auth.actions';

export const orgInitialState: OrganizationState = {
   businessUnits: [],
   selectedBusinessUnitId: null,
   departments: [],
   selectedDepartmentId: null,
   departmentFunctions: [],
   selectedDepartmentFunctionId: null,
   tasks: [],
   selectedTaskId: null,
   dragItem: null,
};

export function reducer(state: OrganizationState | undefined, action: Action) {
   return createReducer(
      orgInitialState,
      on(OrganizationActions.BusinessUnitsUpdated, (state, { businessUnits }) => {
         return {
            ...state,
            businessUnits,
         };
      }),
      on(OrganizationActions.SelectBusinessUnit, (state, { businessUnitId }) => {
         const selectedDepartmentId =
            state.selectedBusinessUnitId == businessUnitId ? state.selectedDepartmentId : null;
         const selectedDepartmentFunctionId =
            state.selectedDepartmentId == selectedDepartmentId
               ? state.selectedDepartmentFunctionId
               : null;
         return {
            ...state,
            selectedBusinessUnitId: businessUnitId,
            selectedDepartmentId,
            selectedDepartmentFunctionId,
         };
      }),
      on(OrganizationActions.DepartmentsUpdated, (state, { departments }) => {
         return {
            ...state,
            departments,
         };
      }),
      on(OrganizationActions.SelectDepartment, (state, { departmentId }) => {
         const selectedDepartmentFunctionId =
            state.selectedDepartmentId == departmentId ? state.selectedDepartmentFunctionId : null;
         return {
            ...state,
            selectedDepartmentId: departmentId,
            selectedDepartmentFunctionId,
         };
      }),
      on(OrganizationActions.DepartmentFunctionsUpdated, (state, { departmentFunctions }) => {
         return {
            ...state,
            departmentFunctions,
         };
      }),
      on(OrganizationActions.SelectDepartmentFunction, (state, { departmentFunctionId }) => {
         return {
            ...state,
            selectedDepartmentFunctionId: departmentFunctionId,
         };
      }),
      on(OrganizationActions.TasksUpdated, (state, { tasks }) => {
         return {
            ...state,
            tasks,
         };
      }),
      on(OrganizationActions.SelectTask, (state, { taskId }) => {
         return {
            ...state,
            selectedTaskId: taskId,
         };
      }),
      on(OrganizationActions.DraggingStarted, (state, { dragItem }) => {
         return {
            ...state,
            dragItem,
         };
      }),
      on(OrganizationActions.DraggingStopped, (state) => {
         return {
            ...state,
            dragItem: null,
         };
      }),
      on(OrganizationActions.DeleteBusinessUnit, (state, { businessUnit }) => {
         if (state.selectedBusinessUnitId == businessUnit.id) {
            return {
               ...state,
               selectedBusinessUnitId: null,
               selectedDepartmentId: null,
               selectedDepartmentFunctionId: null,
            };
         } else {
            return state;
         }
      }),
      on(OrganizationActions.DeleteDepartment, (state, { department }) => {
         if (state.selectedDepartmentId == department.id) {
            return {
               ...state,
               selectedDepartmentId: null,
               selectedDepartmentFunctionId: null,
            };
         } else {
            return state;
         }
      }),
      on(OrganizationActions.DeleteDepartmentFunction, (state, { departmentFunction }) => {
         const selectedDepartmentFunctionId =
            state.selectedDepartmentFunctionId == departmentFunction.id
               ? null
               : state.selectedDepartmentFunctionId;
         return {
            ...state,
            selectedDepartmentFunctionId,
         };
      }),
      on(AuthActions.AuthUserUpdated, (state, { authUser }) => {
         return authUser ? state : orgInitialState;
      }),
      on(OrganizationActions.BusinessUnitSummary, (state, { businessUnitId }) => {
         return {
            ...state,
            selectedBusinessUnitId: businessUnitId,
         };
      })
   )(state, action);
}
