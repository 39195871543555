<h3 mat-dialog-title>
   <ng-container *ngIf="canEdit$ | async">
      {{ editAction }}
   </ng-container>
   Department
   <ng-container *ngIf="!(canEdit$ | async)">Details</ng-container>
</h3>

<form [formGroup]="form" (ngSubmit)="save()">
   <mat-dialog-content>
      <mat-form-field class="name">
         <input
            appAutoFocus
            matInput
            required
            type="text"
            placeholder="Name"
            formControlName="name"
            [readonly]="!(canEdit$ | async)"
         />
      </mat-form-field>
      <app-team-member-autocomplete
         class="manager"
         placeholder="Manager"
         [canEdit]="canEdit$ | async"
         [idFormControl]="getControl('managerId')"
         (optionSelected)="setManagerId($event)"
      ></app-team-member-autocomplete>
      <div class="description">
         <mat-form-field class="fill-height">
            <mat-label *ngIf="form.value.purpose">Purpose</mat-label>
            <textarea
               class="editor"
               matInput
               type="text"
               formControlName="purpose"
               [froalaEditor]="editorConfig"
            ></textarea>
         </mat-form-field>
      </div>
      <div class="description">
         <mat-form-field class="fill-height">
            <mat-label *ngIf="form.value.notes">Notes</mat-label>
            <textarea
               class="editor"
               matInput
               type="text"
               formControlName="notes"
               [froalaEditor]="notesEditorConfig"
            ></textarea>
         </mat-form-field>
      </div>
   </mat-dialog-content>
   <mat-dialog-actions>
      <div class="button-container">
         <button
            type="submit"
            mat-raised-button
            color="primary"
            *ngIf="canEdit$ | async"
            [disabled]="!form.valid"
         >
            Save
         </button>
         <button type="button" mat-raised-button (click)="cancel()">
            <span *ngIf="canEdit$ | async; else noEdit">Cancel</span>
            <ng-template #noEdit>Close</ng-template>
         </button>
      </div>
   </mat-dialog-actions>
</form>
