import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SharedModule } from '@app/shared/shared.module';
import { RocksWidgetComponent } from './components/rocks-widget/rocks-widget.component';
import { ActionItemsWidgetComponent } from './components/action-items-widget/action-items-widget.component';
import { DocFunctionsWidgetComponent } from './components/doc-functions-widget/doc-functions-widget.component';
import { AssignedTasksWidgetComponent } from './components/assigned-tasks-widget/assigned-tasks-widget.component';
import { OverviewWidgetComponent } from './components/overview-widget/overview-widget.component';
import { WidgetComponent } from './components/widget/widget.component';
import { DashboardFacade } from './state/dashboard.facade';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/dashboard.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DashboardEffects } from './state/dashboard.effects';
import { DashboardService } from './services/dashboard.service';
import { AssignedActionsWidgetComponent } from './components/assigned-actions-widget/assigned-actions-widget.component';
import { DocumentationStatusWidgetComponent } from './components/documentation-status-widget/documentation-status-widget.component';
import { PerformanceRatingWidgetComponent } from './components/performance-rating-widget/performance-rating-widget.component';
import { UpcomingMilestonesWidgetComponent } from './components/upcoming-milestones-widget/upcoming-milestones-widget.component';
import { WidgetFilterComponent } from './components/widget-filter/widget-filter.component';
import { AssignedItemsWidgetComponent } from './components/assigned-items-widget/assigned-items-widget.component';
import { AssignedMilestonesWidgetComponent } from './components/assigned-milestones-widget/assigned-milestones-widget.component';
import { QuickActionsWidgetComponent } from './components/quick-actions-widget/quick-actions-widget.component';
import { QueryWidgetComponent } from './components/query-widget/query-widget.component';

@NgModule({
   declarations: [
      DashboardComponent,
      RocksWidgetComponent,
      ActionItemsWidgetComponent,
      DocFunctionsWidgetComponent,
      AssignedTasksWidgetComponent,
      OverviewWidgetComponent,
      WidgetComponent,
      AssignedActionsWidgetComponent,
      DocumentationStatusWidgetComponent,
      PerformanceRatingWidgetComponent,
      UpcomingMilestonesWidgetComponent,
      WidgetFilterComponent,
      AssignedItemsWidgetComponent,
      AssignedMilestonesWidgetComponent,
      QuickActionsWidgetComponent,
      QueryWidgetComponent,
   ],
   imports: [
      CommonModule,
      SharedModule,
      StoreModule.forFeature('dashboards', reducer),
      EffectsModule.forFeature([DashboardEffects]),
   ],
   providers: [DashboardFacade, DashboardService],
})
export class DashboardModule {}
