import { AccessLevel } from '@entities/enums/access-level';
import { AppAreas } from '@entities/enums/app-areas';

export interface PermissionConfig {
   name: string;
   area: AppAreas;
   accessOptions: AccessLevel[];
}

export const permissionConfigs: PermissionConfig[] = [
   {
      name: 'Overview',
      area: AppAreas.Overview,
      accessOptions: [AccessLevel.ALL, AccessLevel.NONE],
   },
   {
      name: 'Org Builder',
      area: AppAreas.Organization,
      accessOptions: [AccessLevel.ALL, AccessLevel.NONE],
   },
   {
      name: 'Documentation',
      area: AppAreas.Documentation,
      accessOptions: [AccessLevel.ALL, AccessLevel.ASSIGNED, AccessLevel.NONE],
   },
   {
      name: 'Performance',
      area: AppAreas.Performance,
      accessOptions: [AccessLevel.ALL, AccessLevel.ASSIGNED, AccessLevel.NONE],
   },
   {
      name: 'Evolve',
      area: AppAreas.Evolve,
      accessOptions: [AccessLevel.ALL, AccessLevel.ASSIGNED, AccessLevel.NONE],
   },
   {
      name: 'Team',
      area: AppAreas.Team,
      accessOptions: [AccessLevel.ALL, AccessLevel.ASSIGNED, AccessLevel.NONE],
   },
   {
      name: 'Meetings',
      area: AppAreas.Meetings,
      accessOptions: [AccessLevel.ALL, AccessLevel.NONE],
   },
];
