<div class="title-container">
   <ng-container *ngIf="showEditName; else title">
      <div class="field-container">
         <mat-form-field class="form-field">
            <input
               #nameInput
               appAutoFocus
               matInput
               required
               type="text"
               [formControl]="formCtrl"
               data-test-id="name-input"
            />
         </mat-form-field>
         <button mat-icon-button (click)="updateName()" data-test-id="update-name-button">
            <mat-icon>done</mat-icon>
         </button>
         <button mat-icon-button (click)="cancelUpdateName()" data-test-id="name-cancel-button">
            <mat-icon>close</mat-icon>
         </button>
      </div>
   </ng-container>
   <ng-template #title>
      <div class="title-display">
         {{ formCtrl.value }}
      </div>
      <button mat-icon-button *ngIf="canEdit" (click)="editName()" data-test-id="edit-name-button">
         <mat-icon>edit</mat-icon>
      </button>
   </ng-template>
</div>
