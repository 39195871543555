import {
   Component,
   OnInit,
   Input,
   SimpleChanges,
   OnChanges,
   Output,
   EventEmitter,
} from '@angular/core';
import { DepartmentFunctionViewModel } from '@app/documentation/state/documentation.state';
import { PerformanceRating } from '@entities/enums/performance-rating';
import { PerformanceFacade } from '@app/performance/state/performance.facade';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DepartmentFunction } from '@entities/department-function';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import { Subscription } from 'rxjs';

@Component({
   selector: 'app-performance-function-details',
   templateUrl: './performance-function-details.component.html',
   styleUrls: ['./performance-function-details.component.scss'],
})
export class PerformanceFunctionDetailsComponent implements OnInit, OnChanges {
   @Input() viewModel: {
      businessUnit: BusinessUnit;
      department: Department;
      departmentFunction: DepartmentFunction;
   };
   @Input() canEdit: boolean;
   @Output() dirtyChange = new EventEmitter<boolean>();

   departmentFunction: DepartmentFunction;
   businessUnitName: string;
   departmentName: string;

   form: UntypedFormGroup;

   dirty = false;
   dirtySubscription: Subscription;

   rating = PerformanceRating;
   ratingKeys = Object.keys(PerformanceRating)
      .map((key) => +key)
      .filter((key) => !isNaN(key));

   constructor(private performanceFacade: PerformanceFacade) {}

   ngOnInit() {
      this.initForm();
      this.setValues();
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['viewModel'] && this.form && this.form.pristine) {
         this.setValues();
      }
      if (changes['canEdit']) {
         this.initForm();
      }
   }

   initForm() {
      if (!this.form) {
         this.form = new UntypedFormGroup({
            benchmark: new UntypedFormControl(),
            rating: new UntypedFormControl(),
            kpi: new UntypedFormControl(),
            ratingNotes: new UntypedFormControl(),
         });
      }
      if (this.canEdit) {
         this.form.enable();
      } else {
         this.form.disable();
      }
      if (this.dirtySubscription) {
         this.dirtySubscription.unsubscribe();
      }
      this.dirtySubscription = this.form.valueChanges.subscribe(() => {
         if (this.dirty !== this.form.dirty) {
            this.dirty = this.form.dirty;
            this.dirtyChange.emit(this.dirty);
         }
      });
   }

   setValues() {
      if (this.viewModel) {
         if (this.viewModel.departmentFunction) {
            this.departmentFunction = this.viewModel.departmentFunction;
            this.form.patchValue(this.viewModel.departmentFunction);
            if (
               this.departmentFunction.rating == undefined ||
               this.departmentFunction.rating == null
            ) {
               this.form.patchValue({ rating: PerformanceRating['No Value'] });
               this.form.markAsDirty();
               this.dirtyChange.emit(true);
            }
         }
         if (this.viewModel.department) {
            this.departmentName = this.viewModel.department.name;
         }
         if (this.viewModel.businessUnit) {
            this.businessUnitName = this.viewModel.businessUnit.name;
         }
      }
   }

   save() {
      const departmentFunction = {
         ...this.viewModel.departmentFunction,
         ...this.form.value,
      };
      this.performanceFacade.saveDepartmentFunction(departmentFunction);
      this.form.markAsPristine();
   }
}
