import { GoalPeriod } from './enums/goal-period';
import { Status } from './enums/status';
import { GoalLevel } from './enums/goal-level';
import { UserComment } from './user-comment';

export class Goal {
   id: string;

   name: string;

   description: string;

   status: Status;

   targetQuarter: string;

   level: GoalLevel;

   linkedEntityId: string;

   linkedGoals: string[];

   rocks: string[];

   comments: UserComment[];

   approvedDate: string;

   assigneeId?: string;

   /**
    * @deprecated
    */
   departmentId: string;

   /**
    * @deprecated
    */
   period: GoalPeriod;
}
