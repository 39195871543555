import {
   Component,
   OnInit,
   Input,
   OnDestroy,
   OnChanges,
   Output,
   EventEmitter,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Rock } from '@entities/rock';
import { takeUntil } from 'rxjs/operators';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import * as moment from 'moment';
import { TeamMember } from '@entities/team-member';
import { RockStatus } from '@entities/enums/rock-status';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
   selector: 'app-rocks-roadmap',
   templateUrl: './rocks-roadmap.component.html',
   styleUrls: ['./rocks-roadmap.component.scss'],
})
export class RocksRoadmapComponent implements OnInit, OnChanges, OnDestroy {
   @Input() rocks: Rock[];
   @Input() businessUnits: BusinessUnit[];
   @Input() departments: Department[];
   @Input() quarters: string[];
   @Input() teamMembers: TeamMember[];
   @Input() canEdit = true;

   @Output() editRock = new EventEmitter<Rock>();
   @Output() saveRock = new EventEmitter<Rock>();

   activeBusinessUnits: any[] = [];
   activeDepartments: any[] = [];
   activeQuarters: string[] = [];

   rockStatus = RockStatus;

   private destroyed$ = new Subject<void>();

   constructor() {}

   ngOnInit(): void {}

   ngOnChanges() {
      if (
         this.departments &&
         this.departments.length > 0 &&
         this.businessUnits &&
         this.businessUnits.length > 0 &&
         this.rocks &&
         this.rocks.length > 0
      ) {
         this.buildTree();
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   buildTree() {
      this.activeBusinessUnits = [];
      this.activeDepartments = [];
      if (this.quarters && this.quarters.length > 0) {
         this.activeQuarters = this.quarters;
      } else {
         this.activeQuarters = this.generateQuarters();
      }
      this.rocks.forEach((rock) => {
         const department = this.departments.find((d) => d.id === rock.departmentId);
         const businessUnit = department
            ? this.businessUnits.find((bu) => bu.id === department.businessUnitId)
            : null;
         if (department && businessUnit) {
            let activeDepartment = this.activeDepartments.find((d) => d.id === department.id);
            if (!activeDepartment) {
               activeDepartment = { id: department.id, name: department.name, rocks: [rock] };
               this.activeDepartments.push(activeDepartment);
            } else {
               activeDepartment.rocks.push(rock);
            }
            let activeBusinessUnit = this.activeBusinessUnits.find(
               (bu) => bu.id === businessUnit.id
            );
            if (!activeBusinessUnit) {
               activeBusinessUnit = {
                  id: businessUnit.id,
                  name: businessUnit.name,
                  departments: [activeDepartment],
               };
               this.activeBusinessUnits.push(activeBusinessUnit);
            } else {
               const dept = activeBusinessUnit.departments.find((d) => d.id === department.id);
               if (!dept) {
                  activeBusinessUnit.departments.push(activeDepartment);
               }
            }
         }
      });
      this.activeBusinessUnits = this.activeBusinessUnits.sort((a, b) =>
         a.name.localeCompare(b.name)
      );
      this.activeBusinessUnits.forEach((businessUnit) => {
         businessUnit.departments = businessUnit.departments.sort((a, b) =>
            a.name.localeCompare(b.name)
         );
         businessUnit.departments.forEach((department) => {
            department.rocks = department.rocks.sort((a, b) => a.name.localeCompare(b.name));
         });
      });
   }

   generateQuarters() {
      const today = moment();
      let year = today.get('year');
      let quarter = today.get('quarter');
      const quarters = [];
      while (quarters.length < 4) {
         quarters.push(`${year}Q${quarter}`);
         quarter++;
         if (quarter > 4) {
            quarter = 1;
            year++;
         }
      }
      return quarters;
   }

   rocksForQuarter(rocks: Rock[], quarter: string) {
      return rocks.filter((r) => r.quarter === quarter);
   }

   rockDropped(event: CdkDragDrop<any>) {
      const target = event.container.id.split('.');
      const departmentId = target[0];
      const quarter = target[1];
      const updatedRock = {
         ...event.item.data,
         departmentId,
         quarter,
      };
      this.saveRock.emit(updatedRock);
   }
}
