import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Task } from '@entities/task';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { froalaConfig } from '@app/shared/config/froala.config';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { DepartmentFunction } from '@entities/department-function';
import { Department } from '@entities/department';
import { BusinessUnit } from '@entities/business-unit';

@Component({
   selector: 'app-documentation-task-report',
   templateUrl: './documentation-task-report.component.html',
   styleUrls: ['./documentation-task-report.component.scss'],
})
export class DocumentationTaskReportComponent implements OnInit, OnDestroy {
   task: Task;
   deptFn: DepartmentFunction;
   department: Department;
   businessUnit: BusinessUnit;
   orgName: string;
   logo: string;
   options = {
      ...froalaConfig,
      documentReady: true,
      toolbarButtons: ['print'],
   };

   private destroyed$ = new Subject<void>();

   constructor(
      private documentationFacade: DocumentationFacade,
      private userOrgFacade: UserOrgFacade,
      private sanitizer: DomSanitizer
   ) {}

   ngOnInit() {
      this.userOrgFacade.selectedOrg$.pipe(takeUntil(this.destroyed$)).subscribe((org) => {
         if (org) {
            this.orgName = org.name;
            this.logo = org.logo;
         }
      });
      this.documentationFacade.selectedTaskViewModel$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((viewModel) => {
            if (viewModel) {
               this.task = { ...viewModel.task };
               this.task.competencies = this.task.competencies?.replace('\n', '<br/>');
               this.deptFn = viewModel.departmentFunction;
               this.department = viewModel.department;
               this.businessUnit = viewModel.businessUnit;
            }
         });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   back() {
      history.back();
   }

   sanitize(instructions) {
      return this.sanitizer.bypassSecurityTrustHtml(instructions);
   }
}
