// Third party modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// app modules
import { SharedModule } from '@app/shared/shared.module';
import { TeamModule } from '@app/team/team.module';

// components
import { BusinessUnitEditComponent } from './components/business-unit-edit/business-unit-edit.component';
import { BusinessUnitListComponent } from './components/business-unit-list/business-unit-list.component';
import { DepartmentEditComponent } from './components/department-edit/department-edit.component';
import { DepartmentListComponent } from './components/department-list/department-list.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import { TaskAddComponent } from './components/task-add/task-add.component';
import { OrganizationComponent } from './components/organization/organization.component';
import { AssignTeamMemberComponent } from './components/assign-team-member/assign-team-member.component';
import { DepartmentFunctionEditComponent } from './components/department-function-edit/department-function-edit.component';
import { DepartmentFunctionListComponent } from './components/department-function-list/department-function-list.component';

// services
import { BusinessUnitService } from './services/business-unit.service';
import { DepartmentFunctionService } from './services/department-function.service';
import { DepartmentService } from './services/department.service';
import { TaskService } from './services/task.service';
import { OrgBuilderFacade } from './state/org-builder.facade';

// state
import { reducer } from './state/organization.reducer';
import { OrganizationEffects } from './state/organization.effects';
import { TaskAssignmentService } from './services/task-assignment.service';
import { DropTargetDirective } from './directives/drop-target.directive';
import { OrgSummaryReportComponent } from './components/org-summary-report/org-summary-report.component';
import { BusinessUnitSummaryComponent } from './components/business-unit-summary/business-unit-summary.component';
import { DepartmentSummaryComponent } from './components/department-summary/department-summary.component';
import { OrgChartComponent } from './components/org-chart/org-chart.component';

@NgModule({
   declarations: [
      BusinessUnitListComponent,
      DepartmentListComponent,
      DepartmentFunctionEditComponent,
      DepartmentFunctionListComponent,
      BusinessUnitEditComponent,
      DepartmentEditComponent,
      DepartmentFunctionEditComponent,
      TaskListComponent,
      TaskAddComponent,
      OrganizationComponent,
      AssignTeamMemberComponent,
      DropTargetDirective,
      OrgSummaryReportComponent,
      BusinessUnitSummaryComponent,
      DepartmentSummaryComponent,
      OrgChartComponent,
   ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      SharedModule,
      StoreModule.forFeature('organization', reducer),
      EffectsModule.forFeature([OrganizationEffects]),
      TeamModule,
   ],
   providers: [
      OrgBuilderFacade,
      BusinessUnitService,
      DepartmentService,
      DepartmentFunctionService,
      TaskService,
      TaskAssignmentService,
   ],
   exports: [TaskListComponent],
})
export class OrgBuilderModule {}
