import { Pipe, PipeTransform } from '@angular/core';
import { ActionItem } from '@entities/action-item';

@Pipe({
   name: 'actionItemName',
   pure: true,
})
export class ActionItemNamePipe implements PipeTransform {
   transform(value?: string, rocks: ActionItem[] = []): any {
      const actionItem = rocks.find((t) => t.id == value);
      return actionItem ? `${actionItem.name}` : '';
   }
}
