import { ActionReducer, Action, createFeatureSelector, createAction } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import * as router from '@ngrx/router-store';
import * as LogRocket from 'logrocket';
import createNgrxMiddleware from 'logrocket-ngrx';
import { adminInitialState } from './admin/state/admin.reducer';
import { authInitialState } from './auth/state/auth.reducer';
import { teamInitialState } from './team/state/team.reducer';
import { orgInitialState } from './org-builder/state/organization.reducer';
import { documentationInitialState } from './documentation/state/documentation.reducer';
import { evolveInitialState } from './evolve/state/evolve.state';
import { searchInitialState } from './search/state/search.reducer';
import { layoutInitialState } from './layout/state/layout.reducer';
import { userOrgInitialState } from './user-org/state/user-org.reducer';
import { meetingsInitialState } from './meetings/state/meetings.reducer';
import { dashboardInitialState } from './dashboard/state/dashboard.reducer';
import { internalToolsInitialState } from './internal-tools/state/internal-tools.reducer';
import { historyInitialState } from './shared/state/history.state';
import { isEmpty } from 'lodash';

// tslint:disable-next-line: no-empty-interface
export interface State {}

const logrocketMiddleware = createNgrxMiddleware(LogRocket);

export const initialState = {
   history: historyInitialState,
   admin: adminInitialState,
   auth: authInitialState,
   team: teamInitialState,
   organization: orgInitialState,
   documentation: documentationInitialState,
   evolve: evolveInitialState,
   search: searchInitialState,
   layout: layoutInitialState,
   userOrg: userOrgInitialState,
   meetings: meetingsInitialState,
   dashboards: dashboardInitialState,
   internalTools: internalToolsInitialState,
};

const checkStateValidity = (state: any, reference: any) => {
   if (typeof reference === 'object') {
      const stateValid = Object.keys(reference)
         .map((key) => {
            const referenceValue = reference[key];
            const stateValue = state?.[key];
            if (typeof referenceValue === 'object' && !isEmpty(referenceValue)) {
               return checkStateValidity(stateValue, referenceValue);
            } else if (
               (stateValue === null || stateValue === undefined) &&
               referenceValue !== null &&
               referenceValue !== undefined
            ) {
               return false;
            } else {
               return true;
            }
         })
         .reduce((previous, current) => previous && current, true);
      return stateValid;
   } else {
      return !(
         (state === null || state === undefined) &&
         reference !== null &&
         reference !== undefined
      );
   }
};

export function persistStateReducer(reducer: ActionReducer<any>) {
   return (state: any, action: Action) => {
      if (state === undefined || state === null) {
         const localStorageState = localStorage.getItem('state');
         if (localStorageState) {
            const persistedState = JSON.parse(localStorageState);
            const stateValid = checkStateValidity(persistedState, initialState);
            if (stateValid) {
               return persistedState;
            } else {
               return reducer(state, action);
            }
         } else {
            return reducer(state, action);
         }
      }
      const newState = reducer(state, action);
      // don't store router state
      const toStringify = { ...newState, router: null };
      localStorage.setItem('state', JSON.stringify(toStringify));
      return newState;
   };
}

export const navigationCancelled = createAction('[Router] Navigation cancelled');

export const devMetaReducers = [persistStateReducer, storeFreeze, logrocketMiddleware];
export const prodMetaReducers = [persistStateReducer, logrocketMiddleware];

export const getRouterState = createFeatureSelector<router.RouterReducerState>('router');
export const routerSelectors = router.getSelectors(getRouterState);

export class NoOpAction implements Action {
   public readonly type = 'NO ACTION';
}
