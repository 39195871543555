import { State } from './layout.state';
import * as LayoutActions from './layout.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { UserOrgActions } from '@app/user-org/state/user-org.actions';

export const layoutInitialState: State = {
   sidenavOpen: false,
   helpOpen: false,
   recentOrgs: [],
};

export function reducer(state: State | undefined, action: Action) {
   return createReducer(
      layoutInitialState,
      on(LayoutActions.OpenSideNav, (state) => {
         return {
            ...state,
            sidenavOpen: true,
         };
      }),
      on(LayoutActions.CloseSidenav, (state) => {
         return {
            ...state,
            sidenavOpen: false,
         };
      }),
      on(LayoutActions.ToggleSidenav, (state) => {
         return {
            ...state,
            sidenavOpen: !state.sidenavOpen,
         };
      }),
      on(LayoutActions.OpenHelp, (state) => {
         return {
            ...state,
            helpOpen: true,
         };
      }),
      on(LayoutActions.CloseHelp, (state) => {
         return {
            ...state,
            helpOpen: false,
         };
      }),
      on(LayoutActions.ToggleHelp, (state) => {
         return {
            ...state,
            helpOpen: !state.helpOpen,
         };
      }),
      on(UserOrgActions.SelectOrganization, (state, { organizationId }) => {
         const recentOrgs = state.recentOrgs
            ? [
                 organizationId,
                 ...state.recentOrgs.filter((org) => org != organizationId).slice(0, 4),
              ]
            : [organizationId];
         return {
            ...state,
            recentOrgs,
         };
      })
   )(state, action);
}
