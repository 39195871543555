import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { PerformanceFacade } from '@app/performance/state/performance.facade';
import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { DepartmentFunction } from '@entities/department-function';
import { UntypedFormGroup, UntypedFormControl, FormArray } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { PerformanceRating } from '@entities/enums/performance-rating';
import { DepartmentFunctionViewModel } from '@app/documentation/state/documentation.state';
import { TableService } from '@app/shared/services/table.service';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { RoleGuard } from '@app/admin/services/role.guard';
import { CsvService } from '@app/shared/services/csv.service';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { PerformanceFunctionDetailsComponent } from '../performance-function-details/performance-function-details.component';
import { AppAreas } from '@entities/enums/app-areas';

@Component({
   selector: 'app-performance-function-list',
   templateUrl: './performance-function-list.component.html',
   styleUrls: ['./performance-function-list.component.scss'],
})
export class PerformanceFunctionListComponent implements OnInit, SaveChangesComponent {
   @ViewChildren(PerformanceFunctionDetailsComponent)
   detailsComponents: QueryList<PerformanceFunctionDetailsComponent>;
   filterBarConfig = {
      departments: true,
      businessUnits: true,
      rating: PerformanceRating,
   };
   filterChange$: BehaviorSubject<FilterValues> = new BehaviorSubject({});
   rating = PerformanceRating;
   ratingKeys = Object.keys(PerformanceRating)
      .map((key) => +key)
      .filter((key) => !isNaN(key));

   loaded = false;
   allDepartmentFunctions: DepartmentFunctionViewModel[] = [];
   departmentFunctions: DepartmentFunctionViewModel[];
   departmentFunctions$: Observable<DepartmentFunctionViewModel[]>;
   form: UntypedFormGroup = new UntypedFormGroup({});

   canEdit$: Observable<boolean>;

   destroyed$ = new Subject<void>();

   constructor(
      private performanceFacade: PerformanceFacade,
      private tableService: TableService,
      private roleGuard: RoleGuard,
      private csvService: CsvService
   ) {}

   ngOnInit() {
      this.subscribeToDepartmentFunctions();
      this.canEdit$ = this.roleGuard.canEdit([], AppAreas.Team);
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   subscribeToDepartmentFunctions() {
      combineLatest([
         this.performanceFacade.departmentFunctions$,
         this.filterChange$.asObservable(),
      ])
         .pipe(
            takeUntil(this.destroyed$),
            map(([departmentFunctions, filter]) => {
               this.allDepartmentFunctions = departmentFunctions;
               this.setDeptFnForms();
               filter = filter || {};
               if (filter.departments && filter.departments.length > 0) {
                  departmentFunctions = departmentFunctions.filter(
                     (viewModel) =>
                        viewModel.department &&
                        filter.departments.some(
                           (department) => department.id == viewModel.department.id
                        )
                  );
               } else if (filter.businessUnits && filter.businessUnits.length > 0) {
                  departmentFunctions = departmentFunctions.filter(
                     (viewModel) =>
                        viewModel.businessUnit &&
                        filter.businessUnits.some(
                           (businessUnit) => businessUnit.id == viewModel.businessUnit.id
                        )
                  );
               }
               if (filter.rating && filter.rating.length > 0) {
                  departmentFunctions = departmentFunctions.filter(
                     (viewModel) =>
                        (viewModel.departmentFunction.rating != null &&
                           filter.rating.includes(viewModel.departmentFunction.rating)) ||
                        (viewModel.departmentFunction.rating == null &&
                           filter.rating.includes(PerformanceRating['No Value']))
                  );
               }
               return this.tableService.filter(departmentFunctions, filter.search, [
                  'departmentFunction.name',
               ]);
            })
         )
         .subscribe((deptFns) => {
            this.departmentFunctions = deptFns;
            this.loaded = true;
         });
   }

   filterFormChanged(filter: FilterValues) {
      this.filterChange$.next(filter);
   }

   setDirty(id: string, dirty: boolean) {
      const formControl = this.form.get(id);
      formControl.setValue(dirty);
      if (dirty) {
         formControl.markAsDirty();
      } else {
         formControl.markAsPristine();
      }
   }

   setDeptFnForms() {
      this.allDepartmentFunctions.forEach((viewModel) => {
         if (!this.form.get(viewModel.departmentFunction.id)) {
            this.form.addControl(viewModel.departmentFunction.id, new UntypedFormControl(false));
         }
      });
   }

   save() {
      this.saveAll();
   }

   saveAll() {
      this.detailsComponents.forEach((component) => {
         if (component.form.dirty) {
            component.save();
         }
      });
   }

   trackByFn(index, item: DepartmentFunctionViewModel) {
      return item.departmentFunction ? item.departmentFunction.id : index;
   }

   export(filterData: boolean) {
      const toExport = filterData
         ? this.mapData(this.departmentFunctions)
         : this.mapData(this.allDepartmentFunctions);
      const filename = 'Function Performance';
      this.csvService.export(toExport, filename);
   }

   mapData(data: DepartmentFunctionViewModel[]) {
      if (data) {
         return data.map((row) => {
            return {
               Function: row?.departmentFunction?.name,
               Department: row?.department?.name,
               'Business Unit': row?.businessUnit?.name,
               KPI: row?.departmentFunction?.kpi,
               Benchmark: row?.departmentFunction?.benchmark,
               Rating: PerformanceRating[row?.departmentFunction?.rating],
               Notes: row?.departmentFunction?.ratingNotes,
            };
         });
      } else {
         return [];
      }
   }
}
