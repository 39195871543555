<table mat-table [dataSource]="users">
   <ng-container matColumnDef="accountIcon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
         <mat-icon
            *ngIf="!item.teamMemberId"
            svgIcon="account-off"
            [matTooltip]="userNotLinkedMessage"
         ></mat-icon>
      </td>
   </ng-container>
   <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let item">{{ item.firstName }}</td>
   </ng-container>
   <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let item">{{ item.lastName }}</td>
   </ng-container>
   <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let item">{{ item.email }}</td>
   </ng-container>
   <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
         <button mat-icon-button [matMenuTriggerFor]="optionsMenu">
            <mat-icon>more_vert</mat-icon>
         </button>
         <mat-menu #optionsMenu="matMenu">
            <button mat-menu-item (click)="assignUser.emit(item)">{{ assignUserLabel }}</button>
         </mat-menu>
      </td>
   </ng-container>
   <tr mat-header-row *matHeaderRowDef="membersColumns"></tr>
   <tr mat-row *matRowDef="let row; columns: membersColumns"></tr>
</table>
